import { useState } from "react";
import { Box } from "@mui/material";

import useStyles from "./index.styles";

import { useAppSelector } from "../../app/hooks";
import { shortAddress, shortName, toUSDFormat } from "../../utils";
import useInfiniteScroll, {
  ScrollDirection,
} from "react-easy-infinite-scroll-hook";
import Blocky from "../Blocky";

interface TourLeaderBoardProps {
  readonly viewHistory?: (dat: object) => void;
}

export default function TourLeaderBoard(props: TourLeaderBoardProps) {
  const { classes } = useStyles();

  const { profiles } = useAppSelector((state) => state.user);
  const { entries, gameResults } = useAppSelector(
    (state) => state.marchMadness
  );
  const [page, setPage] = useState(1);

  const data = entries.slice().sort((a, b) => {
    const predictionA = a.predictions;
    const correctA = gameResults.reduce(
      (count: number, res: number, index: number) => {
        if (res.toString() === predictionA[index]) {
          return count + 1;
        }
        return count;
      },
      0
    );
    const predictionB = b.predictions;
    const correctB = gameResults.reduce(
      (count: number, res: number, index: number) => {
        if (res.toString() === predictionB[index]) {
          return count + 1;
        }
        return count;
      },
      0
    );
    if (correctA === correctB) {
      return b.ticketCount - a.ticketCount;
    }
    return correctB - correctA;
  });

  let ranking = 0;
  let previousTicket = -1;

  const next = async (direction: ScrollDirection) => {
    try {
      setPage((page: number) => page + 1);
    } finally {
    }
  };

  const ref = useInfiniteScroll({
    next,
    rowCount: data.length,
    hasMore: { down: true },
  });
  return (
    <Box className={classes.body}>
      <Box className={classes.title}>
        <Box component="img" src="/images/icons/podium.png" />
        <Box>Tournament leaderboard</Box>
      </Box>
      <Box className={classes.content}>
        <Box ref={ref} className={classes.contentHorizontal}>
          <Box className={classes.mainContent}>
            <Box>
              <Box className={classes.rowHeader}>
                <Box>POS.</Box>
                <Box>TICKET ID</Box>
                <Box>USER</Box>
                <Box>AMOUNT</Box>
                <Box>VALUE</Box>
                <Box>STATUS</Box>
                <Box>VIEW</Box>
              </Box>
            </Box>
            {data.slice(0, page * 20).map((dat: any, index) => {
              const correct = gameResults.reduce(
                (count: number, res: number, index: number) => {
                  if (res.toString() === dat.predictions[index]) {
                    return count + 1;
                  }
                  return count;
                },
                0
              );
              if (previousTicket !== correct) {
                ranking++;
                previousTicket = correct;
              }
              const profile = profiles.find(
                (profile) =>
                  profile.address.toLowerCase() === dat.player.toLowerCase()
              );

              return (
                <Box key={index} className={classes.row}>
                  <Box className={classes.rowContent}>
                    <Box>
                      {ranking}.{" "}
                      {ranking === 1 ? (
                        <Box component="img" src="/images/icons/medal.png" />
                      ) : null}
                    </Box>
                    <Box>#{dat.entryId}</Box>

                    <Box className={classes.profile}>
                      {profile && profile.avatar.length > 0 ? (
                        <img src={profile.avatar} alt="" srcSet="" />
                      ) : (
                        <Blocky
                          address={dat.player.toLowerCase()}
                          size={16}
                          scale={1}
                        />
                      )}

                      {profile ? (
                        profile.name.length > 15 ? (
                          shortName(profile.name)
                        ) : (
                          profile.name
                        )
                      ) : (
                        <span>{shortAddress(dat.player)}</span>
                      )}
                    </Box>
                    <Box>{dat.ticketCount} tickets</Box>
                    <Box>${toUSDFormat(Number(dat.usdAmount))}</Box>
                    <Box>{correct} correct</Box>
                    <Box>
                      <Box
                        onClick={() =>
                          props.viewHistory && props.viewHistory(dat)
                        }
                      >
                        VIEW
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
