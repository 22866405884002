import { MenuItem, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Dot from "../Dot";
import theme from "../../theme";

import useStyles from "./index.styles";

interface CustomDropdownItem {
  value: number;
  text: string;
  color?: string;
}
interface CustomDropdownProps {
  readonly items: CustomDropdownItem[];
  readonly value: number;
  readonly sx?: object;
  readonly setValue: (value: number) => void;
}

export default function CustomDropdown(props: CustomDropdownProps) {
  const { classes } = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    props.setValue(Number(event.target.value));
  };

  return (
    <FormControl className={classes.formControl} sx={{ ...props.sx }}>
      <Select
        value={props.value.toString()}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: theme.palette.background.dropdown,
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
      >
        {props.items.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <Box className={classes.detail}>
              <Dot
                sx={{
                  backgroundColor: item.color,
                  width: "10px",
                  height: "10px",
                }}
              ></Dot>
              {item.text}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
