import Theme from '../../theme';
import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme: any) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    gap: '20px',
    paddingBottom: '30px',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1200px',
    }
  },
  headerText: {
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    '&>div:nth-of-type(2)': {

    }
  },
  cardBody: {
    display: 'flex',
    gap: '30px',
    flexWrap: 'wrap',
  }
}));

export default styles;
