import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  formControl: {
    minWidth: 120,
    margin: 0,
    backgroundColor: theme.palette.background.dropdown,
    borderRadius: "20px",

    "& .MuiInputBase-root": {
      height: "30px",
      fontSize: "13px",

      "& .MuiSelect-select": {
        textAlign: "center",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.text.disabled,
      },
    },
  },
  detail: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
}));

export default useStyles;
