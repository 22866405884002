import { Box } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import { useEffect, useState } from "react";

import CustomProgressBar from "../CustomProgressBar";
import PredictPriceChart from "../PredictPriceChart";
import CreateVsCard from "./CreateVsCard";
import PredictBetSection from "./PredictBetSection";
import PredictStatus from "./PredictStatus";
import TakeVsCard from "./TakeVsCard";

import { useAppDispatch } from "../../app/hooks";
import { PredictRoundStatus } from "../../constants/prediction";
import { BetToken } from "../../helpers/contractFunctions";
import {
  getBetTokens,
  getTokenBalances,
  setRoundStatus,
} from "../../reducers/predict.slice";
import { CalculateFullDateByTimeStamp } from "../../utils";
import useStyles from "./index.styles";

interface PredictDetailMiddleSectionProps {
  readonly roundInfo: any;
  readonly usdAmount: number;
  readonly enteringToken: BetToken | null;
  readonly setEnteringToken: (enteringToken: BetToken) => void;
  readonly setUsdAmount: (usdAmount: number) => void;
  readonly curTime: number;
}

export default function PredictDetailMiddleSection(
  props: PredictDetailMiddleSectionProps
) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const [fakePrice, setFakePrice] = useState(0);

  useEffect(() => {
    dispatch(getBetTokens());
  }, [dispatch]);

  useEffect(() => {
    account && dispatch(getTokenBalances(account));
  }, [account, dispatch]);

  useEffect(() => {
    props.setUsdAmount(
      Math.round(
        (props.roundInfo.roundMaximumValue - props.roundInfo.currentTotalUsd) *
          100
      ) / 100
    );
  }, [props.roundInfo.roundMaximumValue, props.roundInfo.currentTotalUsd]);

  const progress = () => {
    const nowTime = new Date().getTime() / 1000;
    if (nowTime >= props.roundInfo.endTime) return 100;
    let progress =
      ((nowTime - props.roundInfo.createdTime) /
        (props.roundInfo.endTime - props.roundInfo.createdTime)) *
      100;
    if (progress) {
      progress = Math.floor(progress);
    } else {
      progress = 0;
    }
    return progress;
  };

  useEffect(() => {
    if (
      props.roundInfo.status === PredictRoundStatus.Waiting &&
      props.curTime >= props.roundInfo.expireTime &&
      props.roundInfo.expireTime
    ) {
      dispatch(setRoundStatus(PredictRoundStatus.Expired));
    }
  }, [props.curTime]);

  return (
    <Box className={classes.body}>
      <Box className={classes.title}>
        <PredictStatus
          status={props.roundInfo.status}
          curTime={props.curTime}
          endTime={props.roundInfo.endTime}
        />
        <Box>
          {props.roundInfo.status === PredictRoundStatus.Waiting ? (
            <>
              {progress()}% Progress (Expires in
              {CalculateFullDateByTimeStamp(
                props.curTime,
                props.roundInfo.expireTime || props.roundInfo.endTime
              )}{" "}
              left)
            </>
          ) : props.roundInfo.status === PredictRoundStatus.Started &&
            props.curTime < props.roundInfo.endTime ? (
            <Box component={"span"} sx={{ color: "white" }}>
              {CalculateFullDateByTimeStamp(
                props.curTime,
                props.roundInfo.endTime
              )}{" "}
              left
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Box component="img" src="/images/small-logo.png" />#
          {props.roundInfo.roundId}
        </Box>
      </Box>
      <Box className={classes.progress}>
        <CustomProgressBar progress={progress()} />
      </Box>
      <Box className={classes.chart}>
        <Box>
          <PredictPriceChart
            roundInfo={props.roundInfo}
            setFakePrice={setFakePrice}
          />
        </Box>
      </Box>
      <Box className={classes.vsCards}>
        <CreateVsCard roundInfo={props.roundInfo} fakePrice={fakePrice} />
        <Box>vs</Box>
        <TakeVsCard roundInfo={props.roundInfo} fakePrice={fakePrice} />
      </Box>
      <Box className={classes.betSection}>
        <PredictBetSection
          roundInfo={props.roundInfo}
          enteringToken={props.enteringToken}
          usdAmount={props.usdAmount}
          setEnteringToken={props.setEnteringToken}
          fakePrice={fakePrice}
        />
      </Box>
    </Box>
  );
}
