import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  body: {
    paddingLeft: "40px",
    paddingRight: "40px",
    width: "100%",
    gap: "30px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      flexDirection: "column",
      gap: "20px",
    },
  },
  headerText: {
    display: "flex",
    fontSize: "24px",
    gap: "15px",
    alignItems: "center",
    "& img": {
      width: "30px",
    },
    "& span": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },
  detailsText: {
    fontSize: "12px",
    color: theme.palette.text.disabled,
    display: "flex",
    gap: "15px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "end",
      flexDirection: "column",
      width: "100%",
    },
    "& img": {
      width: "25px",
    },
  },
  resultsPart: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  cardsBody: {
    width: "100%",
    maxHeight: "420px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '4px',
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
      border: 'solid 3px rgba(255, 0, 0, 0)',
    },
    
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: '10px',
      background: '#34344b',
    }
  },
  cardsLiveBody: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
  },
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  cardsSection: {
    display: "flex",
    width: "100%",
    gap: "30px",
    flexWrap: "wrap",
  },
  skeleton: {
    padding: "70px 0",
    width: "calc((100% - 90px) / 4)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  createBut: {
    color: "white",
    backgroundColor: "#1c1f28",
    padding: "7px 15px",
    textTransform: "none",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    "& svg": {
      color: "#23aa71",
    },
  },
  paginationBody: {
    display: "flex",
    justifyContent: "center",
  },
  noRounds: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    color: "#444E68",
  },
  butPart: {
    display: "flex",
    gap: "10px",
  },
}));

export default useStyles;
