import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import useInfiniteScroll, {
  ScrollDirection,
} from "react-easy-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";

import CircularLogo from "../../components/CircularLogo";
import CreateStakePopup from "../../components/CreateStakePopup";
import PageLoadingMov from "../../components/PageLoadingMov";
import StakeCard from "../../components/StakeCard";

import { useConnectWallet } from "@web3-onboard/react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  middleAirdropTime,
  pageLoadingTime,
  predictAirdropTime,
} from "../../constants";
import {
  getAirdropInfo,
  getIsFinishedList,
  getStakes,
  getSupplyList,
  Supply,
  unstake,
} from "../../reducers/airdrop.slice";
import { shortAddress, shortName, toUSDFormat } from "../../utils";
import { getCurrentAirdropForStake } from "../../utils/airdrop";
import useStyles from "./index.styles";

const getRemaingTime = (time: number) => {
  const remaingSecs = predictAirdropTime.getTime() / 1000 - time;
  const oneDay = 3600 * 24;
  const oneHour = 3600;
  const oneMinute = 60;
  const day = Math.floor(remaingSecs / oneDay);
  const hour = Math.floor((remaingSecs % oneDay) / oneHour);
  const min = Math.floor((remaingSecs % oneHour) / oneMinute);
  const sec = Math.floor(remaingSecs % oneMinute);
  if (day > 0) {
    return `${day} day${day > 1 ? "s" : ""} and ${hour} hour${
      hour > 1 ? "s" : ""
    }`;
  } else if (hour > 0) {
    return `${hour} hour${hour > 1 ? "s" : ""} and ${min} min${
      min > 1 ? "s" : ""
    }`;
  } else if (min > 0) {
    return `${min} min${min > 1 ? "s" : ""} and ${sec} sec${
      sec > 1 ? "s" : ""
    }`;
  } else {
    return `${sec} second${sec > 1 ? "s" : ""}`;
  }
};

export default function Airdrop() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const {
    info,
    stakingList,
    supplyList,
    beforeSupplyList,
    totalAmount,
    isLoadingSupplyList,
    isFinishedStakingList,
  } = useAppSelector((state) => state.airdrop);
  const [{ wallet }] = useConnectWallet();
  const { profiles } = useAppSelector((state) => state.user);

  const [time, setTime] = useState(Date.now() / 1000);
  const [isStakePopup, setIsStakePopup] = useState(false);
  const [leaderBoardPage, setLeaderBoardPage] = useState(1);
  const [myRank, setMyRank] = useState(-1);
  const [myBeforeRank, setMyBeforeRank] = useState(-1);
  const [loading, setLoading] = useState(true);
  const account = wallet ? wallet.accounts[0].address : "";
  const currentTotalStakeAirdrop = stakingList.reduce(
    (total, item) =>
      total +
      getCurrentAirdropForStake(middleAirdropTime.getTime() / 1000, item),
    0
  );
  const totalAirdropAmount =
    info.activity + info.nft + info.sacrifice + currentTotalStakeAirdrop;
  const remainingMaxStake =
    1000 - stakingList.reduce((total, item) => total + item.amount, 0);

  const next = async (direction: ScrollDirection) => {
    try {
      setLeaderBoardPage((page: number) => page + 1);
    } finally {
    }
  };

  const ref = useInfiniteScroll({
    next,
    rowCount: supplyList?.length,
    hasMore: { down: true },
  });

  const handleCloseStakePopup = (): void => {
    setIsStakePopup(false);
  };

  const handleUnstaking = (stakeId: number) => {
    dispatch(unstake({ stakeId, account }))
      .unwrap()
      .then(() => {
        setTimeout(() => {
          dispatch(getAirdropInfo(account));
          dispatch(getStakes(account));
        }, 500);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, pageLoadingTime);
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    dispatch(getAirdropInfo(account));
    dispatch(getStakes(account));
  }, [wallet, dispatch, account]);

  useEffect(() => {
    if (wallet) {
      const myRank: number = supplyList.findIndex(
        (item: Supply) =>
          item.address.toLowerCase() ===
          wallet.accounts[0].address.toLowerCase()
      );
      setMyRank(myRank === -1 ? supplyList.length + 1 : myRank + 1);
      const myBeforeRank: number = beforeSupplyList.findIndex(
        (item: Supply) =>
          item.address.toLowerCase() ===
          wallet.accounts[0].address.toLowerCase()
      );
      setMyBeforeRank(
        myRank === -1 ? beforeSupplyList.length + 1 : myBeforeRank + 1
      );
    } else {
      setMyRank(-1);
    }
  }, [wallet, supplyList, beforeSupplyList]);

  useEffect(() => {
    dispatch(getSupplyList());
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setInterval(() => setTime(Date.now() / 1000), 1000);
    }
  }, [loading]);

  useEffect(() => {
    if (stakingList.length > 0) {
      dispatch(getIsFinishedList({ stakingList }));
    }
  }, [stakingList]);

  return loading ? (
    <PageLoadingMov />
  ) : (
    <Box className={classes.body}>
      <CreateStakePopup
        open={isStakePopup}
        closeStakePopup={handleCloseStakePopup}
      />
      {/* ----left-section---- */}
      <Box className={classes.leftSection}>
        <Box className={classes.leftTitle}>
          <Box component="img" src="/images/icons/get-money.png" />
          <Box>Boost your airdrop</Box>
        </Box>
        <Box className={classes.earnActivity}>
          <Box>Earn with activity</Box>
          <Box>
            100 $PREDICT per 1 USD played from Price Predictions or Sport Events
          </Box>
          <Button onClick={() => navigate("/earn")}>Earn now!</Button>
        </Box>
        <Box className={classes.earnActivity}>
          <Box>Stake list</Box>
          <Box className={classes.stakeList}>
            <Box
              className={classes.stakeListHeader + " " + classes.stakeDetail}
            >
              <Box>Your stakes</Box>
              <Box></Box>
              <Box>Max. {toUSDFormat(remainingMaxStake)} PLSP</Box>
            </Box>
            <Box className={classes.stakeListBody}>
              {stakingList.length === isFinishedStakingList.length
                ? stakingList.map((item, index) =>
                    !isFinishedStakingList[index] ? (
                      <Box className={classes.stakeItem} key={index}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component="img"
                            src="/images/tokens/plsp_round.png"
                          />
                          {toUSDFormat(item.amount)} PLSP
                        </Box>
                        {Date.now() / 1000 - 3600 * 24 * 90 - item.timestamp >
                        0 ? (
                          <Button onClick={() => handleUnstaking(item.id)}>
                            Unstake
                          </Button>
                        ) : (
                          <Button disabled>Unstake</Button>
                        )}

                        <Box>
                          <CircularLogo width={30} />
                          {toUSDFormat(item.amount * 500)}
                        </Box>
                      </Box>
                    ) : null
                  )
                : null}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* ----middle section ---- */}
      <Box className={classes.middleSection}>
        <CircularLogo width={65} />
        <Box>$PREDICT</Box>
        <Box>{getRemaingTime(time)}</Box>
        <Box>Your token earnings</Box>
        <StakeCard
          text="Sacrifices"
          value={info.sacrifice}
          sx={{ marginTop: "20px" }}
        />
        <StakeCard
          text="Activity"
          value={info.activity}
          sx={{ marginTop: "10px" }}
        />
        <StakeCard
          text="Staking"
          value={currentTotalStakeAirdrop}
          sx={{ marginTop: "10px" }}
        />
        <StakeCard text="NFT's" value={info.nft} sx={{ marginTop: "10px" }} />
        <Button
          disabled
          sx={{
            minWidth: "300px",
            ":disabled": {
              color: "#2b2c3b",
            },
          }}
        >
          <Box component="span">Claim {toUSDFormat(totalAirdropAmount, 2)}</Box>
          <Box component="img" src="/images/logo.png" />
        </Button>
      </Box>
      {/* ----right section ---- */}
      <Box className={classes.rightSection}>
        <Box></Box>
        <Box className={classes.rightTitle}>
          <Box>
            <Box component="img" src="/images/icons/pie-chart.png" />
            <Box>Total supply</Box>
          </Box>
          <Box>{toUSDFormat(totalAmount, 0)}</Box>
        </Box>
        <Box className={classes.leaderboard}>
          {myRank !== -1 ? (
            <Box className={classes.leaderboardCard}>
              <Box>
                <Box component="span">You are in&nbsp;</Box> Top {myRank}{" "}
                <Box component="span">&nbsp;holders of $PREDICT</Box>
              </Box>
              {myRank <= myBeforeRank ? (
                <Box className={classes.rankUp}>
                  +{myBeforeRank - myRank} positions today!
                </Box>
              ) : (
                <Box className={classes.rankDown}>
                  -{myRank - myBeforeRank} positions today!
                </Box>
              )}
            </Box>
          ) : null}
          <Box
            className={
              classes.leaderboardDetail + " " + classes.leaderboardHeader
            }
          >
            <Box>Pos.</Box>
            <Box>Wallet</Box>
            <Box>%</Box>
            <Box>Tokens</Box>
          </Box>
          <Box
            ref={ref}
            className={classes.leaderboardBody}
            id="leaderboardBody"
          >
            {isLoadingSupplyList ? (
              <Box className={classes.loading}>Loading...</Box>
            ) : (
              <Box>
                {supplyList
                  ?.slice(0, 20 * leaderBoardPage)
                  .map((data: Supply, index) => {
                    const profile = profiles.find(
                      (profile) =>
                        profile.address.toLowerCase() ===
                        data.address.toLowerCase()
                    );
                    return (
                      <Box
                        className={
                          classes.leaderboardDetail +
                          " " +
                          classes.leaderboardList
                        }
                        key={index}
                      >
                        <Box>{index + 1}.</Box>
                        <Box>
                          {profile ? (
                            profile.name.length > 15 ? (
                              shortName(profile.name)
                            ) : (
                              profile.name
                            )
                          ) : (
                            <span>{shortAddress(data.address)}</span>
                          )}
                        </Box>
                        <Box>
                          {((data.amount / totalAmount) * 100).toFixed(3)}%
                        </Box>
                        <Box>{toUSDFormat(data.amount, 0)}</Box>
                      </Box>
                    );
                  })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
