import { Box, BoxProps, Skeleton } from "@mui/material";

import TokenImage from "../TokenImage";
import Dot from "../Dot";

import {
  calculateDaysDifference,
  calculateDaysDifferenceByTimeStamp,
} from "../../utils";

import useStyles from "./index.styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface EventsCardProps {
  readonly matchDay: string;
  readonly status: string;
  readonly image: string;
  readonly url?: string;
  readonly matchDayFormat?: string;
  readonly matchDayTimeStamp?: number;
  readonly sx?: object;
}

export default function PredictionCard(props: EventsCardProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image loading
  return (
    <Box
      className={classes.mainBody}
      sx={{ ...props.sx, cursor: "pointer" }}
      onClick={() => {
        if (props.url) {
          navigate(`/events/${props.url}`);
        }
      }}
    >
      {props.matchDayTimeStamp ? (
        calculateDaysDifferenceByTimeStamp(
          Math.floor(new Date().getTime() / 1000),
          props.matchDayTimeStamp
        ) === "-1" ? (
          <Box className={classes.title}>
            <Dot
              sx={{
                backgroundColor: "#ff1b1b",
                width: "10px",
                height: "10px",
                boxShadow:
                  "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24;",
              }}
            />
            <Box>Live now!</Box>
          </Box>
        ) : (
          <Box className={classes.title}>
            {props.url ? (
              <>
                <Dot
                  sx={{
                    backgroundColor: "#37d344",
                    width: "10px",
                    height: "10px",
                    boxShadow:
                      "0 0 8px #1c6d23, 0 0 16px #1c6d23, 0 0 24px #1c6d23, 0 0 56px #1c6d23;",
                  }}
                />
                Bet Opened
              </>
            ) : null}{" "}
            {calculateDaysDifferenceByTimeStamp(
              new Date().getTime() / 1000,
              props.matchDayTimeStamp
            )}{" "}
            left
          </Box>
        )
      ) : props.matchDayFormat ? (
        calculateDaysDifference(new Date(), new Date(props.matchDayFormat)) <=
        0 ? (
          <Box className={classes.title}>
            <Dot
              sx={{
                backgroundColor: "#ff1b1b",
                width: "10px",
                height: "10px",
                boxShadow:
                  "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24;",
              }}
            />
            <Box>Live now!</Box>
          </Box>
        ) : (
          <Box className={classes.title}>
            {props.url ? (
              <>
                <Dot
                  sx={{
                    backgroundColor: "#37d344",
                    width: "10px",
                    height: "10px",
                    boxShadow:
                      "0 0 8px #1c6d23, 0 0 16px #1c6d23, 0 0 24px #1c6d23, 0 0 56px #1c6d23;",
                  }}
                />
                Bet Opened
              </>
            ) : null}{" "}
            {props.matchDayFormat &&
              calculateDaysDifference(
                new Date(),
                new Date(props.matchDayFormat)
              )}{" "}
            days left
          </Box>
        )
      ) : null}
      <Box className={classes.body}>
        {!imageLoaded && (
          <Skeleton
            variant="rounded"
            width={230}
            height={127}
            animation="wave"
          />
        )}
        <Box
          component="img"
          className={classes.logo}
          src={"images/events/" + props.image}
          onLoad={() => setImageLoaded(true)}
          style={{ display: imageLoaded ? "block" : "none" }}
        />
        <Box className={classes.footer}>
          <Box>{props.matchDay}</Box>
          <Box className={props.status}>{props.status}</Box>
        </Box>
      </Box>
    </Box>
  );
}
