import Web3 from "web3";
import { ethers } from "ethers";
import { gameConfig } from "../../config";
import ERC20Abi from "../../abis/erc20.json";
import { WalletState } from "@web3-onboard/core";

export const getWeb3 = () => new Web3(gameConfig.providerList[0]);
export const getProvider = () => new Web3((window as any).provider);

export interface BetToken {
  id: number;
  name: string;
  decimal: number;
  tokenAddress: string;
  price: number;
  isEnabled: boolean;
  isStable: boolean;
}

export const getTokenBalance = async (
  tokenAddress: string,
  userAddress: string
) => {
  const web3 = getWeb3();
  const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAddress);
  let balance: any, decimal: string;

  if (tokenAddress === ethers.constants.AddressZero) {
    balance = await web3.eth.getBalance(userAddress);
    return ethers.utils.formatEther(balance);
  } else {
    balance = await tokenContract.methods.balanceOf(userAddress).call();
    decimal = await tokenContract.methods.decimals().call();
    return ethers.utils.formatUnits(balance, decimal);
  }
};

export const getTokenAllowance = async (
  tokenAddress: string,
  userAddress: string,
  contractAddress: string
) => {
  const web3 = getWeb3();
  const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAddress);
  const allowance: string = await tokenContract.methods
    .allowance(userAddress, contractAddress)
    .call();
  const decimal: string = await tokenContract.methods.decimals().call();

  return ethers.utils.formatUnits(allowance, decimal);
};

export const getBetTokens = async (contract: any) => {
  const result = await contract.methods.getBetTokens().call();
  const tokens: any[] = result[0];
  const prices: string[] = result[1];
  return tokens
    .map(
      (item, index) =>
        ({
          id: index,
          name: item.name,
          decimal: Number(item.decimal),
          tokenAddress: item.tokenAddress,
          price: Number(ethers.utils.formatEther(prices[index])),
          isEnabled: item.isEnabled,
          isStable: item.isStable,
        } as BetToken)
    )
    .filter((item: any) => item.isEnabled);
};

export const approveToken = async (
  tokenAddress: string,
  amount: number,
  to: string,
  account: string
) => {
  const web3 = getProvider();
  const tokenContract = new web3.eth.Contract(ERC20Abi, tokenAddress);
  const allowanceBN = (await tokenContract.methods
    .allowance(account, to)
    .call()) as string;
  const decimals = Number(await tokenContract.methods.decimals().call());
  const allowance = Number(ethers.utils.formatUnits(allowanceBN, decimals));
  if (allowance < amount) {
    await tokenContract.methods
      .approve(
        to,
        ethers.utils.parseUnits(amount.toFixed(decimals), decimals).toString()
      )
      .send({ from: account });
  }
};

export const signMessage = async (message: any, wallet: WalletState) => {
  const provider = wallet.provider;

  const ethersProvider = new ethers.providers.Web3Provider(provider, "any");

  const signer = ethersProvider.getSigner();

  return await signer.signMessage(JSON.stringify(message));
};
