const acceptedTokens = [
  {
    symbol: "PLS",
    price: 10000000000,
    name: "PLS",
    address: "0x0000000000000000000000000000000000000000",
    decimal: 18,
  },
  {
    symbol: "WPLS",
    price: 10000000000,
    name: "WPLS",
    address: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    decimal: 18,
  },
  {
    symbol: "HEX",
    price: 10000000000,
    name: "HEX",
    address: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
    decimal: 8,
  },
  {
    symbol: "PLSX",
    price: 10000000000,
    name: "PLSX",
    address: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
    decimal: 18,
  },
  {
    symbol: "INC",
    price: 10000000000,
    name: "INC",
    address: "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
    decimal: 18,
  },
  {
    symbol: "eUSDT",
    price: 10000000000,
    name: "eUSDT",
    address: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
    decimal: 6,
  },
  {
    symbol: "eDAI",
    price: 10000000000,
    name: "eDAI",
    address: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
    decimal: 18,
  },
  {
    symbol: "eUSDC",
    price: 10000000000,
    name: "eUSDC",
    address: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    decimal: 6,
  },
  {
    symbol: "USDL",
    price: 10000000000,
    name: "USDL",
    address: "0x0dEEd1486bc52aA0d3E6f8849cEC5adD6598A162",
    decimal: 18,
  },
  {
    symbol: "WATT",
    price: 10000000000,
    name: "WATT",
    address: "0xDfdc2836FD2E63Bba9f0eE07901aD465Bff4DE71",
    decimal: 18,
  },
  {
    symbol: "eETH",
    price: 10000000000,
    name: "eETH",
    address: "0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C",
    decimal: 18,
  },
  {
    symbol: "9INCH",
    price: 10000000000,
    name: "9INCH",
    address: "0x3ca80d83277e721171284667829c686527B8b3c5",
    decimal: 18,
  },
  {
    symbol: "MINT",
    price: 10000000000,
    name: "MINT",
    address: "0x207e6b4529840A4fd518f73c68bc9c19B2A15944",
    decimal: 18,
  },
  {
    symbol: "LOAN",
    price: 10000000000,
    name: "LOAN",
    address: "0x9159f1D2a9f51998Fc9Ab03fbd8f265ab14A1b3B",
    decimal: 18,
  },
  {
    symbol: "ATROPA",
    price: 10000000000,
    name: "ATROPA",
    address: "0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6",
    decimal: 18,
  },
  {
    symbol: "pDAI",
    price: 10000000000,
    name: "pDAI",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    decimal: 18,
  },
  {
    symbol: "CST",
    price: 10000000000,
    name: "CST",
    address: "0x600136dA8cc6D1Ea07449514604dc4ab7098dB82",
    decimal: 6,
  },
  {
    symbol: "SPARK",
    price: 10000000000,
    name: "SPARK",
    address: "0x6386704cd6f7a584ea9d23ccca66af7eba5a727e",
    decimal: 18,
  },
  {
    symbol: "PHUX",
    price: 10000000000,
    name: "PHUX",
    address: "0x9663c2d75ffd5f4017310405fce61720af45b829",
    decimal: 18,
  },
  {
    symbol: "PHAME",
    price: 10000000000,
    name: "PHAME",
    address: "0x8854bc985fb5725f872c8856bea11b917caeb2fe",
    decimal: 18,
  },
  {
    symbol: "TYRH",
    price: 10000000000,
    name: "TYRH",
    address: "0xc91562626B9a697af683555dA9946986278Ac9a5",
    decimal: 18,
  },
  {
    symbol: "PLSP",
    price: 10000000000,
    name: "PLSP",
    address: "0xC52F739f544d20725BA7aD47Bb42299034F06f4F",
    decimal: 18,
  },
  {
    symbol: "ZKZX",
    price: 10000000000,
    name: "ZKZX",
    address: "0x319e55Be473C77C35316651995C048a415219604",
    decimal: 18,
  },
  {
    symbol: "NOPE",
    price: 10000000000,
    name: "NOPE",
    address: "0x8D36123903f504eB81eEB832727af517c0db26bD",
    decimal: 18,
  },
  {
    symbol: "TEDDY BEAR",
    price: 10000000000,
    name: "TEDDY BEAR",
    address: "0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d",
    decimal: 18,
  },
  // { symbol: "WPLS", price: 10000000000, name: "WPLS", address: "0x82A0B644945c06d5B4832A04a113809e772Fe321", decimal: 18 },
];

export { acceptedTokens };
