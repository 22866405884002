import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.background.predictionCard,
    padding: "30px 20px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    height: "100%",
    flexDirection: "column",
  },
  token: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  tokenText: {
    fontWeight: "700",
    fontSize: "26px",
  },
  usdText: {
    backgroundColor: theme.palette.background.usdCard,
    marginTop: "10px",
    padding: "3px 10px",
    borderRadius: "5px",
  },
  timeText: {
    fontSize: "14px",
    marginTop: "15px",
    "& div": {
      display: "flex",
      justifyContent: "center",
    },
  },
  largeText: {
    fontSize: "15px",
  },
  remainTimeText: {
    marginTop: "30px",
    fontSize: "12px",
    color: theme.palette.text.disabled,
  },
  creatorAddress: {
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    gap: "15px",
    marginTop: "20px",
    alignItems: "center",
    "&>div:first-of-type": {
      color: theme.palette.text.disabled,
    },
  },
}));

export default styles;
