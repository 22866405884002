import { Box, Button } from "@mui/material";
import { ethers } from "ethers";

import Spinner from "../Spinner";
import TokenImage from "../TokenImage";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils";
import useStyles from "./index.styles";
import { BetToken } from "../../helpers/contractFunctions";

const exceptTokens = ["TYRH", "ZKZX"];

interface TokenListPopupProps {
  handleSelectToken: (
    tokenName: string,
    tokenBalance: number,
    tokenUSDValue: number
  ) => void;
}

export default function TokenListPopup(props: TokenListPopupProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { handleSelectToken } = props;
  const { betTokens, tokenBalances, betTokensLoading, loadingTokenBalances } =
    useAppSelector((state) => state.ufc);

  const renderTokens = () => {
    // Inside the component function
    // First, calculate the USD value for each token and store it in a new array
    const tokensWithUsdValue = betTokens.map(
      (token: BetToken, index: number) => {
        const balance = parseFloat(tokenBalances[token.name] || "0");
        const usdValue = balance * token.price;
        return { ...token, usdValue, balance, tokenId: index }; // Include the token details along with its USD value
      }
    );

    // Then, sort the tokens based on their USD values
    tokensWithUsdValue.sort((a: any, b: any) => b.usdValue - a.usdValue);

    return tokensWithUsdValue.map((token: any, _: any) => {
      const balance = token.balance;
      const usdValue = token.usdValue;
      const index = token.tokenId;

      return exceptTokens.indexOf(token.name) == -1 ? (
        <Box className="token-list-item " key={index}>
          <Box className="token-list-item-coin">
            <TokenImage token={token.name} />
            <span className="token-list-item-name">
              <>{token.name}</>
            </span>
          </Box>

          <Box className="token-list-item-balance">
            <span>{balance.toFixed(4)}</span>
            <span>$ {toUSDFormat(usdValue, 4)}</span>
          </Box>

          <Box className="token-list-item-use">
            <Button
              onClick={() => handleSelectToken(token.name, balance, usdValue)}
            >
              Select
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      );
    });
  };

  return (
    <Box className={classes.tokenListPopup}>
      <Box className="token-list-header">
        <span>Asset</span>
        <span>Balance</span>
        <span>Use</span>
      </Box>
      <Box className="token-list-items">
        {betTokensLoading || loadingTokenBalances ? (
          <Box className="token-list-loading">
            <Spinner />
          </Box>
        ) : betTokens ? (
          renderTokens()
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
