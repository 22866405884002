import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  euroMyBetPanel: {
    borderRadius: 16,
    backgroundColor: "#1b1f28",
    padding: 16,
    maxHeight: 480,
    minWidth: 330,
    maxWidth: 330,
    [theme.breakpoints.down("md")]: {
      order: 2,
      maxWidth: "none",
      width: "100%",
    },
    // margin: "64px 0",
  },

  header: {
    fontSize: 20,
    textAlign: "center",
    padding: "8px 0",
    fontWeight: "900",
  },

  betList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 520,
    overflowY: "scroll",
  },

  betListItem: {
    fontSize: 14,
    padding: "8px 8px",
    display: "grid",
    gridTemplateColumns: "1fr 5fr 2.5fr 2.5fr 3fr",
    borderBottom: "1px solid #222630",
    alignItems: "center",
  },

  matchCountryFlag: {
    width: "36px!important",
    height: "20px!important",
    objectFit: "cover",
  },

  resultWin: {
    color: "#179449 ",
  },

  reward: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  resultLose: {
    color: "#9e3718",
  },

  address: {},
}));

export default styles;
