import { Box } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import { useEffect, useState } from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import PageLoadingMov from "../../components/PageLoadingMov";
import UfcDisclaimerModal from "../../components/UfcDisclaimerModal";
import UfcEntryPanel from "../../components/UfcEntryPanel";
import UfcHeader from "../../components/UfcHeader";
// import NightFightRoundInfo from "../../components/NightFightRoundInfo";
import UFCRoundInfo from "../../components/UfcRoundInfo";
import UFCRoundSwitch from "../../components/UfcRoundSwitch";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { pageLoadingTime } from "../../constants";
import useStyles from "./index.styles";

import {
  getBetTokensList,
  getRoundStatus,
  getRoundInfo,
  getRoundInfoById,
  getTokenBalances,
  setShowDisclaimer,
  setShowBetCompleteModal,
} from "../../reducers/ufc.slice";
import { FCEventType, UFC_EVENTS } from "../../constants/ufc";
import {
  formatUFCEnteredRoundInfo,
  formatUFCRoundCancelledInfo,
  formatUFCRoundFinishedInfo,
} from "../../utils/ufc";
import { gameConfig } from "../../config";
import UfcBetCompletePopup from "../../components/UfcBetCompletePopup";
import ChatRoom from "../../components/ChatBox";

interface EventsUFCProps {
  eventType: number;
}

function getServer(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
      return gameConfig.serverUrl;
    case FCEventType.UFC299:
      return gameConfig.serverUrl;
    case FCEventType.UFC300:
      return gameConfig.serverUrl;
    case FCEventType.DAZN:
      return gameConfig.serverUrl;
    case FCEventType.ELECTION:
      return gameConfig.serverUrl;
  }

  return "";
}

function getSSEEndpoint(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
      return "event/ifc";
    case FCEventType.UFC299:
      return "event/karate";
    case FCEventType.UFC300:
      return "event/ufc";
    case FCEventType.DAZN:
      return "event/dazn";
    case FCEventType.ELECTION:
      return "event/ufc";
  }
}

function getRoundId(roundId: number, eventType: number) {
  if (eventType === FCEventType.IFC) return roundId;
  else if (eventType === FCEventType.DAZN) return 3 - roundId;
  else if (eventType === FCEventType.ELECTION) return 1 - roundId;
  else return 5 - roundId;
}

export default function EventsUFC(props: EventsUFCProps) {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { eventType } = props;

  const [{ wallet }] = useConnectWallet();

  const {
    currentRound,
    showDisclaimerModal,
    lastBettedUsdAmount,
    showBetCompleteModal,
  } = useAppSelector((state) => state.ufc);

  const account = wallet ? wallet.accounts[0].address : "";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getBetTokensList(eventType));
  }, [dispatch, eventType]);

  useEffect(() => {
    if (!account) return;

    dispatch(getTokenBalances(account));
  }, [account, dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, pageLoadingTime);
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    dispatch(getRoundInfo({ eventType }));
    dispatch(getRoundInfoById({ eventType, roundId: currentRound }));
    dispatch(getRoundStatus({ eventType, roundId: currentRound }));
  }, [currentRound, dispatch, eventType]);

  useEffect(() => {
    const setupEventSource = () => {
      const eventSource = new EventSource(
        `${getServer(eventType)}${getSSEEndpoint(eventType)}`
      );
      eventSource.onmessage = async (e) => {
        console.log("UFC SSE connected.");

        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);
          console.log("UFC Event", res);

          if (res.eventName === UFC_EVENTS.EnteredRound) {
            const enteredRoundInfo = formatUFCEnteredRoundInfo(res);
            if (
              getRoundId(currentRound, eventType) === enteredRoundInfo.roundId
            ) {
              dispatch(getRoundInfo({ eventType }));
              dispatch(getRoundInfoById({ eventType, roundId: currentRound }));
            }
          } else if (res.eventName === UFC_EVENTS.RoundFinished) {
            const finishedRoundInfo = formatUFCRoundFinishedInfo(res);

            if (
              getRoundId(currentRound, eventType) === finishedRoundInfo.roundId
            ) {
              dispatch(getRoundStatus({ eventType, roundId: currentRound }));
              dispatch(getRoundInfo({ eventType }));
              dispatch(getRoundInfoById({ eventType, roundId: currentRound }));
            }
          } else if (res.eventName === UFC_EVENTS.RoundCancelled) {
            const cancelledRoundInfo = formatUFCRoundCancelledInfo(res);
            if (
              getRoundId(currentRound, eventType) === cancelledRoundInfo.roundId
            ) {
              dispatch(getRoundStatus({ eventType, roundId: currentRound }));
            }
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();

    return () => {
      eventSource.close();
    };
  }, [dispatch, currentRound, eventType]);

  return loading ? (
    <PageLoadingMov />
  ) : (
    <Box
      className={
        eventType === FCEventType.ELECTION ? classes.election : classes.ufc
      }
    >
      <Box>
        {eventType !== FCEventType.ELECTION && (
          <UFCRoundSwitch eventType={eventType} />
        )}
        <UfcHeader eventType={eventType} />
        <UFCRoundInfo eventType={eventType} />
        <UfcEntryPanel />
      </Box>

      {eventType === FCEventType.ELECTION && (
        <Box className={classes.ufcRightCard}>
          <LiteYouTubeEmbed
            id="qQYMi2Dwt-Y"
            title="Final sprint to the 2024 presidential election"
          />

          <ChatRoom />
        </Box>
      )}

      {showDisclaimerModal && (
        <Box>
          <UfcDisclaimerModal
            eventType={eventType}
            onClose={() => {
              dispatch(setShowDisclaimer(false));
            }}
          ></UfcDisclaimerModal>
        </Box>
      )}

      {showBetCompleteModal && (
        <UfcBetCompletePopup
          open={showBetCompleteModal}
          onClose={() => {
            dispatch(setShowBetCompleteModal(false));
          }}
          onCreateBet={() => {
            dispatch(setShowBetCompleteModal(false));
          }}
          betUsdAmount={lastBettedUsdAmount}
        ></UfcBetCompletePopup>
      )}
    </Box>
  );
}
