import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  header: {
    display: "flex",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    borderBottom: "1px solid #222630",
    paddingBottom: "20px",

    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "32px",
    gap: "20px",

    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  inputBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    gap: 8,
    color: "#ff3e3e",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    justifyContent: "center",
    "& img": {
      width: "25px",
    },
    "&>div:first-of-type": {
      width: "100%",
      maxWidth: "280px",
      height: "50px",
      display: "flex",
      gap: "15px",
      backgroundColor: "#0f1117",
      borderRadius: "10px",
      padding: "15px 15px",
      color: "white",
      textTransform: "none",
      fontSize: "17px",
      justifyContent: "left",
      alignItems: "center",
    },

    "& fieldset": {
      outline: "none!important",
      border: "none",
    },

    "&>div>div": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      fontSize: 14,

      "& img": { width: 20, height: 20 },
    },

    "& svg": {
      color: "white",
    },
  },

  selectMenuItem: {
    padding: "6px 8px 6px 16px",
    display: "grid",
    gridTemplateColumns: "1fr 4fr 3fr 3fr",
    fontSize: 12,

    "& img": {
      width: 18,
      height: 18,
    },
  },

  confirmButton: {
    maxWidth: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "&>button": {
      color: "white",
      backgroundColor: "#21ac74",
      maxWidth: "120px",
      minWidth: "120px",
      height: "50px",
      borderRadius: "10px",
      padding: "0 30px",
      textTransform: "none",
      ":disabled": {
        backgroundColor: theme.palette.background.lightBack,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },

  descriptionBox: {
    display: "grid",
    width: "80%",
    gap: "50px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& img": {
      width: "25px",
    },
    "&>div:first-of-type": {
      maxWidth: "250px",
      width: "100%",
      height: "50px",
      display: "flex",
      gap: "15px",
      backgroundColor: "#0f1117",
      borderRadius: "10px",
      padding: "15px 15px",
      color: "white",
      textTransform: "none",
      fontSize: "17px",
      justifyContent: "left",
      marginTop: "10px",
      alignItems: "center",
    },
    "&>div:last-of-type": {
      maxWidth: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
  },

  matchCountryFlag: {
    width: "32px !important",
    objectFit: "cover",
    marginLeft: 8,
  },
}));

export default styles;
