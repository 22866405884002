export const MarchMadnessRoundStatus = {
  Waiting: 0,
  Started: 1,
  Closed: 2,
  Finishing: 3,
  Fished: 4,
};

export const madnessStatus = {
  waiting: 0,
  enable: 1,
  winner: 2,
  loser: 3,
};

export const madnessPlay = {
  round16: 1,
  quarter: 2,
  semi: 3,
  final: 4,
  winner: 5,
};

export const MoneyLineStatus = {
  Favorite: 0,
  UnderDog: 1,
};

export const NotificationType = {
  PredictionRoundStarted: "PREDICTION_ROUND_STARTED",
};

export const globalRankWeight = {
  Airdrop: 15,
  WinRate: 15,
  ProfitLoss: 40,
  TotalBets: 30,
};

export const ProfileRankGrade = {
  Great: "green",
  Middle: "yellow",
  Bad: "red",
};

export const dailyRankBonus = [
  150000, 120000, 100000, 75000, 50000, 25000, 25000, 25000, 25000, 25000,
  15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 15000, 10000,
  10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
  10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
  10000, 10000, 10000, 10000, 10000, 10000, 10000,
];

export const middleAirdropTime = new Date("2024-06-26T00:00:00.000Z");
export const predictAirdropTime = new Date("2024-10-26T00:00:00.000Z");
export const pageLoadingTime = 1000;
export const walletDelayTime = 15 * 1000;
export const deadAddress = "0x000000000000000000000000000000000000dead";
