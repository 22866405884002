import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: any) => ({
  body: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  oddBoxes: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
      overflowX: "auto",
      padding: "0 20px",
      justifyContent: "normal",
      "&::-webkit-scrollbar": {
        height: "4px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px grey",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "silver",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#7e889e",
        },
      },
    },
  },
  oddUnderBox: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    maxWidth: "150px",
    minWidth: "150px",
    width: "150px",
  },
  oddUpBox: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    maxWidth: "150px",
    minWidth: "150px",
    width: "150px",
  },
  selectedIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "45px",
    "&>img": {
      width: "25px",
    },
  },
  mainOddBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    transition: "0.3s",
  },
  selectOddBox: {
    transition: "0.3s",
    boxShadow: "inset 20px 0px 20px 8px #00000033, inset 10px 0px 10px #140b0b",
  },
  selectArrow: {
    height: "40px",
    "&>img": {
      width: "30px",
    },
  },
  oddPart: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    gap: "10px",
    width: "100%",
    padding: "10px",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
    "&>div:nth-of-type(1)": {
      fontSize: "12px",
    },
    "&>div:nth-of-type(2)": {
      fontSize: "28px",
    },
    "&>div:nth-of-type(3)": {
      fontSize: "14px",
      color: "black",
    },
  },
  mainUnderOddPart: {
    backgroundColor: "rgb(251,169,128)",
  },
  mainOverOddPart: {
    backgroundColor: "#18a86b",
  },
  oddText: {
    fontSize: "30px",
    fontWeight: "bold",
  },
  oddSliderPart: {
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down("md")]: {},
  },
  oddSlider: {
    "& .MuiSlider-rail": {
      height: "10px",
      backgroundColor: "#523657",
      opacity: 1,
    },
    "& .MuiSlider-track": {
      height: "10px",
      backgroundColor: "#523657",
      border: "none",
    },
    "& .MuiSlider-thumb": {
      width: 30,
      height: 30,
      backgroundColor: "#cbc0cd",
      boxShadow: "0px 0px 0px 8px rgba(156, 39, 176, 0.16)",
    },
  },
  takerBox: {
    display: "flex",
    marginTop: "50px",
    gap: "30px",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  enterInputBox: {
    backgroundColor: theme.palette.background.card,
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    height: "40px",
    gap: "10px",
    maxWidth: "100px",
    width: "100px",
  },
  enterInput: {
    outline: "none",
    backgroundColor: "transparent",
    "& .MuiInputBase-input": {
      padding: "0",
    },

    "& fieldset": {
      border: "none",
    },
  },
  tokenTakerPart: {
    backgroundColor: theme.palette.background.card,
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "200px",
    width: "200px",
    borderRadius: "20px",
    gap: "10px",
    cursor: "pointer",
    color: "white",
    "&>img": {
      width: "20px",
    },
  },
  createPredictCallButton: {
    backgroundColor: "#1b1f28",
    color: "white",
    height: "50px",
    borderRadius: "12px",
    fontSize: "16px",
    padding: "0 20px",
    marginTop: "20px",
  },
  oddValue: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  editIcon: {
    position: "absolute",
    right: 0,
    bottom: 0,
    fontSize: "14px",
    transition: "0.3s",
    "&:hover": {
      color: "#ffffff76",
      transition: "0.3s",
    },
  },
  oddEditor: {
    width: "70%",
    height: "42px",
    "& .MuiInputBase-input": {
      padding: "5px 10px",
    },
  },
}));
