import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  cardBody: {
    display: "flex",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 15px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  chain: {
    color: "#444E68",
    fontSize: "13px",
  },
  token: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "7px",
    width: "100%",
    paddingTop: "10px",
  },
  tokenImage: {
    display: "flex",
    alignItems: "center",
  },
  tokenText: {
    fontSize: "16px",
  },
  amount: {
    color: theme.palette.text.disabled,
    fontSize: "13px",
  },
  star: {
    color: "#444E68",
    cursor: "pointer",
    transition: ".3s",
    "&:hover": {
      color: "#d0bc07",
      transition: ".3s",
    },
  },
  starFocused: {
    color: "#d0bc07",
  },
}));

export default useStyles;
