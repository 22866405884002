import { Box, Button } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import AddIcon from "@mui/icons-material/Add";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useNavigate } from "react-router-dom";
import useStyles from "./index.styles";

export default function PredictionCreate() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.body}>
      <Box className={classes.container}>
        <Box className={classes.title}>Create a new bet</Box>
        <Box className={classes.betContainer}>
          <Box className={classes.betDetail}>
            <Box className={classes.basicIcon}>
              <BoltIcon />
            </Box>
            <Box className={classes.betTitle}>Quick bet</Box>
            <Button
              className={classes.betButton}
              onClick={() => navigate("/predictions/quick-create")}
            >
              <AddIcon />
            </Button>
            <Box className={classes.betDesc}>
              <Box className={classes.betDescDetail}>
                <BoltIcon className={classes.quickColor} />
                Short format
              </Box>
              <Box className={classes.betDescDetail}>
                <BoltIcon className={classes.quickColor} />
                Equal odds
              </Box>
            </Box>
          </Box>
          <Box className={classes.orTitle}>or</Box>
          <Box className={classes.betDetail}>
            <Box className={classes.basicIcon}>
              <SettingsSuggestIcon />
            </Box>
            <Box className={classes.betTitle}>Advanced bet</Box>
            <Button
              className={classes.betButton}
              onClick={() => navigate("/predictions/advanced-create")}
            >
              <AddIcon />
            </Button>
            <Box className={classes.betDesc}>
              <Box className={classes.betDescDetail}>
                <SettingsSuggestIcon className={classes.advancedColor} />
                Custom format
              </Box>
              <Box className={classes.betDescDetail}>
                <SettingsSuggestIcon className={classes.advancedColor} />
                Custom odds
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
