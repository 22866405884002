import useStyles from "./index.styles";

import { Box } from "@mui/material";

const Spinner = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Box>
  );
};
export default Spinner;
