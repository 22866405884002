import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  tokenListPopup: {
    padding: "32px",
    backgroundColor: "#414754",
    "& .token-list-header": {
      width: "340px",
      display: "flex",
      color: "white",
      paddingBottom: "16px",
      borderBottom: "1px solid #4b515f",
      paddingRight: "12px",
      "& span": {
        flexGrow: 1,
        "&:first-of-type": {
          width: "100px",
          flexGrow: "initial",
        },
        "&:last-of-type": {
          width: "105px",
          flexGrow: "initial",
          textAlign: "center",
        },
      },
    },
    "& .token-list-items": {
      maxHeight: "360px",
      overflowY: "auto",
      paddingRight: "12px",
      "& .token-list-loading": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      },
      "& .token-list-item": {
        display: "flex",
        flexDirection: "row",
        paddingTop: "16px",
        paddingBottom: "16px",
        borderBottom: "1px solid #4b515f",
        "&.disabled": {
          opacity: 0.5,
          pointerEvents: "none",
        },
        "& .token-list-item-coin": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          fontSize: "16px",
          color: "white",
          fontWeight: "700",
          width: "100px",
        },
        "& .token-list-item-balance": {
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          flexGrow: 1,
          "& span:first-of-type": {
            color: "white",
            fontSize: "16px",
          },
          "& span:last-of-type": {
            color: "#56637e",
            fontSize: "14px",
          },
        },
        "& .token-list-item-use button": {
          padding: "4px 20px",
          borderRadius: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          color: "white",
          background: "rgb(46, 178, 130)",
          backgroundImage:
            "linear-gradient(180deg, rgba(46, 178, 130, 1) 0%, rgba(24, 168, 107, 1) 100%)",
          "& .loading-spinner": {
            width: "24px !important",
            height: "24px !important",
          },
          "&:disabled": {
            opacity: 0.5,
            background: "rgb(90, 90, 90)",
            backgroundImage:
              "linear-gradient(180deg, rgb(58, 58, 58) 0%, rgb(43, 43, 43) 100%)",
          },
        },
        "& img": {
          width: "28px",
          height: "28px",
        },
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px grey",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#646a78",
        "&:hover": {
          background: "#7e889e",
        },
      },
    },
  },
}));

export default styles;
