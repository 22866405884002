import { useRef, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import PredictionRulesDialog from "../PredictionRulesDialog";
import useStyles from "./index.styles";
import { Toast, toUSDFormat } from "../../utils";
import {
  PredictToken,
  enterRound,
  finishRound,
  cancelRound,
  getRoundInfo,
  getRoundMetaInfo,
} from "../../reducers/predict.slice";
import { BetToken } from "../../helpers/contractFunctions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getLpStatus } from "../../utils/predict";
import clsx from "clsx";
import { PredictRoundStatus } from "../../constants/prediction";

interface PredictDetailRightSectionProps {
  readonly roundInfo: any;
  readonly tokens: PredictToken[];
  readonly usdAmount: number;
  readonly enteringToken: BetToken | null;
  readonly curTime: number;
}
export default function PredictDetailRightSection(
  props: PredictDetailRightSectionProps
) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { tokenLps, loadingCancelRound, loadingFinishRound, creatingRound } =
    useAppSelector((state) => state.predict);
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const { id } = useParams();
  const navigate = useNavigate();
  const timeoutRef = useRef<any>(null);

  const token: PredictToken | undefined = props.tokens.find((tok, index) => {
    if (props.roundInfo.priceTokenName) {
      return (
        tok.name.toLowerCase() === props.roundInfo?.priceTokenName.toLowerCase()
      );
    }
    return false;
  });
  const lp = token
    ? tokenLps.find((tokenLp, index) => {
        return tokenLp.pairAddress.toLowerCase() === token.pair.toLowerCase();
      })
    : undefined;

  const amount = props.enteringToken?.price
    ? (props.usdAmount / props.enteringToken.price) * 1.2
    : 0;

  const reloadRound = async (roundId: number) => {
    if (id === roundId.toString()) {
      await dispatch(getRoundInfo(Number(id))).unwrap();
      await dispatch(getRoundMetaInfo(Number(id))).unwrap();
    }
  };

  const finishPredictRound = () => {
    dispatch(finishRound({ roundId: props.roundInfo.roundId, account }))
      .unwrap()
      .then(() => {
        timeoutRef.current = setTimeout(() => {
          reloadRound(props.roundInfo.roundId);
        }, 1000);
      });
  };

  const CancelPredictRound = () => {
    dispatch(cancelRound({ roundId: props.roundInfo.roundId, account }))
      .unwrap()
      .then(() => {
        timeoutRef.current = setTimeout(() => {
          reloadRound(props.roundInfo.roundId);
        }, 1000);
      });
  };

  const betToken = () => {
    props.enteringToken &&
      dispatch(
        enterRound({
          tokenId: props.enteringToken.id,
          tokenAddress: props.enteringToken.tokenAddress,
          usdAmount: props.usdAmount,
          tokenAmount: amount,
          account,
          roundId: props.roundInfo.roundId,
        })
      )
        .unwrap()
        .then(() => {
          setTimeout(() => {
            timeoutRef.current = reloadRound(props.roundInfo.roundId);
          }, 1000);
        });
  };

  const onClipboardTokenContract = async () => {
    await navigator.clipboard.writeText(token?.tokenAddress ?? "");
    Toast.fire({
      icon: "success",
      title: "Successfully copied.",
    });
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Box className={classes.body}>
      <Box className={classes.title}>
        <PredictionRulesDialog />
        <Button
          className={classes.createBut}
          onClick={() => {
            navigate(`/predictions/create`);
          }}
        >
          <AddIcon />
          Create bet
        </Button>
      </Box>
      <Box className={classes.card}>
        <Box className={classes.topContent}>
          <Box>
            <Box>LP Safety</Box>

            {lp && getLpStatus(lp.liquidity) ? (
              <Box
                className={clsx(
                  classes.badge,
                  getLpStatus(lp.liquidity)?.toLowerCase()
                )}
              >
                {getLpStatus(lp.liquidity)}
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box>
            <Box>Total value</Box>
            <Box>${toUSDFormat(props.roundInfo.roundMaximumValue)}</Box>
          </Box>
          <Box>
            <Box>Token contract</Box>
            <Box>
              <Box
                component="img"
                src="/images/icons/copy.png"
                onClick={onClipboardTokenContract}
              />
              <Box>{token?.tokenAddress?.substring(0, 6)}</Box>
            </Box>
          </Box>
          <Box>
            <Box>Total Liquidity</Box>
            <Box>${toUSDFormat(lp?.liquidity, 0)}</Box>
          </Box>
          <Box>
            <Box>Pricing information</Box>
            <Box>Pulsex V2</Box>
          </Box>
        </Box>
        <Box className={classes.bottomContent}>
          <Box>
            <Box>Stake</Box>
            <Box>
              $
              {toUSDFormat(
                props.roundInfo.roundMaximumValue -
                  props.roundInfo.creatorUSDValue,
                2
              )}
            </Box>
          </Box>
          <Box>
            <Box>Potential win</Box>
            <Box>${toUSDFormat(props.roundInfo.roundMaximumValue)}</Box>
          </Box>
          <Box>
            <Box>Profit</Box>
            <Box>
              +
              {toUSDFormat(
                (props.roundInfo.roundMaximumValue * 97) / 100 -
                  (props.roundInfo.roundMaximumValue -
                    props.roundInfo.creatorUSDValue),
                2
              )}
              $
            </Box>
          </Box>
        </Box>
      </Box>
      {props.roundInfo.status === PredictRoundStatus.Waiting &&
      account !== props.roundInfo.creator.toLowerCase() ? (
        <Button
          className={clsx(
            classes.actionButton,
            props.enteringToken ? classes.finalizeButton : classes.acceptButton
          )}
          disabled={creatingRound || props.enteringToken ? false : true}
          onClick={() => {
            if (!props.enteringToken) {
              Toast.fire({
                icon: "info",
                title: "Please select the token",
              });
            } else if (props.usdAmount && props.enteringToken) {
              betToken();
            }
          }}
        >
          Accept bet @ ${toUSDFormat(props.usdAmount, 2)}{" "}
          {creatingRound ? (
            <CircularProgress
              size={15}
              sx={{ color: "white", marginLeft: "3px" }}
            />
          ) : null}
        </Button>
      ) : props.roundInfo.status === PredictRoundStatus.Waiting &&
        account === props.roundInfo.creator.toLowerCase() &&
        props.roundInfo.bets &&
        props.roundInfo.bets.length < 2 ? (
        <Button
          className={clsx(classes.acceptButton, classes.actionButton)}
          disabled={loadingCancelRound}
          onClick={() => {
            CancelPredictRound();
          }}
        >
          Cancel bet{" "}
          {loadingCancelRound ? (
            <CircularProgress
              size={15}
              sx={{ color: "white", marginLeft: "3px" }}
            />
          ) : null}
        </Button>
      ) : props.roundInfo.status === PredictRoundStatus.Started &&
        props.roundInfo.endTime > props.curTime ? (
        <Button
          className={clsx(classes.acceptButton, classes.actionButton)}
          disabled={loadingFinishRound}
        >
          Waiting to finalize
        </Button>
      ) : props.roundInfo.status === PredictRoundStatus.Started &&
        props.roundInfo.endTime <= props.curTime ? (
        <Button
          className={clsx(classes.finalizeButton, classes.actionButton)}
          onClick={() => {
            if (!account) {
              Toast.fire({
                icon: "info",
                title: "Please connect wallet.",
              });
            } else {
              finishPredictRound();
            }
          }}
        >
          <CheckIcon /> Finalize bet now!{" "}
          {loadingFinishRound ? (
            <CircularProgress
              size={15}
              sx={{ color: "white", marginLeft: "3px" }}
            />
          ) : null}
        </Button>
      ) : props.roundInfo.status === PredictRoundStatus.Finished ? (
        <Button className={clsx(classes.finalizedButton, classes.actionButton)}>
          <CheckIcon /> Bet was finalized
        </Button>
      ) : props.roundInfo.status === PredictRoundStatus.Expired ? (
        props.roundInfo.expireWithdrawStatus === PredictRoundStatus.Waiting &&
        account.toLowerCase() === props.roundInfo.creator.toLowerCase() ? (
          <Button
            className={clsx(classes.acceptButton, classes.actionButton)}
            onClick={() => CancelPredictRound()}
            disabled={loadingCancelRound}
          >
            Withdraw
            {loadingCancelRound ? (
              <CircularProgress
                size={15}
                sx={{ color: "white", marginLeft: "3px" }}
              />
            ) : null}
          </Button>
        ) : (
          <Button className={clsx(classes.expiredButton, classes.actionButton)}>
            Expired
          </Button>
        )
      ) : props.roundInfo.status === PredictRoundStatus.Canceled ? (
        <Button className={clsx(classes.expiredButton, classes.actionButton)}>
          Canceled
        </Button>
      ) : (
        ""
      )}
    </Box>
  );
}
