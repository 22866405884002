import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gameConfig } from "../config";

const SERVER_URL = gameConfig.serverUrl;
// const SERVER_URL = "http://192.168.8.154:3000/";

export interface Notification {
  id: number;
  address: string;
  content: string;
  timestamp: number;
  roundId: number;
  type: string;
}

export interface NotificationState {
  notifications: Notification[];
  notificationCount: number;
  isLoadingNotifications: boolean;
  hasMore: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  notificationCount: 0,
  isLoadingNotifications: false,
  hasMore: true,
};

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async ({
    address,
    from,
    to,
  }: {
    address: string;
    from: number;
    to: number;
  }) => {
    const response = await fetch(
      `${SERVER_URL}notification/all/${address}/${from}/${to}`
    );

    const result = await response.json();
    return result;
  }
);

export const getUnreadNotificationInfo = createAsyncThunk(
  "notification/getUnreadNotificationInfo",
  async ({
    address,
    notificationId,
  }: {
    address: string;
    notificationId: number;
  }) => {
    const response = await fetch(
      `${SERVER_URL}notification/unread/${address}/${notificationId}`
    );

    const result = await response.json();
    return result;
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotificationBadge: (state) => {
      state.notificationCount = 0;
    },

    handleNewNotification: (state, { payload }: { payload: Notification }) => {
      if (
        state.notifications.findIndex(
          (notification, index) => notification.id === payload.id
        ) === -1
      ) {
        state.notifications = [payload, ...state.notifications];
        state.notificationCount += 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoadingNotifications = true;
    });

    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.isLoadingNotifications = false;
      state.notifications = [
        ...state.notifications,
        ...payload.notificationData.filter(
          (newNotification: Notification) =>
            !state.notifications.some(
              (existingNotification) =>
                existingNotification.id === newNotification.id
            )
        ),
      ];

      if (payload.notificationData.length > 0) state.hasMore = true;
      else state.hasMore = false;
    });

    builder.addCase(getNotifications.rejected, (state, { error }) => {
      state.isLoadingNotifications = false;
    });

    builder.addCase(getUnreadNotificationInfo.pending, (state) => {});
    builder.addCase(
      getUnreadNotificationInfo.fulfilled,
      (state, { payload }) => {
        state.notificationCount = payload.unreadCount;
      }
    );
    builder.addCase(
      getUnreadNotificationInfo.rejected,
      (state, { error }) => {}
    );
  },
});

export const { clearNotificationBadge, handleNewNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
