import theme from "../../theme";
import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "0",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
      gap: "50px",
    },
  },
  slideButton: {
    width: "50px",

    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    "&>img": {
      width: "22px",
    },
  },
  carousel: {
    width: "calc(100% - 140px)",
    display: "flex",
    flexWrap: "wrap",
    "& .slider-container": {
      width: "100%",
    },
    "& .slider-control-bottomcenter": {
      display: "none",
    },
    "& .slide": {
      display: "flex",
      justifyContent: "center",
    },
    "& .slider-control-centerleft": {
      display: "none",
    },
    "& .slider-control-centerright": {
      display: "none",
    },
  },
  carouselBody: {
    display: "flex",
    width: "100%"
  },
}));

export default styles;
