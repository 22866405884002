import { createTheme, TypeBackground, TypeText } from "@mui/material/styles";

interface CustomTypeBackground extends TypeBackground {
  dropdown?: string;
  card?: string;
  lightBack?: string;
  predictionCard?: string;
  usdCard?: string;
  openColor?: string;
  startColor?: string;
  progressBar?: string;
  eventActive?: string;
  eventDisable?: string;
  timeLeftDot?: string;
  greenMark?: string;
  pickButton?: string;
  closeButton?: string;
}

interface CustomTypeText extends TypeText {
  increase?: string;
  decrease?: string;
  stormGrey?: string;
  dark?: string;
  santasGrey?: string;
  winnerText?: string;
  activeLine: string;
  tableFont?: string;
  tableBorder?: string;
}

const theme: any = createTheme({
  palette: {
    background: {
      default: "#13151E",
      card: "#1b1f28",
      dropdown: "#1c1f28",
      lightBack: "#393b47",
      predictionCard: "#101115",
      usdCard: "#409657",
      openColor: "#18d50f",
      startColor: "#ef3434",
      progressBar: "#15181d",
      eventActive: "#1c1f28",
      eventDisable: "#151821",
      timeLeftDot: "#e91d24",
      greenMark: "#1b6518",
      pickButton: "#1c6d23",
      closeButton: "#702526",
    } as CustomTypeBackground,
    text: {
      primary: "#ffffff",
      disabled: "#444E68",
      increase: "#77a555",
      decrease: "#c56061",
      stormGrey: "#74758d",
      dark: "#3d3d4d",
      santasGrey: "#979dac",
      winnerText: "#726033",
      activeLine: "#196118",
      tableFont: "#42485c",
      tableBorder: "#222630",
    } as CustomTypeText,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },
  typography: {
    fontFamily: "K2D",
  },
});

export default theme;
