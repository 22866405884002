import React, { useState, useEffect } from "react";
import { Box, Switch, Tooltip, TextField, Button } from "@mui/material";

import { useStyles } from "./index.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CustomNumberFormat } from "../PredictionCreateOptions/CustomNumberFormat";
import CustomDateTimePicker from "../PredictionCreateOptions/CustomDateTimePicker";
import {
  setIsPrivate,
  setOnlyOneTaker,
  setIsMaxUSD,
  setIsMinUSD,
  setExpireTime,
  setIsExpirePick,
  setIsExpirePercent,
  setExpirePercent,
  setCustomComment,
  setIsCustomComment,
} from "../../reducers/predict.slice";
import clsx from "clsx";
import { CustomPercentFormat } from "../PredictionCreateOptions/CustomPercentFormat";

interface PredictCreateRightPartProps {
  mobile?: boolean;
}

const PredictCreateRightPart = (props: PredictCreateRightPartProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { newRoundInfo } = useAppSelector((state) => state.predict);
  const {
    isPrivate,
    isMaxUSD,
    isMinUSD,
    expireTime,
    isExpirePick,
    isExpirePercent,
    expirePercent,
    customComment,
    isCustomComment,
    endTime,
  } = newRoundInfo;
  const [open, setOpen] = useState(false);

  const handleSetting = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    switch (name) {
      case "isPrivate":
        dispatch(setIsPrivate(e.target.checked));
        break;
      case "onlyOneTaker":
        dispatch(setOnlyOneTaker(e.target.checked));
        if (e.target.checked && (isMaxUSD || isMinUSD)) {
          dispatch(setIsMinUSD(false));
          dispatch(setIsMaxUSD(false));
        }
        break;
      case "isExpirePick":
        dispatch(setIsExpirePick(e.target.checked));
        if (e.target.checked && isExpirePercent) {
          dispatch(setIsExpirePercent(false));
        }
        break;
      case "isExpirePercent":
        dispatch(setIsExpirePercent(e.target.checked));
        dispatch(setExpirePercent(20));
        if (e.target.checked && isExpirePick) {
          dispatch(setIsExpirePick(false));
        }
        break;
      case "isCustomComment":
        dispatch(setIsCustomComment(e.target.checked));
        if (e.target.checked) {
          dispatch(setCustomComment(""));
        }
        break;
      default:
        break;
    }
  };

  const handleOpen = (status: boolean) => {
    setOpen(status);
  };
  const handleDate = (value: number) => {
    dispatch(setExpireTime(new Date(value.toString()).getTime()));
  };
  const openDateTimePicker = () => {
    setOpen(true);
  };

  const handleExpirePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setExpirePercent(e.target.value));
  };

  const changeComment = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.target.value.length < 200) {
      dispatch(setCustomComment(e.target.value));
    } else {
      return;
    }
  };


  return (
    <Box
      className={clsx(classes.body, props.mobile ? classes.mobile : classes.pc)}
    >
      <Box className={classes.feeCard}>
        <Box className={classes.title}>
          <Box component={"img"} src="/images/prediction/view.png"></Box>
          Fee structure
        </Box>
        <Box className={classes.cardBody}>
          <Box className={classes.feeBody}>
            <Box className={classes.completePart}>
              <Box>Complete</Box>
              <Box>3%</Box>
            </Box>
            <Box className={classes.middleLine}></Box>
            <Box className={classes.completePart}>
              <Box>Expired or canceled</Box>
              <Box>1.5%</Box>
            </Box>
          </Box>
          <Box className={classes.termsPart}>
            <Box component={"img"} src="/images/prediction/done.png"></Box>I
            accept provided Terms of Service
          </Box>
        </Box>
      </Box>
      <Box className={classes.settingCard}>
        <Box className={classes.title}>
          <Box component={"img"} src="/images/prediction/control.png"></Box>
          Advanced setting
        </Box>
        <Box className={classes.cardBody}>
          <Box className={classes.settingDetail}>
            <Box
              className={classes.detailIcon}
              component={"img"}
              src="/images/prediction/security.png"
            ></Box>
            <Box className={classes.detailBody}>
              <Box className={classes.detailText}>Private listing</Box>
              <Box className={classes.detailRightPart}>
                <Switch
                  checked={isPrivate}
                  onChange={(e) => handleSetting(e, "isPrivate")}
                />
                <Tooltip
                  title={
                    <Box className={classes.tooltip}>
                      <p>
                        This option gives you the ability to hide your price
                        call bet from the overview page.
                      </p>
                      <p>
                        This is useful if your bet is intended for a specific
                        taker.
                      </p>
                      <p>You can share the direct link.</p>
                    </Box>
                  }
                >
                  <img
                    className={classes.infoImg}
                    src="/images/prediction/info.png"
                    alt="info"
                    width={15}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box className={classes.settingDetail}>
            <Box
              className={classes.detailIcon}
              component={"img"}
              src="/images/prediction/calendar.png"
            ></Box>
            <Box className={classes.detailBody}>
              <Box className={classes.detailText}>Expire on date/time</Box>
              <Box className={classes.detailRightPart}>
                <Switch
                  checked={isExpirePick}
                  onChange={(e) => handleSetting(e, "isExpirePick")}
                />
                <Tooltip
                  title={
                    <Box className={classes.tooltip}>
                      <p>
                        Use this option to expire a price call on a specific
                        date.
                      </p>
                      <p>
                        Takers needs to supply all the needed value before this
                        date/time otherwise the creator can cancel the bet and
                        funds are returned.
                      </p>
                    </Box>
                  }
                >
                  <img
                    className={classes.infoImg}
                    src="/images/prediction/info.png"
                    alt="info"
                    width={15}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              paddingLeft: "30px",
              display: isExpirePick ? "block" : "none",
            }}
          >
            <CustomDateTimePicker
              open={open}
              date={expireTime}
              handleOpen={handleOpen}
              handleDate={handleDate}
              openDateTimePicker={openDateTimePicker}
            />
          </Box>
          <Box className={classes.settingDetail}>
            <Box
              className={classes.detailIcon}
              component={"img"}
              src="/images/prediction/clock_.png"
            ></Box>
            <Box className={classes.detailBody}>
              <Box className={classes.detailText}>Expire based on % passed</Box>
              <Box className={classes.detailRightPart}>
                <Switch
                  checked={isExpirePercent}
                  onChange={(e) => handleSetting(e, "isExpirePercent")}
                />
                <Tooltip
                  title={
                    <Box className={classes.tooltip}>
                      <p>
                        Use this option to expire you bet based on a certain
                        amount of time passed.
                      </p>
                    </Box>
                  }
                >
                  <img
                    className={classes.infoImg}
                    src="/images/prediction/info.png"
                    alt="info"
                    width={15}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: isExpirePercent ? "flex" : "none" }}
            className={classes.inputField}
          >
            <img
              src="/images/prediction/tag.png"
              alt="tag"
              width={18}
              className={classes.expireTagImg}
            />
            <TextField
              variant="filled"
              className={classes.expirePercentInput}
              label=""
              name="expirePercent"
              value={expirePercent}
              onChange={handleExpirePercent}
              placeholder="0"
              InputProps={{
                inputComponent: CustomPercentFormat,
              }}
            />
          </Box>
          <Box className={classes.settingDetail}>
            <Box
              className={classes.detailIcon}
              component={"img"}
              src="/images/prediction/comment.png"
            ></Box>
            <Box className={classes.detailBody}>
              <Box className={classes.detailText}>Enable custom comment</Box>
              <Box className={classes.detailRightPart}>
                <Switch
                  checked={isCustomComment}
                  onChange={(e) => handleSetting(e, "isCustomComment")}
                />
                <Tooltip
                  title={
                    <Box className={classes.tooltip}>
                      <p>
                        Use this feature to send a cheeky message to your
                        opponent!
                      </p>
                    </Box>
                  }
                >
                  <img
                    className={classes.infoImg}
                    src="/images/prediction/info.png"
                    alt="info"
                    width={15}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.inputField}
            sx={{ display: isCustomComment ? "block" : "none" }}
          >
            <TextField
              multiline
              variant="filled"
              className={classes.commentTextArea}
              placeholder={`200 characters comment limit...`}
              inputProps={{ maxLength: 200 }}
              InputProps={{ disableUnderline: true }}
              value={customComment}
              onChange={(e) => changeComment(e)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PredictCreateRightPart;
