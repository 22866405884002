export const UFC299FightRoundInfos = [
  {
    player1: {
      name: "Sean O'Malley",
      photo: "sean.png",
      record: "17-1-0, INC",
    },
    player2: {
      name: "Marlon Vera",
      photo: "vera.png",
      record: "21-8-1",
    },
    weight: "BANTAMWEIGHT",
  },
  {
    player1: {
      name: "Dustin Poirier",
      photo: "dustin.png",
      record: "29-8-0, INC",
    },
    player2: {
      name: "Benoît Saint Denis",
      photo: "denis.png",
      record: "13-1-0, INC",
    },
    weight: "LIGHTWEIGHT",
  },
  {
    player1: {
      name: "Kevin Holland",
      photo: "holland.png",
      record: "25-10-0, INC",
    },
    player2: {
      name: "Michael Page",
      photo: "page.png",
      record: "21-2-0",
    },
    weight: "WELTERWEIGHT",
  },
  {
    player1: {
      name: "Gilbert Burns",
      photo: "burns.png",
      record: "22-6-0",
    },
    player2: {
      name: "Jack Maddalena",
      photo: "della.png",
      record: "16-2-0",
    },
    weight: "WELTERWEIGHT",
  },
  {
    player1: {
      name: "Petr Yan",
      photo: "yan.png",
      record: "16-5-0",
    },
    player2: {
      name: "Song Yadong",
      photo: "song.png",
      record: "21-7-1, INC",
    },
    weight: "BANTAMWEIGHT",
  },
];

export const UFC300FightRoundInfos = [
  {
    player1: {
      name: "Alex Pereira",
      photo: "pereira.png",
      record: "9-2-0",
    },
    player2: {
      name: "Jamahal Hill",
      photo: "hill.png",
      record: "12-1-0",
    },
    weight: "Light Heavyweight",
  },
  {
    player1: {
      name: "Zhang Weili",
      photo: "weili.png",
      record: "24-3-0",
    },
    player2: {
      name: "Yan Xiaonan",
      photo: "xiaonan.png",
      record: "17-3-0",
    },
    weight: "Women's Strawweight",
  },
  {
    player1: {
      name: "Justin Gaethje",
      photo: "gaethje.png",
      record: "26-4-0",
    },
    player2: {
      name: "Max Holloway",
      photo: "holloway.png",
      record: "25-7-0",
    },
    weight: "Lightweight",
  },
  {
    player1: {
      name: "Charles Oliveira",
      photo: "oliveira.png",
      record: "34-9-0",
    },
    player2: {
      name: "Arman Tsarukyan",
      photo: "tsarukyan.png",
      record: "21-3-0",
    },
    weight: "Lightweight",
  },
  {
    player1: {
      name: "Bo Nickal",
      photo: "nickal.png",
      record: "5-0-0",
    },
    player2: {
      name: "Cody Brundage",
      photo: "brundage.png",
      record: "10-5-0",
    },
    weight: "Middleweight",
  },
];

export const DAZNFightRoundInfo = [
  {
    player1: {
      name: "Tyson Fury",
      photo: "fury.png",
      record: "34-1-0",
    },
    player2: {
      name: "Oleksandr Usyk",
      photo: "usyk.png",
      record: "21-0-0",
    },
    weight: "heavyweight",
  },
  {
    player1: {
      name: "Jai Opetaia",
      photo: "jai.png",
      record: "24-0-0",
    },
    player2: {
      name: "Mairis Briedis",
      photo: "mairis.png",
      record: "30-0-2",
    },
    weight: "cruiserweight",
  },
  {
    player1: {
      name: "Joe Cordina",
      photo: "cordina.png",
      record: "17-0-0",
    },
    player2: {
      name: "Anthony Cacace",
      photo: "cacace.png",
      record: "21-1-0",
    },
    weight: "Super Featherweight",
  },
];

export const ELECTIONRoundInfo = [
  {
    player1: {
      name: "Donald Trump",
      photo: "trump-icon.png",
      record: "Republican party",
    },
    player2: {
      name: "Kamala Harris",
      photo: "harris-icon.png",
      record: "Democrat party",
    },
  },
];

export const IFCFightRoundInfos = [
  {
    player1: {
      name: "Ben Armstrong",
      photo: "ben-purple.png",
      twitter: "https://twitter.com/BenArmstrongsX",
    },
    player2: {
      name: "More Light",
      photo: "more-blue.png",
      twitter: "https://twitter.com/tickerBITCOINbb",
    },
    weight: "WELTERWEIGHT",
  },
  {
    player1: {
      name: "Crypto Crow",
      photo: "crow-purple.png",
      twitter: "https://twitter.com/jasonappleton",
    },
    player2: {
      name: "Moon Daddy",
      photo: "moon-blue.png",
      twitter: "https://twitter.com/RameelTv",
    },
    weight: "HEAVYWEIGHT",
  },
  {
    player1: {
      name: "The Dreamer",
      photo: "dreamer-purple.png",
      twitter: "https://twitter.com/DreamerDFK",
    },
    player2: {
      name: "Phil Russell",
      photo: "phil-blue.png",
      twitter: "https://twitter.com/PhilRussell_",
    },
    weight: "WELTERWEIGHT",
  },
  {
    player1: {
      name: "Adam Charles",
      photo: "adam-purple.png",
      twitter: "https://twitter.com/AdamCharlesMKT",
    },
    player2: {
      name: "Keith Berry",
      photo: "keith-blue.png",
      twitter: "https://twitter.com/kokid951",
    },
    weight: "HEAVYWEIGHT",
  },
  {
    player1: {
      name: "Crypto Grady",
      photo: "grady-purple.png",
      twitter: "https://twitter.com/CryptoGrady1",
    },
    player2: {
      name: "Grimace",
      photo: "grimace-blue.png",
      twitter: "https://twitter.com/grimace85",
    },
    weight: "HEAVYWEIGHT",
  },
];

export const UFCRoundStatus = {
  WAITING: 0,
  STARTED: 1,
  CLOSED: 2,
  FINISHING: 3,
  FINISHED: 4,
  CANCELING: 5,
  CANCELLED: 6,
};

export const UFCPlayerType = {
  PLAYER1: 0,
  PLAYER2: 1,
};

export const UFCRoundResult = {
  NORMAL: 0,
  DRAW: 2,
};

export const FCEventType = {
  UFC299: 0,
  IFC: 1,
  UFC300: 2,
  DAZN: 3,
  ELECTION: 4,
};

export const UFC_EVENTS = {
  EnteredRound: "EnteredRound",
  RoundFinished: "RoundFinished",
  RoundCancelled: "RoundCancelled",
};

export const BET_APPROVE_OFFSET = 1;
