import { Box, TextField } from "@mui/material";

import theme from "../../theme";

const MAX_BIO_LENGTH = 120;

interface CustomTextAreaProps {
  text: string;
  onTextChange: (newValue: string) => void; // More specific type for event handler
}
const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  text,
  onTextChange,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <TextField
        aria-label="minimum height"
        rows={3}
        multiline
        onChange={(e) => onTextChange(e.target.value)}
        value={text}
        sx={{
          boxSizing: "border-box",
          width: "100%",
          fontSize: "0.875rem",
          fontWeight: "400",
          lineHeight: "1.5",
          padding: "8px 12px",
          borderRadius: "12px",
          outline: "none",
          border: "none",
          resize: "none",
          backgroundColor: theme.palette.background.card,
          color: theme.palette.text.primary,
          fieldset: {
            border: "none",
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          right: "16px",
          top: "16px",
          color: theme.palette.text.disabled,
        }}
      >{`${text.length}/${MAX_BIO_LENGTH}`}</Box>
    </Box>
  );
};

export default CustomTextArea;
