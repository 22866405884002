import { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { LastPriceAnimationMode, createChart } from "lightweight-charts";
import type { LineData, SeriesMarker, Time } from "lightweight-charts";
import { useParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { getUserGlobalRankList } from "../../reducers/user.slice";

export default function PredictionGlobalRankChart() {
  const { userGlobalRank, loadingUserGlobalRank } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();
  const { address } = useParams();

  useEffect(() => {
    if (userGlobalRank.length === 0) return;
    const newChartElement = document.createElement("div");
    newChartElement.style.width = "100%";
    newChartElement.style.height = "100%";
    newChartElement.style.minHeight = "300px";

    const chartContainer = document.getElementById("globalRankChartContainer");
    if (chartContainer) {
      chartContainer.appendChild(newChartElement);
    } else return;

    const chart = createChart(newChartElement, {
      layout: {
        background: { color: "#0d131c" },
        textColor: "#DDD",
      },
      grid: {
        vertLines: { color: "#44444475" },
        horzLines: { color: "#44444475" },
      },
      timeScale: {
        secondsVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          const date = new Date(parseInt(time) * 1000);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so we add 1
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const formattedTime = `${month}-${day} ${hours}:${minutes}`;
          return formattedTime;
        },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.2,
          bottom: 0,
        },
        visible: true,
      },
    });
    const lineSeries = chart.addLineSeries({
      color: "#185b5f",
      lastPriceAnimation: LastPriceAnimationMode.Continuous,
      lineWidth: 3,
      lineType: 2,
      priceFormat: {
        type: "custom",
        formatter: (price: any) => {
          return price - Math.floor(price) === 0 ? (-price).toFixed(0) : "";
        },
      },
      priceLineVisible: false,
    });
    const data: LineData[] = userGlobalRank.map((item) => ({
      time: (new Date(item.date).getTime() / 1000) as Time,
      value: -item.rank,
    }));

    lineSeries.setData(data);
    const step =
      Math.floor(data.length / 10) === 0 ? 1 : Math.floor(data.length / 10);
    const markers: SeriesMarker<Time>[] = [];
    data.forEach((point, index) => {
      if (index % step === 0) {
        markers.push({
          time: point.time,
          position: "inBar",
          color: "white",
          shape: "circle",
          size: 0.3,
          text: (-point.value).toString(),
        });
      }
    });
    lineSeries.setMarkers(markers);

    const startTime = Number(data[0].time);
    const endTime = Number(data[data.length - 1].time);
    const timeRange = endTime - startTime;

    chart.timeScale().setVisibleRange({
      from: (startTime - timeRange * 0.1) as Time,
      to: (endTime + timeRange * 0.1) as Time,
    });

    return () => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
    };
  }, [userGlobalRank]);

  useEffect(() => {
    address && dispatch(getUserGlobalRankList(address));
  }, [address, dispatch]);
  return (
    <>
      {loadingUserGlobalRank ? (
        <Skeleton width={"100%"} height={300}></Skeleton>
      ) : (
        <Box
          id={"globalRankChartContainer"}
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "30px",
              left: "30px",
              zIndex: 100,
              color: "#444E68",
            }}
          >
            Global rank
          </Box>
        </Box>
      )}
    </>
  );
}
