import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  tableContainer: {
    overflow: "auto",
    maxHeight: "400px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "silver",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#7e889e",
      },
    },
  },
  row: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 3fr",
    padding: "5px 20px",
    borderBottom: "1px solid #42485c33",
    fontSize: "13px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
    "&>div:nth-of-type(1)": {
      display: "flex",
      alignItems: "center",
      gap: "7px",
      "&>img": {
        width: "30px",
      },
    },
    "&>div:nth-of-type(2)": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      "&>div:nth-of-type(2)": {
        color: "#56637e",
      },
    },
    "&>div:nth-of-type(3)>button": {
      background: "linear-gradient(180deg,#2eb282,#18a86b)",
      borderRadius: "20px",
      color: theme.palette.text.primary,
      textTransform: "none",
      "&.tokenDisabled": {
        background: "linear-gradient(180deg,#3a3a3a,#2b2b2b)",
        cursor: "not-allowed",
      },
    },
  },
}));

export default styles;
