import { Box, Skeleton } from "@mui/material";

import TokenImage from "../TokenImage";

import { useAppSelector } from "../../app/hooks";
import { PredictRoundStatus } from "../../constants/prediction";
import {
  CalculateFullDateByTimeStamp,
  convertUTCTime,
  shortAddress,
  shortName,
  toUSDFormat,
} from "../../utils";
import Blocky from "../Blocky";
import useStyles from "./index.styles";

interface PredictionCardProps {
  readonly roundInfo: any;
  readonly isLoading: boolean;
  readonly curTime: number;
}

export default function PredictionCard(props: PredictionCardProps) {
  const { classes } = useStyles();
  const { profiles } = useAppSelector((state) => state.user);
  const profile = profiles.find(
    (profile) =>
      profile.address.toLowerCase() === props.roundInfo?.creator?.toLowerCase()
  );
  return (
    <Box className={classes.body}>
      <Box className={classes.token}>
        {props.isLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={140}
            height={40}
          />
        ) : (
          <>
            <TokenImage
              classNames="middle"
              token={props.roundInfo?.priceTokenName?.toLowerCase()}
            />
            <Box className={classes.tokenText}>
              {props.roundInfo?.priceTokenName}
            </Box>
          </>
        )}
      </Box>
      {props.isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={140}
          height={30}
          sx={{ marginTop: "10px" }}
        />
      ) : (
        <Box className={classes.usdText}>
          ${toUSDFormat(props.roundInfo.pricePoint, 11)}
        </Box>
      )}
      {props.isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={200}
          height={50}
          sx={{ marginTop: "10px" }}
        />
      ) : (
        <Box className={classes.timeText}>
          <Box>
            or {props.roundInfo?.operator === 1 ? "under" : "above"} on the
          </Box>
          <Box className={classes.largeText}>
            {props.roundInfo?.endTime &&
              props.roundInfo.duration >= 0 &&
              convertUTCTime(
                props.roundInfo.status === PredictRoundStatus.Waiting &&
                  props.roundInfo.isQuickBet
                  ? props.roundInfo.duration * 1000 +
                      Math.floor(new Date().getTime())
                  : props.roundInfo.endTime * 1000
              )}{" "}
            UTC
          </Box>
        </Box>
      )}
      {props.isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={170}
          height={30}
          sx={{ marginTop: "10px" }}
        />
      ) : props.roundInfo.status === PredictRoundStatus.Waiting ||
        (props.roundInfo.status === PredictRoundStatus.Started &&
          props.curTime < props.roundInfo.endTime) ? (
        <Box className={classes.remainTimeText}>
          {CalculateFullDateByTimeStamp(
            props.curTime,
            props.roundInfo?.endTime
          ) !== "-1"
            ? CalculateFullDateByTimeStamp(
                props.curTime,
                props.roundInfo.endTime
              )
            : ""}{" "}
          left
        </Box>
      ) : (
        ""
      )}
      {props.isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={170}
          height={20}
          sx={{ marginTop: "20px" }}
        />
      ) : (
        <Box className={classes.creatorAddress}>
          <Box>by</Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {profile && profile.avatar.length > 0 ? (
              <img src={profile.avatar} alt="" width={20} height={20} />
            ) : (
              <Blocky address={props.roundInfo?.creator} size={20} scale={1} />
            )}{" "}
            {profile
              ? profile.name.length > 15
                ? shortName(profile.name)
                : profile.name
              : props.roundInfo?.creator &&
                shortAddress(props.roundInfo.creator.toLowerCase())}
          </Box>
        </Box>
      )}
    </Box>
  );
}
