import { Box } from "@mui/material";
import { Fragment, useState, useEffect, useRef } from "react";

import EuroMatchDetailPanel from "../EuroMatchDetailPanel";
import EuroMatchEnterPopup from "../EuroMatchEnterPopup";
import EuroMatchListItem from "../EuroMatchListItem";
import EuroRoundFinishPopup from "../EuroRoundFinishPopup";

import { useAppSelector } from "../../app/hooks";
import { euro24MatchInfo } from "../../constants/euro24";
import { EuroMatchInfo } from "../../types/EuroMatchInfo";

import useStyles from "./index.styles";

export default function EuroMatchList({ isAdmin }: { isAdmin: boolean }) {
  const { classes } = useStyles();

  const { roundInfos, isLoadingRoundInfo } = useAppSelector(
    (state) => state.euro24
  );
  const [open, setOpen] = useState(false);
  const [roundFinishPopupOpen, setRoundFinishPopupOpen] = useState(false);
  const [matchInfo, setMatchInfo] = useState<EuroMatchInfo>();
  const [matchResult, setMatchResult] = useState(0);
  const [roundDetailId, setRoundDetailId] = useState(-1);
  const [maskStyle, setMaskStyle] = useState("");

  const activeIndex =
    roundInfos.findIndex((round, index) => round.roundData.result === -1) - 1;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const matchRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (activeIndex > -1 && matchRefs.current[activeIndex]) {
      const scrollToActive = () => {
        const container = containerRef.current;
        const targetElement = matchRefs.current[activeIndex];

        if (container && targetElement) {
          const containerRect = container.getBoundingClientRect();
          const targetRect = targetElement.getBoundingClientRect();

          const scrollTop = container.scrollTop;
          const targetOffset = targetRect.top - containerRect.top + scrollTop;

          container.scrollTo({
            top: targetOffset - 15,
            behavior: "smooth",
          });
        }
      };

      const timer = setTimeout(scrollToActive, 1500);

      return () => clearTimeout(timer);
    }
  }, [activeIndex]);

  const handleScroll = (event: any) => {
    const scrollPosition = event.target.scrollTop;
    const container = containerRef.current;
    if (container) {
      const containerRect = container.getBoundingClientRect();

      if (scrollPosition > 30) {
        if (
          container.scrollTop + containerRect.height >
          container.scrollHeight - 30
        ) {
          setMaskStyle(
            "linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 95%, rgba(0,0,0,0) 100%)"
          );
        } else {
          setMaskStyle(
            "linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 95%, rgba(0,0,0,0) 100%)"
          );
        }
      } else {
        setMaskStyle("");
      }
    }
  };

  return (
    <Box
      className={classes.euroMatchList}
      ref={containerRef}
      sx={{
        WebkitMaskImage: maskStyle,
      }}
      onScroll={handleScroll}
    >
      {euro24MatchInfo.map((match, index) => {
        return (
          <Fragment key={index}>
            <div ref={(element) => (matchRefs.current[index] = element)}>
              <EuroMatchListItem
                match={match}
                roundInfo={isLoadingRoundInfo ? undefined : roundInfos[index]}
                setMatchInfo={setMatchInfo}
                setMatchResult={setMatchResult}
                roundDetailId={roundDetailId}
                setRoundDetailId={setRoundDetailId}
                onOpenPopup={setOpen}
                enableDraw={match.matchId >= 37 ? false : true}
                isAdmin={isAdmin}
                onOpenRoundFinishPopup={setRoundFinishPopupOpen}
              />
            </div>

            {roundDetailId === match.matchId && (
              <EuroMatchDetailPanel
                match={match}
                enableDraw={match.matchId >= 37 ? false : true}
              />
            )}
          </Fragment>
        );
      })}
      <EuroMatchEnterPopup
        open={open}
        matchInfo={matchInfo}
        matchResult={matchResult}
        closeMatchEnterPopup={() => {
          setOpen((prev) => !prev);
        }}
      />
      <EuroRoundFinishPopup
        open={roundFinishPopupOpen}
        matchInfo={matchInfo}
        closeRoundFinishPopup={() => {
          setRoundFinishPopupOpen((prev) => !prev);
        }}
      />
    </Box>
  );
}
