import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  body: {
    marginTop: "50px",
    width: "100%",
    padding: "20px 20px",
    backgroundColor: "#1b1f28",
    overflowX: "auto",
  },
  scrollBody: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    [theme.breakpoints.down("md")]: {
      minWidth: "600px",
      width: "600px",
      overflowX: "auto",
    },
  },
  tableHeader: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 2fr 2fr",
    color: "#444E68",
  },
  tableBody: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "500px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "silver",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#7e889e",
      },
    },
  },
  detail: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 2fr 2fr",
    borderBottom: "1px solid #292c38",
    padding: "15px 10px",
    transition: "0.3s",
    cursor: "pointer",
    "&>div": {
      display: "flex",
      alignItems: "center",
    },
    "&:hover": {
      backgroundColor: "#30333b",
      transition: "0.3s",
    },
  },
  thunderPart: {
    "&>img": {
      width: "20px",
    },
  },
  idPart: {
    gap: "10px",
    color: "#444E68",
  },
  assetPart: {
    gap: "5px",
    "&>img": {
      width: "20px",
    },
  },
  profitLossPart: {},
  valuePart: {},
  plus: {
    color: "#41ba3b",
  },
  minus: {
    color: "#ce4b14",
  },
  statusPart: {
    textTransform: "uppercase",
    fontSize: "13px",
  },
  actionPart: {
    "& button": {
      textTransform: "none",
      width: "80px",
      borderRadius: "5px",
      padding: "3px 0",
    },
  },
  enableBut: {
    backgroundColor: "#3caa7c",
    color: "white",
    "&:hover": {
      backgroundColor: "#2f8963",
      color: "white",
    },
  },
  disableBut: {
    backgroundColor: "#292c38",
    color: "#595e6e",
    "&:hover": {
      backgroundColor: "#474b5c",
      color: "#8b96b6",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

export default useStyles;
