import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ethers } from "ethers";

import { gameConfig } from "../../src/config";
import { acceptedTokens } from "../config/tokens";
import {
  PredictionGettingRoundsType,
  PredictRoundStatus,
  TokenList,
} from "../constants/prediction";
import { BetToken, getTokenBalance } from "../helpers/contractFunctions";
import {
  cancelPredictRound,
  createPredictRound,
  enterPredictRound,
  finishPredictRound,
  getPredictBetTokens,
  getPredictToken,
  readPriceTokens,
  readRoundInfo,
} from "../helpers/contractFunctions/predict";
import { ceilDecimal, Toast } from "../utils";

export interface PredictState {
  rounds: PredictRound[];
  totalRoundsCount: number;
  loadingRounds: boolean;
  liveRounds: PredictRound[];
  liveTotalRoundsCount: number;
  loadingLiveRounds: boolean;
  allRounds: PredictRound[];
  allRoundsCount: number;
  loadingAllRounds: boolean;
  countPerPage: number;
  myRoundCountPerPage: number;
  homeLiveRounds: PredictRound[];
  homeOpenRounds: PredictRound[];

  hasMoreRounds: boolean;
  hasMoreAllRounds: boolean;

  tokens: PredictToken[];
  prices: {
    priceUsd?: number;
    priceChange?: number;
  };
  roundMaxValue: number;
  roundCount: number;
  loadingRoundMaxValue: boolean;
  newRoundInfo: PredictNewRoundInfo;
  roundInfo: PredictRoundInfo | null;
  metaInfo: any;
  loadingMetaInfo: boolean;
  loadingRoundInfo: boolean;

  betTokens: BetToken[];
  betUSDAmount: number;
  loadingBetTokens: boolean;
  tokenBalances: { [key: string]: string };
  loadingTokenBalances: boolean;

  enteringRound: boolean;
  creatingRound: boolean;

  tokenLps: PriceTokenLp[];
  loadingTokenLps: boolean;
  lpStatus: number;

  detailTokenPrice: number;
  loadingFinishRound: boolean;
  loadingCancelRound: boolean;

  favoriteStatus: boolean;
  favoriteTokens: FavoriteToken[];
}

const initialState: PredictState = {
  rounds: [],
  liveRounds: [],
  countPerPage: 12,
  myRoundCountPerPage: 8,
  totalRoundsCount: 0,
  liveTotalRoundsCount: 0,
  loadingRounds: false,
  loadingLiveRounds: false,
  allRounds: [],
  loadingAllRounds: false,
  allRoundsCount: 0,
  homeLiveRounds: [],
  homeOpenRounds: [],
  hasMoreRounds: true,
  hasMoreAllRounds: true,
  tokens: [],
  prices: {
    priceUsd: 0,
    priceChange: 0,
  },
  roundMaxValue: 0,
  roundCount: 0,
  loadingRoundMaxValue: false,
  newRoundInfo: {
    term: true,
    yesNo: false,
    priceToken: undefined,
    operator: -1,
    pricePoint: 1,
    endTime: new Date().getTime() + 3600000,
    odd: 500,
    stableCoinOnly: false,
    onlyOneTaker: true,
    isPrivate: false,
    startEarlyEnabled: false,
    customComment: "",
    initialBetAmount: 1000,
    isExpirePick: false,
    expireTime: new Date().getTime() + 3600000,
    isExpirePercent: false,
    expirePercent: 20,
    isCustomComment: false,
    isMaxUSD: false,
    maxUSDPerPlayer: 0,
    isMinUSD: false,
    minUSDPerPlayer: 0,
  },
  roundInfo: null,
  metaInfo: {},
  loadingMetaInfo: false,
  loadingRoundInfo: false,
  betTokens: [],
  loadingBetTokens: false,
  tokenBalances: {},
  loadingTokenBalances: false,
  enteringRound: false,
  creatingRound: false,
  tokenLps: [],
  loadingTokenLps: false,
  lpStatus: 0,
  detailTokenPrice: 0,
  betUSDAmount: 0,
  loadingFinishRound: false,
  loadingCancelRound: false,
  favoriteStatus: false,
  favoriteTokens: [],
};

interface FilterOptions {
  statusValue?: number;
  sortValue: number;
  coinValue: string[];
  minValue: number;
  maxValue: number;
  activePage: number;
  countPerPage: number;
  player?: string;
  typeValue: string;
  roundsType: number;
  initStatus: boolean;
}

export interface FavoriteToken {
  id: number;
  address: string;
  tokenAddress: string;
}
export interface PredictToken {
  token: string;
  img: string;
  name: string;
  chain: string;
  id: number;
  pair: string;
  dexLink: string;
  chartLink: string;
  disabled?: boolean;
  price?: number;
  tokenAddress?: string;
  favorite?: boolean;
}

export interface PredictRound {
  creator: string;
  creatorUsdValue: number;
  currentUsdValue: number;
  endTime: number;
  expireTime: number;
  feeAmount: number;
  isCreatorWinner: number;
  maxUsdPerPlayer: number;
  minUsdPerPlayer: number;
  roundMaximumValue: number;
  operator: number;
  pricePoint: number;
  priceTokenName: string;
  roundId: number;
  startTime: number;
  status: number;
  expireWithdrawStatus: number;
  createdTime: number;
  recordedPrice: number;
  isPrivate: boolean;
  comments: string;
  txHash: string;
  isQuickBet: number;
  duration: number;
}

export interface PredictBet {
  player: string;
  betTokenId: number;
  usdValue: number;
}

export interface PredictNewRoundInfo {
  term?: boolean;
  yesNo?: boolean;
  priceToken?: PredictToken;
  operator?: number;
  pricePoint?: number;
  endTime?: number;
  odd?: number;
  stableCoinOnly?: boolean;
  onlyOneTaker?: boolean;
  isPrivate?: boolean;
  startEarlyEnabled?: boolean;
  customComment?: string;
  initialBetAmount?: number;
  isExpirePick?: boolean;
  expireTime?: number;
  isExpirePercent?: boolean;
  expirePercent?: number;
  isCustomComment?: boolean;
  isMaxUSD?: boolean;
  maxUSDPerPlayer?: number;
  isMinUSD?: boolean;
  minUSDPerPlayer?: number;
}

export interface PredictRoundInfo {
  bets: PredictBet[];
  creator: string;
  currentTotalUsd: number;
  endTime: number;
  createdTime: number;
  expireTime: number;
  isCreatorWinner: boolean;
  maxUsdPerPlayer: number;
  minUsdPerPlayer: number;
  roundMaximumValue: number;
  operator: number;
  pricePoint: number;
  priceTokenId: number;
  roundId: number;
  startTime: number;
  status: number;
  expireWithdrawStatus: number;
  recordedPrice: number;
  onlyOneTaker: boolean;
  isPrivate: boolean;
  startEarlyEnabled: boolean;
  isQuickBet: number;
}
export interface PriceTokenLp {
  dexId: string;
  baseToken: BaseToken;
  quoteToken: QuoteToken;
  pairCreatedAt: number;
  pairAddress: string;
  liquidity: number;
}

export interface BaseToken {
  address: string;
  name: string;
  symbol: string;
}

export interface QuoteToken {
  address: string;
  name: string;
  symbol: string;
}

export const getRounds = createAsyncThunk(
  "predict/getRounds",
  async ({
    statusValue,
    sortValue,
    coinValue,
    minValue,
    maxValue,
    activePage,
    countPerPage,
    player,
    typeValue,
    roundsType,
    initStatus,
  }: FilterOptions) => {
    const { data } = await axios.get(
      `${gameConfig.serverUrl}prediction/rounds/${
        (activePage - 1) * countPerPage
      }/${countPerPage}`,
      {
        params: {
          status: statusValue,
          min: minValue,
          max: maxValue,
          sort: sortValue,
          priceTokenNames: coinValue.toString(),
          direction: "ASC",
          player: player ? player : undefined,
          type: typeValue,
        },
      }
    );
    return data;
  }
);

export const getPriceTokens = createAsyncThunk(
  "predict/getPriceTokens",
  async () => {
    const [tokens, prices] = await readPriceTokens();
    return { tokens, prices };
  }
);

export const getRoundMaxValue = createAsyncThunk(
  "predict/getRoundMaxValue",
  async () => {
    const { data } = await axios.get(
      `${gameConfig.serverUrl}prediction/info/maxRound`
    );
    const res = await axios.get(
      `${gameConfig.serverUrl}prediction/info/roundCount`
    );
    return { maxRound: data, roundCount: res.data };
  }
);

export const getTokenPrice = createAsyncThunk(
  "predict/getTokenPrice",
  async ({ tx }: { tx: string }) => {
    const { data } = await axios.get(tx);
    return data;
  }
);

export const getTokenPriceFromContract = createAsyncThunk(
  "predict/getTokenPriceFromContract",
  async ({ tokenId }: { tokenId: number }) => {
    const data = await getPredictToken(tokenId);
    return ethers.utils.formatEther(data);
  }
);

export const getRoundInfo = createAsyncThunk(
  "predict/getRoundInfo",
  async (id: number) => {
    const res = await readRoundInfo(id);
    res.expireWithdrawStatus = Number(res.status);
    if (
      Number(res.status) === PredictRoundStatus.Waiting &&
      res.endTime < new Date().getTime() / 1000
    ) {
      res.status = PredictRoundStatus.Expired;
    }

    const roundInfo: PredictRoundInfo = {
      bets: res.bets.map((bet: any) => ({
        player: bet.player,
        betTokenId: Number(bet.betTokenId),
        usdValue: Number(ethers.utils.formatUnits(bet.usdAmount, 18)),
      })),
      creator: res.creator,
      currentTotalUsd: Number(
        ethers.utils.formatUnits(res.currentTotalUSD, 18)
      ),
      endTime: Number(res.endTime),
      createdTime: Number(res.createdTime),
      expireTime: Number(res.expireTime),
      isCreatorWinner: res.isCreatorWinner,
      maxUsdPerPlayer: Number(
        ethers.utils.formatUnits(res.maxUSDPerPlayer, 18)
      ),
      minUsdPerPlayer: Number(
        ethers.utils.formatUnits(res.minUSDPerPlayer, 18)
      ),
      roundMaximumValue: Number(
        ethers.utils.formatUnits(res.roundMaximumValue, 18)
      ),
      operator: Number(res.operator),
      pricePoint: Number.parseFloat(ethers.utils.formatEther(res.pricePoint)),
      priceTokenId: Number(res.priceTokenId),
      roundId: Number(res.roundId),
      startTime: Number(res.startTime),
      status: Number(res.status),
      expireWithdrawStatus: Number(res.expireWithdrawStatus),
      recordedPrice: Number.parseFloat(
        ethers.utils.formatEther(res.recordedPrice)
      ),
      onlyOneTaker: res.onlyOneTaker,
      isPrivate: res.isPrivate,
      startEarlyEnabled: res.startEarlyEnabled,
      isQuickBet: res.isQuickBet ? 1 : 0,
    };
    return roundInfo;
  }
);

export const getRoundMetaInfo = createAsyncThunk(
  "predict/getRoundMetaInfo",
  async (id: number) => {
    const { data } = await axios.get(
      `${gameConfig.serverUrl}prediction/info/round/${id}`
    );
    return data;
  }
);

export const getBetTokens = createAsyncThunk(
  "predict/getBetTokens",
  async () => {
    const result = await getPredictBetTokens();
    return result;
  }
);

export const getTokenBalances = createAsyncThunk(
  "predict/getTokenBalances",
  async (account: string) => {
    const balanceArray = await Promise.all(
      acceptedTokens.map(async (token) => {
        const balance = await getTokenBalance(token.address, account);
        return { [token.name]: balance };
      })
    );
    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});
    return balances;
  }
);

export const enterRound = createAsyncThunk(
  "predict/enterRound",
  async ({
    tokenId,
    tokenAddress,
    tokenAmount,
    account,
    roundId,
    usdAmount,
  }: {
    tokenId: number;
    tokenAddress: string;
    tokenAmount: number;
    account: string;
    roundId: number;
    usdAmount: number;
  }) => {
    await enterPredictRound(
      tokenId,
      tokenAddress,
      tokenAmount,
      account,
      roundId,
      usdAmount
    );
  }
);

export const saveCustomComment = createAsyncThunk(
  "predict/saveCustomComment",
  async ({
    isCustomComment,
    customComment,
    roundId,
  }: {
    isCustomComment: boolean;
    customComment: string;
    roundId: number;
  }) => {
    if (isCustomComment) {
      const resComment = await axios.post(
        `${gameConfig.serverUrl}prediction/comment`,
        {
          roundId,
          comment: customComment,
        }
      );
      return resComment;
    } else return null;
  }
);

export const finishRound = createAsyncThunk(
  "predict/finishRound",
  async ({ roundId, account }: { roundId: number; account: string }) => {
    await finishPredictRound(roundId, account);
  }
);

export const cancelRound = createAsyncThunk(
  "predict/cancelRound",
  async ({ roundId, account }: { roundId: number; account: string }) => {
    await cancelPredictRound(roundId, account);
  }
);

export const createPredict = createAsyncThunk(
  "/predict/createRound",
  async (
    {
      account,
      tokenId,
      tokenAmount,
      isQuickBet,
      duration,
    }: {
      account: string;
      tokenId: number;
      tokenAmount: number;
      isQuickBet: boolean;
      duration: number;
    },
    { getState }
  ) => {
    const getStates: any = getState();
    const roundInfo = getStates.predict.newRoundInfo;
    const betUSDAmount = getStates.predict.betUSDAmount;
    const betTokens = getStates.predict.betTokens;
    let newRoundInfo: any = {};
    newRoundInfo.priceTokenId = roundInfo.priceToken.index ?? 0;
    newRoundInfo.endTime = Math.floor(roundInfo.endTime / 1000);
    if (!roundInfo.isExpirePick && !roundInfo.isExpirePercent)
      newRoundInfo.expireTime = 0;
    else {
      if (roundInfo.isExpirePick)
        newRoundInfo.expireTime = Math.floor(roundInfo.expireTime / 1000);
      else if (roundInfo.isExpirePercent) {
        newRoundInfo.expireTime = Math.floor(
          (new Date().getTime() +
            ((-new Date().getTime() + roundInfo.endTime) *
              Number(roundInfo.expirePercent)) /
              100) /
            1000
        );
      } else
        newRoundInfo.expireTime = Math.floor(
          (new Date().getTime() +
            ((-new Date().getTime() + roundInfo.endTime) * 20) / 100) /
            1000
        );
    }
    newRoundInfo.pricePoint = ethers.utils
      .parseEther(roundInfo.pricePoint.toFixed(18))
      .toString();
    newRoundInfo.operator = roundInfo.operator;
    if (roundInfo.isMaxUSD)
      newRoundInfo.maxUSDPerPlayer = ethers.utils
        .parseEther(roundInfo.maxUSDPerPlayer.toString())
        .toString();
    else newRoundInfo.maxUSDPerPlayer = ethers.utils.parseEther("0").toString();
    if (roundInfo.isMinUSD)
      newRoundInfo.minUSDPerPlayer = ethers.utils
        .parseEther(roundInfo.minUSDPerPlayer.toString())
        .toString();
    else
      newRoundInfo.minUSDPerPlayer = ethers.utils
        .parseEther(
          (
            (roundInfo?.initialBetAmount * (1000 - roundInfo?.odd)) /
            (140 * 1000)
          ).toFixed(18)
        )
        .toString();
    newRoundInfo.roundMaximumValue = ethers.utils
      .parseEther(roundInfo.initialBetAmount.toString())
      .toString();
    newRoundInfo.stableCoinOnly = roundInfo.stableCoinOnly;
    newRoundInfo.onlyOneTaker = roundInfo.onlyOneTaker;
    newRoundInfo.isPrivate = roundInfo.isPrivate;
    newRoundInfo.startEarlyEnabled = roundInfo.startEarlyEnabled;
    newRoundInfo.initialBet = [
      tokenId,
      ethers.utils.parseEther(betUSDAmount.toString()).toString(),
    ];
    // newRoundInfo.expireTime ?? (newRoundInfo.expireTime = newRoundInfo.endTime);
    console.log({ newRoundInfo });
    await createPredictRound(
      newRoundInfo,
      account,
      tokenAmount,
      betTokens[tokenId],
      isQuickBet,
      duration
    );
  }
);

export const getPriceTokenLp = createAsyncThunk(
  "/predict/getPriceTokenLp",
  async (pairs: string[]) => {
    const middleIndex = 20;
    const data1: any = await axios.get(
      `https://api.dexscreener.com/latest/dex/pairs/pulsechain/${pairs
        .slice(0, middleIndex)
        .join(",")}`
    );
    const data2: any = await axios.get(
      `https://api.dexscreener.com/latest/dex/pairs/pulsechain/${pairs
        .slice(middleIndex)
        .join(",")}`
    );
    return [...data1.data.pairs, ...data2.data.pairs];
  }
);

export const getPriceOneToken = createAsyncThunk(
  "/predict/getPriceOneToken",
  async (tokenId: number) => {
    const data = await getPredictToken(tokenId);
    return ethers.utils.formatEther(data);
  }
);

export const addFavoriteToken = createAsyncThunk(
  "/predict/addFavoriteToken",
  async ({ account, address }: { account: string; address: string }) => {
    await axios.post(`${gameConfig.serverUrl}favorite/${account}/${address}`);
    return true;
  }
);

export const removeFavoriteToken = createAsyncThunk(
  "/predict/removeFavoriteToken",
  async ({ account, address }: { account: string; address: string }) => {
    await axios.delete(`${gameConfig.serverUrl}favorite/${account}/${address}`);
    return true;
  }
);

export const getFavoriteToken = createAsyncThunk(
  "/predict/getFavoriteToken",
  async (account: string) => {
    const { data } = await axios.get(
      `${gameConfig.serverUrl}favorite/${account}`
    );
    return data;
  }
);

export const predictSlice = createSlice({
  name: "predict",
  initialState,
  reducers: {
    initRoundInfo: (state) => {
      state.newRoundInfo = {
        term: false,
        yesNo: false,
        priceToken: undefined,
        operator: -1,
        pricePoint: 1,
        endTime: new Date().getTime(),
        odd: 500,
        stableCoinOnly: false,
        onlyOneTaker: true,
        isPrivate: false,
        startEarlyEnabled: false,
        customComment: "",
        initialBetAmount: 1000,
        isExpirePick: false,
        expireTime: new Date().getTime(),
        isExpirePercent: false,
        expirePercent: 20,
        isCustomComment: false,
        isMaxUSD: false,
        maxUSDPerPlayer: 10,
        isMinUSD: true,
        minUSDPerPlayer: 3.5,
      };
    },

    setLpStatus: (state, { payload }) => {
      state.lpStatus = payload;
    },

    setPriceToken: (state, { payload: { item, index } }) => {
      if (item !== undefined && index !== undefined) {
        state.newRoundInfo = {
          ...state.newRoundInfo,
          priceToken: { ...item, index },
        };
      }
    },
    setOperator: (state, { payload }: { payload: number }) => {
      state.newRoundInfo = { ...state.newRoundInfo, operator: payload };
    },
    setPricePoint: (state, { payload }) => {
      state.newRoundInfo = {
        ...state.newRoundInfo,
        pricePoint: Number(payload),
      };
    },
    setEndTime: (state, { payload }) => {
      state.newRoundInfo = {
        ...state.newRoundInfo,
        endTime: payload,
      };
    },
    setOdd: (state, { payload }) => {
      const minUSDPerPlayer = ceilDecimal(
        (Number(state.newRoundInfo.initialBetAmount) *
          (1000 - Number(payload))) /
          (1000 * 140),
        2
      );

      state.newRoundInfo = {
        ...state.newRoundInfo,
        odd: Number(payload),
        minUSDPerPlayer,
      };
    },
    setInitialBetAmount: (state, { payload }) => {
      const minUSDPerPlayer = ceilDecimal(
        (Number(payload) * (1000 - Number(state.newRoundInfo.odd))) /
          (1000 * 140),
        2
      );

      state.newRoundInfo = {
        ...state.newRoundInfo,
        initialBetAmount: Number(payload),
        minUSDPerPlayer,
      };
    },
    setIsPrivate: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, isPrivate: payload };
    },
    setOnlyOneTaker: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, onlyOneTaker: payload };
    },
    setIsMaxUSD: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, isMaxUSD: payload };
    },
    setIsMinUSD: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, isMinUSD: payload };
    },
    setIsExpirePick: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, isExpirePick: payload };
    },
    setExpireTime: (state, { payload }) => {
      state.newRoundInfo = {
        ...state.newRoundInfo,
        expireTime: payload,
      };
    },
    setIsExpirePercent: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, isExpirePercent: payload };
    },
    setExpirePercent: (state, { payload }) => {
      state.newRoundInfo = {
        ...state.newRoundInfo,
        expirePercent: Number(String(payload).replace("%", "").trim()),
      };
    },
    setIsCustomComment: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, isCustomComment: payload };
    },
    setCustomComment: (state, { payload }) => {
      state.newRoundInfo = { ...state.newRoundInfo, customComment: payload };
    },
    setBetUSDAmount: (state, { payload }) => {
      state.betUSDAmount = payload;
    },
    setDetailTokenPrice: (state, { payload }) => {
      state.detailTokenPrice = payload;
    },
    initDetailData: (state) => {
      state.roundInfo = null;
      state.metaInfo = {};
      state.detailTokenPrice = 0;
    },
    setRounds: (state) => {
      state.rounds = [];
    },
    setRoundStatus: (state, { payload }) => {
      if (state.roundInfo) {
        state.roundInfo = { ...state.roundInfo, status: payload };
      }
    },
    initDetailRoundInfo: (state) => {
      state.roundInfo = null;
      state.metaInfo = {};
    },
    setHasMoreRounds: (state, { payload }) => {
      state.hasMoreRounds = payload;
    },
    setHasMoreAllRounds: (state, { payload }) => {
      state.hasMoreAllRounds = payload;
    },
    setFavoriteToken: (state) => {
      state.favoriteTokens = [];
    },
    setStatusForRounds: (state, { payload }) => {
      state.rounds = state.rounds.map((round) => {
        if (round.roundId === payload.roundId) {
          return { ...round, status: payload.status };
        }
        return round;
      });
    },
    setCreatingStatus: (state, { payload }) => {
      state.creatingRound = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRounds.pending, (state, { meta }) => {
      if (
        meta.arg.roundsType ===
          PredictionGettingRoundsType.NormalsPredictIndex ||
        meta.arg.roundsType === PredictionGettingRoundsType.NormalRoundsProfile
      ) {
        state.loadingRounds = true;
      } else if (
        meta.arg.roundsType ===
          PredictionGettingRoundsType.LiveRoundsPredictIndex ||
        meta.arg.roundsType === PredictionGettingRoundsType.LiveRoundsProfile
      ) {
        state.loadingLiveRounds = true;
      } else if (
        meta.arg.roundsType === PredictionGettingRoundsType.AllRoundsProfile
      ) {
        state.loadingAllRounds = true;
      }
    });
    builder.addCase(getRounds.fulfilled, (state, { payload, meta }) => {
      let rounds: PredictRound[] = [];
      payload?.rounds?.forEach((item: any) => {
        const round: PredictRound = {
          creator: item.creator,
          creatorUsdValue: Number(item.creatorUsdValue),
          currentUsdValue: Number(item.currentUsdValue),
          endTime: Number(item.endTime),
          expireTime: Number(item.expireTime),
          feeAmount: Number(item.feeAmount),
          isCreatorWinner: item.isCreatorWinner,
          maxUsdPerPlayer: Number.parseFloat(item.maxUsdPerPlayer),
          minUsdPerPlayer: Number.parseFloat(item.minUsdPerPlayer),
          roundMaximumValue: Number.parseFloat(item.roundMaximumValue),
          operator: Number(item.operator),
          pricePoint: Number(item.pricePoint),
          priceTokenName: item.priceTokenName,
          roundId: Number(item.roundId),
          startTime: Number(item.startTime),
          status: Number(item.status),
          expireWithdrawStatus: Number(item.expireWithdrawStatus),
          createdTime: Number(item.createdTime),
          recordedPrice: Number.parseFloat(item.recordedPrice),
          isPrivate: Boolean(item.isPrivate),
          comments: item.comment,
          txHash: item.txHash,
          isQuickBet: item.isQuickBet ? 1 : 0,
          duration: item.duration / 60,
        };
        rounds.push(round);
      });
      if (
        meta.arg.roundsType ===
          PredictionGettingRoundsType.NormalsPredictIndex ||
        meta.arg.roundsType === PredictionGettingRoundsType.NormalRoundsProfile
      ) {
        if (rounds.length === 0) {
          state.hasMoreRounds = false;
        }
        meta.arg.initStatus
          ? (state.rounds = [...rounds])
          : (state.rounds = [...state.rounds, ...rounds]);
        state.totalRoundsCount = payload.totalCount;
        state.loadingRounds = false;
      } else if (
        meta.arg.roundsType ===
          PredictionGettingRoundsType.LiveRoundsPredictIndex ||
        meta.arg.roundsType === PredictionGettingRoundsType.LiveRoundsProfile
      ) {
        state.liveRounds = [...rounds];
        state.liveTotalRoundsCount = payload.totalCount;
        state.loadingLiveRounds = false;
      } else if (
        meta.arg.roundsType === PredictionGettingRoundsType.LiveRoundsHomeIndex
      ) {
        state.homeLiveRounds = [...rounds];
      } else if (
        meta.arg.roundsType === PredictionGettingRoundsType.OpenRoundsHomeIndex
      ) {
        state.homeOpenRounds = [...rounds];
      } else if (
        meta.arg.roundsType === PredictionGettingRoundsType.AllRoundsProfile
      ) {
        meta.arg.initStatus
          ? (state.allRounds = [...rounds])
          : (state.allRounds = [...state.allRounds, ...rounds]);
        state.allRoundsCount = payload.totalCount;
        state.loadingAllRounds = false;
      }
    });
    builder.addCase(getRounds.rejected, (state, { error, meta }) => {
      console.log({ error });
      if (
        meta.arg.roundsType === PredictionGettingRoundsType.NormalsPredictIndex
      ) {
        state.loadingRounds = false;
      } else if (
        meta.arg.roundsType ===
        PredictionGettingRoundsType.LiveRoundsPredictIndex
      ) {
        state.loadingLiveRounds = false;
      } else if (
        meta.arg.roundsType === PredictionGettingRoundsType.AllRoundsProfile
      ) {
        state.loadingAllRounds = false;
      }
    });

    builder.addCase(getPriceTokens.pending, (state) => {
      state.tokens = [];
    });
    builder.addCase(getPriceTokens.fulfilled, (state, { payload }) => {
      state.tokens = payload.tokens;
      if (payload.tokens.length && payload.prices.length) {
        state.tokens = payload.tokens
          .map((item: any, index: number) => {
            const tokenInfo: PredictToken | undefined = TokenList.find(
              (t) => t.token.toLowerCase() === item.name.toLowerCase()
            );
            if (!tokenInfo) return null;
            return {
              ...tokenInfo,
              price: payload.prices[index],
              tokenName: item.name,
              tokenAddress: item.tokenAddress,
            };
          })
          .filter((token) => token !== null) as PredictToken[];
      }
    });
    builder.addCase(getPriceTokens.rejected, (state, { error }) => {
      console.log({ error });
    });

    builder.addCase(getRoundMaxValue.pending, (state) => {
      state.loadingRoundMaxValue = true;
    });
    builder.addCase(getRoundMaxValue.fulfilled, (state, { payload }) => {
      state.roundMaxValue = Number(payload.maxRound.maxValue);
      state.roundCount = Number(payload.roundCount.roundCount);
      state.loadingRoundMaxValue = false;
    });
    builder.addCase(getRoundMaxValue.rejected, (state, { error }) => {
      console.log({ error });
      state.loadingRoundMaxValue = false;
    });

    builder.addCase(getRoundInfo.pending, (state) => {
      state.roundInfo = null;
      state.loadingRoundInfo = true;
    });
    builder.addCase(getRoundInfo.fulfilled, (state, { payload }) => {
      state.roundInfo = payload;
      state.loadingRoundInfo = false;
    });
    builder.addCase(getRoundInfo.rejected, (state, { error }) => {
      console.log({ error });
      state.loadingRoundInfo = false;
    });

    builder.addCase(getRoundMetaInfo.pending, (state) => {
      state.metaInfo = {};
      state.loadingMetaInfo = true;
    });
    builder.addCase(getRoundMetaInfo.fulfilled, (state, { payload }) => {
      state.metaInfo = { ...payload.roundData, ...payload.betData };
      state.loadingMetaInfo = false;
    });
    builder.addCase(getRoundMetaInfo.rejected, (state, { error }) => {
      console.log({ error });
      state.loadingMetaInfo = false;
    });

    builder.addCase(getBetTokens.pending, (state) => {
      state.loadingBetTokens = true;
    });
    builder.addCase(getBetTokens.fulfilled, (state, { payload }) => {
      state.betTokens = payload;
      state.loadingBetTokens = false;
    });
    builder.addCase(getBetTokens.rejected, (state, { error }) => {
      state.betTokens = [];
      state.loadingBetTokens = false;
      console.log({ error });
    });

    builder.addCase(getTokenBalances.pending, (state) => {
      state.loadingTokenBalances = true;
    });
    builder.addCase(getTokenBalances.fulfilled, (state, { payload }) => {
      state.tokenBalances = payload;
      state.loadingTokenBalances = false;
    });
    builder.addCase(getTokenBalances.rejected, (state, { error }) => {
      state.tokenBalances = {};
      state.loadingBetTokens = false;
      console.log({ error });
    });

    builder.addCase(enterRound.pending, (state) => {
      state.enteringRound = true;
    });
    builder.addCase(enterRound.fulfilled, (state) => {
      state.enteringRound = false;
      Toast.fire({
        icon: "success",
        title: "Placed bet successfully",
      });
    });
    builder.addCase(enterRound.rejected, (state, { error }) => {
      state.enteringRound = false;
      Toast.fire({
        icon: "error",
        title: "Failed to bet",
      });
      console.log({ error });
    });

    builder.addCase(createPredict.pending, (state) => {
      state.creatingRound = true;
    });
    builder.addCase(createPredict.fulfilled, (state) => {
      // state.creatingRound = false;
      // Toast.fire({
      //   icon: "success",
      //   title: "Placed bet successfully",
      // });
    });
    builder.addCase(createPredict.rejected, (state, error) => {
      state.creatingRound = false;
      Toast.fire({
        icon: "error",
        title: "Failed to bet",
      });
      console.log({ error });
    });

    builder.addCase(finishRound.pending, (state) => {
      state.loadingFinishRound = true;
    });
    builder.addCase(finishRound.fulfilled, (state) => {
      state.loadingFinishRound = false;
      Toast.fire({
        icon: "success",
        title: "Finalized successfully",
      });
    });
    builder.addCase(finishRound.rejected, (state, error) => {
      state.loadingFinishRound = false;
      Toast.fire({
        icon: "error",
        title: "Failed to finalize",
      });
      console.log({ error });
    });

    builder.addCase(cancelRound.pending, (state) => {
      state.loadingCancelRound = true;
    });
    builder.addCase(cancelRound.fulfilled, (state) => {
      state.loadingCancelRound = false;
      Toast.fire({
        icon: "success",
        title: "Canceled successfully",
      });
    });
    builder.addCase(cancelRound.rejected, (state, error) => {
      state.loadingCancelRound = false;
      Toast.fire({
        icon: "error",
        title: "Failed to cancel",
      });
      console.log({ error });
    });

    builder.addCase(getPriceTokenLp.pending, (state) => {
      state.loadingTokenLps = true;
    });
    builder.addCase(getPriceTokenLp.fulfilled, (state, { payload }) => {
      state.loadingTokenLps = false;
      state.tokenLps = payload.map((pair, index) => {
        return {
          dexId: pair.dexId,
          baseToken: pair.baseToken,
          quoteToken: pair.quoteToken,
          pairCreatedAt: pair.pairCreatedAt,
          pairAddress: pair.pairAddress,
          liquidity: pair.liquidity.usd,
        };
      });
    });
    builder.addCase(getPriceTokenLp.rejected, (state, { error }) => {
      state.loadingTokenLps = false;
    });

    builder.addCase(getPriceOneToken.pending, (state) => {
      state.detailTokenPrice = 0;
    });
    builder.addCase(getPriceOneToken.fulfilled, (state, { payload }) => {
      state.detailTokenPrice = Number(payload);
    });
    builder.addCase(getPriceOneToken.rejected, (state, { error }) => {});

    builder.addCase(getTokenPrice.pending, (state) => {});
    builder.addCase(getTokenPrice.fulfilled, (state, { payload }) => {
      state.prices = {
        ...state.prices,
        priceChange: payload?.pair?.priceChange,
      };
    });
    builder.addCase(getTokenPrice.rejected, (state) => {
      state.prices = {};
    });

    builder.addCase(getTokenPriceFromContract.pending, (state) => {});
    builder.addCase(
      getTokenPriceFromContract.fulfilled,
      (state, { payload }) => {
        state.prices = {
          ...state.prices,
          priceUsd: Number(payload),
        };
      }
    );
    builder.addCase(getTokenPriceFromContract.rejected, (state) => {
      state.prices = { ...state.prices, priceUsd: 0 };
    });

    builder.addCase(addFavoriteToken.pending, () => {});
    builder.addCase(addFavoriteToken.fulfilled, () => {});
    builder.addCase(addFavoriteToken.rejected, () => {});

    builder.addCase(removeFavoriteToken.pending, () => {});
    builder.addCase(removeFavoriteToken.fulfilled, () => {});
    builder.addCase(removeFavoriteToken.rejected, () => {});

    builder.addCase(getFavoriteToken.pending, () => {});
    builder.addCase(getFavoriteToken.fulfilled, (state, { payload }) => {
      state.favoriteTokens = payload;
    });
    builder.addCase(getFavoriteToken.rejected, () => {});
  },
});

export const {
  setLpStatus,
  setPriceToken,
  setOperator,
  setPricePoint,
  setEndTime,
  setOdd,
  setInitialBetAmount,
  setIsPrivate,
  setOnlyOneTaker,
  setIsMaxUSD,
  setIsMinUSD,
  setIsExpirePick,
  setExpireTime,
  setIsExpirePercent,
  setExpirePercent,
  setIsCustomComment,
  setCustomComment,
  setBetUSDAmount,
  initRoundInfo,
  setDetailTokenPrice,
  initDetailData,
  setRounds,
  setRoundStatus,
  initDetailRoundInfo,
  setHasMoreRounds,
  setHasMoreAllRounds,
  setFavoriteToken,
  setStatusForRounds,
  setCreatingStatus,
} = predictSlice.actions;

export default predictSlice.reducer;
