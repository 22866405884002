import { useState, useEffect } from "react";
import useStyles from "./index.styles";
import { Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

import CircularLogo from "../CircularLogo";
import { shortAddress, shortName, toUSDFormat } from "../../utils";
import { UserInfo } from "../../reducers/user.slice";
import { dailyRankBonus } from "../../constants";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import Blocky from "../Blocky";
import clsx from "clsx";
interface LeaderBoardTablePros {
  data: UserInfo[];
}

export default function LeaderBoardTable(props: LeaderBoardTablePros) {
  const { classes } = useStyles();
  const { profiles } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(props.data.length > 0);
  const [page, setPage] = useState(1);
  const perPage = 50;
  const [newData, setNewData] = useState(props.data.slice(0, page * perPage));
  const handleNext = () => {
    setPage((page) => {
      return page + 1;
    });
  };
  const getProfileImg = (address: string) => {
    const profile = profiles.find(
      (profile) => profile.address.toLowerCase() === address
    );
    return profile && profile.avatar.length > 0 ? (
      <Box
        component={"img"}
        src={profile.avatar}
        alt=""
        width={30}
        height={30}
        sx={{
          borderRadius: "50%",
        }}
      />
    ) : (
      <Blocky
        address={address}
        size={30}
        scale={1}
        sx={{
          borderRadius: "50%",
        }}
      />
    );
  };

  const getProfileName = (address: string) => {
    const profile = profiles.find(
      (profile) => profile.address.toLowerCase() === address
    );
    return profile
      ? profile.name.length > 15
        ? shortName(profile.name)
        : profile.name
      : shortAddress(address);
  };

  const toProfile = (address: string) => {
    navigate("/profile/" + address);
  };

  useEffect(() => {
    if (page > 1) {
      setNewData((data) => {
        return [...data, ...props.data.slice((page - 1) * 50, page * 50)];
      });
    }
  }, [page]);

  return (
    <Box className={classes.body}>
      <Box className={classes.mainTableBody}>
        <Box className={classes.header}>
          <Box>Global rank #</Box>
          <Box>Player name</Box>
          <Box>Daily rank bonus</Box>
          <Box>Win %</Box>
          <Box>Total bets</Box>
          <Box>Profit / Loss</Box>
        </Box>
        <Box className={classes.bodySection} id={"scrollable-div"}>
          <InfiniteScroll
            dataLength={newData.length}
            hasMore={hasMore}
            next={handleNext}
            scrollableTarget="scrollable-div"
            loader={<Box className={classes.loading}></Box>}
            className={classes.infiniteBody}
          >
            {newData.map((dat: UserInfo, index: number) => (
              <Box
                className={classes.tableBody}
                onClick={() => toProfile(dat.address)}
                key={index}
              >
                <Box className={classes.rankDetail}>
                  <Box component={"img"} src="/images/prediction/quality.png" />
                  {index + 1}
                </Box>
                <Box className={classes.playerDetail}>
                  {getProfileImg(dat.address.toLowerCase())}
                  {getProfileName(dat.address.toLowerCase())}
                </Box>
                <Box className={classes.airdropDetail}>
                  <CircularLogo width={25} />+
                  {toUSDFormat(dailyRankBonus[index])}
                </Box>
                <Box className={classes.winPercentDetail}>
                  {toUSDFormat(
                    ((dat.quickRoundsWon + 0.0 + dat.advancedRoundsWon) /
                      (dat.totalBets - 0.0)) *
                      100.0,
                    0
                  )}
                </Box>
                <Box className={classes.totalBetsDetail}>
                  {toUSDFormat(dat.totalBets)}
                </Box>

                {dat.profitLoss > 0 ? (
                  <Box className={clsx(classes.profitLossDetail, classes.plus)}>
                    +$ {toUSDFormat(dat.profitLoss)}
                  </Box>
                ) : (
                  <Box
                    className={clsx(classes.profitLossDetail, classes.minus)}
                  >
                    -$ {toUSDFormat(Math.abs(dat.profitLoss))}
                  </Box>
                )}
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
}
