import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    textTransform: "none",
    color: "white",
    justifyContent: "space-between",
    "& img": {
      width: "22px",
    },
    fontSize: "17px",
  },
  card: {
    display: "flex",
    padding: "40px 40px",
    backgroundColor: theme.palette.background.predictionCard,
    marginTop: "20px",
    flexDirection: "column",
    borderRadius: "10px",
  },
  topContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
    "&>div": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      fontSize: "15px",
      "&>div:nth-of-type(1)": {
        color: theme.palette.text.dark,
      },
      "&>div": {
        display: "flex",
        gap: "5px",
      },
    },
    "& img": {
      width: "22px",
      height: "22px",
      cursor: "pointer",
    },
  },
  bottomContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "80px",
    "&>div": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "17px",
      padding: "8px 0px",
      "&:not(:last-child)": {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.text.dark,
      },
      "&:last-child>div:last-child": {
        color: theme.palette.text.increase,
      },
    },
  },
  badge: {
    borderRadius: "8px",
    padding: "2px 10px",
    "&.good": {
      backgroundColor: "#4CAF50EE",
    },
    "&.caution": {
      backgroundColor: "#ad9e1eee",
    },
    "&.risky": {
      backgroundColor: "#bf7a15ee",
    },
    "&.danger": {
      backgroundColor: "#F44336EE",
    },
  },
  actionButton: {
    padding: "10px 0",
    marginTop: "20px",
    borderRadius: "10px",
    fontSize: "17px",
    textTransform: "none",
    display: "flex",
    gap: "10px",
  },
  acceptButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.card,
    "&:disabled": {
      color: "#ffffff50",
    },
  },
  finalizeButton: {
    color: theme.palette.text.primary,
    backgroundColor: "#419657",
    "&:hover": {
      backgroundColor: "#306e41",
    },
  },
  finalizedButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.card,
    "& svg": {
      color: "#79d50d",
    },
  },
  expiredButton: {
    color: "#918297",
    backgroundColor: theme.palette.background.card,
  },
  createBut: {
    color: theme.palette.text.primary,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.background.card,
    borderRadius: "9px",
    padding: "10px 20px",
    display: "flex",
    textTransform: "none",
    gap: "15px",
    "& img": {
      width: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 5px",
    },
  },
}));

export default styles;
