import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { gameConfig } from "../config";
import { acceptedTokens } from "../config/tokens";
import { BetToken, getTokenBalance } from "../helpers/contractFunctions";
import {
  enterRound,
  enterRoundWithPLS,
  finishRoundfun,
  getEuro24BetTokens,
} from "../helpers/contractFunctions/euro24";
import { EuroEntryInfo } from "../types/EuroEntryInfo";
import { EuroLeaderBoardItem } from "../types/EuroLeaderBoardItem";
import { EuroRoundInfo } from "../types/EuroRoundInfo";
import { Toast } from "../utils";

export interface EuroState {
  betTokens: BetToken[];
  isLoadingBetTokens: boolean;
  tokenBalances: { [key: string]: string };
  isLoadingTokenBalances: boolean;

  roundInfos: EuroRoundInfo[];
  isLoadingRoundInfo: boolean;

  myEntries: EuroEntryInfo[];
  isLoadingMyEntries: boolean;

  leaderboard: EuroLeaderBoardItem[];
  isLoadingLeaderBoard: boolean;

  isEntering: boolean;
  isFinishing: boolean;
}

const initialState: EuroState = {
  betTokens: [],
  isLoadingBetTokens: false,
  tokenBalances: {},
  isLoadingTokenBalances: false,

  roundInfos: [],
  isLoadingRoundInfo: false,

  myEntries: [],
  isLoadingMyEntries: false,

  leaderboard: [],
  isLoadingLeaderBoard: false,

  isEntering: false,
  isFinishing: false,
};

export const getBetTokensList = createAsyncThunk(
  "euro/getBetTokensList",
  async () => {
    const result = await getEuro24BetTokens();
    return result;
  }
);

export const getTokenBalances = createAsyncThunk(
  "euro/getTokenBalances",
  async (account: string) => {
    const balanceArray = await Promise.all(
      acceptedTokens.map(async (token) => {
        const balance = await getTokenBalance(token.address, account);
        return { [token.name]: balance };
      })
    );

    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});

    return balances;
  }
);

export const getRoundInfo = createAsyncThunk("euro/getRoundInfo", async () => {
  const response = await fetch(`${gameConfig.serverUrl}euro24/roundInfo`);

  const result = await response.json();

  return result.roundInfoList as EuroRoundInfo[];
});

export const getRoundInfoById = createAsyncThunk(
  "euro/getRoundInfoById",
  async ({ roundId }: { roundId: number }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${gameConfig.serverUrl}euro24/round/${roundId}`
      );

      const result = await response.json();

      return { data: result.data, roundId };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    // Adding extra meta data
    serializeError: (error: any) => ({
      message: error.message || "Unknown error",
    }),
    getPendingMeta: ({ arg }) => ({ roundId: arg.roundId }),
  }
);

export const getMyBets = createAsyncThunk(
  "euro/getMyBets",
  async ({ account }: { account: string }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${gameConfig.serverUrl}euro24/bet/${account}`
      );

      const result = await response.json();

      return result.roundData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLeaderBoard = createAsyncThunk(
  "euro/getLeaderBoard",
  async () => {
    const response = await fetch(`${gameConfig.serverUrl}euro24/leaderboard`);

    const result = await response.json();

    return result.data;
  }
);

export const finishRound = createAsyncThunk(
  "euro/finishRound",
  async ({
    account,
    roundId,
    result,
  }: {
    account: string;
    roundId: number;
    result: number;
  }) => {
    await finishRoundfun(account, roundId, result);
  }
);

export const enterEuroRound = createAsyncThunk(
  "euro/enterRound",
  async ({
    roundId,
    tokenId,
    tokenAmount,
    tokenAddress,
    usdAmount,
    expectation,
    account,
  }: {
    roundId: number;
    tokenId: number;
    tokenAmount: number;
    tokenAddress: string;
    usdAmount: number;
    expectation: number;
    account: string;
  }) => {
    if (tokenAddress !== ethers.constants.AddressZero) {
      await enterRound(
        roundId,
        tokenId,
        tokenAmount,
        tokenAddress,
        usdAmount,
        expectation,
        account
      );
    } else {
      await enterRoundWithPLS(
        roundId,
        tokenId,
        tokenAmount,
        usdAmount,
        expectation,
        account
      );
    }

    return {
      roundId,
      tokenId,
      tokenAmount,
      tokenAddress,
      usdAmount,
      expectation,
      account,
    };
  }
);

export const euroReducer = createSlice({
  name: "euro",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoundInfo.pending, (state) => {
      state.isLoadingRoundInfo = true;
    });
    builder.addCase(getRoundInfo.fulfilled, (state, { payload }) => {
      state.roundInfos = payload;
      state.isLoadingRoundInfo = false;
    });
    builder.addCase(getRoundInfo.rejected, (state, { error }) => {
      state.isLoadingRoundInfo = true;
    });

    builder.addCase(getRoundInfoById.pending, (state, action) => {
      const {
        arg: { roundId },
      } = action.meta;

      if (state.roundInfos[roundId - 1])
        state.roundInfos[roundId - 1].isLoading = true;
    });
    builder.addCase(getRoundInfoById.fulfilled, (state, { payload }) => {
      const { data, roundId } = payload;

      if (state.roundInfos[roundId - 1]) {
        state.roundInfos[roundId - 1] = {
          ...state.roundInfos[roundId - 1],
          ...data,
        };

        state.roundInfos[roundId - 1].isLoading = false;
      }
    });
    builder.addCase(getRoundInfoById.rejected, (state, { error }) => {
      console.error(`Error fetching round info: ${error.message}`);
    });

    builder.addCase(getMyBets.pending, (state) => {
      state.isLoadingMyEntries = true;
    });
    builder.addCase(getMyBets.fulfilled, (state, { payload }) => {
      state.myEntries = payload;
      state.isLoadingMyEntries = false;
    });
    builder.addCase(getMyBets.rejected, (state, { error }) => {
      state.isLoadingMyEntries = false;
    });

    builder.addCase(getLeaderBoard.pending, (state) => {
      state.isLoadingLeaderBoard = true;
    });
    builder.addCase(getLeaderBoard.fulfilled, (state, { payload }) => {
      state.leaderboard = payload;
      state.isLoadingLeaderBoard = false;
    });
    builder.addCase(getLeaderBoard.rejected, (state, { error }) => {
      state.isLoadingLeaderBoard = false;
    });

    builder.addCase(getBetTokensList.pending, (state) => {
      state.isLoadingBetTokens = true;
    });

    builder.addCase(getBetTokensList.fulfilled, (state, { payload }) => {
      state.isLoadingBetTokens = false;
      state.betTokens = payload;
    });

    builder.addCase(getBetTokensList.rejected, (state, { error }) => {
      state.isLoadingBetTokens = false;
      state.betTokens = [];
      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Failed to get bet token list.",
      });
    });

    builder.addCase(getTokenBalances.pending, (state) => {
      state.isLoadingTokenBalances = true;
    });
    builder.addCase(getTokenBalances.fulfilled, (state, { payload }) => {
      state.tokenBalances = payload;
      state.isLoadingTokenBalances = false;
    });
    builder.addCase(getTokenBalances.rejected, (state, { error }) => {
      state.tokenBalances = {};
      state.isLoadingBetTokens = false;
      console.log(error);
    });

    builder.addCase(enterEuroRound.pending, (state) => {
      state.isEntering = true;
    });

    builder.addCase(enterEuroRound.fulfilled, (state, { payload }) => {
      state.isEntering = false;

      Toast.fire({
        icon: "success",
        title: "Entered round successfully",
      });
    });

    builder.addCase(enterEuroRound.rejected, (state, { error }) => {
      state.isEntering = false;

      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Failed to enter round.",
      });
    });

    builder.addCase(finishRound.pending, (state) => {
      state.isFinishing = true;
    });
    builder.addCase(finishRound.fulfilled, (state, { payload }) => {
      state.isFinishing = false;
      Toast.fire({
        icon: "success",
        title: "Finished round successfully",
      });
    });
    builder.addCase(finishRound.rejected, (state, { error }) => {
      state.isFinishing = false;
      console.log({ error });
      Toast.fire({
        icon: "error",
        title: "Failed to finish round",
      });
    });
  },
});

export default euroReducer.reducer;
