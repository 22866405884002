import { useCallback } from "react";
import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setShowDisclaimer } from "../../reducers/ufc.slice";

import { FCEventType } from "../../constants/ufc";
import { getUFCContractAddress } from "../../helpers/contractFunctions/ufc";
import useStyles from "./index.styles";

interface UfcHeaderProps {
  eventType: number;
}

export default function UfcHeader(props: UfcHeaderProps) {
  const dispatch = useAppDispatch();
  const { eventType } = props;
  const { classes } = useStyles();

  const { currentRoundStatus, loadingRoundStatus } = useAppSelector(
    (state) => state.ufc
  );

  const calculateTimeLeft = useCallback(() => {
    const difference =
      +new Date(currentRoundStatus.closeAt * 1000) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }, [currentRoundStatus.closeAt]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());
    return () => clearInterval(timer);
  }, [loadingRoundStatus, calculateTimeLeft]);

  const handleShowDisclaimer = () => {
    dispatch(setShowDisclaimer(true));
  };

  const handleShowContract = () => {
    window.open(
      `https://scan.mypinata.cloud/ipfs/bafybeignzhc77l2eszm4jvjvvnx2t2hy7lxdo4prnpnovzqqntsg47kmmy/#/address/${getUFCContractAddress(
        eventType
      )}?tab=contract`,
      "_blank"
    );
  };

  const renderLogo = () => {
    switch (eventType) {
      case FCEventType.UFC299:
        return (
          <Box className={classes.nightFightHeaderLogo}>
            <Box
              component="a"
              href="https://www.ufc.com/event/ufc-299"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                component="img"
                src="/images/events/ufc/logo/ufc_logo1.png"
                alt=""
              />
              View official event site
            </Box>
          </Box>
        );
      case FCEventType.UFC300:
        return (
          <Box className={classes.nightFightHeaderLogo}>
            <Box
              component="a"
              href="https://www.ufc.com/event/ufc-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                component="img"
                src="/images/events/ufc/logo/ufc_logo1.png"
                alt=""
              />
              View official event site
            </Box>
          </Box>
        );
      case FCEventType.IFC:
        return (
          <Box className={classes.nightFightHeaderLogo}>
            <Box
              component="a"
              href="https://karate.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                component="img"
                src="/images/events/ifc/logo/header_logo.png"
                alt=""
              />
              View official event site
            </Box>
          </Box>
        );
      case FCEventType.DAZN:
        return (
          <Box className={classes.nightFightHeaderLogo}>
            <Box
              component="a"
              href="https://www.dazn.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                minWidth: "180px",
              }}
            >
              <Box
                component="img"
                src="/images/events/dazn/logo.png"
                alt=""
                sx={{
                  width: "100px!important",
                }}
              />
              View official event site
            </Box>
          </Box>
        );

      case FCEventType.ELECTION:
        return (
          <Box className={classes.nightFightHeaderLogo}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                minWidth: "240px",
              }}
            >
              <Box
                component="img"
                src="/images/events/us-election-white.png"
                alt=""
                sx={{
                  width: "140px!important",
                }}
              />
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box className={classes.nightFightHeader}>
      <Box className={classes.nightFightHeaderTimer}>
        <Box
          component="img"
          src="/images/events/ufc/header_timer.png"
          alt=""
          srcSet=""
        />
        {loadingRoundStatus ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ bgcolor: "#ffffff1c" }}
            width={296}
            height={20}
          />
        ) : (
          <>
            <span>Bets Closing:</span>
            <span>
              {timeLeft.days > 0 &&
                `${timeLeft.days} day${timeLeft.days > 1 ? "s" : ""}, `}
              {timeLeft.hours > 0 &&
                `${timeLeft.hours} hour${timeLeft.hours > 1 ? "s" : ""}, `}
              {timeLeft.minutes > 0 &&
                `${timeLeft.minutes} min${timeLeft.minutes > 1 ? "s" : ""} `}
            </span>
          </>
        )}
      </Box>

      {renderLogo()}

      <Box className={classes.nightFightHeaderInfo}>
        <Box
          className={classes.nightFightHeaderLink}
          onClick={handleShowDisclaimer}
        >
          <Box
            component="img"
            src="/images/events/ufc/header_info.png"
            alt=""
          />
          Disclaimer
        </Box>
        <Box
          className={classes.nightFightHeaderLink}
          onClick={handleShowContract}
        >
          <Box
            component="img"
            src="/images/events/ufc/header_contract.png"
            alt=""
          />
          Verified Contract
        </Box>
      </Box>
    </Box>
  );
}
