import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme: any) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    flex: 2,
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    '&>img' : {
      width: '25px',
    },
    '&>div': {
      fontSize: '24px',
    }
  },
  content: {
    display: 'flex',
    padding: '20px 20px',
    backgroundColor: theme.palette.background.dropdown,
    borderRadius: '20px',
    flexDirection: 'column',
    maxHeight: '400px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    }
  },
  contentHorizontal: {
    overflowY: 'auto',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'silver',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#7e889e',
      }
    },
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '500px',
  },
  row: {
    fontSize: '15px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#252831',
      transition: '0.3s',
      '&>div>div:last-child>div': {
        backgroundColor: '#569435',
      },
    },
  },
  rowHeader: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 2fr 3fr 2fr',
    alignItems: 'center',
    padding: '15px 0',
    height: '100%',
    color: theme.palette.text.tableFont,
    fontSize: '12px',
    width: '90%',
    margin: '0 5%',
  },
  rowContent: {
    display: 'grid',
    width: '90%',
    margin: '0 5%',
    gridTemplateColumns: '2fr 3fr 2fr 3fr 2fr',
    alignItems: 'center',
    padding: '5px 0',
    height: '100%',
    fontSize: '14px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.tableBorder,
    '& img': {
      width: '22px',
    },
    '&>div:first-of-type': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',
    },
    '&>div:last-child>div': {
      backgroundColor: '#232630',
      padding: '7px 10px',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      transition: '0.3s',
      cursor: 'pointer',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#569435',
        transition: '0.3s',
      }
    },
  }
}));

export default styles;