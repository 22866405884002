import { EuroMatchInfo } from "../types/EuroMatchInfo";

export const EURO_EVENTS = {
  EnteredRound: "EnteredRound",
  RoundFinished: "RoundFinished",
  RoundCancelled: "RoundCancelled",
};

export const leaderBoardReward = [
  2000000, 1500000, 1000000, 700000, 500000, 300000, 250000, 200000, 150000,
  125000,
];

export const euro24MatchInfo: EuroMatchInfo[] = [
  {
    matchId: 1,
    country1: "Germany",
    country2: "Scotland",
    closeTime: 1718391600,
  },
  {
    matchId: 2,
    country1: "Hungary",
    country2: "Switzerland",
    closeTime: 1718456400,
  },
  { matchId: 3, country1: "Spain", country2: "Croatia", closeTime: 1718467200 },
  { matchId: 4, country1: "Italy", country2: "Albania", closeTime: 1718478000 },
  {
    matchId: 5,
    country1: "Poland",
    country2: "Netherlands",
    closeTime: 1718542800,
  },
  {
    matchId: 6,
    country1: "Slovenia",
    country2: "Denmark",
    closeTime: 1718553600,
  },
  {
    matchId: 7,
    country1: "Serbia",
    country2: "England",
    closeTime: 1718564400,
  },
  {
    matchId: 8,
    country1: "Romania",
    country2: "Ukraine",
    closeTime: 1718629200,
  },
  {
    matchId: 9,
    country1: "Belgium",
    country2: "Slovakia",
    closeTime: 1718640000,
  },
  {
    matchId: 10,
    country1: "Austria",
    country2: "France",
    closeTime: 1718650800,
  },
  {
    matchId: 11,
    country1: "Turkey",
    country2: "Georgia",
    closeTime: 1718726400,
  },
  {
    matchId: 12,
    country1: "Portugal",
    country2: "Czech Republic",
    closeTime: 1718737200,
  },
  {
    matchId: 13,
    country1: "Croatia",
    country2: "Albania",
    closeTime: 1718802000,
  },
  {
    matchId: 14,
    country1: "Germany",
    country2: "Hungary",
    closeTime: 1718812800,
  },
  {
    matchId: 15,
    country1: "Scotland",
    country2: "Switzerland",
    closeTime: 1718823600,
  },
  {
    matchId: 16,
    country1: "Slovenia",
    country2: "Serbia",
    closeTime: 1718888400,
  },
  {
    matchId: 17,
    country1: "Denmark",
    country2: "England",
    closeTime: 1718899200,
  },
  { matchId: 18, country1: "Spain", country2: "Italy", closeTime: 1718910000 },
  {
    matchId: 19,
    country1: "Slovakia",
    country2: "Ukraine",
    closeTime: 1718974800,
  },
  {
    matchId: 20,
    country1: "Poland",
    country2: "Austria",
    closeTime: 1718985600,
  },
  {
    matchId: 21,
    country1: "Netherlands",
    country2: "France",
    closeTime: 1718996400,
  },
  {
    matchId: 22,
    country1: "Georgia",
    country2: "Czech Republic",
    closeTime: 1719061200,
  },
  {
    matchId: 23,
    country1: "Turkey",
    country2: "Portugal",
    closeTime: 1719072000,
  },
  {
    matchId: 24,
    country1: "Belgium",
    country2: "Romania",
    closeTime: 1719082800,
  },
  {
    matchId: 25,
    country1: "Switzerland",
    country2: "Germany",
    closeTime: 1719169200,
  },
  {
    matchId: 26,
    country1: "Scotland",
    country2: "Hungary",
    closeTime: 1719169200,
  },
  {
    matchId: 27,
    country1: "Albania",
    country2: "Spain",
    closeTime: 1719255600,
  },
  {
    matchId: 28,
    country1: "Croatia",
    country2: "Italy",
    closeTime: 1719255600,
  },
  {
    matchId: 29,
    country1: "France",
    country2: "Poland",
    closeTime: 1719331200,
  },
  {
    matchId: 30,
    country1: "Netherlands",
    country2: "Austria",
    closeTime: 1719331200,
  },
  {
    matchId: 31,
    country1: "Denmark",
    country2: "Serbia",
    closeTime: 1719342000,
  },
  {
    matchId: 32,
    country1: "England",
    country2: "Slovenia",
    closeTime: 1719342000,
  },
  {
    matchId: 33,
    country1: "Slovakia",
    country2: "Romania",
    closeTime: 1719417600,
  },
  {
    matchId: 34,
    country1: "Ukraine",
    country2: "Belgium",
    closeTime: 1719417600,
  },
  {
    matchId: 35,
    country1: "Georgia",
    country2: "Portugal",
    closeTime: 1719428400,
  },
  {
    matchId: 36,
    country1: "Czech Republic",
    country2: "Turkey",
    closeTime: 1719428400,
  },
  {
    matchId: 37,
    country1: "Switzerland",
    country2: "Italy",
    closeTime: 1719676800,
  },
  {
    matchId: 38,
    country1: "Germany",
    country2: "Denmark",
    closeTime: 1719687600,
  },
  {
    matchId: 39,
    country1: "England",
    country2: "Slovakia",
    closeTime: 1719763200,
  },
  {
    matchId: 40,
    country1: "Spain",
    country2: "Georgia",
    closeTime: 1719774000,
  },
  {
    matchId: 41,
    country1: "France",
    country2: "Belgium",
    closeTime: 1719849600,
  },
  {
    matchId: 42,
    country1: "Portugal",
    country2: "Slovenia",
    closeTime: 1719860400,
  },
  {
    matchId: 43,
    country1: "Romania",
    country2: "Netherlands",
    closeTime: 1719936000,
  },
  {
    matchId: 44,
    country1: "Austria",
    country2: "Turkey",
    closeTime: 1719946800,
  },
  {
    matchId: 45,
    country1: "Spain",
    country2: "Germany",
    closeTime: 1720195200,
  },
  {
    matchId: 46,
    country1: "Portugal",
    country2: "France",
    closeTime: 1720206000,
  },
  {
    matchId: 47,
    country1: "England",
    country2: "Switzerland",
    closeTime: 1720281600,
  },
  {
    matchId: 48,
    country1: "Netherlands",
    country2: "Turkey",
    closeTime: 1720292400,
  },
  {
    matchId: 49,
    country1: "Spain",
    country2: "France",
    closeTime: 1720551600,
  },
  {
    matchId: 50,
    country1: "Netherlands",
    country2: "England",
    closeTime: 1720638000,
  },
  {
    matchId: 51,
    country1: "Spain",
    country2: "England",
    closeTime: 1720987200,
  },
];
