import { Box, Skeleton } from "@mui/material";
import { useRef, useEffect, useState } from "react";

import { useAppSelector } from "../../app/hooks";
import TokenImage from "../TokenImage";
import { getMoneyLineOdds, toUSDFormat } from "../../utils";
import { calculatePercent } from "../../utils/predict";
import { PlayerStatus, PredictRoundStatus } from "../../constants/prediction";
import useStyles from "./index.styles";

interface CreateVsCardProps {
  roundInfo: any;
  fakePrice: number;
}

export default function CreateVsCard(props: CreateVsCardProps) {
  const { loadingMetaInfo, loadingRoundInfo } = useAppSelector(
    (state) => state.predict
  );
  const { classes } = useStyles();

  const calculateOdd = () => {
    return toUSDFormat(
      props.roundInfo.roundMaximumValue / props.roundInfo.creatorUSDValue
    );
  };

  return (
    <Box>
      <Box>
        {loadingMetaInfo || loadingRoundInfo ? (
          <Skeleton animation="wave" variant="rectangular" width={170} />
        ) : (
          <>
            <TokenImage
              token={props.roundInfo.betTokenName?.toLowerCase()}
              classNames="middle"
            />
            ${toUSDFormat(props.roundInfo?.creatorUSDValue, 2)}
          </>
        )}
      </Box>
      <Box>
        {loadingMetaInfo || loadingRoundInfo ? (
          <Skeleton animation="wave" variant="rectangular" width={170} />
        ) : (
          <>
            {((props.roundInfo &&
              props.roundInfo.status === PredictRoundStatus.Waiting) ||
              props.roundInfo.status === PredictRoundStatus.Started) &&
            calculatePercent(
              props.roundInfo.operator,
              PlayerStatus.Creator,
              props.fakePrice,
              props.roundInfo.pricePoint
            ) !== 0 &&
            props.fakePrice ? (
              <Box className={classes.cardPriceBody}>
                <span>
                  Needs price to{" "}
                  {props.roundInfo.operator === 0 ? "increase" : "decrease"}{" "}
                </span>
                <span style={{ color: "#b8811c" }}>
                  {calculatePercent(
                    props.roundInfo.operator,
                    PlayerStatus.Creator,
                    props.fakePrice,
                    props.roundInfo.pricePoint
                  )}
                  %
                </span>
              </Box>
            ) : props.fakePrice &&
              (props.roundInfo.status === PredictRoundStatus.Waiting ||
                props.roundInfo.status === PredictRoundStatus.Started) ? (
              <Box className={classes.cardPriceBody}>
                <Box
                  component={"img"}
                  src="/images/prediction/check1.png"
                  sx={{ width: "25px" }}
                />
                <Box component={"span"}>Currently winning</Box>
              </Box>
            ) : props.roundInfo.status === PredictRoundStatus.Started ||
              props.roundInfo.status === PredictRoundStatus.Waiting ? (
              <Skeleton width={100} />
            ) : (
              ""
            )}

            {props.roundInfo.status &&
            props.roundInfo.status === PredictRoundStatus.Finished ? (
              !props.roundInfo.isCreatorWinner ? (
                <Box className={classes.cardPriceBody}>
                  <span>
                    Needed price to{" "}
                    {props.roundInfo.operator === 0 ? "increase" : "decrease"}{" "}
                  </span>
                  <span style={{ color: "#b8811c" }}>
                    {toUSDFormat(
                      (Math.abs(
                        props.roundInfo.recordedPrice -
                          props.roundInfo.pricePoint
                      ) /
                        props.roundInfo.recordedPrice) *
                        100,
                      2
                    )}
                    %
                  </span>
                </Box>
              ) : (
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src="/images/prediction/check1.png"
                    sx={{ width: "25px" }}
                  />
                  <Box component={"span"}>Winner</Box>
                </Box>
              )
            ) : (
              ""
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
