import { makeStyles } from 'tss-react/mui';
import theme from '../../theme';

const arrowAnimation = {
  '0%': {
    paddingTop: '30px',
  },
  '50%': {
    paddingTop: '0',
  },
  '100%': {
    paddingTop: '30px',
  },
};

const styles = makeStyles()((theme: any) => ({
  middleBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '215px',
    minWidth: '215px',
  },
  description: {
    color: theme.palette.text.disabled,
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "& img": {
      width: "20px",
      height: "20px",
      borderRadius: "4px",
      marginRight: "8px"
    }
  },

  descriptionProfile: {
    display: "flex",
    alignItems: "center"
  },
  logo: {
    width: '150px',
  },
  remainTime: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    gap: '10px',
    fontSize: '14px',
    color: theme.palette.text.disabled,
  },
  timeLeft: {
    fontSize: '17px',
    marginTop: '5px',
  },
  roundType: {
    fontSize: '13px',
    color: theme.palette.text.disabled,
    marginTop: '5px',
  },
  trophy: {
    width: '50px',
    marginTop: '30px',
  },
  championText: {
    fontSize: '17px',
    marginTop: '10px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop :'20px',
      flexDirection: 'column',
    }
  },
  viewingTicket: {
    color: theme.palette.text.primary,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.background.card,
    borderRadius: '9px',
    padding: '10px 20px',
  },
  howToPlay: {
    color: theme.palette.text.primary,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.background.card,
    borderRadius: '9px',
    padding: '10px 20px',
    display: 'flex',
    textTransform: 'none',
    gap: '15px',
    '& img': {
      width: '22px',
    }
  },
  ticketSold: {
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '&>div:nth-of-type(1)': {
      fontSize: '13px',
      color: theme.palette.text.dark,
    },
    '&>div:nth-of-type(2)': {
      fontsize: '17px',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      backgroundColor: theme.palette.background.card,
      borderRadius: '10px',
      display: 'flex',
      gap: '10px',
      '& img': {
        width: '25px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  ticketSoldMobile: {
    minWidth: '120px',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    marginTop: '10px',
    '&>div:nth-of-type(1)': {
      fontSize: '13px',
      color: theme.palette.text.dark,
      flex: 1,
    },
    '&>div:nth-of-type(2)': {
      fontsize: '17px',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      backgroundColor: theme.palette.background.card,
      borderRadius: '10px',
      display: 'flex',
      flex: 3,gap: '10px',
      '& img': {
        width: '25px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    }
  },
  pooledFunds: {
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '&>div:nth-of-type(1)': {
      fontSize: '13px',
      color: theme.palette.text.dark,
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
    '&>div:nth-of-type(2)': {
      fontsize: '17px',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      backgroundColor: theme.palette.background.card,
      borderRadius: '10px',
      display: 'flex',
      gap: '10px',
      '& img': {
        width: '25px',
      },
      [theme.breakpoints.down('sm')]: {
        flex: 3,
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
    }
  },
  animationArrow: {
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: '#13151E',
    alignItems: 'center',
    zIndex: '1000',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    '&>svg' : {
      fontSize: '45px',
      minHeight: '80px',
      color: '#258542',
      animation: 'arrowAnimation 1.5s infinite ease-in-out',
      '@keyframes arrowAnimation': arrowAnimation,
    },
    '&>div': {
      color: theme.palette.text.dark,
    }
  },
  totalUsd: {
    height: '60px',
    border: '4px solid #1b1f28',
    width: '130px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      width: '75px'
    }
  },
  viewTicketButton: {
    height: '60px',
    backgroundColor: '#1b1f28',
    width: '230px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    gap: '10px',
    color: 'white',
    textTransform: 'none',
    '& img': {
      width: '27px',
    }
  },
  viewTicketButtonBody: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
  }
  
}));

export default styles;