import {
  LpRange,
  PlayerStatus,
  PredictLpStatus,
} from "../constants/prediction";

export const getLpStatus = (liquidity: number) => {
  for (let i = 0; i < LpRange.length; i++) {
    const range = LpRange[i];
    if (range.from < liquidity && range.to >= liquidity)
      return PredictLpStatus[range.status].text;
  }
};
export const getLpStatusValue = (liquidity: number) => {
  for (let i = 0; i < LpRange.length; i++) {
    const range = LpRange[i];
    if (range.from < liquidity && range.to >= liquidity)
      return PredictLpStatus[range.status].value;
  }
};

export const calculatePercent = (
  operator: number,
  player: number,
  currentPrice: number,
  pricePoint: number
): number => {
  if (currentPrice) {
    if (operator === 1) {
      if (player === PlayerStatus.Creator) {
        if (currentPrice <= pricePoint) {
          return 0;
        }
      } else if (player === PlayerStatus.Taker) {
        if (currentPrice > pricePoint) {
          return 0;
        }
      }
    } else if (operator === 0) {
      if (player === PlayerStatus.Creator) {
        if (currentPrice >= pricePoint) {
          return 0;
        }
      } else if (player === PlayerStatus.Taker) {
        if (currentPrice < pricePoint) {
          return 0;
        }
      }
    }
    let res = Number(
      ((Math.abs(pricePoint - currentPrice) / currentPrice) * 100).toFixed(2)
    );
    return res === 0 ? 0.1 : res;
  }
  return 0;
};

export const getDurationType = (createdAt: number, finishedAt: number) => {
  const duration = finishedAt - createdAt;
  if (duration < 20 * 60) {
    return 10;
  } else if (duration < 45 * 60) {
    return 30;
  } else if (duration < 90 * 60) {
    return 60;
  } else return 0;
};
