export interface EarnData {
  status: boolean;
  text: string;
  value: number;
  id: number;
}

export const EarnDataStatus = {
  ConnectWallet: {
    id: 0,
    text: "Connect your wallet to PulsePredict",
    value: 2000,
  },
  SetCustomProfile: {
    id: 1,
    text: "Set a custom name, avatar and description",
    value: 20000,
  },
  // StakingPLSP: {
  //   id: 2,
  //   text: "Stake up to 1,000 PLSP with 2% auto compounding",
  //   value: 50000,
  // },
  SportsEvent: {
    id: 3,
    text: "Participate in a PulsePredict sports event",
    value: 10000,
  },
  CreateQuickBet: {
    id: 4,
    text: "Create a PulsePredict Quick bet",
    value: 10000,
  },
  CreateAdvancedBet: {
    id: 5,
    text: "Create a PulsePredict Advanced bet",
    value: 20000,
  },
  FollowUser: {
    id: 6,
    text: "Follow another PulsePredict user",
    value: 5000,
  },
  FollowAsset: {
    id: 7,
    text: "Follow a supported asset",
    value: 5000,
  },
  WinQuickBet: {
    id: 8,
    text: "Win a quick bet",
    value: 15000,
  },
  WinAdvancedBet: {
    id: 9,
    text: "Win a advanced bet",
    value: 15000,
  },
  GetFollower: {
    id: 10,
    text: "Get a follower",
    value: 15000,
  },
  LoseQuickBet: {
    id: 11,
    text: "Lose a quick bet",
    value: 15000,
  },
  PlaceInGlobal50: {
    id: 12,
    text: "Place in the Global top 50",
    value: 15000,
  },
  PlaceInGlobal10: {
    id: 13,
    text: "Place in the Global top 10",
    value: 50000,
  },
  WinMore100SingleBet: {
    id: 14,
    text: "Win more than $100 in a single bet",
    value: 50000,
  },
  WinMore200SingleBet: {
    id: 15,
    text: "Win more than $200 in a single bet",
    value: 75000,
  },
  WinMore500SingleBet: {
    id: 16,
    text: "Win more than $500 in a single bet",
    value: 100000,
  },
  WinMore20SingleBet: {
    id: 17,
    text: "Win more than $20 in a single bet",
    value: 10000,
  },
  WinMore50SingleBet: {
    id: 18,
    text: "Win more than $50 in a single bet",
    value: 25000,
  },
  Get50CompletedBets: {
    id: 19,
    text: "Get 50 completed bets",
    value: 150000,
  },
  Get10CanceledBets: {
    id: 20,
    text: "Get 10 canceled bets",
    value: 50000,
  },
  Get20ExpiredBets: {
    id: 21,
    text: "Get 20 expired bets",
    value: 100000,
  },
  Create20QuickBets: {
    id: 22,
    text: "Create 20 quick bets",
    value: 20000,
  },
  Create50QuickBets: {
    id: 23,
    text: "Create 50 quick bets",
    value: 35000,
  },
  Create100QuickBets: {
    id: 24,
    text: "Create 100 quick bets",
    value: 50000,
  },
  Win5QuickBets: {
    id: 25,
    text: "Win 5 quick bets",
    value: 15000,
  },
  Win20QuickBets: {
    id: 26,
    text: "Win 20 quick bets",
    value: 50000,
  },
  Win50QuickBets: {
    id: 27,
    text: "Win 50 quick bets",
    value: 250000,
  },
  Lose5Quick: {
    id: 28,
    text: "Lose 5 quick bets",
    value: 30000,
  },
  Lose20Quick: {
    id: 29,
    text: "Lose 20 quick bets",
    value: 100000,
  },
  Lose50Quick: {
    id: 30,
    text: "Lose 50 quick bets",
    value: 200000,
  },
  GetPredict100K: {
    id: 31,
    text: "Get more than 100,000 $PREDICT",
    value: 10000,
  },
  GetPredict500K: {
    id: 32,
    text: "Get more than 500,000 $PREDICT",
    value: 50000,
  },
  GetPredict1500K: {
    id: 33,
    text: "Get more than 1,500,000 $PREDICT",
    value: 100000,
  },
  PlaceInGlobal5: {
    id: 34,
    text: "Place in the Global top 5",
    value: 100000,
  },
  PlaceInGlobal25: {
    id: 35,
    text: "Place in the Global top 25",
    value: 25000,
  },
  Get25Followers: {
    id: 36,
    text: "Get 25 followers",
    value: 50000,
  },
  Get100Followers: {
    id: 37,
    text: "Get 100 followers",
    value: 10000,
  },
  SportsEntry20: {
    id: 38,
    text: "Make 20 events",
    value: 20000,
  },
  SportsEntry50: {
    id: 39,
    text: "Make 50 events",
    value: 35000,
  },
  SportsEntry100: {
    id: 40,
    text: "Make 100 events",
    value: 50000,
  },
  Euro24Bet: {
    id: 41,
    text: "Make bet on Euro 2024",
    value: 5000,
  },
};
