import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  matchDetailPanel: {
    display: "flex",
    gap: 32,

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },

  subMatchDetailPanel: {
    gap: 180,
  },

  panel: {
    backgroundColor: "#1b1f28",
    borderRadius: 12,
    width: 200,
    height: 200,
    padding: 12,

    [theme.breakpoints.down("md")]: {
      minWidth: 320,
      width: "100%",
    },
  },

  subPanel: {
    width: 300,
  },

  panelHeader: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr 1fr 1fr",
    width: "100%",
    justifyItems: "center",
  },

  betCount: {
    fontSize: 12,
    color: "#363742",
  },

  betAmount: {
    fontSize: 14,
  },

  betList: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 8,
    overflowY: "scroll",
    height: 146,
  },

  betListItem: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr 3fr 1fr",
    fontSize: 12,
    alignItems: "center",

    "& a": {
      textDecoration: "none",
    },
  },

  avatar: {
    width: 14,
    height: 14,
  },

  matchCountryFlag: {
    width: "32px !important",
    objectFit: "cover",
  },
}));

export default styles;
