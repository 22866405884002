import { Box, InputBase, Paper, Tooltip, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

const useStyles = makeStyles()((theme: any) => ({
  tagImg: {
    position: "absolute",
    left: "15px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  dateTimePicker: {
    width: "100%",
    borderRadius: "15px",
    background: theme.palette.background.card,
    border: "none",
    outline: "none",
    "& .MuiInput-underline:before": {
      borderBottom: `none !important`,
    },
    "& .MuiInput-underline": {
      borderBottom: `none !important`,
      background: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottom: `none !important`,
    },
    "& .MuiInput-underline:hover": {
      borderBottom: `none !important`,
    },
    "& .MuiButtonBase-root": {
      display: "none",
    },
    "& input": {
      height: "35px",
      padding: "5px 20px 5px 60px",
      fontSize: "1rem",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  datePickerBtn: {
    position: "absolute",
    minWidth: "10px",
    padding: "4px",
    left: "91%",
    top: "50%",
    transform: "translateY(-50%)",
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
  },
}));

interface CustomDateTimePickerProps {
  date?: number;
  open: boolean;
  handleOpen: (status: boolean) => void;
  handleDate: (value: number) => void;
  openDateTimePicker: () => void;
  style?: any;
}

const CustomDateTimePicker = (props: CustomDateTimePickerProps) => {
  const { classes } = useStyles();
  const { date, open, handleOpen, handleDate, openDateTimePicker, style } =
    props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box position={"relative"}>
        <DateTimePicker
          open={open}
          value={dayjs(date)}
          onOpen={() => handleOpen(true)}
          onClose={() => handleOpen(false)}
          format="MM/DD/YYYY - HH:mm"
          onChange={(newValue: any) => handleDate(newValue)}
          className={classes.dateTimePicker}
          minDateTime={dayjs(new Date())}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            layout: {
              sx: {
                backgroundColor: "#1b1f28",
                color: "white",
                "& .MuiButtonBase-root:not(.Mui-disabled)": {
                  color: "white",
                },
                "& .MuiButtonBase-root.Mui-disabled": {
                  color: "#ffffff55",
                },
                "& .MuiDayCalendar-weekDayLabel": {
                  color: "#ffffff55",
                },
              },
            },
          }}
        />
        <img
          src="/images/prediction/calendar_.png"
          width={20}
          alt="calendar"
          className={classes.tagImg}
        />
        <Button
          variant="contained"
          className={classes.datePickerBtn}
          onClick={openDateTimePicker}
        >
          <img src="/images/prediction/more.png" alt="three-dot" width={5} />
        </Button>
      </Box>
    </LocalizationProvider>
  );
};
export default CustomDateTimePicker;
