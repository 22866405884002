import { Box } from "@mui/material";
import theme from "../../theme";

export default function PredictRulesCard() {
  return (
    <Box
      sx={{
        backgroundColor: "#1b1f28",
        marginTop: "80px",
        maxWidth: "350px",
        maxHeight: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: "20px",
        padding: "0 20px",
        [theme.breakpoints.down("md")]: {
          marginTop: "0",
          width: "100%",
          maxWidth: "none",
          minHeight: "450px",
        },
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
        }}
      >
        Details
      </Box>
      <Box
        sx={{
          color: "#444E68",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Creator can cancel opened bet at any time
        <br />
        Bet price point is recorded on bet creation
        <br />
        All open bets expire after 120 min
        <br />
        Completing a bet is done with a public call
        <br />
        Notifications are pushed with 5 min left
        <br />
      </Box>
      <Box
        sx={{
          fontSize: "25px",
          marginTop: "40px",
        }}
      >
        Fees
      </Box>
      <Box
        sx={{
          color: "#444E68",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        0% Expiry fee
        <br />
        1.5% Cancel fee
        <br />
        3% Winners fee
        <br />
      </Box>
    </Box>
  );
}
