import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import theme from "../../theme";

const BorderLinearProgress = styled(LinearProgress)(({ theme: any }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.background.progressBar,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.usdCard,
  },
}));

interface CustomProgressBarProps {
  readonly progress: number;
}

export default function CustomProgressBar(props: CustomProgressBarProps) {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={props.progress} />
    </Stack>
  );
}
