import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    width: "30%",
    gap: "30px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  feeCard: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  title: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    "&>img": {
      width: "20px",
    },
  },
  cardBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "20px",
    backgroundColor: theme.palette.background.card,
    gap: "10px",
  },
  feeBody: {
    display: "grid",
    gridTemplateColumns: "3fr 0fr 3fr",
  },
  completePart: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    "&>div:nth-of-type(1)": {
      color: "#ffffff36",
      fontSize: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  middleLine: {
    width: 0,
    borderLeft: "1px solid #ffffff22",
  },
  termsPart: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
    color: "#ffffff36",
    alignItems: "center",
    "&>img": {
      width: "20px",
    },
  },
  settingCard: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  settingDetail: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  detailIcon: {
    width: "20px",
  },
  detailBody: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid #ffffff48",
    padding: "5px 0",
    alignItems: "center",
  },
  detailText: {
    color: "#ffffff76",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  detailRightPart: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    fontSize: 12,
    color: "white",
    width: 250,
    "& p": {
      margin: "5px 0px",
      textIndent: 6,
      textAlign: "justify",
    },
  },
  infoImg: {
    width: "20px",
    cursor: "pointer",
  },
  expirePercentInput: {
    width: "100%",
    color: theme.palette.text.primary,
    borderRadius: "15px",
    position: "relative",
    background: theme.palette.background.card,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: `none !important`,
    },
    "& .MuiFilledInput-underline": {
      borderBottom: `none !important`,
      background: "transparent",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: `none !important`,
    },
    "& .MuiFilledInput-underline:hover": {
      borderBottom: `none !important`,
    },
    "& .MuiFilledInput-input": {
      borderRadius: "15px",
      fontSize: "1rem",
      padding: "5px 0px 5px 30px",
      height: "30px",
      color: theme.palette.text.primary,
    },
  },
  expireTagImg: {},
  inputField: {
    paddingLeft: "45px",
    alignItems: "center",
  },
  commentTextArea: {
    width: "100%",
    height: "100px",
    overflowY: "auto",
    "& div": {
      padding: 0,
      background: "transparent",
    },

    "& div:hover": {
      background: "transparent",
      border: "none",
    },

    "& .Mui-focused": {
      background: "transparent",
      border: "none",
    },
    "& textarea": {
      fontSize: "0.75rem",
      paddingTop: "10px",
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      width: "4px",
      borderRadius: "4px",
      backgroundColor: "#34344b",
    },
    "&::-webkit-scrollbar-track": {
      width: "4px",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
      border: "solid 3px rgba(255,0,0,0)",
    },
  },
  mobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  pc: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
