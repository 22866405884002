import { makeStyles } from "tss-react/mui";

const scaleUpDownAnimation = {
  "0%": {
    scale: "1",
  },
  "50%": {
    scale: "1.2",
  },
  "100%": {
    scale: "1",
  },
};

const useStyles = makeStyles()((theme: any) => ({
  body: {
    paddingLeft: "40px",
    paddingRight: "40px",
    gap: "30px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    maxWidth: "800px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  back: {
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  backBut: {
    display: "flex",
    gap: "10px",
    fontSize: "12px",
    color: "#ffffff50",
    textTransform: "none",
    "&>img": {
      width: "15px",
    },
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
      gap: "20px",
    },
  },
  headerText: {
    display: "flex",
    fontSize: "24px",
    gap: "15px",
    alignItems: "center",
    "& img": {
      width: "30px",
    },
    "& span": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px",
      },
    },
  },
  detailsText: {
    fontSize: "12px",
    color: theme.palette.text.disabled,
    display: "flex",
    gap: "15px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "end",
      flexDirection: "column",
      width: "100%",
    },
    "& img": {
      width: "25px",
    },
  },
  betBody: {
    "&>div:not(:last-of-type)": {
      borderBottom: "1px solid #1f2123",
    },
  },
  betDetail: {
    height: "100px",
    color: "#53575f",
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      height: "140px",
    },
  },
  betPart: {
    display: "flex",
    alignItems: "center",
    "&>button:not(:first-of-type)": {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  betText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "30px",
  },
  roundButton: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#252627",
    textTransform: "none",
    "&:disabled": {
      color: "#29ad73",
    },
  },
  betTokenBut: {
    "&>svg": {
      color: "#29ad73",
    },
  },
  focusBut: {
    backgroundColor: "#3e3c3e",
    color: "white",
    "&:hover": {
      backgroundColor: "#3e3c3e",
    },
  },
  scaleAnimation: {
    animation: "scaleUpDownAnimation 1s infinite ease-in-out",
    "@keyframes scaleUpDownAnimation": scaleUpDownAnimation,
  },
  tokenImg: {
    width: "40px",
  },
  disabled: {
    opacity: "0.3",
  },
  priceUpBut: {
    display: "flex",
    flexDirection: "column",
    color: "#29ad73",
    "&>img": {
      width: "20px",
    },
  },
  priceDownBut: {
    display: "flex",
    flexDirection: "column",
    color: "#9a4444",
    "&>img": {
      width: "20px",
    },
  },
  timeBut: {
    color: "#ffffff36",
  },
  usdBut: {
    color: "#ffffff36",
  },
  createBut: {
    textTransform: "none",
    display: "flex",
    gap: "10px",
    color: "white",
    backgroundColor: "#2c303a",
    padding: "10px 20px",
    borderRadius: "12px",
    "&>svg": {
      color: "#29ad73",
    },
    "&:disabled": {
      color: "#29ad73",
    },
  },
  usdValue: {
    color: "white",
    display: "flex",
    backgroundColor: "transparent",
    padding: "7px 10px",
    borderRadius: "20px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      width: "50px",
      padding: "0",
      textAlign: "right",
    },
    "&>span": {
      color: "#ffffff36",
      transition: ".3s",
      marginLeft: "5px",
      "&:hover": {
        color: "white",
        transition: ".3s",
      },
    },
  },
}));

export default useStyles;
