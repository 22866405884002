import { Box } from "@mui/material";
import Profile from "../../components/Profile";
import RoundDetail from "../../components/RoundDetail";
import PredictionGlobalRankChart from "../../components/PredictionGlobalRankChart";
import PredictionProfitLossChart from "../../components/PredictionProfitLossChart";
import RoundListTable from "../../components/RoundListTable";
import theme from "../../theme";

const Profiles = () => {
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        padding: "32px 0",
        gap: "0",
        [theme.breakpoints.down("lg")]: {
          padding: "32px 32px",
          margin: "auto",
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: "1440px",
          margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
          padding: "32px 16px",
        },
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          padding: "20px",
          paddingTop: 0,
        },
      }}
    >
      <Profile />
      <Box
        sx={{
          display: "grid",
          gap: "40px",
          width: "100%",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "70px",
          [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <PredictionGlobalRankChart />
        <PredictionProfitLossChart />
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "40px",
          width: "100%",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "20px",
          [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <RoundDetail />
        <RoundListTable />
      </Box>
    </Box>
  );
};

export default Profiles;
