import { makeStyles } from 'tss-react/mui';
import theme from '../../theme';

const styles = makeStyles()((theme: any) => ({
  header: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    borderBottom: '1px solid #222630',
    paddingBottom: '20px',
  },
  checkIcon: {
    fontSize: '35px',
    color: '#56c740',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
  },
  createButton: {
    width: '250px',
    display: 'flex',
    gap: '15px',
    backgroundColor: '#0f1117',
    borderRadius: '10px',
    padding: '15px 15px',
    color: 'white',
    textTransform: 'none',
    fontSize: '17px',
    justifyContent: 'left',
    '& svg': {
      color: '#56c740',
      fontSize: '30px',
    },
  },
  viewTicketButton: {
    width: '250px',
    display: 'flex',
    gap: '15px',
    backgroundColor: '#0f1117',
    borderRadius: '10px',
    padding: '15px 15px',
    color: 'white',
    textTransform: 'none',
    fontSize: '17px',
    justifyContent: 'left',
    marginTop: '10px',
    '& img': {
      width: '29px',
    },
  },
  orText: {
    paddingTop: '20px',
    fontSize: '16px',
  },
  description: {
    fontSize: '18px',
    paddingTop: '40px',
    color: '#7d7f84',
    width: '380px',
    display: 'flex',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    }
  },
  predictText: {
    color: '#494e5b',
    marginTop: '40px',
  },
  predictBody: {
    marginTop: '20px',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    fontSize: '19px',
    '& div:nth-child(1)': {
      backgroundColor: '#0f1117',
      width: '45px',
      height: '45px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      '&>img': {
        width: '35px',
      },
    },
    '& img:nth-child(4)': {
      width: '35px',
    }
  }
}));

export default styles;