import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";

import useStyles from "./index.styles";
import { FCEventType } from "../../constants/ufc";

interface NightFightDisclaimerModalPros {
  eventType: number;
  readonly onClose: () => void;
}

export default function UfcDisclaimerModal({
  eventType,
  onClose,
}: NightFightDisclaimerModalPros) {
  const { classes } = useStyles();

  const renderEventName = () => {
    switch (eventType) {
      case FCEventType.UFC299:
        return <>UFC 299</>;
      case FCEventType.IFC:
        return <>KFC44 IFC</>;
      case FCEventType.UFC300:
        return <>UFC 300</>;
      case FCEventType.DAZN:
        return <>DAZN</>;
      default:
        break;
    }
  };

  return (
    <Box>
      <Box className={classes.modalOverlay}>
        <Box className={classes.modalContent}>
          <button className={classes.modalClose} onClick={onClose}>
            <Close scale={32} />
          </button>

          <Box className={classes.disclaimerContent}>
            <h3>Disclaimer</h3>
            <p>
              1. The results of all {renderEventName()} fights will be updated
              into the contract by game admins. (No oracle available on
              Pulsechain)
            </p>
            <p>
              2.The odds will dynamically move based on incoming bet value until
              bets close.
            </p>
            <p>
              3. Betting funds inside fight contracts cannot be accessed by game
              admins
            </p>
            <p>
              4. Contract will automatically cancel bet and return funds if no
              result is provided
            </p>
            <p>
              5. Draw is possible and all bets will get returned excluding fee.
            </p>
            <p>
              6. All fights pay a 3% fee to buy and burn PLSP from the winner
              payout pool.
            </p>
            <p>
              7. All incoming bet tokens are autoswapped to eDAI to preserve
              value of bets.
            </p>
            <p>8. All payouts are done in eDAI</p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
