import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { signMessage } from "../../helpers/contractFunctions";

import CustomTextArea from "../CustomTextArea";
import CustomUploadButton from "../CustomUploadButton";
import TextBox from "../TextBox";
import useStyles from "./index.styles";
import { getUserInfo, updateUserProfile } from "../../reducers/user.slice";
import { Toast } from "../../utils";
import firebaseConfig from "../../config/firebase";

// Initialize Firebase

export default function MyProfile() {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { userInfo } = useAppSelector((state) => state.user);
  const [{ wallet }] = useConnectWallet();
  const isConnected = wallet ? true : false;

  const [profileName, setProfileName] = useState(userInfo.name);
  const [profileBio, setProfileBio] = useState(userInfo.bio);
  const [profileAvatar, setProfileAvatar] = useState(userInfo.avatar);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    setProfileName(userInfo.name);
    setProfileBio(userInfo.bio);
    setProfileAvatar(userInfo.avatar);
  }, [userInfo]);

  const sign = async () => {
    if (!isConnected || !wallet) return;
    if (file) {
      try {
        const app = initializeApp(firebaseConfig);
        const newMetadata = {
          cacheControl: "public,max-age=4000",
        };
        const storageRef = ref(getStorage(app), `images/${file.name}`);
        // 'file' comes from the Blob or File API
        await uploadBytes(storageRef, file, newMetadata);
        const url = await getDownloadURL(storageRef);
        const message = {
          address: wallet?.accounts[0].address,
          name: profileName,
          bio: profileBio,
          avatar: url,
        };
        const signature = await signMessage(message, wallet);
        await dispatch(
          updateUserProfile({
            address: wallet.accounts[0].address,
            name: profileName,
            bio: profileBio,
            avatar: url,
            signature: signature,
          })
        );
        await dispatch(getUserInfo(wallet.accounts[0].address));
        Toast.fire({
          icon: "success",
          title: "Profile updated",
        });
      } catch (error) {
        console.log(error);
        Toast.fire({
          icon: "error",
          title: "Profile update failed",
        });
      }
    } else {
      try {
        const message = {
          address: wallet?.accounts[0].address,
          name: profileName,
          bio: profileBio,
          avatar: profileAvatar,
        };
        const signature = await signMessage(message, wallet);
        await dispatch(
          updateUserProfile({
            address: wallet.accounts[0].address,
            name: profileName,
            bio: profileBio,
            avatar: profileAvatar,
            signature: signature,
          })
        );
        await dispatch(getUserInfo(wallet.accounts[0].address));

        Toast.fire({
          icon: "success",
          title: "Profile updated",
        });
      } catch (error) {
        console.log(error);
        Toast.fire({
          icon: "error",
          title: "Profile update failed",
        });
      }
    }
  };

  const handleNameChange = (newName: string) => {
    setProfileName(newName);
  };

  const handleBioChange = (newBio: string) => {
    setProfileBio(newBio);
  };

  const handleAvatarChange = (newAvatar: string) => {
    setProfileAvatar(newAvatar);
  };

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };

  return isConnected ? (
    <Box className={classes.body}>
      <Box className={`${classes.profileName} ${classes.editColumn}`}>
        <Box component="label" className={classes.label}>
          Profile name
        </Box>
        <Box>
          <TextBox text={profileName} onTextChange={handleNameChange} />
        </Box>
      </Box>
      <Box className={`${classes.description} ${classes.editColumn}`}>
        <Box component="label" className={classes.label}>
          Description
        </Box>
        <Box>
          <CustomTextArea text={profileBio} onTextChange={handleBioChange} />
        </Box>
      </Box>
      <Box className={`${classes.profileAvatar} ${classes.editColumn}`}>
        <Box component="label" className={classes.label}>
          Avatar upload
        </Box>
        <Box>
          <CustomUploadButton
            avatar={profileAvatar}
            handleChangeAvatar={handleAvatarChange}
            file={file}
            handleChangeFile={handleFileChange}
          />
        </Box>
      </Box>
      <Box className={`${classes.profileAvatar} ${classes.editColumn}`}>
        <Button className={classes.sign} onClick={sign}>
          Sign & Upload
        </Button>
      </Box>
    </Box>
  ) : (
    <Box>Please connect wallet.</Box>
  );
}
