import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    width: "60%",
    marginLeft: "20%",
    alignItems: "center",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginLeft: "5%",
    },
  },
  leaderBoardIcon: {
    width: "50px",
  },
  header: {
    fontSize: "24px",
    marginTop: "15px",
  },
  cardBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    height: "120px",
    gap: "50px",
    marginTop: "50px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
  },
  detailCard: {
    flexDirection: "column",
    display: "flex",
    gap: "15px",
    backgroundColor: "#1c1f28",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      height: "120px",
    },
  },
  playersCard: {
    "&>div:nth-of-type(2)": {
      fontSize: "20px",
    },
  },
  softColor: {
    color: "#515c77",
  },
  timeCard: {
    "&>div:nth-of-type(2)": {
      fontSize: "22px",
    },
    position: "relative",
  },
  airdropIcon: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
  },
  infoIcon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    padding: 0,
    minWidth: "auto",
    "& img": {
      width: "20px",
    },
  },
  payoutCard: {
    "&>div:nth-of-type(2)": {
      fontSize: "20px",
      display: "flex",
      gap: "5px",
      alignItems: "center",
    },
  },
  leaderBoardTable: {
    width: "100%",
    marginTop: "50px",
  },
  skeletonBody: {
    display: "flex",
    gap: "5px",
    flexDirection: "column",
  },
  detailSkeleton: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 2fr 1fr 2fr 3fr",
    gap: "10px",
  },
}));

export default styles;
