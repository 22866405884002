import theme from "../../theme";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    alignItems: "center",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // background: `radial-gradient(circle, white, rgba(255,255,255,0))`,
  },
  topText: {
    fontSize: "22px",
    color: theme.palette.text.disabled,
  },
  middleText: {
    fontSize: "45px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    fontWeight: "700",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
  button: {
    backgroundColor: "#191e2b",
    width: "250px",
    color: theme.palette.text.primary,
    textTransform: "none",
    padding: "10px 10px",
    borderRadius: "10px",
    fontSize: "20px",
    display: "flex",
    gap: "5px",
    position: "relative",
    marginTop: "15px",
  },
  createIcon: {
    width: "30px",
  },
  handsIcon: {
    width: "40px",
    position: "absolute",
    right: "-20px",
    top: "-30px",
  },
}));

export default useStyles;
