import lookup from "country-code-lookup";
import { ethers } from "ethers";
import { EuroRoundInfo } from "../types/EuroRoundInfo";

const CountryAbbreviations = [
  { countryName: "Scotland", abbreviation: "GB-SCT" },
  { countryName: "England", abbreviation: "GB-ENG" },
  { countryName: "Czech Republic", abbreviation: "CZ" },
];

export const getCountryAbbreviationByName = (countryName?: string) => {
  return (
    lookup.byCountry(countryName || "")?.internet ||
    CountryAbbreviations.find(
      (country, index) => country.countryName === countryName
    )?.abbreviation ||
    ""
  );
};

export const formatEuroEnteredRoundInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    entryId: Number(event.result.returnValues.entryId),
    player: event.result.returnValues.player,
    tokenName: event.result.returnValues.tokenName,
    tokenAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.tokenAmount)
    ),
    usdAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.usdAmount)
    ),
    expectation: Number(event.result.returnValues.expectation),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};
export const formatEuroRoundFinishedInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    result: Number(event.result.returnValues.result),
    winner: event.result.returnValues.winner,
    usdAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.usdAmount)
    ),
    fee: Number(ethers.utils.formatEther(event.result.returnValues.fee)),
    plspBurnt: Number(
      ethers.utils.formatEther(event.result.returnValues.plspBurnt)
    ),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};
export const formatEuroRoundCancelledInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};

export const calculateMatchOdd = (roundInfo: EuroRoundInfo, result: number) => {
  const totalUsd = roundInfo?.totalUsd || 0;
  const playerUsd =
    result === 0
      ? roundInfo?.player1TotalUsd || 0
      : result === 1
      ? roundInfo?.player2TotalUsd || 0
      : roundInfo?.drawUsd || 0;

  let odd = (totalUsd - playerUsd) / playerUsd + 1;

  return odd;
};
