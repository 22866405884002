import { useMemo, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  DocumentData,
  query as firestoreQuery,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  QuerySnapshot,
  startAfter,
} from "firebase/firestore";
import { useEffect } from "react";

import { useAppSelector } from "../../app/hooks";
import { shortAddress, shortName, Toast } from "../../utils";
import Blocky from "../Blocky";
import Spinner from "../Spinner";

import "./index.scss";

const firebaseConfig = {
  apiKey: "AIzaSyD_Tv5ydMXqrftrVeoboQLPctE5ty5w-vQ",
  authDomain: "uselection-2b602.firebaseapp.com",
  projectId: "uselection-2b602",
  storageBucket: "uselection-2b602.appspot.com",
  messagingSenderId: "840686128318",
  appId: "1:840686128318:web:9b47795de9bf21a00762ef",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const getSeconds = (a: any) => {
  return a.createdAt ? a.createdAt : new Date().getTime() / 1000;
};

interface IChatMessage {
  id: string;
  text: string;
  uid: string;
  createdAt: number;
}

const ChatMessage = ({
  account,
  message,
}: {
  account: string;
  message: IChatMessage;
}) => {
  const { text, uid } = message;
  const messageClass = uid === account ? "sent" : "received";
  const { profiles } = useAppSelector((state) => state.user);
  const profile = profiles.find(
    (profile) => profile.address.toLowerCase() === uid.toLowerCase()
  );

  return (
    <Box className={`message ${messageClass}`}>
      <Box className="user-id">
        {profile && profile.avatar.length > 0 ? (
          <img src={profile.avatar} alt="" srcSet="" />
        ) : (
          <Blocky address={uid.toLowerCase()} size={20} scale={1} />
        )}

        {profile ? (
          profile.name.length > 15 ? (
            shortName(profile.name)
          ) : (
            profile.name
          )
        ) : (
          <span>{shortAddress(uid)}</span>
        )}
      </Box>
      {text}
    </Box>
  );
};

const ChatRoom = () => {
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const dummy = useRef<null | HTMLDivElement>();
  const messagesRef = collection(firestore, "messages");

  const query = firestoreQuery(
    messagesRef,
    orderBy("createdAt", "desc"),
    limit(25)
  );
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastKey, setLastKey] = useState<DocumentData>();
  const [empty, setEmpty] = useState(false);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [formValue, setFormValue] = useState("");

  const unsubscribe = useMemo(() => {
    const q = firestoreQuery(collection(firestore, "messages"));
    return onSnapshot(q, (snapshot) => {
      console.log(snapshot.docChanges());

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log("Added:", change.doc.data());
          const newMessage = change.doc.data() as IChatMessage;

          setMessages((messages) => {
            if (
              messages.findIndex(
                (message) =>
                  message.createdAt === newMessage.createdAt &&
                  message.text === newMessage.text
              ) === -1
            ) {
              return [newMessage, ...messages].sort(
                (a, b) => a.createdAt - b.createdAt
              );
            } else {
              return messages;
            }
          });
        }
      });
    });
  }, [messages]);

  useEffect(() => {
    // Return the unsubscribe function in the useEffect cleanup function
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (loading) return;
      setLoading(true);

      const collections: QuerySnapshot<DocumentData> = await getDocs(query);

      updateState(
        collections.docs.map(
          (document: DocumentData) => document.data() as IChatMessage
        )
      );
      setLastKey(collections.docs[collections.docs.length - 1]);
    };

    fetchMessages();
  }, []);

  const updateState = (data: IChatMessage[]) => {
    const isCollectionEmpty = !data || data.length === 0;
    if (!isCollectionEmpty) {
      const newMessages = data.filter((item: IChatMessage) => {
        return (
          messages.findIndex(
            (msg: IChatMessage) =>
              msg.uid === item.uid &&
              getSeconds(msg) === getSeconds(item) &&
              msg.text === item.text
          ) === -1
        );
      });

      setMessages(
        [...newMessages, ...messages].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else {
      setEmpty(true);
    }
    setLoading(false);
  };

  const fetchMorePosts = () => {
    console.log("Fetching more posts");
    if (loading || empty) return;
    setLoading(true);
    getDocs(
      firestoreQuery(
        messagesRef,
        orderBy("createdAt", "desc"),
        startAfter(lastKey),
        limit(25)
      )
    ).then((collections: QuerySnapshot<DocumentData>) => {
      updateState(
        collections.docs.map(
          (document: DocumentData) => document.data() as IChatMessage
        )
      );
      setLastKey(collections.docs[collections.docs.length - 1]);
    });
  };

  const sendMessage = async (e: any) => {
    e.preventDefault();
    if (formValue.length > 150) {
      Toast.fire({
        icon: "warning",
        title: "You can't type anymore than 150.",
      });
      return;
    }
    setFormValue("");

    await addDoc(messagesRef, {
      text: formValue,
      createdAt: Math.round(new Date().getTime() / 1000),
      uid: account,
    });
  };

  const onScroll = (e: any) => {
    const element = e.target;
    if (element.scrollTop <= lastScrollTop) {
      setIsAtBottom(false);
      if (element.scrollTop <= 0) {
        fetchMorePosts();
      }
    } else {
      if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
    setLastScrollTop(element.scrollTop <= 0 ? 0 : element.scrollTop);
  };

  useEffect(() => {
    if (isAtBottom) {
      setTimeout(
        () =>
          dummy.current && dummy.current.scrollIntoView({ behavior: "auto" }),
        300
      );
    }
  }, [isAtBottom, messages]);

  return (
    <Box className={"chatbox-container"}>
      <main id="msg-list-container" onScroll={onScroll}>
        {loading && (
          <Box style={{ marginTop: "5px" }} className="waitingFP">
            <Box className="loading-spinner">
              Loading
              <Spinner />
            </Box>
          </Box>
        )}
        {messages &&
          messages.map((msg, index) => (
            <ChatMessage account={account} key={index} message={msg} />
          ))}

        <Box ref={dummy}></Box>
      </main>

      <form onSubmit={sendMessage}>
        <input
          value={formValue}
          onFocus={(e) => e.preventDefault()}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder={
            account ? "Type your message here..." : "Connect Wallet to chat"
          }
          disabled={!account}
          style={{ width: "65%" }}
        />
        <button type="submit" disabled={!formValue || !account}>
          Send
        </button>
      </form>
    </Box>
  );
};

export default ChatRoom;
