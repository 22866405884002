import React, { useEffect, useState } from "react";
import { useConnectWallet } from "@web3-onboard/react";
import { Button, Box, CircularProgress, Tooltip } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { BetToken } from "../../helpers/contractFunctions";
import {
  enterRound,
  getBetTokens,
  getTokenBalances,
} from "../../reducers/uefa24.slice";

import TokenTakerDialog from "../TokenTakerDialog";
import useStyles from "./index.styles";

interface TokenTakerProps {
  readonly text: string;
  readonly usdAmount: number;
  readonly predictions: number[];
  readonly totalMatches: number;
}

export default function Uefa24TicketPurchaseBtn(props: TokenTakerProps) {
  const dispath = useAppDispatch();
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const { betTokens, tokenBalances, enteringRound, ticketPrice } =
    useAppSelector((state) => state.uefa24);
  const { predictions, usdAmount } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const isPredictionComplete = predictions.length === props.totalMatches;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const buyTicket = (tokenId: number, tokenAmount: number) => {
    const tokenInfo = betTokens.find((item) => item.id == tokenId);
    const ticketCount = usdAmount / ticketPrice;

    if (!tokenInfo) return;
    dispath(
      enterRound({
        tokenId,
        tokenAddress: tokenInfo.tokenAddress,
        tokenAmount,
        ticketCount,
        predictions,
        account,
      })
    )
      .unwrap()
      .then(() => {
        handleClose();
        dispath(getTokenBalances(account));
      });
  };

  useEffect(() => {
    dispath(getBetTokens());
  }, []);

  useEffect(() => {
    if (wallet) {
      dispath(getTokenBalances(account));
    }
  }, [wallet]);

  return (
    <Box className={classes.body}>
      <Tooltip
        title={
          !isPredictionComplete
            ? "Choose all results to purchase a ticket."
            : !account
            ? "Connect Wallet"
            : !props.usdAmount
            ? "Select Ticket amount"
            : ""
        }
        arrow={true}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "background.card",
              "& .MuiTooltip-arrow": {
                color: "background.card",
              },
            },
          },
        }}
        enterTouchDelay={0}
      >
        <Box>
          <Button
            onClick={
              account && isPredictionComplete && props.usdAmount > 0
                ? handleClickOpen
                : () => {}
            }
            className={classes.ticketButton}
            disabled={!isPredictionComplete || !account || !props.usdAmount}
          >
            <Box component="img" src="/images/icons/ticket-green.png" />{" "}
            {props.text}
          </Button>
        </Box>
      </Tooltip>
      <Box className={classes.description}>Click here to select token</Box>
      <TokenTakerDialog
        betTokens={betTokens}
        tokenBalances={tokenBalances}
        usdAmount={props.usdAmount}
        open={open}
        processing={enteringRound}
        onClose={handleClose}
        onAccept={buyTicket}
      />
    </Box>
  );
}
