import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BetToken, getTokenBalance } from "../helpers/contractFunctions";
import {
  enterMarchMadnessRound,
  getMarchMadnessBetTokens,
  getMarchMadnessMatchesInfo,
} from "../helpers/contractFunctions/marchMadness";
import { acceptedTokens } from "../../src/config/tokens";
import { Toast } from "../utils";
import { gameConfig } from "../../src/config";

export interface MarchMadnessState {
  betTokens: BetToken[];
  loadingBetTokens: boolean;
  tokenBalances: { [key: string]: string };
  loadingTokenBalances: boolean;
  roundStatus: number;
  totalTickets: number;
  ticketPrice: number;
  closeBetAt?: number;
  totalRoundUSD: number;
  loadingMatchesInfo: boolean;
  enteringRound: boolean;
  gameResults: number[];
  entries: Entry[];
  loadingEntries: boolean;
  confirmingTransaction: boolean;
}

export interface Entry {
  entryId: number;
  player: string;
  predictions: string[];
  ticketCount: number;
  timestamp: number;
  tokenAmount: number;
  tokenName: string;
  usdAmount: string;
}

const initialState: MarchMadnessState = {
  betTokens: [],
  loadingBetTokens: false,

  tokenBalances: {},
  loadingTokenBalances: false,

  roundStatus: 0,
  totalTickets: 0,
  ticketPrice: 0,
  closeBetAt: undefined,
  totalRoundUSD: 0,
  loadingMatchesInfo: false,
  enteringRound: false,
  confirmingTransaction: false,

  gameResults: [],

  entries: [],
  loadingEntries: false,
};

export const getBetTokens = createAsyncThunk(
  "marchMadness/getBetTokens",
  async () => {
    const result = await getMarchMadnessBetTokens();
    return result;
  }
);

export const getTokenBalances = createAsyncThunk(
  "marchMadness/getTokenBalances",
  async (account: string) => {
    const balanceArray = await Promise.all(
      acceptedTokens.map(async (token) => {
        const balance = await getTokenBalance(token.address, account);
        return { [token.name]: balance };
      })
    );

    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});

    return balances;
  }
);

export const getMatchesInfo = createAsyncThunk(
  "marchMadness/getMatchesInfo",
  async () => {
    const matchesInfo = await getMarchMadnessMatchesInfo();
    return matchesInfo;
  }
);

export const enterRound = createAsyncThunk(
  "marchMadness/enterRound",
  async ({
    tokenId,
    tokenAddress,
    tokenAmount,
    ticketCount,
    predictions,
    account,
  }: {
    tokenId: number;
    tokenAddress: string;
    tokenAmount: number;
    ticketCount: number;
    predictions: number[];
    account: string;
  }) => {
    await enterMarchMadnessRound(
      tokenId,
      tokenAddress,
      tokenAmount,
      ticketCount,
      predictions,
      account
    );
  }
);
export const getEntries = createAsyncThunk(
  "marchMadness/getEntriesData",
  async () => {
    const response = await fetch(gameConfig.serverUrl + "march-madness/entry");
    const result = await response.json();
    return result.map(
      (item: any) =>
        ({
          entryId: Number(item.entryId),
          player: item.player,
          tokenName: item.tokenName,
          predictions: JSON.parse(item.predictions),
          ticketCount: Number(item.ticketCount),
          timestamp: Number(item.timestamp),
          tokenAmount: Number(item.tokenAmount),
          usdAmount: item.usdAmount,
        } as Entry)
    );
  }
);

export const marchMadnessSlice = createSlice({
  name: "marchMadness",
  initialState,
  reducers: {
    addEntry: (state, { payload }: { payload: Entry }) => {
      const newEntries = state.entries.slice();
      if (!newEntries.find((item) => item.entryId === payload.entryId)) {
        newEntries.push(payload);
      }
      state.entries = newEntries;
    },
    setConfirmingTransaction: (state) => {
      state.confirmingTransaction = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBetTokens.pending, (state) => {
      state.loadingBetTokens = true;
    });
    builder.addCase(getBetTokens.fulfilled, (state, { payload }) => {
      state.betTokens = payload;
      state.loadingBetTokens = false;
    });
    builder.addCase(getBetTokens.rejected, (state) => {
      state.betTokens = [];
      state.loadingBetTokens = false;
    });

    builder.addCase(getTokenBalances.pending, (state) => {
      state.loadingTokenBalances = true;
    });
    builder.addCase(getTokenBalances.fulfilled, (state, { payload }) => {
      state.tokenBalances = payload;
      state.loadingTokenBalances = false;
    });
    builder.addCase(getTokenBalances.rejected, (state, { error }) => {
      state.tokenBalances = {};
      state.loadingBetTokens = false;
      console.log(error);
    });

    builder.addCase(getMatchesInfo.pending, (state) => {
      state.loadingMatchesInfo = true;
    });
    builder.addCase(getMatchesInfo.fulfilled, (state, { payload }) => {
      state.roundStatus = payload.roundStatus;
      state.ticketPrice = payload.ticketPrice;
      state.totalTickets = payload.totalTickets;
      state.closeBetAt = payload.closeBetAt;
      state.totalRoundUSD = payload.totalRoundUSD;
      state.gameResults = payload.results;
      state.loadingMatchesInfo = false;
    });
    builder.addCase(getMatchesInfo.rejected, (state, { error }) => {
      state.loadingMatchesInfo = false;
      console.log(error);
    });
    builder.addCase(enterRound.pending, (state) => {
      state.enteringRound = true;
    });
    builder.addCase(enterRound.fulfilled, (state) => {
      state.enteringRound = false;
      state.confirmingTransaction = true;
      Toast.fire({
        icon: "success",
        title: "Purchased ticket successfully",
      });
    });
    builder.addCase(enterRound.rejected, (state) => {
      state.enteringRound = false;
      Toast.fire({
        icon: "error",
        title: "Failed to purchase ticket.",
      });
    });
    builder.addCase(getEntries.pending, (state) => {
      state.loadingEntries = true;
    });
    builder.addCase(getEntries.fulfilled, (state, { payload }) => {
      state.entries = payload;
      state.loadingEntries = false;
    });
    builder.addCase(getEntries.rejected, (state, { error }) => {
      state.loadingEntries = false;
      console.log(error);
    });
  },
});

export const { addEntry, setConfirmingTransaction } = marchMadnessSlice.actions;

export default marchMadnessSlice.reducer;
