import * as React from "react";
import { Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import theme from "../../theme";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PredictionRulesDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        sx={{
          color: theme.palette.text.primary,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.background.card,
          borderRadius: "9px",
          padding: "10px 20px",
          display: "flex",
          textTransform: "none",
          gap: "15px",
          "& img": {
            width: "22px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "5px 5px",
          },
        }}
        onClick={handleClickOpen}
      >
        <Box component="img" src="/images/icons/info.png" />
        <Box
          sx={{
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          information
        </Box>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              minHeight: "600px",
              backgroundColor: "#1b1f28",
              padding: "20px 10px",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "300px",
                minHeight: "300px",
              },
              "& .MuiDialogContent-root": {
                borderBottom: "none",
                borderTop: "1px solid #2f3441",
                [theme.breakpoints.down("sm")]: {
                  padding: "0",
                },
              },
              "&>.MuiTypography-root": {
                textAlign: "center",
              },
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            alignItems: "center",
          }}
          id="customized-dialog-title"
        >
          <Box
            component="img"
            src="/images/icons/info.png"
            sx={{
              width: "25px",
            }}
          />
          <Box
            sx={{
              fontSize: "30px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "24px",
              },
            }}
          >
            information
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              padding: "30px 50px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              alignItems: "center",
              fontSize: "20px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                padding: "30px 20px",
              },
            }}
          >
            <Box>Details</Box>
            <Box
              sx={{
                color: "#6a6d73",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Creator can cancel opened bet at any time
              <br />
              Bet price point is recorded on bet creation
              <br />
              All open bets expire after 120 min
              <br />
              Completing a bet is done with a public call
              <br />
              Notifications are pushed with 5 min left
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                padding: "30px 50px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                fontSize: "20px",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "16px",
                  padding: "10px 20px",
                },
              }}
            >
              Fees
            </Box>
            <Box
              sx={{
                color: "#6a6d73",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "18px",
                width: "70%",
                justifyContent: "center",
                textAlign: "center",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                  padding: "10px 20px",
                  width: "100%",
                },
              }}
            >
              0% Expiry fee
              <br />
              1.5% Cancel fee
              <br />
              3% Winners fee
              <br />
            </Box>
          </Box>
          <Box></Box>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
