import { PredictToken } from "../reducers/predict.slice";

export const predictionStatus = [
  { value: 0, color: "#1eff00", text: "Open" },
  // { value: 1, color: "#ff9c00", text: "Live" },
  { value: 5, color: "#7000ff", text: "Waiting" },
  { value: 4, color: "#008aff", text: "Completed" },
  { value: 3, color: "#ff0066", text: "Expired" },
  { value: 2, color: "#830606", text: "Canceled" },
];

export const sortArr = [
  {
    text: "Expiry",
    value: 0,
  },
  {
    text: "Value",
    value: 1,
  },
  {
    text: "Duration",
    value: 2,
  },
  {
    text: "Odds",
    value: 3,
  },
];

export const TokenFilterValues = [
  {
    text: "Liquidity",
    value: 0,
  },
  {
    text: "Name",
    value: 1,
  },
  {
    text: "Favorite",
    value: 2,
  },
];

export const TokenFilterStatus = {
  Liquidity: 0,
  Name: 1,
  Favorite: 2,
};

export const DexFilterValues = [
  {
    text: "All",
    value: 0,
  },
  {
    text: "PulseX",
    value: 1,
  },
  {
    text: "9INCH",
    value: 2,
  },
];

export const DexFilterStatus = {
  All: 0,
  PulseX: 1,
  Inch9: 2,
};

export const PredictSortStatus = {
  Expiry: 0,
  Value: 1,
  Duration: 2,
  Odds: 3,
};

export const PredictRoundStatus = {
  Waiting: 0,
  Started: 1,
  Canceled: 2,
  Expired: 3,
  Finished: 4,
  WaitingForFinalize: 5,
  ExpireOrCanceled: 6,
};

export const PredictLp = {
  ALL: 0,
  SAFE: 1,
  CAUTION: 2,
  RISKY: 3,
  DANGER: 4,
};

export const PlayerStatus = {
  Creator: 1,
  Taker: 2,
};

export const PredictLpStatus = [
  {
    text: "All",
    value: PredictLp.ALL,
  },
  {
    text: "Good",
    value: PredictLp.SAFE,
  },
  {
    text: "Caution",
    value: PredictLp.CAUTION,
  },
  {
    text: "Risky",
    value: PredictLp.RISKY,
  },
  {
    text: "Danger",
    value: PredictLp.DANGER,
  },
];

export const PredictType = {
  All: 0,
  Quick: 1,
  Advanced: 2,
};

export const BetTypes = [
  {
    text: "All",
    value: 0,
    valueStr: "all",
  },
  {
    text: "Quick",
    value: 1,
    valueStr: "quick",
  },
  {
    text: "Advanced",
    value: 2,
    valueStr: "advanced",
  },
];

export const LpRange = [
  {
    from: 0,
    to: 50000,
    status: PredictLp.DANGER,
  },
  {
    from: 50000,
    to: 100000,
    status: PredictLp.RISKY,
  },
  {
    from: 100000,
    to: 5000000,
    status: PredictLp.CAUTION,
  },
  {
    from: 5000000,
    to: 1000000000000,
    status: PredictLp.SAFE,
  },
];

export const PredictionEventName = {
  BetPlaced: "BetPlaced",
};

export const PredictionGettingRoundsType = {
  NormalsPredictIndex: 1,
  LiveRoundsPredictIndex: 2,
  LiveRoundsHomeIndex: 3,
  OpenRoundsHomeIndex: 4,
  NormalRoundsProfile: 5,
  LiveRoundsProfile: 6,
  AllRoundsProfile: 7,
};

export const TokenList: PredictToken[] = [
  {
    token: "PLS",
    img: "pls",
    name: "pls",
    chain: "PulseX",
    id: 0,
    pair: "0xE56043671df55dE5CDf8459710433C10324DE0aE",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xE56043671df55dE5CDf8459710433C10324DE0aE",
    chartLink:
      "https://dexscreener.com/pulsechain/0xE56043671df55dE5CDf8459710433C10324DE0aE",
  },
  {
    token: "HEX",
    img: "hex",
    name: "hex",
    chain: "PulseX",
    id: 1,
    pair: "0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65",
    chartLink:
      "https://dexscreener.com/pulsechain/0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65",
  },
  {
    token: "PLSX",
    img: "plsx",
    name: "plsx",
    chain: "PulseX",
    id: 2,
    pair: "0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9",
    chartLink:
      "https://dexscreener.com/pulsechain/0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9",
  },
  {
    token: "INC",
    img: "inc",
    name: "inc",
    chain: "PulseX",
    id: 3,
    pair: "0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA",
    chartLink:
      "https://dexscreener.com/pulsechain/0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA",
  },
  {
    token: "PLSP",
    img: "plsp",
    name: "plsp",
    chain: "PulseX",
    id: 4,
    pair: "0x5da3F2B568073Cc04B136E866a44F920603556B4",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x5da3F2B568073Cc04B136E866a44F920603556B4",
    chartLink:
      "https://dexscreener.com/pulsechain/0x5da3F2B568073Cc04B136E866a44F920603556B4",
  },
  {
    token: "BTC",
    img: "btc",
    name: "btc",
    chain: "PulseX",
    id: 5,
    pair: "0xDB82b0919584124A0Eb176ab136A0Cc9F148B2D1",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xDB82b0919584124A0Eb176ab136A0Cc9F148B2D1",
    chartLink:
      "https://dexscreener.com/pulsechain/0xDB82b0919584124A0Eb176ab136A0Cc9F148B2D1",
  },
  {
    token: "ETH",
    img: "eth",
    name: "eth",
    chain: "PulseX",
    id: 6,
    pair: "0x42AbdFDB63f3282033C766E72Cc4810738571609",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x42AbdFDB63f3282033C766E72Cc4810738571609",
    chartLink:
      "https://dexscreener.com/pulsechain/0x42AbdFDB63f3282033C766E72Cc4810738571609",
  },
  {
    token: "TIME",
    img: "time",
    name: "time",
    chain: "PulseX",
    id: 7,
    pair: "0xEFab2c9c33C42960F2fF653aDb39dC5C4c10630e",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xEFab2c9c33C42960F2fF653aDb39dC5C4c10630e",
    chartLink:
      "https://dexscreener.com/pulsechain/0xEFab2c9c33C42960F2fF653aDb39dC5C4c10630e",
    disabled: false,
  },
  {
    token: "9INCH",
    img: "9inch",
    name: "9inch",
    chain: "9INCH",
    id: 8,
    pair: "0x1164daB36Cd7036668dDCBB430f7e0B15416EF0b",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x1164daB36Cd7036668dDCBB430f7e0B15416EF0b",
    chartLink:
      "https://dexscreener.com/pulsechain/0x1164daB36Cd7036668dDCBB430f7e0B15416EF0b",
    disabled: false,
  },
  {
    token: "MINT",
    img: "mint",
    name: "mint",
    chain: "PulseX",
    id: 9,
    pair: "0x5F2D8624e6aBEA8F679a1095182f4bC84fe148e0",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x5F2D8624e6aBEA8F679a1095182f4bC84fe148e0",
    chartLink:
      "https://dexscreener.com/pulsechain/0x5F2D8624e6aBEA8F679a1095182f4bC84fe148e0",
    disabled: false,
  },
  {
    token: "LOAN",
    img: "loan",
    name: "loan",
    chain: "PulseX",
    id: 10,
    pair: "0x6D69654390c70D9e8814B04c69a542632DC93161",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x6D69654390c70D9e8814B04c69a542632DC93161",
    chartLink:
      "https://dexscreener.com/pulsechain/0x6D69654390c70D9e8814B04c69a542632DC93161",
    disabled: false,
  },
  {
    token: "ATROPA",
    img: "atropa",
    name: "atropa",
    chain: "PulseX",
    id: 11,
    pair: "0xF892d93199B4DE0aB1CDf35799Ccf9D5A425581B",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xF892d93199B4DE0aB1CDf35799Ccf9D5A425581B",
    chartLink:
      "https://dexscreener.com/pulsechain/0xF892d93199B4DE0aB1CDf35799Ccf9D5A425581B",
    disabled: false,
  },
  {
    token: "pDAI",
    img: "pdai",
    name: "pdai",
    chain: "PulseX",
    id: 12,
    pair: "0xaE8429918FdBF9a5867e3243697637Dc56aa76A1",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xaE8429918FdBF9a5867e3243697637Dc56aa76A1",
    chartLink:
      "https://dexscreener.com/pulsechain/0xaE8429918FdBF9a5867e3243697637Dc56aa76A1",
    disabled: false,
  },
  {
    token: "EARN",
    img: "earn",
    name: "earn",
    chain: "PulseX",
    id: 13,
    pair: "0xed77CbbB80e5a5C3A1FE664419d6F690766b5913",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xed77CbbB80e5a5C3A1FE664419d6F690766b5913",
    chartLink:
      "https://dexscreener.com/pulsechain/0xed77CbbB80e5a5C3A1FE664419d6F690766b5913",
    disabled: true,
  },
  {
    token: "TYRH",
    img: "tyrh",
    name: "tyrh",
    chain: "PulseX",
    id: 14,
    pair: "0x2Eff73Dca3eDB60019834A21758A468cbb22EB4c",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x2Eff73Dca3eDB60019834A21758A468cbb22EB4c",
    chartLink:
      "https://dexscreener.com/pulsechain/0x2Eff73Dca3eDB60019834A21758A468cbb22EB4c",
    disabled: false,
  },
  {
    token: "ZKZX",
    img: "zkzx",
    name: "zkzx",
    chain: "PulseX",
    id: 15,
    pair: "0x2E109c85Ca018D14f86E81e28063446cc1500203",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x2E109c85Ca018D14f86E81e28063446cc1500203",
    chartLink:
      "https://dexscreener.com/pulsechain/0x2E109c85Ca018D14f86E81e28063446cc1500203",
    disabled: false,
  },
  {
    token: "NOPE",
    img: "nope",
    name: "nope",
    chain: "9INCH",
    id: 16,
    pair: "0xb823F30963511fb0a083073763f55d1285900C0c",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xb823F30963511fb0a083073763f55d1285900C0c",
    chartLink:
      "https://dexscreener.com/pulsechain/0xb823F30963511fb0a083073763f55d1285900C0c",
    disabled: false,
  },
  {
    token: "TEDDY BEAR",
    img: "bear",
    name: "teddy bear",
    chain: "PulseX",
    id: 17,
    pair: "0x31eF9a41500E6BD18524404aC9c5B88D04AA924E",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x31eF9a41500E6BD18524404aC9c5B88D04AA924E",
    chartLink:
      "https://dexscreener.com/pulsechain/0x31eF9a41500E6BD18524404aC9c5B88D04AA924E",
    disabled: false,
  },
  {
    token: "PEPE",
    img: "pepe",
    name: "pepe",
    chain: "PulseX",
    id: 18,
    pair: "0x11659a752AC07452B9F139B897c917338e2dFB16",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x11659a752AC07452B9F139B897c917338e2dFB16",
    chartLink:
      "https://dexscreener.com/pulsechain/0x11659a752AC07452B9F139B897c917338e2dFB16",
    disabled: false,
  },
  {
    token: "ICSA",
    img: "icsa",
    name: "icsa",
    chain: "PulseX",
    id: 19,
    pair: "0x91454D72cFBA6190aC71D539d26eD40B6531BFE9",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x91454D72cFBA6190aC71D539d26eD40B6531BFE9",
    chartLink:
      "https://dexscreener.com/pulsechain/0x91454D72cFBA6190aC71D539d26eD40B6531BFE9",
    disabled: false,
  },
  {
    token: "AXIS",
    img: "axis",
    name: "axis",
    chain: "PulseX",
    id: 20,
    pair: "0x06e5b9714b4669DFC5eE8896e400c95030F99E35",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x06e5b9714b4669DFC5eE8896e400c95030F99E35",
    chartLink:
      "https://dexscreener.com/pulsechain/0x06e5b9714b4669DFC5eE8896e400c95030F99E35",
    disabled: false,
  },
  {
    token: "uP",
    img: "up",
    name: "up",
    chain: "PulseX",
    id: 21,
    pair: "0x5CeBb68Fd22a385b039eFD50DB6B2597Bd425bF1",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x5CeBb68Fd22a385b039eFD50DB6B2597Bd425bF1",
    chartLink:
      "https://dexscreener.com/pulsechain/0x5CeBb68Fd22a385b039eFD50DB6B2597Bd425bF1",
    disabled: false,
  },
  {
    token: "TETRAp",
    img: "tetrap",
    name: "tetrap",
    chain: "PulseX",
    id: 22,
    pair: "0xFD21A7889729095fEFcE07A0Ef9F9fAa0B07A119",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xFD21A7889729095fEFcE07A0Ef9F9fAa0B07A119",
    chartLink:
      "https://dexscreener.com/pulsechain/0xFD21A7889729095fEFcE07A0Ef9F9fAa0B07A119",
    disabled: false,
  },
  {
    token: "pTGC",
    img: "ptgc",
    name: "ptgc",
    chain: "PulseX",
    id: 23,
    pair: "0xf5A89A6487D62df5308CDDA89c566C5B5ef94C11",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xf5A89A6487D62df5308CDDA89c566C5B5ef94C11",
    chartLink:
      "https://dexscreener.com/pulsechain/0xf5A89A6487D62df5308CDDA89c566C5B5ef94C11",
    disabled: false,
  },
  {
    token: "WOLF",
    img: "wolf",
    name: "wolf",
    chain: "PulseX",
    id: 24,
    pair: "0x794f1c6E67f78Ef5593aE9dCed56dEfD2bAC0cE0",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x794f1c6E67f78Ef5593aE9dCed56dEfD2bAC0cE0",
    chartLink:
      "https://dexscreener.com/pulsechain/0x794f1c6E67f78Ef5593aE9dCed56dEfD2bAC0cE0",
    disabled: false,
  },
  {
    token: "BLAST",
    img: "blast",
    name: "blast",
    chain: "PulseX",
    id: 25,
    pair: "0xA755A5dd5AaC63f9e8869a0F25905ae6B635A92C",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xA755A5dd5AaC63f9e8869a0F25905ae6B635A92C",
    chartLink:
      "https://dexscreener.com/pulsechain/0xA755A5dd5AaC63f9e8869a0F25905ae6B635A92C",
    disabled: false,
  },
  {
    token: "PTP",
    img: "ptp",
    name: "ptp",
    chain: "PulseX",
    id: 26,
    pair: "0xb8eFCcb3FA5D4Bc68524989173Dc603E1ACC0362",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xb8eFCcb3FA5D4Bc68524989173Dc603E1ACC0362",
    chartLink:
      "https://dexscreener.com/pulsechain/0xb8eFCcb3FA5D4Bc68524989173Dc603E1ACC0362",
    disabled: false,
  },
  {
    token: "BBC",
    img: "bbc",
    name: "bbc",
    chain: "PulseX",
    id: 27,
    pair: "0xb543812ddEbC017976f867Da710ddb30cCA22929",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xb543812ddEbC017976f867Da710ddb30cCA22929",
    chartLink:
      "https://dexscreener.com/pulsechain/0xb543812ddEbC017976f867Da710ddb30cCA22929",
    disabled: true,
  },
  {
    token: "eHEX",
    img: "ehex",
    name: "ehex",
    chain: "PulseX",
    id: 28,
    pair: "0xf0ea3efe42c11c8819948ec2d3179f4084863d3f",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xf0ea3efe42c11c8819948ec2d3179f4084863d3f",
    chartLink:
      "https://dexscreener.com/pulsechain/0xf0ea3efe42c11c8819948ec2d3179f4084863d3f",
    disabled: false,
  },
  {
    token: "GOFURS",
    img: "gofurs",
    name: "GOFURS",
    chain: "PulseX",
    id: 29,
    pair: "0xe34c1a936aa844da23fb6e70aa143e97135691d5",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xe34c1a936aa844da23fb6e70aa143e97135691d5",
    chartLink:
      "https://dexscreener.com/pulsechain/0xe34c1a936aa844da23fb6e70aa143e97135691d5",
    disabled: false,
  },
  {
    token: "HOA",
    img: "hoa",
    name: "HOA",
    chain: "PulseX",
    id: 30,
    pair: "0x714d3e632bdd5e840a37eddae6399b4cca859e26",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x714d3e632bdd5e840a37eddae6399b4cca859e26",
    chartLink:
      "https://dexscreener.com/pulsechain/0x714d3e632bdd5e840a37eddae6399b4cca859e26",
    disabled: false,
  },
  {
    token: "SOLIDX",
    img: "solidx",
    name: "SOLIDX",
    chain: "PulseX",
    id: 31,
    pair: "0xa1186671046c7e19f1083b93b8e72c297e5Ba7f7",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xa1186671046c7e19f1083b93b8e72c297e5Ba7f7",
    chartLink:
      "https://dexscreener.com/pulsechain/0xa1186671046c7e19f1083b93b8e72c297e5Ba7f7",
    disabled: false,
  },
  {
    token: "BRO",
    img: "bro",
    name: "BRO",
    chain: "PulseX",
    id: 32,
    pair: "0x5Ce2E1B0d987E17ceec95363bD2097855B1940c1",
    dexLink:
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x5Ce2E1B0d987E17ceec95363bD2097855B1940c1",
    chartLink:
      "https://dexscreener.com/pulsechain/0x5Ce2E1B0d987E17ceec95363bD2097855B1940c1",
    disabled: false,
  },
  // {
  //   token: "DWB",
  //   img: "dwb",
  //   name: "DWB",
  //   chain: "PulseX",
  //   id: 33,
  //   pair: "0xe644f9B23375D07f5fE11cC223716C6Db7EA356B",
  //   dexLink:
  //     "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xe644f9B23375D07f5fE11cC223716C6Db7EA356B",
  //   chartLink:
  //     "https://dexscreener.com/pulsechain/0xe644f9B23375D07f5fE11cC223716C6Db7EA356B",
  //   disabled: false,
  // },
];
