import React, { useState, useEffect } from 'react';
import {Button, Box, Popover, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



import useStyles from './index.styles';
import { useAppSelector } from '../../app/hooks';

interface TicketAmountSelectProps {
  readonly setTotalUsdAmount: (totalAmount: number) => void;
  readonly ticketPrice: number;
}

export default function TicketAmountSelect(props: TicketAmountSelectProps) {

  const { classes } = useStyles();
  const [amount, setAmount] = useState(5);
  const tickets = [1, 2, 5, 10, 50, 200];

  const changeAmount = (tempAmount: string) => {
    if(parseInt(tempAmount)) {
      setAmount(parseInt(tempAmount));
    } else {
      setAmount(0);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.setTotalUsdAmount && props.setTotalUsdAmount(amount * props.ticketPrice);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Box sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Button aria-describedby={id} variant="contained" onClick={handleClick} className={classes.button}>
          {amount}
          <KeyboardArrowDownIcon />
        </Button>
        <Box className={classes.description}>Amount</Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popBody}
      >
        <Box className={classes.title}>Select ticket amount</Box>
        <Box className={classes.amountBody}>
          <Box>
            <TextField
              hiddenLabel
              defaultValue={amount}
              size="small"
              placeholder='Amount...'
              className={classes.amountInput}
              onInput={(e) => changeAmount((e.target as HTMLInputElement).value)}
            />
          </Box>
          <Box>${(amount * props.ticketPrice).toFixed(2)}</Box>
        </Box>
        <Box className={classes.ticketList}>
          {tickets.map((ticket, index) => (
            <Box className={classes.ticketRow} key={index} onClick={() => {
              changeAmount(ticket.toString());
              setAnchorEl(null);
              props.setTotalUsdAmount(ticket * props.ticketPrice);
            }}>
              <Box className={classes.ticketRowContent}>
                <Box>{ticket} ticket{ticket > 1 && 's'}</Box>
                <Box>${(props.ticketPrice * ticket).toFixed(2)}</Box>
                <Box><ArrowForwardIosIcon /></Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={classes.confirmButtonBody}>
          <Button className={classes.confirmButton} onClick={handleClose}>Confirm</Button>
        </Box>
      </Popover>
    </div>
  );
}
