import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  body: {
    paddingLeft: '40px',
    paddingRight: '40px',
    width: '100%',
    gap: '30px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '30px',
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    }
  },
  headerText: {
    display: 'flex',
    fontSize: '24px',
    gap: '15px',
    alignItems: 'center',
    '& img': {
      width: '30px',
    }
  },
  detailsText: {
    fontSize: '12px',
    color: theme.palette.text.disabled,
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    '& img': {
      width: '25px'
    }
  },
  cardsBody: {
    display: 'flex',
    gap: '30px',
    flexWrap: 'wrap',
  }
}));

export default useStyles;