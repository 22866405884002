import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import airdropReducer from "../reducers/airdrop.slice";
import marchMadnessReducer from "../reducers/march.slice";
import ufcReducer from "../reducers/ufc.slice";
import uefa24Reducer from "../reducers/uefa24.slice";
import euro24Reducer from "../reducers/euro24.slice";
import predictReducer from "../reducers/predict.slice";
import userReducer from "../reducers/user.slice";
import notificationReducer from "../reducers/notification.slice";

export const store = configureStore({
  reducer: {
    marchMadness: marchMadnessReducer,
    uefa24: uefa24Reducer,
    euro24: euro24Reducer,
    airdrop: airdropReducer,
    ufc: ufcReducer,
    predict: predictReducer,
    user: userReducer,
    notification: notificationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
