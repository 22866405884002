import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import { Entry } from '../../reducers/march.slice';


import TicketEnteredDialog from '../TicketEnteredDialog';
import useStyles from './index.styles';

interface TicketEnteredProps {
  readonly ticketId: number;
  readonly onNewCreate: () => void;
  readonly setConfirmingTransactionFalse: () => void;
  readonly setIsShowView: (isShowView: boolean) => void;
  readonly setTicketView: (address: string, ticketId: number) => void;
  readonly entryInfo: Entry;
}

export default function TicketEnteredPopup(props: TicketEnteredProps) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    props.setTicketView(props.entryInfo.player, props.entryInfo.entryId);
  }, [props])

  const handleClose = () => {
    setOpen(false);
    props.setConfirmingTransactionFalse();
  };

  return (
    <Box className={classes.body}>
      <TicketEnteredDialog
        onNewCreate={() => {
          props.onNewCreate();
          handleClose();
        }}
        ticketId={props.ticketId}
        onTicketView={() => {
          props.setIsShowView(true);
          handleClose();
        }}
        open={open}
        onClose={handleClose}
        ticketCount={props.entryInfo.ticketCount}
      />
    </Box>
  );
}
