import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./index.styles";

interface PredictionCreateHeaderProps {
  headerText?: string;
}

const PredictionCreateHeader = (props: PredictionCreateHeaderProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const returnOverview = () => {
    navigate("/predictions");
  };
  return (
    <Box className={classes.root}>
      <Box display={`flex`} alignItems={`flex-start`}>
        <Typography variant="h6" className={classes.title}>
          {props?.headerText}
        </Typography>
      </Box>
      <Box className={classes.menuBtn} onClick={returnOverview}>
        <img src="/images/prediction/return.png" alt="return" width={15} />
        <Typography variant="body2" className={classes.back}>
          Back to overview
        </Typography>
      </Box>
    </Box>
  );
};

export default PredictionCreateHeader;
