import { UFCPlayerType } from "../../constants/ufc";
import UfcEntries from "../UfcEntries";

import useStyles from "./index.styles";

export default function UfcEntryPanel() {
  const { classes } = useStyles();

  return (
    <div className={classes.ufcEntryPanel}>
      <UfcEntries player={UFCPlayerType.PLAYER1} />
      <UfcEntries player={UFCPlayerType.PLAYER2} />
    </div>
  );
}
