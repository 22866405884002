import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100vw",
    boxSizing: "border-box",
    background: "url(/images/events/euro/bg.png) no-repeat",
    backgroundSize: "100%",
    marginTop: -140,
    paddingTop: 160,
    minHeight: 600,

    [theme.breakpoints.down("sm")]: {
      marginTop: -56,
    },
  },

  logo: {
    height: 200,
  },

  container: {
    display: "flex",
    gap: 16,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  bodyContainer: {
    overflowX: "auto",
    maxWidth: "calc(100vw - 100px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100vw - 20px)",
    },
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#646a78",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#7e889e",
      },
    },
  },
}));

export default styles;
