import { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { NavLink, useParams, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import { useConnectWallet } from "@web3-onboard/react";

import useStyles from "./index.styles";
import clsx from "clsx";
import {
  finishRound,
  getRoundMaxValue,
  getRounds,
  setHasMoreAllRounds,
  cancelRound,
} from "../../reducers/predict.slice";
import {
  PredictRoundStatus,
  PredictionGettingRoundsType,
} from "../../constants/prediction";
import { toUSDFormat } from "../../utils";
import { gameConfig } from "../../config";

export default function RoundListTable() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const {
    roundMaxValue,
    countPerPage,
    allRounds,
    allRoundsCount,
    hasMoreAllRounds,
    loadingFinishRound,
    loadingCancelRound,
  } = useAppSelector((state) => state.predict);
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const { address } = useParams();

  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const finishPredictRound = (roundId: number) => {
    dispatch(finishRound({ roundId, account }));
  };

  const cancelPredictRound = (roundId: number) => {
    if (address?.toLowerCase() !== account.toLowerCase()) return;
    dispatch(cancelRound({ roundId, account }));
  };

  const handleMoreRounds = () => {
    dispatch(setHasMoreAllRounds(true));
    dispatch(
      getRounds({
        statusValue: -1,
        sortValue: 0,
        coinValue: [],
        minValue: 0,
        maxValue: roundMaxValue,
        typeValue: "all",
        activePage: page + 1,
        countPerPage: countPerPage,
        roundsType: PredictionGettingRoundsType.AllRoundsProfile,
        initStatus: false,
        player: address,
      })
    );
    setPage((page) => page + 1);
  };

  const gotoDetailPage = (roundId: number) => {
    navigate("/predictions/detail/" + roundId);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getRoundMaxValue());
    };

    fetchData();

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (roundMaxValue > 0) {
      dispatch(
        getRounds({
          statusValue: -1,
          sortValue: 0,
          coinValue: [],
          minValue: 0,
          maxValue: roundMaxValue,
          typeValue: "all",
          activePage: page,
          countPerPage: countPerPage,
          roundsType: PredictionGettingRoundsType.AllRoundsProfile,
          initStatus: true,
          player: address,
        })
      );
    }
  }, [address, roundMaxValue, dispatch]);

  return (
    <Box className={classes.body}>
      <Box className={classes.scrollBody}>
        <Box className={classes.tableHeader}>
          <Box></Box>
          <Box>Id</Box>
          <Box>Asset</Box>
          <Box>Value</Box>
          <Box>Profit/loss</Box>
          <Box>Status</Box>
          <Box>Action</Box>
        </Box>
        <Box className={classes.tableBody} id={"scrollableDiv"}>
          <InfiniteScroll
            dataLength={allRounds.length}
            hasMore={
              allRounds.length >= allRoundsCount ? false : hasMoreAllRounds
            }
            next={handleMoreRounds}
            scrollableTarget="scrollableDiv"
            loader={<Box className={classes.loading}>loading</Box>}
          >
            {allRounds.map((round, index) => (
              <Box
                className={classes.detail}
                key={index}
                onClick={() => gotoDetailPage(round.roundId)}
              >
                <Box className={classes.thunderPart}>
                  <Box component={"img"} src="/images/prediction/thunder.png" />
                </Box>
                <Box className={classes.idPart}>{round.roundId}</Box>
                <Box className={classes.assetPart}>
                  <Box
                    component={"img"}
                    src={`/images/tokens/${round.priceTokenName.toLowerCase()}.png`}
                  />
                  {round.priceTokenName}
                </Box>
                <Box className={clsx(classes.valuePart)}>
                  ${toUSDFormat(round.roundMaximumValue, 1)}
                </Box>
                <Box className={clsx(classes.profitLossPart)}>
                  {round.status === PredictRoundStatus.Finished ? (
                    <>
                      {(round.isCreatorWinner &&
                        address?.toLowerCase() ===
                          round.creator.toLowerCase()) ||
                      (!round.isCreatorWinner &&
                        address?.toLowerCase() !==
                          round.creator.toLowerCase()) ? (
                        <Box className={classes.plus}>
                          +$
                          {round.isCreatorWinner
                            ? round.isQuickBet
                              ? toUSDFormat(
                                  round.roundMaximumValue -
                                    round.feeAmount -
                                    round.roundMaximumValue / 2,
                                  2
                                )
                              : "none"
                            : round.isQuickBet
                            ? toUSDFormat(
                                round.roundMaximumValue -
                                  round.feeAmount -
                                  round.roundMaximumValue / 2,
                                2
                              )
                            : "none"}
                        </Box>
                      ) : (
                        <Box className={classes.minus}>
                          -$
                          {round.isCreatorWinner
                            ? round.isQuickBet
                              ? toUSDFormat(round.roundMaximumValue / 2, 2)
                              : "none"
                            : round.isQuickBet
                            ? toUSDFormat(round.roundMaximumValue / 2, 2)
                            : "none"}
                        </Box>
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box className={classes.statusPart}>
                  {round.status === PredictRoundStatus.Waiting &&
                  round.endTime >= new Date().getTime() / 1000
                    ? "open"
                    : round.status === PredictRoundStatus.Started &&
                      round.endTime >= new Date().getTime() / 1000
                    ? "live"
                    : round.status === PredictRoundStatus.Started &&
                      round.endTime < new Date().getTime() / 1000
                    ? "waiting"
                    : round.status === PredictRoundStatus.Finished
                    ? "completed"
                    : round.status === PredictRoundStatus.Waiting &&
                      round.endTime < new Date().getTime() / 1000
                    ? "expired"
                    : round.status === PredictRoundStatus.Canceled
                    ? "canceled"
                    : ""}
                </Box>
                <Box className={classes.actionPart}>
                  {round.status === PredictRoundStatus.Waiting &&
                  round.endTime < new Date().getTime() / 1000 ? (
                    <Button
                      className={classes.enableBut}
                      onClick={(e) => {
                        e.stopPropagation();
                        cancelPredictRound(round.roundId);
                      }}
                      disabled={loadingFinishRound || loadingCancelRound}
                    >
                      Withdraw
                      {loadingFinishRound || loadingCancelRound ? (
                        <CircularProgress
                          size={15}
                          sx={{ color: "white", marginLeft: "3px" }}
                        />
                      ) : null}
                    </Button>
                  ) : round.status === PredictRoundStatus.Started &&
                    round.endTime < new Date().getTime() / 1000 ? (
                    <Button
                      className={classes.enableBut}
                      disabled={loadingFinishRound || loadingCancelRound}
                      onClick={(e) => {
                        e.stopPropagation();
                        finishPredictRound(round.roundId);
                      }}
                    >
                      Finalize
                      {loadingFinishRound || loadingCancelRound ? (
                        <CircularProgress
                          size={15}
                          sx={{ color: "white", marginLeft: "3px" }}
                        />
                      ) : null}
                    </Button>
                  ) : (
                    <NavLink
                      to={`${gameConfig.explorerUrl}tx/${round.txHash}`}
                      target="__blank"
                    >
                      <Button className={classes.disableBut}>View tx</Button>
                    </NavLink>
                  )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
}
