import { useState, useEffect } from "react";
import { Box } from "@mui/material";

import TokenCardDetail from "../TokenCardDetail";

import useStyles from "./index.styles";
import { PredictToken, PriceTokenLp } from "../../reducers/predict.slice";
import { useAppSelector } from "../../app/hooks";
import {
  DexFilterStatus,
  DexFilterValues,
  TokenFilterStatus,
} from "../../constants/prediction";

interface SupportedTokensProps {
  sort: number;
  dex: number;
  addFavoriteToken: (address: string) => void;
  removeFavoriteToken: (address: string) => void;
}

export default function SupportedTokens(props: SupportedTokensProps) {
  const { classes } = useStyles();
  const { tokens, tokenLps, favoriteTokens } = useAppSelector(
    (state) => state.predict
  );
  const [newFilterTokens, setNewFilterTokens] = useState<PredictToken[]>([]);

  useEffect(() => {
    setNewFilterTokens(() => {
      let newTokens = [...tokens];
      if (props.dex !== DexFilterStatus.All) {
        newTokens = tokens.filter(
          (token) => token.chain === DexFilterValues[props.dex].text
        );
      }

      if (props.sort === TokenFilterStatus.Name) {
        newTokens.sort((a, b) => a.name.localeCompare(b.name));
      } else if (props.sort === TokenFilterStatus.Liquidity) {
        newTokens.sort((a, b) => {
          const liquidityA =
            tokenLps.find(
              (lp) => lp.pairAddress.toLowerCase() === a.pair.toLowerCase()
            )?.liquidity || 0;
          const liquidityB =
            tokenLps.find(
              (lp) => lp.pairAddress.toLowerCase() === b.pair.toLowerCase()
            )?.liquidity || 0;
          return liquidityB - liquidityA;
        });
      } else if (props.sort === TokenFilterStatus.Favorite) {
        const favorite = newTokens.filter((token) =>
          favoriteTokens.some(
            (fav) =>
              fav.tokenAddress.toLowerCase() ===
              token.tokenAddress?.toLowerCase()
          )
        );
        const unFavorite = newTokens.filter((token) =>
          favoriteTokens.every(
            (fav) =>
              fav.tokenAddress.toLowerCase() !==
              token.tokenAddress?.toLowerCase()
          )
        );
        newTokens = [...favorite, ...unFavorite];
      }
      return newTokens;
    });
  }, [props.sort, props.dex, tokens, tokenLps]);

  return (
    <Box className={classes.body}>
      <Box className={classes.header}>
        <Box className={classes.detailsText}>
          <Box component="span">{tokens.length} assets supported</Box>
        </Box>
      </Box>
      <Box className={classes.cardsBody}>
        {newFilterTokens.map((token, index) => (
          <TokenCardDetail
            key={index}
            token={token}
            lp={
              tokenLps.find(
                (lp) =>
                  lp.pairAddress.toLowerCase() === token.pair?.toLowerCase()
              ) || null
            }
            addFavoriteToken={props.addFavoriteToken}
            removeFavoriteToken={props.removeFavoriteToken}
            favorite={favoriteTokens.findIndex(
              (favToken) =>
                favToken.tokenAddress.toLowerCase() ===
                token.tokenAddress?.toLowerCase()
            )}
          />
        ))}
      </Box>
    </Box>
  );
}
