import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: 20,
  },

  container: {
    display: "flex",
    flexDirection: "column",
  },

  title: {
    fontSize: "32px",
    padding: "80px 0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      padding: "60px 0",
    },
  },

  orTitle: {
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  betContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "center",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "2fr 1fr 2fr",
    },
  },

  betDetail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
  },

  basicIcon: {
    "& svg": {
      fontSize: "72px",
      color: "#4e5976",
      [theme.breakpoints.down("sm")]: {
        fontSize: "60px",
      },
    },
  },

  betTitle: {
    fontSize: "24px",
    padding: "18px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      padding: "0",
    },
  },

  betButton: {
    width: 100,
    height: 100,
    padding: 0,
    borderRadius: "50%",
    backgroundColor: "#1c1f28",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "auto",

    "& svg": {
      fontSize: "72px",
      color: "#4e5976",
      [theme.breakpoints.down("sm")]: {
        fontSize: "60px",
      },
    },

    [theme.breakpoints.down("sm")]: {
      width: 70,
      height: 70,
    },
  },

  betDesc: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 0",
    gap: "8px",

    [theme.breakpoints.down("sm")]: {
      padding: "18px 0",
    },
  },

  betDescDetail: {
    display: "flex",
    alignItems: "center",
    gap: "4px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  advancedColor: {
    color: "#32c39a",
  },

  quickColor: {
    color: "#dfbe37",
  },
}));

export default styles;
