import { NumericFormat } from "react-number-format";
import React, { forwardRef } from "react";
import { InputBaseComponentProps } from '@mui/material';

// Define TypeScript interface for props
interface CustomNumberFormatProps extends InputBaseComponentProps  {
  onChange: (event : any) => void; // Define the expected function signature
  name: string; // If using name prop explicitly, it should be defined
  [key: string]: any; // This allows any other props to be passed down
}

export const CustomNumberFormat = forwardRef<HTMLInputElement, CustomNumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      defaultValue={1}
      getInputRef={ref}
      onChange={(e) => onChange(e)}
      thousandSeparator
      prefix="$"
      suffix="&nbsp;USD"
    />
  );
});