import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TokenFilters from "../../components/TokenFilters";
import SupportedTokens from "../../components/SupportedTokens";
import { useConnectWallet } from "@web3-onboard/react";

import theme from "../../theme";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getPriceTokenLp,
  getPriceTokens,
  addFavoriteToken,
  removeFavoriteToken,
  getFavoriteToken,
  setFavoriteToken,
} from "../../reducers/predict.slice";
import { Toast } from "../../utils";

const Home = () => {
  const dispatch = useAppDispatch();
  const { tokens } = useAppSelector((state) => state.predict);
  const [sortValue, setSortValue] = useState(0);
  const [dexValue, setDexValue] = useState(0);
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const changeSortValue = (value: number) => {
    setSortValue(value);
  };
  const changeDexValue = (value: number) => {
    setDexValue(value);
  };

  const handleAddFavoriteToken = (address: string) => {
    dispatch(addFavoriteToken({ account, address }))
      .unwrap()
      .then(() => {
        dispatch(getFavoriteToken(account));
        Toast.fire({ icon: "success", title: "Favorite token added." });
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveFavoriteToken = (address: string) => {
    dispatch(removeFavoriteToken({ account, address }))
      .unwrap()
      .then(() => {
        dispatch(getFavoriteToken(account));
        Toast.fire({ icon: "success", title: "Favorite token removed." });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(getPriceTokens());
  }, []);

  useEffect(() => {
    if (account) {
      dispatch(getFavoriteToken(account));
    }
    dispatch(setFavoriteToken());
  }, [account]);

  useEffect(() => {
    if (tokens.length === 0) return;
    const fetchLp = async () => {
      await dispatch(getPriceTokenLp(tokens.map((token, index) => token.pair)));
    };

    fetchLp();
  }, [tokens]);

  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: "20px",
        width: "90%",
        marginLeft: "5%",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
        [theme.breakpoints.up("xl")]: {
          width: "70%",
          marginLeft: "15%",
        },
      }}
    >
      <TokenFilters
        sort={sortValue}
        dex={dexValue}
        handleSort={changeSortValue}
        handleDex={changeDexValue}
      />
      <SupportedTokens
        sort={sortValue}
        dex={dexValue}
        addFavoriteToken={handleAddFavoriteToken}
        removeFavoriteToken={handleRemoveFavoriteToken}
      />
    </Box>
  );
};

export default Home;
