import { Box, Button } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { gsap } from "gsap";
import { useEffect, useMemo, useRef, useState } from "react";

import Blocky from "../../components/Blocky";
import Dot from "../../components/Dot";
import PageLoadingMov from "../../components/PageLoadingMov";
import TicketAmountSelect from "../../components/TicketAmountSelect";
import TicketEnteredPopup from "../../components/TicketEnteredPopup";
import Uefa24InformationDialog from "../../components/Uefa24InformationDialog";
import Uefa24TicketPurchaseBtn from "../../components/Uefa24TicketPurchaseBtn";
import Uefa24TicketTable from "../../components/Uefa24TicketTable";
import Uefa24TourLeaderBoard from "../../components/Uefa24TourLeaderBoard";
import UefaTeamCard from "../../components/UefaTeamCard";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { gameConfig } from "../../config";
import { pageLoadingTime } from "../../constants";
import {
  uefa24PairStatus,
  uefa24Play,
  uefa24RoundStatus,
  uefa24Status,
} from "../../constants/uefa24";
import {
  addEntry,
  getEntries,
  getMatchesInfo,
  setChampionId,
  setConfirmingTransaction,
  Uefa24Card,
} from "../../reducers/uefa24.slice";
import { shortAddress, shortName } from "../../utils";
import useStyles from "./index.styles";
import { UserInfo } from "../../reducers/user.slice";
const eventURL = "event/uefa";

export default function UEFA24() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [{ wallet }] = useConnectWallet();
  const { profiles } = useAppSelector((state) => state.user);

  const players = useMemo(
    () => [
      { id: 0, name: "", avatar: "" },
      { id: 1, name: "Arsenal", avatar: "arsenal.png" },
      { id: 2, name: "Bayern", avatar: "bayern.png" }, // not yet
      { id: 3, name: "Real Madrid", avatar: "real-madrid.png" },
      { id: 4, name: "Man City", avatar: "man-city.png" },
      { id: 5, name: "PSG", avatar: "psg.png" },
      { id: 6, name: "Barcelona", avatar: "barcelona.png" },
      { id: 7, name: "A.Madrid", avatar: "atletico-madrid.png" },
      { id: 8, name: "Dortmund", avatar: "dortmund.png" },
    ],
    []
  );

  const [cardInfos, setCardInfos] = useState<Uefa24Card[]>([]);
  const [isShowView, setIsShowView] = useState(false);
  const [totalUsdAmount, setTotalUsdAmount] = useState(25);
  const [viewTicket, setViewTicket] = useState(0);
  const [viewTicketAddress, setViewTicketAddress] = useState("");
  const [viewTicketProfile, setViewTicketProfile] = useState<
    UserInfo | undefined
  >();

  const [remainingTime, setRemainingTime] = useState("");
  const [loading, setLoading] = useState(true);
  const account = wallet ? wallet.accounts[0].address : "";
  const accountRef = useRef("");
  const presentTicket = useRef(0);
  const isAnimating = useRef<{ [key: number]: boolean }>({});
  const [presentMatchGrade, setPresentMatchGrade] = useState(
    uefa24Play.QUARTER
  );

  const {
    roundStatus,
    totalTickets,
    closeBetAt,
    totalRoundUSD,
    entries,
    confirmingTransaction,
    gameResults,
    ticketPrice,
  } = useAppSelector((state) => state.uefa24);

  const setTicketView = (address: string, ticketId: number) => {
    setViewTicket(ticketId);
    setViewTicketAddress(address);
  };

  const getPredictions = () => {
    const predictions = cardInfos
      .filter((item) => item && item.status === uefa24Status.WINNER)
      .map(({ playerId }) => playerId);
    return predictions;
  };
  const predictions = getPredictions();

  const updateCardInfos = (cardId: number, updateObj: object) => {
    setCardInfos((prevCardInfos) => {
      const updatedCardInfos = [...prevCardInfos];
      updatedCardInfos[cardId] = {
        ...updatedCardInfos[cardId],
        ...updateObj,
      };
      return updatedCardInfos;
    });
  };

  const getWinnerInfo = (card: any) => {
    let winnerCardId = -1;
    let side = card.side;
    if (card.gameGrade === uefa24Play.QUARTER) {
      winnerCardId = Math.floor(card.id / 2) + 8;
    } else if (card.gameGrade === uefa24Play.SEMI) {
      winnerCardId = Math.floor((card.id - 8) / 2) + 12;
      side = 1;
    } else if (card.gameGrade === uefa24Play.FINAL) {
      winnerCardId = Math.floor((card.id - 12) / 2) + 14;
      side = 1;
    }

    const winnerPairId = winnerCardId % 2 ? winnerCardId - 1 : winnerCardId + 1;

    return [winnerCardId, winnerPairId, side];
  };

  const pickClick = (card: Uefa24Card) => {
    let t1 = gsap.timeline();
    let time = 0;
    if (card.id >= 0 && card.id < 8) {
      let x: number[] = [70, 70, 70, 70, -70, -70, -70, -70];
      let y: number[] = [85, 85, -85, -85, 85, 85, -85, -85];
      time = 0.6;

      t1.to(".football" + card.id, {
        opacity: 1,
        scale: 1,
        duration: 0.1,
        ease: "power2.out",
      })
        .to(".football" + card.id, {
          x: x[card.id],
          rotate: 200,
          duration: 0.3,
          ease: "none",
        })
        .to(".football" + card.id, {
          y: y[card.id],
          rotate: 400,
          duration: 0.3,
          ease: "none",
        })
        .to(".football" + card.id, {
          opacity: 0,
          scale: 5,
          duration: 0.5,
          ease: "power2.out",
        })
        .to(".football" + card.id, {
          scale: 1,
          x: 0,
          y: 0,
          rotate: 0,
        });
    } else if (card.id >= 8 && card.id < 12) {
      time = 0.8;
      let x: number[][] = [
        [80, 160],
        [80, 160],
        [-80, -160],
        [-80, -160],
      ];
      let y: number[] = [55, 55, 55, 55];
      t1.to(".football" + card.id, {
        opacity: 1,
        scale: 1,
        duration: 0.1,
        ease: "power2.out",
      })
        .to(".football" + card.id, {
          x: x[card.id - 8][0],
          rotate: 200,
          duration: 0.3,
          ease: "none",
        })
        .to(".football" + card.id, {
          y: y[card.id - 8],
          rotate: 300,
          duration: 0.2,
          ease: "none",
        })
        .to(".football" + card.id, {
          x: x[card.id - 8][1],
          rotate: 500,
          duration: 0.3,
          ease: "none",
        })
        .to(".football" + card.id, {
          opacity: 0,
          scale: 5,
          duration: 0.5,
          ease: "power2.out",
        })
        .to(".football" + card.id, {
          scale: 1,
          x: 0,
          y: 0,
          rotate: 0,
        });
    } else if (card.id === 13 || card.id === 12) {
      time = 0.5;
      t1.to(".football" + card.id, {
        opacity: 1,
        scale: 1,
        duration: 0.1,
        ease: "power2.out",
      })
        .to(".football" + card.id, {
          y: -50,
          rotate: 150,
          duration: 0.3,
          ease: "none",
        })
        .to(".football" + card.id, {
          opacity: 0,
          scale: 5,
          duration: 0.3,
          ease: "power2.out",
        })
        .to(".football" + card.id, {
          scale: 1,
          x: 0,
          y: 0,
          rotate: 0,
        });
    }
    const [winnerCardId, winnerPairId, side] = getWinnerInfo(card);
    isAnimating.current[winnerCardId] = true;

    t1.call(
      (card) => {
        const newWinnerCard: Uefa24Card = {
          id: winnerCardId,
          playerId: card.playerId,
          status: uefa24Status.ENABLE,
          pairStatus:
            cardInfos[winnerPairId] &&
            (cardInfos[winnerPairId].status === uefa24Status.ENABLE ||
              isAnimating.current[winnerPairId])
              ? uefa24PairStatus.PICKABLE
              : uefa24PairStatus.PICKED,
          gameGrade: card.gameGrade + 1,
          latestStatus: true,
          side: side,
          name: players[card.playerId].name,
          url: players[card.playerId].avatar,
          gameResult: 0,
          isShowView: false,
        };
        updateCardInfos(winnerCardId, newWinnerCard);
        setTimeout(() => (isAnimating.current[winnerCardId] = false), 1000);
        if (cardInfos[winnerPairId] && cardInfos[winnerPairId].playerId > 0) {
          updateCardInfos(winnerPairId, { pairStatus: 1 });
        }

        if (card.gameGrade === uefa24Play.FINAL) {
          dispatch(setChampionId(card.playerId));
        }
      },
      [card],
      time
    );

    updateCardInfos(card.id, {
      status: uefa24Status.WINNER,
      latestStatus: false,
    });

    const pairId = card.id % 2 ? card.id - 1 : card.id + 1;
    updateCardInfos(pairId, {
      status: uefa24Status.LOSER,
      latestStatus: false,
    });
  };

  const closeClick = (card: Uefa24Card) => {
    if (card.gameGrade === uefa24Play.WINNER) {
      updateCardInfos(14, {
        status: uefa24Status.WAITING,
        latestStatus: false,
      });
      updateCardInfos(12, { status: uefa24Status.ENABLE, latestStatus: true });
      updateCardInfos(13, { status: uefa24Status.ENABLE, latestStatus: true });
    } else if (card.gameGrade === uefa24Play.FINAL) {
      updateCardInfos(card.id, {
        status: uefa24Status.WAITING,
        latestStatus: false,
        pairStatus: 0,
      });
      updateCardInfos(card.id % 2 ? card.id - 1 : card.id + 1, {
        pairStatus: 0,
      });
      if (card.id === 12) {
        updateCardInfos(8, { status: uefa24Status.ENABLE, latestStatus: true });
        updateCardInfos(9, { status: uefa24Status.ENABLE, latestStatus: true });
      } else if (card.id === 13) {
        updateCardInfos(10, {
          status: uefa24Status.ENABLE,
          latestStatus: true,
        });
        updateCardInfos(11, {
          status: uefa24Status.ENABLE,
          latestStatus: true,
        });
      }
    } else if (card.gameGrade === uefa24Play.SEMI) {
      updateCardInfos(card.id, {
        status: uefa24Status.WAITING,
        latestStatus: false,
        pairStatus: 0,
      });
      updateCardInfos(card.id % 2 ? card.id - 1 : card.id + 1, {
        pairStatus: 0,
      });
      if (card.id === 8) {
        updateCardInfos(0, { status: uefa24Status.ENABLE });
        updateCardInfos(1, { status: uefa24Status.ENABLE });
      } else if (card.id === 9) {
        updateCardInfos(2, { status: uefa24Status.ENABLE });
        updateCardInfos(3, { status: uefa24Status.ENABLE });
      } else if (card.id === 10) {
        updateCardInfos(4, { status: uefa24Status.ENABLE });
        updateCardInfos(5, { status: uefa24Status.ENABLE });
      } else if (card.id === 11) {
        updateCardInfos(6, { status: uefa24Status.ENABLE });
        updateCardInfos(7, { status: uefa24Status.ENABLE });
      }
    }
    dispatch(setChampionId(0));
  };

  const onNewCreate = () => {
    setIsShowView(false);
    dispatch(setChampionId(0));
    setCardInfos((prevCardInfos) => {
      return prevCardInfos.map((cardInfo, index) => {
        if (index < 8) {
          return { ...cardInfo, status: uefa24Status.ENABLE };
        } else {
          return {
            ...cardInfo,
            status: uefa24Status.WAITING,
            latestStatus: false,
          };
        }
      });
    });
  };

  const getGameGrade = (index: number): number => {
    if (index >= 0 && index < 8) {
      return uefa24Play.QUARTER;
    } else if (index >= 8 && index < 12) {
      return uefa24Play.SEMI;
    } else if (index >= 12 && index < 14) {
      return uefa24Play.FINAL;
    } else if (index === 14) {
      return uefa24Play.WINNER;
    }
    return 0;
  };

  const getSide = (index: number): number => {
    if (
      (index >= 0 && index < 4) ||
      index === 8 ||
      index === 9 ||
      index === 12 ||
      index === 13 ||
      index === 14
    ) {
      return 1;
    } else {
      return 2;
    }
  };

  const handleViewHistory = (dat: any) => {
    setIsShowView(true);
    setViewTicket(dat.entryId);
    setViewTicketAddress(dat.player);
    const profile = profiles.find(
      (profile) => profile.address.toLowerCase() == dat.player.toLowerCase()
    );

    setViewTicketProfile(profile);

    const predictions: string[] = dat.predictions;
    dispatch(setChampionId(parseInt(predictions[predictions.length - 1])));

    setCardInfos((prevCardInfos: Uefa24Card[]) => {
      const updatedCards = [...prevCardInfos];
      for (let i = 0; i < 8; i++) {
        const status =
          cardInfos[i].playerId.toString() === predictions[Math.floor(i / 2)]
            ? uefa24Status.WINNER
            : uefa24Status.LOSER;
        updatedCards[i] = { ...updatedCards[i], status, isShowView: true };
      }
      for (let i = 8; i < 15; i++) {
        if (predictions[i - 8] === "0") {
          updatedCards[i] = {
            ...updatedCards[i],
            playerId: 0,
            status: uefa24Status.WAITING,
            latestStatus: false,
            name: "",
            url: "",
          };
          continue;
        }
        let status = uefa24Status.ENABLE;
        if (
          (getGameGrade(i) === uefa24Play.SEMI ||
            getGameGrade(i) === uefa24Play.FINAL) &&
          predictions[i - 8] !== "0" &&
          predictions[i - 7] !== "0" &&
          predictions[Math.floor((i - 8) / 2) + 4] !== "0"
        ) {
          status =
            predictions[i - 8] === predictions[Math.floor((i - 8) / 2) + 4]
              ? uefa24Status.WINNER
              : uefa24Status.LOSER;
        }

        updatedCards[i] = {
          ...updatedCards[i],
          playerId: parseInt(predictions[i - 8]),
          status,
          name: players[parseInt(predictions[i - 8])].name,
          url: players[parseInt(predictions[i - 8])].avatar,
          latestStatus: false,
          isShowView: true,
        };
      }
      return updatedCards;
    });
  };

  const setConfirmingTransactionFalse = () => {
    dispatch(setConfirmingTransaction());
  };

  useEffect(() => {
    accountRef.current = account;
  }, [account]);

  useEffect(() => {
    dispatch(getMatchesInfo());
    dispatch(getEntries());

    const setupEventSource = () => {
      const eventSource = new EventSource(`${gameConfig.serverUrl}${eventURL}`);
      eventSource.onmessage = async (e) => {
        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);
          console.log("Uefa24 event: ", res);
          dispatch(addEntry(res.result));
          if (accountRef.current === res.result.player.toLowerCase()) {
            presentTicket.current = Number(res.result.entryId);
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();
    return () => {
      eventSource.close();
    };
  }, [dispatch]);

  useEffect(() => {
    for (let i = 0; i < gameResults.length; i++) {
      if (gameResults[i] === 0) {
        if (i >= 0 && i < 4) {
          setPresentMatchGrade(uefa24Play.QUARTER);
        } else if (i >= 4 && i < 6) {
          setPresentMatchGrade(uefa24Play.SEMI);
        } else if (i === 6) {
          setPresentMatchGrade(uefa24Play.FINAL);
        } else {
          setPresentMatchGrade(0);
        }
        break;
      }
    }
  }, [gameResults]);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const currentTimeUnix = Math.floor(Date.now() / 1000);
      if (!closeBetAt) {
        return;
      }
      const timeDiff = closeBetAt - currentTimeUnix;
      if (timeDiff < 0) {
        setRemainingTime("Finished");
        return;
      }
      const days: number = Math.floor(timeDiff / 3600 / 24);
      const hours: number = Math.floor((timeDiff % (3600 * 24)) / 3600);
      const minutes: number = Math.floor((timeDiff % 3600) / 60);
      setRemainingTime(
        (days > 1 ? days + " days, " : days === 1 ? "1 day, " : "") +
          (hours > 1 ? hours + " hours & " : hours === 1 ? "1 hour & " : "") +
          (minutes > 1
            ? " " + minutes + " mins"
            : minutes === 1
            ? " 1 min"
            : "")
      );
    };

    calculateRemainingTime();
    setInterval(calculateRemainingTime, 10000);
  }, [closeBetAt]);

  useEffect(() => {
    gameResults &&
      gameResults.length > 0 &&
      setCardInfos((prevCardInfos: Uefa24Card[]) => {
        const newCardInfos: Uefa24Card[] = [];

        for (let i = 1; i < players.length; i++) {
          const card: Uefa24Card = {
            id: i - 1,
            playerId: players[i].id,
            status:
              gameResults[Math.floor((i - 1) / 2)] === 0
                ? uefa24Status.ENABLE
                : gameResults[Math.floor((i - 1) / 2)] === players[i].id
                ? uefa24Status.WINNER
                : uefa24Status.LOSER,
            pairStatus: uefa24PairStatus.PICKABLE,
            gameGrade: uefa24Play.QUARTER,
            side: i <= 4 ? 1 : 2,
            name: players[i].name,
            url: players[i].avatar,
            latestStatus: false,
            gameResult: 0,
            isShowView: false,
          };
          newCardInfos.push(card);
        }
        for (let i = 0; i < 7; i++) {
          const gameGrade = getGameGrade(i + 8);
          let winner = 0;
          if (gameGrade === uefa24Play.SEMI) {
            winner = i === 0 || i === 1 ? 4 : 5;
          } else if (gameGrade === uefa24Play.FINAL) {
            winner = i === 4 || i === 5 ? 6 : 0;
          } else if (gameGrade === uefa24Play.WINNER) {
            winner = 6;
          }
          const side = getSide(i + 8);
          const card: Uefa24Card = {
            id: i + 8,
            playerId: gameResults[i],
            status: !gameResults[Math.floor(i)]
              ? uefa24Status.WAITING
              : !gameResults[winner]
              ? uefa24Status.ENABLE
              : gameResults[i] === players[gameResults[winner]].id
              ? uefa24Status.WINNER
              : uefa24Status.LOSER,
            pairStatus: uefa24PairStatus.PICKED,
            gameGrade,
            side,
            name: players[gameResults[i]] ? players[gameResults[i]].name : "",
            url: players[gameResults[i]] ? players[gameResults[i]].avatar : "",
            latestStatus: false,
            gameResult: gameResults[i],
            isShowView: false,
          };
          newCardInfos.push(card);
        }

        return newCardInfos;
      });
  }, [gameResults, players]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, pageLoadingTime);
    return () => clearTimeout(timeoutId);
  });

  return loading ? (
    <PageLoadingMov />
  ) : (
    <Box className={classes.body}>
      <Box className={classes.header}>
        <Box></Box>
        <Box className={classes.logoPart}>
          <Box className={classes.ticketSold}>
            <Box>Tickets sold</Box>
            <Box>
              <Box component="img" src="/images/icons/ticket.png" />
              <Box>{totalTickets ? totalTickets.toFixed(0) : 0}</Box>
            </Box>
          </Box>
          <Box
            component="img"
            src="/images/events/uefa/uefa-champions-league-seeklogo.png"
            className={classes.logo}
          />
          <Box className={classes.pooledFunds}>
            <Box>Pooled funds</Box>
            <Box>
              <Box component="img" src="/images/tokens/edai.png" />
              <Box>${totalRoundUSD ? totalRoundUSD.toFixed(0) : 0}</Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.information}>
          <Uefa24InformationDialog />
        </Box>
      </Box>
      <Box className={classes.bodyContainer}>
        <Box className={classes.contentBody}>
          <Box
            sx={
              presentMatchGrade === uefa24Play.QUARTER
                ? { top: "-40px", left: "70px", display: "flex" }
                : presentMatchGrade === uefa24Play.SEMI
                ? { top: "20px", left: "240px", display: "flex" }
                : presentMatchGrade === uefa24Play.FINAL
                ? { top: "160px", left: "420px", display: "flex" }
                : { display: "none" }
            }
            className={classes.animationArrow}
          >
            <KeyboardDoubleArrowDownIcon />
            <Box>
              {presentMatchGrade === uefa24Play.QUARTER
                ? "Quarter"
                : presentMatchGrade === uefa24Play.SEMI
                ? "Semi Final"
                : presentMatchGrade === uefa24Play.FINAL
                ? "Final"
                : ""}
            </Box>
          </Box>
          <Box className={classes.semiLeftPart}>
            <UefaTeamCard
              cardInfo={cardInfos[0]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[1]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[2]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[3]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
          </Box>
          <Box className={classes.finalLeftPart}>
            <UefaTeamCard
              cardInfo={cardInfos[8]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[9]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
          </Box>
          <Box className={classes.winnerPart}>
            <Box className={classes.remainTime}>
              <Dot
                sx={{
                  width: "9px",
                  height: "9px",
                  backgroundColor: "#37d344",
                  boxShadow:
                    "0 0 8px #1c6d23, 0 0 16px #1c6d23, 0 0 24px #1c6d23, 0 0 56px #1c6d23",
                }}
              />
              <Box>
                {roundStatus === uefa24RoundStatus.Started
                  ? "Bet Opened"
                  : roundStatus > uefa24RoundStatus.Started
                  ? "Bet Closed"
                  : "Bet Waiting"}
              </Box>
            </Box>
            <Box className={classes.timeLeft}>{remainingTime}</Box>
            <Box
              component="img"
              src="/images/icons/trophy.png"
              className={classes.trophy}
            />
            <Box className={classes.championText}>Champion!</Box>
            <UefaTeamCard
              cardInfo={cardInfos[14]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[12]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[13]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
          </Box>
          <Box className={classes.finalRightPart}>
            <UefaTeamCard
              cardInfo={cardInfos[10]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[11]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
          </Box>
          <Box className={classes.semiRightPart}>
            <UefaTeamCard
              cardInfo={cardInfos[4]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[5]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[6]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
            <UefaTeamCard
              cardInfo={cardInfos[7]}
              pickClick={pickClick}
              closeClick={closeClick}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonsBody}>
        {confirmingTransaction ? (
          <TicketEnteredPopup
            ticketId={presentTicket.current}
            onNewCreate={onNewCreate}
            setConfirmingTransactionFalse={setConfirmingTransactionFalse}
            setIsShowView={setIsShowView}
            setTicketView={setTicketView}
            entryInfo={entries[entries.length - 1]}
          />
        ) : null}
        {!isShowView && roundStatus === uefa24RoundStatus.Started ? (
          <>
            <Uefa24TicketPurchaseBtn
              text="Purchase ticket"
              usdAmount={totalUsdAmount}
              predictions={predictions}
              totalMatches={7}
            />
            <TicketAmountSelect
              setTotalUsdAmount={(value) => setTotalUsdAmount(value)}
              ticketPrice={ticketPrice}
            />
            <Box className={classes.viewTicketButtonBody}>
              <Box className={classes.totalUsd}>
                ${totalUsdAmount.toFixed(0)}
              </Box>
              <Box className={classes.description}>Total</Box>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.viewTicketButtonBody}>
              <Box className={classes.viewTicketButton}>
                <Box component="img" src="/images/icons/eye_green.png" />
                Ticket #{viewTicket}
              </Box>
              <Box className={classes.description}>
                <Box component="span" sx={{ color: "white" }}>
                  Created by{" "}
                </Box>
                <Box className={classes.descriptionProfile}>
                  {viewTicketProfile && viewTicketProfile.avatar.length > 0 ? (
                    <img src={viewTicketProfile.avatar} alt="" srcSet="" />
                  ) : (
                    <Blocky
                      address={viewTicketAddress.toLowerCase()}
                      size={16}
                      scale={1}
                    />
                  )}

                  {viewTicketProfile ? (
                    viewTicketProfile.name.length > 15 ? (
                      <span>{shortName(viewTicketProfile.name)}</span>
                    ) : (
                      <span>{viewTicketProfile.name}</span>
                    )
                  ) : (
                    <span>{shortAddress(viewTicketAddress)}</span>
                  )}
                </Box>{" "}
              </Box>
            </Box>
            {roundStatus === uefa24RoundStatus.Started ? (
              <Box className={classes.viewTicketButtonBody}>
                <Button
                  className={classes.viewTicketButton}
                  onClick={onNewCreate}
                >
                  <Box component="img" src="/images/icons/ticket-green.png" />
                  Create new ticket
                </Button>
                <Box className={classes.description}></Box>
              </Box>
            ) : null}
          </>
        )}
      </Box>
      <Box className={classes.statBody}>
        <Uefa24TicketTable
          viewHistory={(dat: object) => handleViewHistory(dat)}
        />
        <Uefa24TourLeaderBoard
          viewHistory={(dat: object) => handleViewHistory(dat)}
        />
      </Box>
    </Box>
  );
}
