import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    height: "50px",
    cursor: "pointer",
    paddingLeft: "10px",
    paddingRight: "10px",
    justifyContent: "space-between",
    width: "200px",
    borderRadius: "4px",
    position: "relative",
    userSelect: "none",
    backgroundColor: theme.palette.background.eventActive,
  },
  champion: {
    "&::before,&::after": {
      borderColor: "rgb(25, 97, 24) !important",
    },
  },
  nonActiveCard: {
    backgroundColor: theme.palette.background.eventDisable,
    color: "rgb(61, 61, 77)",
  },
  rightSide: {
    "&>div:nth-of-type(1)": {
      order: 1,
      justifyContent: "center",
    },
    "&>div:nth-of-type(3)": {
      order: -1,
      justifyContent: "center",
    },
  },
  leftPart: {
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      width: "22px",
    },
  },
  pickBut: {
    borderRadius: "3px",
    padding: "2px 3px",
    fontSize: "9px",
    backgroundColor: theme.palette.background.pickButton,
  },
  middlePart: {
    width: "120px",
    paddingLeft: "5px",
  },
  namePart: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "15px",
    "& img": {
      width: "25px",
    },
  },
  rightPart: {
    width: "30px",
    display: "flex",
    justifyContent: "center",
    color: "rgb(61, 61, 77)",
    fontSize: "13px",
    position: "relative",
  },
  closeBut: {
    position: "absolute",
    bottom: "0",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.closeButton,
    zIndex: "101",
    right: "0",
    color: "white",
    transform: "translate(50%, 50%)",
    "&.right": {
      left: "0",
      transform: "translate(-50%, 50%)",
    },
  },
  emptyName: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "rgb(68, 78, 104)",
  },
  emptyNameWinner: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "rgb(114, 96, 51)",
  },
  correct: {
    border: "3px solid rgb(38, 133, 68)",
  },
  upCount: {
    fontSize: "17px",
    color: "rgb(38, 133, 68)",
  },
  football: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    zIndex: '100',
    opacity: 0,
    '&.football0':{
      bottom: '-20px',
      right: '-15px',
    },
    '&.football1':{
      top: '-20px',
      right: '-15px',
    },
    '&.football2':{
      bottom: '-20px',
      right: '-15px',
    },
    '&.football3':{
      top: '-20px',
      right: '-15px',
    },
    '&.football4':{
      bottom: '-20px',
      left: '-15px',
    },
    '&.football5':{
      top: '-20px',
      left: '-15px',
    },
    '&.football6':{
      bottom: '-20px',
      left: '-15px',
    },
    '&.football7':{
      top: '-20px',
      left: '-15px',
    },
    '&.football8':{
      bottom: '-20px',
      right: '-15px',
    },
    '&.football9':{
      top: '-20px',
      right: '-15px',
    },
    '&.football10':{
      bottom: '-20px',
      left: '-15px',
    },
    '&.football11':{
      top: '-20px',
      left: '-15px',
    },
    '&.football12':{
      top: '-15px',
      left: '85px',
    },
    '&.football13':{
      top: '-75px',
      left: '85px',
    },
  }
}));

export default styles;
