import { useState, useEffect } from "react";
import { Box, Button, Skeleton } from "@mui/material";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import { useConnectWallet } from "@web3-onboard/react";

import PageLoadingMov from "../../components/PageLoadingMov";
import CircularLogo from "../../components/CircularLogo";

import useStyles from "./index.styles";
import { pageLoadingTime } from "../../constants";
import { EarnDataStatus } from "../../constants/earn";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getEarnHistory } from "../../reducers/airdrop.slice";

export default function Earn() {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { loadingEarnHistory, earnHistory } = useAppSelector(
    (state) => state.airdrop
  );
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const sortedEarnDataStatus = Object.entries(EarnDataStatus).sort(
    ([keyA, valueA], [keyB, valueB]) => valueA.value - valueB.value
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, pageLoadingTime);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (account) {
      dispatch(getEarnHistory(account));
    }
  }, [account, dispatch]);

  const skeletonDetail = () => {
    return (
      <Box className={classes.skeletonDetail}>
        <Skeleton variant="circular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="circular" />
      </Box>
    );
  };

  return loading ? (
    <PageLoadingMov />
  ) : (
    <Box className={classes.body}>
      <Box className={classes.bodyContainer}>
        <Box className={classes.header}>
          <CircularLogo
            width={70}
            sx={{ backgroundColor: "black" }}
          ></CircularLogo>
          How to earn $PREDICT
        </Box>
        <Box className={classes.mainBody}>
          <Box className={classes.pendingBody}>
            <Box className={classes.pendingHeader}>
              <Box component={"img"} src="/images/icons/time-left.png" />
              <Box>Pending tasks</Box>
            </Box>
            <Box
              className={
                loadingEarnHistory
                  ? classes.skeletonBody
                  : classes.pendingDetailsBody
              }
            >
              {loadingEarnHistory ? (
                <>
                  {skeletonDetail()}
                  {skeletonDetail()}
                  {skeletonDetail()}
                  {skeletonDetail()}
                </>
              ) : (
                sortedEarnDataStatus.map(([key, value]) => {
                  const status = earnHistory.findIndex(
                    (it) => it.goalId === value.id
                  );
                  if (status === -1)
                    return (
                      <Box key={value.id}>
                        <Box className={classes.detail}>
                          <Box className={classes.icon}>
                            <HourglassEmptyIcon
                              className={classes.hourGlassIcon}
                            />
                          </Box>
                          <Box className={classes.text}>{value.text}</Box>
                          <Box className={classes.value}>
                            <Box>{value.value}</Box>
                            <CircularLogo
                              sx={{ backgroundColor: "black" }}
                              width={30}
                            ></CircularLogo>
                          </Box>
                        </Box>
                      </Box>
                    );
                })
              )}
            </Box>
          </Box>
          <Box className={classes.completeBody}>
            <Box className={classes.completeHeader}>
              <Box component={"img"} src="/images/icons/like.png" />
              <Box>Completed tasks</Box>
            </Box>
            <Box
              className={
                loadingEarnHistory
                  ? classes.skeletonBody
                  : classes.completeDetailsBody
              }
            >
              {loadingEarnHistory ? (
                <>
                  {skeletonDetail()}
                  {skeletonDetail()}
                  {skeletonDetail()}
                  {skeletonDetail()}
                </>
              ) : (
                sortedEarnDataStatus.map(([key, value]) => {
                  const status = earnHistory.findIndex(
                    (it) => it.goalId === value.id
                  );
                  if (status !== -1)
                    return (
                      <Box key={value.id}>
                        <Box className={classes.detail}>
                          <Box className={classes.icon}>
                            <CheckIcon className={classes.checkIcon} />
                          </Box>
                          <Box className={classes.text}>{value.text}</Box>
                          <Box className={classes.value}>
                            <Box>{value.value}</Box>
                            <CircularLogo
                              sx={{ backgroundColor: "black" }}
                              width={30}
                            ></CircularLogo>
                          </Box>
                        </Box>
                      </Box>
                    );
                })
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
