import { Box } from "@mui/material";

import ReactCountryFlag from "react-country-flag";
import { useAppSelector } from "../../app/hooks";
import { euro24MatchInfo } from "../../constants/euro24";
import { EuroEntryInfo } from "../../types/EuroEntryInfo";
import {
  calculateMatchOdd,
  getCountryAbbreviationByName,
} from "../../utils/euro";
import useStyles from "./index.styles";
import { toUSDFormat } from "../../utils";

export default function EuroMyBetPanel() {
  const { classes } = useStyles();
  const { myEntries, roundInfos } = useAppSelector((state) => state.euro24);

  return (
    <Box className={classes.euroMyBetPanel}>
      <Box className={classes.header}>Your bets</Box>

      <Box className={classes.betList}>
        {myEntries.map((entry: EuroEntryInfo, index: number) => {
          const match = euro24MatchInfo[entry.roundId - 1];

          const odd = calculateMatchOdd(
            roundInfos[entry.roundId - 1],
            entry.expectation
          );

          return (
            <Box className={classes.betListItem} key={index}>
              {entry.expectation === 2 ? (
                <Box>Draw</Box>
              ) : (
                <ReactCountryFlag
                  className={classes.matchCountryFlag}
                  countryCode={getCountryAbbreviationByName(
                    entry.expectation === 0 ? match.country1 : match.country2
                  )}
                  svg
                  aria-label={
                    entry.expectation === 0 ? match.country1 : match.country2
                  }
                />
              )}

              <Box>
                {entry.expectation === 0 ? match.country1 : match.country2}
              </Box>

              <Box>{odd.toFixed(2)}</Box>
              {entry.Euro24Round?.result === -1 ? (
                <Box className={classes.resultUnknown}>
                  ${toUSDFormat(entry.usdAmount * odd)}
                </Box>
              ) : entry.Euro24Round?.result === entry.expectation ? (
                <Box className={classes.resultWin}>
                  ${toUSDFormat(entry.usdAmount * odd)}
                </Box>
              ) : (
                <Box className={classes.resultLose}>-${entry.usdAmount}</Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
