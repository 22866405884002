import { makeStyles } from 'tss-react/mui';
import theme from '../../theme';

const styles = makeStyles()((theme: any) => ({
  button: {
    padding: '12px',
    width: '106px',
    backgroundColor: theme.palette.background.card,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    height: '60px',
    fontSize: '18px',
    borderRadius: '10px',
    textTransform: 'none',
    svg: {
      color: theme.palette.text.disabled,
      position: 'absolute',
      right: '10px',
    },
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.04)',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '78px'
    }
  },
  description: {
    color: theme.palette.text.disabled,
    fontSize: '13px',
  },
  popBody: {
    '& .MuiPaper-root': {
      backgroundColor: '#1c1f28',
      borderRadius: '10px',
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
  },
  amountBody: {
    padding: '20px 30px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '16px',
    borderBottom: '1px solid #10131988',
    '&>div:nth-of-type(2)': {
      width: '80px',
    }
  },
  amountInput: {
    borderRadius: '5px',
    backgroundColor: '#191b22',
    width: '120px',
    input: {
      color: '#526091',
      padding: '5px 10px',
    },
    fieldset: {
      border: 'none',
    }
  },
  ticketList: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  ticketRow: {
    transition: '0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#191b22',
      transition: '0.3s',
    },
    '&.focus': {
      backgroundColor: '#191b22',
    }
  },
  ticketRowContent: {
    display: 'flex',
    padding: '5px 30px',
    gap: '10px',
    fontSize: '16px',
    '&>div:nth-of-type(1)': {
      width: '120px',
      color: '#526091',
    },
    '&>div:nth-of-type(2)': {
      width: '80px',
    },
    '&>div:nth-of-type(3)': {
      width: '20px',
      svg: {
        fontSize: '15px',
        color: '#526091',
      }
    },
  },
  confirmButtonBody: {
    display: 'flex',
    justifyContent: 'center',
  },
  confirmButton: {
    color: 'white',
    fontSize: '17px',
    textTransform: 'none',
    backgroundColor: '#101319',
    padding: '5px 50px',
    borderRadius: '10px',
  },
}));

export default styles;