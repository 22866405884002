import { Box, Skeleton } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import clsx from "clsx";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";

import Dot from "../Dot";

import { useAppSelector } from "../../app/hooks";
import { gameConfig } from "../../config";
import { EuroMatchInfo } from "../../types/EuroMatchInfo";
import { EuroRoundInfo } from "../../types/EuroRoundInfo";
import { convertDateTimeSimple, toUSDFormat } from "../../utils";
import {
  calculateMatchOdd,
  getCountryAbbreviationByName,
} from "../../utils/euro";

import useStyles from "./index.styles";

export default function EuroMatchListItem({
  match,
  roundInfo,
  onOpenPopup,
  setMatchInfo,
  setMatchResult,
  roundDetailId,
  setRoundDetailId,
  enableDraw,
  isAdmin,
  onOpenRoundFinishPopup,
}: {
  match: EuroMatchInfo;
  roundInfo?: EuroRoundInfo;
  onOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setMatchInfo: (matchInfo: EuroMatchInfo) => void;
  setMatchResult: (matchResult: number) => void;

  roundDetailId: number;
  setRoundDetailId: React.Dispatch<React.SetStateAction<number>>;
  enableDraw: boolean;
  isAdmin: boolean;
  onOpenRoundFinishPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { classes } = useStyles();
  const [{ wallet }, connect] = useConnectWallet();

  const { isLoadingRoundInfo } = useAppSelector((state) => state.euro24);

  const handleOnClickBet = (result: number) => {
    if (!wallet) {
      connect();
    } else {
      setMatchInfo(match);
      setMatchResult(result);
      onOpenPopup(true);
    }
  };

  const handleViewDetails = () => {
    if (roundDetailId === match.matchId) setRoundDetailId(-1);
    else setRoundDetailId(match.matchId);
  };

  const renderOdd = (result: number) => {
    if (!roundInfo) return;

    const odd = calculateMatchOdd(roundInfo, result);
    if (odd > 0 && odd !== Infinity) return <>{odd.toFixed(2)}</>;
    else if (odd === Infinity) return <>∞</>;
    else return <>0</>;
  };

  const finishRoundPopup = () => {
    if (!wallet) {
      connect();
    } else {
      setMatchInfo(match);
      onOpenRoundFinishPopup(true);
    }
  };

  return (
    <Box className={classes.euroMatchListItem}>
      <Box
        className={clsx(
          classes.matchInfoBlockGroup,
          classes.matchInfoBlockGroupB
        )}
      >
        {match.closeTime - new Date().getTime() / 1000 > 0 ? (
          <Box className={classes.matchInfoBlock}>
            <Box component="img" src="/images/events/euro/timer.png"></Box>
            <Box>
              <Box className={classes.timeLabel}>Closing in</Box>
              <Box className={classes.time}>
                {convertDateTimeSimple(
                  match.closeTime - new Date().getTime() / 1000
                )}
              </Box>
            </Box>
          </Box>
        ) : roundInfo?.roundData.result === -1 ? (
          <Box className={classes.matchInfoBlock}>
            {isAdmin &&
            new Date().getTime() / 1000 - match.closeTime >= 5400 ? (
              <>
                <Box className={classes.progressBox}>
                  <Dot
                    sx={{
                      backgroundColor: "#179449",
                      width: "10px",
                      height: "10px",
                      boxShadow:
                        "0 0 8px #21c060, 0 0 16px #21c060, 0 0 24px #21c060, 0 0 56px #21c060;",
                    }}
                  />
                </Box>
                <Box onClick={finishRoundPopup} width={72}>
                  <Box className={classes.progressLabel}>Finish</Box>
                </Box>
              </>
            ) : (
              <>
                <Box className={classes.progressBox}>
                  <Dot
                    sx={{
                      backgroundColor: "#ff1b1b",
                      width: "10px",
                      height: "10px",
                      boxShadow:
                        "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24;",
                    }}
                  />
                </Box>
                <Box>
                  <Box className={classes.progressLabel}>In progress</Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Link
            target="_blank"
            to={`${gameConfig.explorerUrl}tx/${roundInfo?.roundData.txHash}`}
          >
            <Box className={classes.matchInfoBlock}>
              <Box component="img" src="/images/plsscan.png"></Box>
              <Box>
                <Box className={classes.completedLabel}>Completed</Box>
                <Box className={classes.completedTx}>View TX</Box>
              </Box>
            </Box>
          </Link>
        )}
      </Box>

      <Box
        className={clsx(classes.matchInfoBlockGroup, classes.disableDrawLine)}
      >
        <Box
          className={clsx(
            classes.matchInfoBlock,
            classes.countryBlock,
            roundInfo?.roundData.result === 0 ? classes.mathInfoBlockWinner : ""
          )}
          onClick={() => {
            handleOnClickBet(0);
          }}
        >
          {isLoadingRoundInfo ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={50}
              height={36}
            />
          ) : (
            <Box className={classes.matchCountryOdd}>{renderOdd(0)}</Box>
          )}

          <Box>
            <Box className={classes.matchCountryName}>{match.country1}</Box>
            {(roundInfo?.roundData.result === -1 ||
              roundInfo?.roundData.result === 0) && (
              <Box className={classes.matchCountryWinner}>Winner</Box>
            )}
          </Box>

          <ReactCountryFlag
            className={classes.matchCountryFlag}
            countryCode={getCountryAbbreviationByName(match.country1)}
            svg
            aria-label={match.country1}
          />
        </Box>

        {enableDraw ? (
          <Box
            className={clsx(
              classes.matchInfoBlock,
              classes.centered,
              roundInfo?.roundData.result === 2
                ? classes.mathInfoBlockWinner
                : ""
            )}
            onClick={() => {
              handleOnClickBet(2);
            }}
          >
            <Box>
              <Box className={classes.drawOddLabel}>Draw</Box>
              {isLoadingRoundInfo ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={72}
                  height={36}
                />
              ) : (
                <Box className={classes.drawOdd}>{renderOdd(2)}</Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box className={classes.disableDraw}></Box>
        )}

        <Box
          className={clsx(
            classes.matchInfoBlock,
            classes.countryBlock,
            classes.reversed,
            roundInfo?.roundData.result === 1 ? classes.mathInfoBlockWinner : ""
          )}
          onClick={() => {
            handleOnClickBet(1);
          }}
        >
          {isLoadingRoundInfo ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={50}
              height={36}
            />
          ) : (
            <Box className={classes.matchCountryOdd}>{renderOdd(1)}</Box>
          )}
          <Box>
            <Box className={classes.matchCountryName}>{match.country2}</Box>
            {(roundInfo?.roundData.result === -1 ||
              roundInfo?.roundData.result === 1) && (
              <Box className={classes.matchCountryWinner}>Winner</Box>
            )}
          </Box>

          <ReactCountryFlag
            className={classes.matchCountryFlag}
            countryCode={getCountryAbbreviationByName(match.country2)}
            svg
            aria-label={match.country2}
          />
        </Box>
      </Box>

      <Box
        className={clsx(
          classes.matchInfoBlockGroup,
          classes.matchInfoBlockGroupC
        )}
      >
        <Box
          className={clsx(
            classes.matchInfoBlock,
            classes.reversed,
            classes.wideGap
          )}
          onClick={handleViewDetails}
        >
          <Box component="img" src="/images/tokens/edai.png"></Box>
          <Box>
            <Box className={classes.viewBets}>View Bets</Box>
            {isLoadingRoundInfo ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                width={72}
                height={24}
              />
            ) : (
              <Box className={classes.betTotal}>
                {toUSDFormat(roundInfo?.totalUsd)}$
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
