import { Box, Button } from "@mui/material";

import PredictionCard from "../PredictionCard";

import useStyles from "./index.styles";
import { useAppSelector } from "../../app/hooks";
import { Toast } from "../../utils";

interface PredictDetailLeftSectionProps {
  readonly roundInfo: any;
  readonly comment: string;
  readonly setComment: (comment: string) => void;
  readonly curTime: number;
}

export default function PredictDetailLeftSection(
  props: PredictDetailLeftSectionProps
) {
  const { classes } = useStyles();
  const { loadingMetaInfo, loadingRoundInfo, tokens } = useAppSelector(
    (state) => state.predict
  );

  const onClipboardDexLink = async () => {
    await navigator.clipboard.writeText(
      tokens.length > 0 && tokens[props.roundInfo.priceTokenId]
        ? `https://dexscreener.com/pulsechain/${
            tokens[props.roundInfo.priceTokenId].pair
          }?embed=1&theme=dark&trades=0&info=0`
        : ""
    );
    Toast.fire({
      icon: "success",
      title: "Successfully copied",
    });
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.predictionTitle}>
        <Box component="img" src="/images/icons/notes.png" />
        <Box>Prediction</Box>
      </Box>
      <Box
        className={classes.predictionCard}
        sx={{
          minHeight: props.roundInfo.comment ? 320 : 500,
        }}
      >
        <PredictionCard
          isLoading={loadingMetaInfo || loadingRoundInfo}
          roundInfo={props.roundInfo}
          curTime={props.curTime}
        />
      </Box>
      <Button className={classes.copyLink} onClick={onClipboardDexLink}>
        <Box
          component="img"
          src="/images/icons/link.png"
          sx={{
            width: "22px",
          }}
        ></Box>
        Copy link
      </Button>
      {props.roundInfo.comment && (
        <>
          <Box
            className={classes.predictionTitle}
            sx={{
              marginTop: "32px",
            }}
          >
            <Box component="img" src="/images/icons/notes.png" />
            <Box>Comment</Box>
          </Box>
          <Box className={classes.comment}>{props.roundInfo.comment}</Box>
        </>
      )}
    </Box>
  );
}
