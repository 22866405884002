import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Pagination, Skeleton } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CardDetail from "../CardDetail";

import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { predictionStatus } from "../../constants/prediction";
import { getPriceTokenLp, getPriceTokens } from "../../reducers/predict.slice";
import theme from "../../theme";
import Dot from "../Dot";
import useStyles from "./index.styles";

interface PredictionBodyProps {
  activePage: number;
  changeActivePage: (activePage: number) => void;
  changeLiveActivePage: (activePage: number) => void;
  status: number;
  liveActivePage: number;
  getRounds: () => void;
}

export default function PredictionBody(props: PredictionBodyProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    rounds,
    totalRoundsCount,
    tokens,
    countPerPage,
    liveRounds,
    loadingLiveRounds,
    liveTotalRoundsCount,
    hasMoreRounds,
  } = useAppSelector((state) => state.predict);

  const colorAnimation = {
    "0%": {
      boxShadow:
        "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24;",
    },
    "50%": {
      boxShadow:
        "0 0 8px yellow, 0 0 16px yellow, 0 0 24px yellow, 0 0 56px yellow;",
    },
    "100%": {
      boxShadow:
        "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24;",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getPriceTokens());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (tokens.length === 0) return;
    const fetch = async () => {
      await dispatch(getPriceTokenLp(tokens.map((token, index) => token.pair)));
    };
    fetch();
  }, [tokens, dispatch]);

  const handleMoreRounds = () => {
    props.getRounds();
  };

  const status = predictionStatus.find(
    (status) => status.value === props.status
  )?.text;

  return (
    <Box className={classes.body}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <Box component="img" src="/images/icons/enter.png" />
          <Box component="span">Predictions by other users</Box>
        </Box>
        <Box className={classes.detailsText}>
          <Box className={classes.resultsPart}>
            <Box component="span">Showing {totalRoundsCount} results</Box>
            <Box component="img" src="/images/icons/sort.png"></Box>
          </Box>
          <Box className={classes.butPart}>
            <Button
              className={classes.createBut}
              onClick={() => {
                navigate(`/predictions/create`);
              }}
            >
              <AddIcon />
              Create bet
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        component={"span"}
        sx={{
          fontSize: "20px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Dot
          sx={{
            backgroundColor: "#ff1b1b",
            animation: "colorAnimation 2s infinite",
            "@keyframes colorAnimation": colorAnimation,
            width: "10px",
            height: "10px",
          }}
        ></Dot>{" "}
        Live
      </Box>
      <Box className={classes.cardsLiveBody}>
        {loadingLiveRounds ? (
          <>
            <Skeleton variant="rectangular" className={classes.skeleton} />
            <Skeleton variant="rectangular" className={classes.skeleton} />
            <Skeleton variant="rectangular" className={classes.skeleton} />
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </>
        ) : liveRounds.length === 0 ? (
          <Box className={classes.noRounds}>No Rounds</Box>
        ) : (
          liveRounds.map((round, index) => {
            return (
              <CardDetail
                key={index}
                round={round}
                sx={{
                  width: "calc((100% - 90px) / 4)",
                  [theme.breakpoints.down("lg")]: {
                    width: "calc((100% - 30px) / 2)",
                  },
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                }}
              />
            );
          })
        )}
      </Box>
      <Box className={classes.paginationBody}>
        {liveRounds.length === 0 ? null : (
          <Pagination
            count={Math.ceil(liveTotalRoundsCount / countPerPage)}
            defaultValue={1}
            page={props.liveActivePage}
            variant="outlined"
            color="primary"
            onChange={(e, value) => props.changeLiveActivePage(value)}
          />
        )}
      </Box>
      {status}
      <Box className={classes.cardsBody} id={"scrollableDiv"}>
        {rounds.length === 0 ? (
          <Box className={classes.noRounds}>No Rounds</Box>
        ) : (
          <InfiniteScroll
            dataLength={rounds.length}
            hasMore={rounds.length >= totalRoundsCount ? false : hasMoreRounds}
            next={handleMoreRounds}
            scrollableTarget="scrollableDiv"
            loader={<Box className={classes.loading}>loading</Box>}
          >
            <Box className={classes.cardsSection}>
              {rounds.map((round, index) => {
                return (
                  <CardDetail
                    key={index}
                    round={round}
                    sx={{
                      width: "calc((100% - 90px) / 4)",
                      [theme.breakpoints.down("lg")]: {
                        width: "calc((100% - 30px) / 2)",
                      },
                      [theme.breakpoints.down("md")]: {
                        width: "100%",
                      },
                    }}
                  />
                );
              })}
            </Box>
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
}
