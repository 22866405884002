import { Box } from "@mui/material";

import { useState } from "react";
import { useConnectWallet } from "@web3-onboard/react";
import { useEffect } from "react";
import EuroMatchList from "../../components/EuroMatchList";

import { useAppDispatch } from "../../app/hooks";

import { gameConfig } from "../../config";
import { EURO_EVENTS } from "../../constants/euro24";
import {
  getBetTokensList,
  getLeaderBoard,
  getMyBets,
  getRoundInfo,
  getRoundInfoById,
  getTokenBalances,
} from "../../reducers/euro24.slice";
import {
  formatEuroEnteredRoundInfo,
  formatEuroRoundCancelledInfo,
  formatEuroRoundFinishedInfo,
} from "../../utils/euro";
import useStyles from "./index.styles";
import EuroMyBetPanel from "../../components/EuroMyBetPanel";
import EuroLeaderBoardPanel from "../../components/EuroLeaderBoardPanel";

export default function EURO24() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isAdmin, setIsAdmin] = useState(false);

  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getBetTokensList());
        await dispatch(getRoundInfo());
        await dispatch(getLeaderBoard());
      } catch (err) {
        console.log("Failed to fetch data:", err);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!account) return;

    dispatch(getTokenBalances(account));
    dispatch(getMyBets({ account }));
  }, [account, dispatch]);

  useEffect(() => {
    const setupEventSource = () => {
      const eventSource = new EventSource(
        `${gameConfig.serverUrl}event/euro24`
      );
      eventSource.onmessage = async (e) => {
        console.log("Euro 2024 SSE connected.");

        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);

          if (res.eventName === EURO_EVENTS.EnteredRound) {
            const enteredRoundInfo = formatEuroEnteredRoundInfo(res);
            dispatch(getRoundInfoById({ roundId: enteredRoundInfo.roundId }));
          } else if (res.eventName === EURO_EVENTS.RoundFinished) {
            const finishedRoundInfo = formatEuroRoundFinishedInfo(res);
            dispatch(getRoundInfoById({ roundId: finishedRoundInfo.roundId }));
          } else if (res.eventName === EURO_EVENTS.RoundCancelled) {
            const cancelledRoundInfo = formatEuroRoundCancelledInfo(res);
            dispatch(getRoundInfoById({ roundId: cancelledRoundInfo.roundId }));
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();

    return () => {
      eventSource.close();
    };
  }, [dispatch]);

  useEffect(() => {
    setIsAdmin(
      gameConfig.admins.map((admin) => admin.toLowerCase()).includes(account)
    );
  }, [account]);

  return (
    <Box className={classes.body}>
      <Box
        component="img"
        className={classes.logo}
        src={"/images/events/euro/logo.png"}
      ></Box>

      <Box className={classes.container}>
        <EuroMyBetPanel />
        <EuroMatchList isAdmin={isAdmin} />
        <EuroLeaderBoardPanel />
      </Box>
    </Box>
  );
}
