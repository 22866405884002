import React from "react";
import {Box} from '@mui/material';
import useStyles from './index.styles';

interface TokenImageProps {
  token: string;
  classNames?: string;
  sx?: object;
}

export default function TokenImage(props: TokenImageProps) {
  const { classes } = useStyles();
  return (
    <Box
      sx={{
        ...props.sx
      }}
      component='img'
      src={`/images/tokens/${props.token?.toLowerCase()}.png`}
      alt={props.token}
      className={props.classNames === 'middle' ? classes.middle : ''}
    />
  );
}
