import React from "react";
import { motion } from "framer-motion";

const variants = {
  initial: {
    opacity: 0,
    y: 50,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const variants_small = {
  initial: {
    opacity: 0,
    y: 15,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const AnimateDiv = ({ children, style, className, small = false }: any) => {
  return React.createElement(
    motion.div,
    {
      className: className,
      style: style,
      initial: "initial",
      animate: "animate",
      variants: small ? variants_small : variants,
    },
    children
  );
};

export default AnimateDiv;
