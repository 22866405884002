import { ethers } from "ethers";
import Uefa24Abi from "../../abis/uefa24.json";
import { gameConfig } from "../../config";
import {
  approveToken,
  getBetTokens,
  getProvider,
  getTokenAllowance,
  getWeb3,
} from "./index";

export const getUefa24Contract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const Uefa24Contract = new web3.eth.Contract(
    Uefa24Abi,
    gameConfig.uefa24Address
  );
  return Uefa24Contract;
};

export const getUefa24BetTokens = async () => {
  const Uefa24Contract = getUefa24Contract();

  return await getBetTokens(Uefa24Contract);
};

export const getUefa24MatchesInfo = async () => {
  const Uefa24Contract = getUefa24Contract();
  const result: any = await Uefa24Contract.methods.getMatchesInfo().call();
  const newRes: any = {
    roundStatus: result["_roundStatus"] ? Number(result["_roundStatus"]) : 0,
    totalTickets: result["_totalTickets"] ? Number(result["_totalTickets"]) : 0,
    ticketPrice: result["_ticketPrice"]
      ? Number(ethers.utils.formatEther(result["_ticketPrice"]))
      : 0,
    closeBetAt: result["_closeBetAt"] ? Number(result["_closeBetAt"]) : 0,
    totalRoundUSD: result["_totalRoundUSD"]
      ? Number(ethers.utils.formatEther(result["_totalRoundUSD"]))
      : 0,
    results: result["results"]
      ? result["results"].map((res: any) => Number(res))
      : [],
  };
  return newRes;
};

export const enterUefa24Round = async (
  tokenId: number,
  tokenAddress: string,
  tokenAmount: number,
  ticketCount: number,
  predictions: number[],
  account: string
) => {
  const Uefa24Contract = getUefa24Contract(true);
  if (tokenAddress === ethers.constants.AddressZero) {
    await Uefa24Contract.methods
      .enterRound(tokenId, ticketCount, predictions)
      .send({
        value: ethers.utils.parseEther(tokenAmount.toFixed(18)).toString(),
        from: account,
      });
  } else {
    const tokenAllowance = await getTokenAllowance(
      tokenAddress,
      account,
      gameConfig.uefa24Address
    );
    if (Number(tokenAllowance) < tokenAmount) {
      await approveToken(
        tokenAddress,
        tokenAmount,
        gameConfig.uefa24Address,
        account
      );
    }
    await Uefa24Contract.methods
      .enterRound(tokenId, ticketCount, predictions)
      .send({ from: account });
  }
};
