import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme: any) => ({
  mainBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    alignItems: 'center',
  },
  body: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.card,
    padding: '10px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    gap: '20px',
  },
  logo: {
    width: '100%',
    borderRadius: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontSize: '13px',
    '&>div:nth-of-type(1)': {
      color: theme.palette.text.disabled,
    },
    '&>div:nth-of-type(2)': {
      '&.Upcoming': {
        color: theme.palette.text.primary,
      },
      '&.Completed': {
        color: theme.palette.text.increase,
      },
    },
  },
}));

export default styles;