import { Box } from "@mui/material";

interface DotProps {
  sx?: object;
}

export default function Dot(props: DotProps) {
  const styles = {
    ...(props.sx ?? {}),
    borderRadius: "50%",
  };

  return <Box sx={styles}></Box>;
}
