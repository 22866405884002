import { Box } from '@mui/material';

import useStyles from "./index.styles";
import { useAppSelector } from '../../app/hooks';
import { useConnectWallet } from '@web3-onboard/react';
import { count } from 'console';
import { toUSDFormat } from '../../utils'

interface TicketTableProps {
  viewHistory?: (dat: object) => void;
}

export default function TicketTable(props: TicketTableProps) {
  
  const [{wallet}] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : '';

  const { classes } = useStyles();
  const { entries, gameResults } = useAppSelector((state) => state.marchMadness);


  const data = entries.filter((entry: any) => entry.player.toLowerCase() === account.toLowerCase());
  return (
    <Box className={classes.body}>
      <Box className={classes.title}>
        <Box component='img' src='/images/icons/ticket.png' />
        <Box>Your tickets</Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.contentHorizontal}>
          <Box className={classes.mainContent}>
            <Box>
              <Box className={classes.rowHeader}>
                <Box>ID</Box>
                <Box>AMOUNT</Box>
                <Box>VALUE</Box>
                <Box>STATUS</Box>
                <Box>VIEW</Box>
              </Box>
            </Box>
            {data && data.length ? (
              data.reverse().map((dat: any, index) => (
                <Box key={index} className={classes.row}>
                  <Box className={classes.rowContent}>
                    <Box>#{dat.entryId} <Box component='img' src='/images/icons/down-arrow.png' /></Box>
                    <Box>{dat.ticketCount} tickets</Box>
                    <Box>${toUSDFormat(Number(dat.usdAmount))}</Box>
                    <Box>{gameResults.reduce((count: number, res: number, index: number) => {
                      if (res.toString() === dat.predictions[index]) {
                        return count + 1;
                      }
                      return count;
                    }, 0).toString()} correct</Box>
                    <Box><Box onClick={() => props.viewHistory && props.viewHistory(dat)}>VIEW</Box></Box>
                  </Box>
                </Box>
              ))
              ): (
                <Box sx={{
                  display: 'flex',
                  padding: '80px 0',
                  justifyContent: 'center',
                }}>No result.</Box>
                
              )}
              
            
          </Box>
        </Box>
      </Box>
    </Box>
  )
}