import {makeStyles} from 'tss-react/mui';

const styles = makeStyles()((theme:any) => ({
  ufcEntryPanel: {
    marginTop: '30px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    flexDirection: 'row',
    gap: '120px',
    [theme.breakpoints.down('lg')]: {
      gap: '80px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    }
  }
}));

export default styles;