import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    fontSize: "14px",
    maxWidth: "250px",
    alignItems: "center",
    color: theme.palette.text.disabled,
    userSelect: "none",
  },
  cardBody: {
    display: "flex",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  token: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
  },
  tokenImage: {
    display: "flex",
    alignItems: "center",
  },
  tokenText: {
    fontSize: "15px",
  },
  rating: {
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    gap: "2px",
    "&>img": {
      width: "15px",
      marginTop: "5px",
    },
  },
  increase: {
    color: theme.palette.text.increase,
  },
  decrease: {
    color: theme.palette.text.decrease,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px 8px 16px",
  },
  remainTime: {
    display: "flex",
    gap: "7px",
    alignItems: "center",
  },
  remainTimeIcon: {
    width: "16px",
    height: "16px",
  },
  remainTimeText: {
    color: theme.palette.text.disabled,
    fontSize: "14px",
  },
  arrowIcon: {
    fontSize: "20px",
    color: "#444E68",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": {
      color: "white",
      transition: ".3s",
    },
  },
  cardHeader: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    color: "#444E68",
    "&>img": {
      width: "15px",
    },
  },
  disableBut: {
    textTransform: "none",
    color: "#444E68",
    backgroundColor: "#181b25",
    borderRadius: "5px",
    padding: "3px 8px",
  },
  enableBut: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#1eaa70",
    borderRadius: "5px",
    padding: "3px 8px",
  },
}));

export default useStyles;
