import { makeStyles } from 'tss-react/mui';
import theme from '../../theme';

const styles = makeStyles()((theme: any) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  description: {
    color: theme.palette.text.disabled,
    fontSize: '13px',
  },
  ticketButton: {
    backgroundColor: theme.palette.background.card,
    color: theme.palette.text.primary,
    height: '60px',
    padding: '0 30px',
    borderRadius: '10px',
    textTransform: 'none',
    display: 'flex',
    fontSize: '18px',
    gap: '15px',
    '&>img': {
      width: '28px',
    }
  },
  disabledButton: {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
    cursor: 'not-allowed',
  },
  tableContainer: {
    overflow: 'auto',
    maxHeight: '400px',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'silver',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#7e889e',
      }
    }
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 1fr',
    padding: '5px 20px',
    borderBottom: '1px solid #4b515f',
    fontSize: '13px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]:{
      padding: '5px 10px',
    },
    '&>div:nth-of-type(1)': {
      display: 'flex',
      alignItems: 'center',
      gap: '7px',
      '&>img': {
        width: '30px',
      }
    },
    '&>div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      '&>div:nth-of-type(2)': {
        color: '#56637e',
      }
    },
    '&>div:nth-of-type(3)>button': {
      background: 'linear-gradient(180deg,#2eb282,#18a86b)',
      borderRadius: '20px',
      color: theme.palette.text.primary,
      '&.tokenDisabled': {
        background: 'linear-gradient(180deg,#3a3a3a,#2b2b2b)',
        cursor: 'not-allowed',
      }
    }
  },
  
}));

export default styles;