import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()((theme: any) => ({
  formControl: {
    m: 1,
    minWidth: 120,
    width: '120px',
    margin: 0, 
    backgroundColor: theme.palette.background.dropdown,
    borderRadius: '20px',
    '& .MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
      maxHeight: '200px',

    },
    '& .MuiInputBase-root': {
      height: '30px',
      fontSize: '13px',

      '& .MuiSelect-select': {
        textAlign: "left",
        paddingLeft: "27px",
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.disabled,
      }
    },
  }
}));

export default useStyles;