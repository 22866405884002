import { Box, Button, CircularProgress, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useConnectWallet } from "@web3-onboard/react";
import { useState } from "react";

import { useAppSelector } from "../../app/hooks";
import useStyles from "./index.styles";

import { useAppDispatch } from "../../app/hooks";
import { getAirdropInfo, getStakes, stake } from "../../reducers/airdrop.slice";
import theme from "../../theme";
import { toUSDFormat } from "../../utils";

interface CreateStakePopupProps {
  readonly closeStakePopup: () => void;
  open: boolean;
}

export default function CreateStakePopup(props: CreateStakePopupProps) {
  const { open, closeStakePopup } = props;
  const { classes } = useStyles();
  const [stakeAmount, setStakeAmount] = useState("");
  const dispatch = useAppDispatch();
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const { isStaking, stakingList } = useAppSelector((state) => state.airdrop);
  const totalStakingAmount = stakingList.reduce(
    (total, item) => total + item.amount,
    0
  );
  const isInputValid = Number.isNaN(Number(stakeAmount));
  const isExceededLimit = (totalStakingAmount + Number(stakeAmount)) > 1000;

  const handleClose = () => {
    closeStakePopup();
  };

  const handleStaking = () => {
    dispatch(stake({ stakeAmount, account }))
      .unwrap()
      .then(() => {
        closeStakePopup();
        setTimeout(() => {
          dispatch(getAirdropInfo(account));
          dispatch(getStakes(account));
        }, 500);
      })
      .catch((error) => {});
  };

  return (
    <Box className={classes.body}>
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              backgroundColor: "#1c1f28",
              padding: "20px 30px",
              borderRadius: "10px",
              [theme.breakpoints.up("lg")]: {
                maxWidth: "700px",
              },
              [theme.breakpoints.down("sm")]: {
                maxWidth: "90%",
              },
              "& .MuiDialogContent-root": {
                [theme.breakpoints.down("sm")]: {
                  padding: "0",
                },
              },
            },
          },
        }}
      >
        <DialogContent>
          <Box className={classes.header}>
            <Box>Create a 90-day stake!</Box>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.inputBox}>
              <Box>
                <Box component="img" src="/images/tokens/plsp_round.png" />
                <TextField
                  placeholder={`Max. ${toUSDFormat(
                    1000 - totalStakingAmount
                  )} PLSP`}
                  onChange={(e) =>
                    setStakeAmount((e.target as HTMLInputElement).value)
                  }
                  value={stakeAmount}
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "17px",
                    fieldset: {
                      border: "none",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "13px",
                      color: "#b04747 !important",
                      top: "12px",
                    },
                  }}
                  // error={isInputValid || isExceededLimit}
                  label={
                    isInputValid
                      ? "Incorrect number"
                      : isExceededLimit
                      ? "Exceeded 1000 PLSP per wallet"
                      : ""
                  }
                />
              </Box>
              <Box>
                <Button
                  onClick={handleStaking}
                  disabled={isInputValid || isExceededLimit}
                >
                  {isStaking ? (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </Box>
            </Box>
            <Box className={classes.descriptionBox}>
              <Box>
                <Box component="img" src="/images/logo.png" />{" "}
                {toUSDFormat(Number(stakeAmount) * 500)} $PREDICT
              </Box>
              <Box>
                137,000% APY
                <Box component="img" src="/images/icons/burn.png" />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
