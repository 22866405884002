import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {},
    "& div:first-of-type": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    "& div:nth-of-type(2)": {
      color: theme.palette.text.disabled,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "& div:nth-of-type(3)": {
      display: "flex",
      alignItems: "center",
      gap: "7px",
      "& img": {
        width: "30px",
      },
    },
  },
  progress: {
    marginTop: "20px",
  },
  chart: {
    backgroundColor: theme.palette.background.predictionCard,
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "300px",
    "&>div:nth-of-type(1)": {
      minHeight: "300px",
      fontSize: "24px",
      color: theme.palette.text.dark,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    "&>div:nth-of-type(2)": {
      fontSize: "13px",
      position: "absolute",
      color: theme.palette.text.disabled,
      display: "flex",
      alignItems: "center",
      gap: "7px",
      right: "20px",
      bottom: "10px",
      cursor: "pointer",
      "& img": {
        width: "22px",
      },
    },
  },
  vsCards: {
    display: "flex",
    marginTop: "40px",

    "&>div:first-of-type": {
      flex: 2,
      backgroundColor: theme.palette.background.predictionCard,
      padding: "25px 0",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      borderRadius: "10px",
      "&>div:first-of-type": {
        display: "flex",
        gap: "10px",
        fontSize: "28px",
        alignItems: "center",
        justifyContent: "center",
      },
      "&>div:nth-of-type(2)": {
        color: theme.palette.text.disabled,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    "&>div:nth-of-type(2)": {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      display: "flex",
      fontSize: "22px",
    },
    "&>div:nth-of-type(3)": {
      flex: 2,
      backgroundColor: theme.palette.background.predictionCard,
      padding: "25px 0",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      borderRadius: "10px",

      "&>div:first-of-type": {
        display: "flex",
        gap: "10px",
        fontSize: "28px",
        alignItems: "center",
        justifyContent: "center",
      },
      "&>div:nth-of-type(2)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: theme.palette.text.disabled,
      },
    },
  },

  betSection: {
    display: "flex",
    marginTop: "40px",
    width: "100%",
    justifyContent: "center",
    "&>div:nth-of-type(1), &>div:nth-of-type(3)": {
      flex: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "15px",
    },
    "&>div:nth-of-type(1)": {
      width: "100%",
      "&>div:nth-of-type(1)": {
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.card,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "&>div:nth-of-type(2)": {
        fontSize: "17px",
      },
      "&>div:nth-of-type(3)": {
        display: "flex",
        gap: "10px",
        "&>div:nth-of-type(1)": {
          color: theme.palette.text.disabled,
        },
        "&>div:nth-of-type(2)": {
          color: theme.palette.text.increase,
        },
      },
    },
    "&>div:nth-of-type(2)": {
      flex: 1,
    },
    "&>div:nth-of-type(3)": {
      display: "flex",
      flexDirection: "column",
      "&>div:nth-of-type(1)": {
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.card,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        textTransform: "none",
        "&>div:nth-of-type(1)": {
          position: "absolute",
          right: "0",
          display: "flex",
          width: "200px",
          gap: "10px",
          color: theme.palette.text.disabled,
          fontSize: "18px",
          transform: "translate(calc(100% + 20px), 0)",
          "&>img": {
            width: "20px",
          },
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
      },
    },
  },
  cardPriceBody: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      fontSize: "14px",
    },
  },
}));

export default styles;
