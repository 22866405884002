import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import EastIcon from "@mui/icons-material/East";

import theme from "../../theme";
import { useNavigate, useParams } from "react-router-dom";

import useStyles from "./index.styles";
import TokenImage from "../TokenImage";

import { PredictRound } from "../../reducers/predict.slice";
import { toUSDFormat, convertDateTimeSimple } from "../../utils";
import { useAppSelector } from "../../app/hooks";
import { PredictRoundStatus } from "../../constants/prediction";
import Dot from "../Dot";
interface CardDetailProps {
  readonly round: PredictRound;
  readonly sx?: object;
  readonly remainTime?: boolean;
  readonly isForProfile?: boolean;
  readonly dotVisible?: boolean;
  readonly withdrawFunds?: (roundId: number) => void;
}

export default function CardDetail(props: CardDetailProps) {
  const { classes } = useStyles();
  const { tokens, loadingCancelRound } = useAppSelector(
    (state) => state.predict
  );
  const [curTime, setCurTime] = useState(
    Math.floor(new Date().getTime() / 1000)
  );
  const navigate = useNavigate();
  const { address } = useParams();

  const tokenPrice =
    tokens.find((token) => token.token === props.round.priceTokenName)?.price ??
    0;

  const calculatePricePercentage = () => {
    const priceToken: string = props.round.priceTokenName;
    const priceTokenInfo = tokens.find((token: any, index: number) => {
      return token.name.toLowerCase() === priceToken.toLowerCase();
    });
    if (!priceTokenInfo) return 0;
    let percent = 0;
    if (priceTokenInfo.price) {
      percent =
        ((props.round.pricePoint - priceTokenInfo.price) /
          priceTokenInfo.price) *
        100;
    }
    if (Math.abs(percent) > 1) {
      percent = Number(percent.toFixed(0));
    } else {
      percent = Number(percent.toFixed(2));
    }
    return percent;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurTime(Math.floor(new Date().getTime() / 1000));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      className={classes.body}
      sx={{
        ...props.sx,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/predictions/detail/${props.round.roundId}`)}
    >
      <Card
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.dropdown,
          padding: "8px 10px",
        }}
      >
        <CardHeader
          title={
            <React.Fragment>
              <Box className={classes.cardHeader}>
                {!props.dotVisible ? (
                  <>
                    <Box
                      component={"img"}
                      src={
                        "/images/prediction/" +
                        (props.round.isQuickBet === 1 ? "thunder" : "gears") +
                        ".png"
                      }
                    />
                    {props.round.isQuickBet === 1
                      ? "Quick bet"
                      : "Advanced bet"}
                  </>
                ) : props.round.status === PredictRoundStatus.Waiting &&
                  props.round.endTime >= new Date().getTime() / 1000 ? (
                  <>
                    <Dot
                      sx={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#1eff00",
                      }}
                    ></Dot>
                    Open
                  </>
                ) : (
                  <>
                    <Dot
                      sx={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#ff1b1b",
                      }}
                    ></Dot>
                    Live
                  </>
                )}
              </Box>
            </React.Fragment>
          }
          subheader={"$" + toUSDFormat(props.round.roundMaximumValue)}
          titleTypographyProps={{
            sx: {
              color: theme.palette.text.default,
              fontSize: "12px",
              display: "inline",
            },
          }}
          subheaderTypographyProps={{
            sx: {
              color: theme.palette.text.default,
              fontSize: "13px",
              display: "inline",
            },
          }}
          sx={{
            paddingTop: "8px",
            paddingBottom: "0",
            "& .MuiCardHeader-content": {
              justifyContent: "space-between",
              display: "flex",
            },
          }}
        />
        <CardContent className={classes.cardContent}>
          <Box className={classes.token}>
            <Box className={classes.tokenImage}>
              <TokenImage
                token={props.round.priceTokenName.toLowerCase()}
                classNames="middle"
              />
            </Box>
            <Box className={classes.tokenText}>
              {props.round.priceTokenName}
            </Box>
          </Box>
          {props.round.isQuickBet === 1 ? (
            props.round.operator === 1 ? (
              <Box className={`${classes.rating} ${classes.decrease}`}>
                <Box
                  component={"img"}
                  src="/images/prediction/down-arrow1.png"
                />
                Down
              </Box>
            ) : props.round.operator === 0 ? (
              <Box className={`${classes.rating} ${classes.increase}`}>
                <Box component={"img"} src="/images/prediction/up-arrow.png" />
                Up
              </Box>
            ) : (
              ""
            )
          ) : calculatePricePercentage() >= 0 ? (
            <Box className={`${classes.rating} ${classes.increase}`}>
              +{calculatePricePercentage()}%
            </Box>
          ) : (
            <Box className={`${classes.rating} ${classes.decrease}`}>
              -{Math.abs(calculatePricePercentage())}%
            </Box>
          )}
        </CardContent>
        <CardActions className={classes.footer}>
          <Box className={classes.remainTime}>
            {props.isForProfile &&
            ((props.round.status === PredictRoundStatus.Waiting &&
              props.round.endTime >= new Date().getTime() / 1000) ||
              props.round.status === PredictRoundStatus.Started) ? (
              <>
                {(props.round.creator.toLowerCase() ===
                  address?.toLowerCase() &&
                  ((props.round.operator === 0 &&
                    tokenPrice >= props.round.pricePoint) ||
                    (props.round.operator === 1 &&
                      tokenPrice <= props.round.pricePoint))) ||
                (props.round.creator.toLowerCase() !== address?.toLowerCase() &&
                  ((props.round.operator === 1 &&
                    tokenPrice > props.round.pricePoint) ||
                    (props.round.operator === 0 &&
                      tokenPrice < props.round.pricePoint))) ? (
                  <Button className={classes.enableBut}>
                    Currently winning
                  </Button>
                ) : (
                  <Button className={classes.disableBut}>
                    Currently losing
                  </Button>
                )}
              </>
            ) : props.isForProfile &&
              props.round.status === PredictRoundStatus.Finished ? (
              <>
                {(props.round.isCreatorWinner &&
                  address?.toLowerCase() ===
                    props.round.creator.toLowerCase()) ||
                (!props.round.isCreatorWinner &&
                  address?.toLowerCase() !==
                    props.round.creator.toLowerCase()) ? (
                  <Button className={classes.enableBut}>
                    Won ${" "}
                    {props.round.isCreatorWinner
                      ? props.round.isQuickBet
                        ? toUSDFormat(
                            props.round.roundMaximumValue -
                              props.round.feeAmount -
                              props.round.roundMaximumValue / 2,
                            2
                          )
                        : "none"
                      : props.round.isQuickBet
                      ? toUSDFormat(
                          props.round.roundMaximumValue -
                            props.round.feeAmount -
                            props.round.roundMaximumValue / 2,
                          2
                        )
                      : "none"}
                  </Button>
                ) : (
                  <Button className={classes.disableBut}>
                    Lost ${" "}
                    {props.round.isCreatorWinner
                      ? props.round.isQuickBet
                        ? toUSDFormat(props.round.roundMaximumValue / 2, 2)
                        : "none"
                      : props.round.isQuickBet
                      ? toUSDFormat(props.round.roundMaximumValue / 2, 2)
                      : "none"}
                  </Button>
                )}
              </>
            ) : props.isForProfile &&
              ((props.round.status === PredictRoundStatus.Waiting &&
                props.round.endTime < new Date().getTime() / 1000) ||
                props.round.status === PredictRoundStatus.Canceled) ? (
              <>
                {props.round.status === PredictRoundStatus.Canceled ? (
                  <Button
                    className={classes.disableBut}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Withdraw complete
                  </Button>
                ) : (
                  <Button
                    className={classes.enableBut}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.withdrawFunds &&
                        props.withdrawFunds(props.round.roundId);
                    }}
                    disabled={loadingCancelRound}
                  >
                    Withdraw funds
                    {loadingCancelRound ? (
                      <CircularProgress
                        size={15}
                        sx={{ color: "white", marginLeft: "3px" }}
                      />
                    ) : null}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Box
                  component="img"
                  src="/images/icons/clock.png"
                  className={classes.remainTimeIcon}
                />
                <Box className={classes.remainTimeText}>
                  {props.round.isQuickBet === 1 ? (
                    <>{props.round.duration} min duration</>
                  ) : (
                    <>
                      {props.round.status === PredictRoundStatus.Started
                        ? "Finishes"
                        : "Expires"}{" "}
                      in{" "}
                      {convertDateTimeSimple(
                        (props.round.status === PredictRoundStatus.Waiting &&
                        props.round.expireTime
                          ? props.round.expireTime
                          : props.round.endTime) -
                          curTime <
                          0
                          ? 0
                          : (props.round.status ===
                              PredictRoundStatus.Waiting &&
                            props.round.expireTime
                              ? props.round.expireTime
                              : props.round.endTime) - curTime
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
          <EastIcon className={classes.arrowIcon} />
        </CardActions>
      </Card>
    </Box>
  );
}
