import { Box, Skeleton } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils";
import { PlayerStatus, PredictRoundStatus } from "../../constants/prediction";
import TokenImage from "../TokenImage";
import { useEffect, useState } from "react";
import { calculatePercent } from "../../utils/predict";

interface TakeVsCardProps {
  roundInfo: any;
  fakePrice: number;
}

export default function TakeVsCard(props: TakeVsCardProps) {
  const { loadingRoundInfo, loadingMetaInfo, betTokens } = useAppSelector(
    (state) => state.predict
  );
  const [betToken, setBetToken] = useState("");

  useEffect(() => {
    if (props.roundInfo.bets && props.roundInfo.bets.length >= 2) {
      setBetToken(
        betTokens
          .find((tok) => {
            return tok.id === props.roundInfo.bets[1].betTokenId;
          })
          ?.name.toLowerCase() ?? ""
      );
    }
  }, [betTokens, props.roundInfo.bets]);
  return (
    <Box
      sx={{
        opacity:
          props.roundInfo.status === PredictRoundStatus.Canceled ||
          props.roundInfo.status === PredictRoundStatus.Expired
            ? 0.5
            : 1,
      }}
    >
      <Box>
        {loadingRoundInfo || loadingMetaInfo ? (
          <Skeleton animation="wave" variant="rectangular" width={170} />
        ) : (props.roundInfo &&
            props.roundInfo.status === PredictRoundStatus.Waiting) ||
          props.roundInfo.status === PredictRoundStatus.Expired ||
          props.roundInfo.status === PredictRoundStatus.Canceled ? (
          <>
            $
            {toUSDFormat(
              props.roundInfo.roundMaximumValue -
                props.roundInfo.currentTotalUsd,
              2
            )}
          </>
        ) : props.roundInfo && props.roundInfo.bets?.length >= 2 ? (
          <>
            <TokenImage token={betToken?.toLowerCase()} classNames="middle" />$
            {toUSDFormat(
              (props.roundInfo && props.roundInfo?.bets[1]?.usdValue) ?? 0,
              2
            )}
          </>
        ) : (
          ""
        )}
      </Box>
      <Box>
        {loadingRoundInfo || loadingMetaInfo ? (
          <Skeleton animation="wave" variant="rectangular" width={170} />
        ) : (
          <>
            {(props.roundInfo.status === PredictRoundStatus.Waiting ||
              props.roundInfo.status === PredictRoundStatus.Started) &&
            calculatePercent(
              props.roundInfo.operator,
              PlayerStatus.Taker,
              props.fakePrice,
              props.roundInfo.pricePoint
            ) !== 0 &&
            props.fakePrice ? (
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <span>
                  Needs price to{" "}
                  {1 - props.roundInfo.operator === 0 ? "increase" : "decrease"}{" "}
                </span>
                <span style={{ color: "#b8811c" }}>
                  {calculatePercent(
                    props.roundInfo.operator,
                    PlayerStatus.Taker,
                    props.fakePrice,
                    props.roundInfo.pricePoint
                  )}
                  %
                </span>
              </Box>
            ) : props.fakePrice &&
              (props.roundInfo.status === PredictRoundStatus.Started ||
                props.roundInfo.status === PredictRoundStatus.Waiting) ? (
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  component={"img"}
                  src="/images/prediction/check1.png"
                  sx={{ width: "25px" }}
                />
                <Box component={"span"}>Currently winning</Box>
              </Box>
            ) : props.roundInfo.status === PredictRoundStatus.Started ||
              props.roundInfo.status === PredictRoundStatus.Waiting ? (
              <Skeleton width={100} />
            ) : (
              ""
            )}

            {props.roundInfo.status &&
            props.roundInfo.status === PredictRoundStatus.Finished ? (
              props.roundInfo.isCreatorWinner ? (
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <span>
                    Needed price to{" "}
                    {1 - props.roundInfo.operator === 0
                      ? "increase"
                      : "decrease"}{" "}
                  </span>
                  <span style={{ color: "#b8811c" }}>
                    {toUSDFormat(
                      (Math.abs(
                        props.roundInfo.recordedPrice -
                          props.roundInfo.pricePoint
                      ) /
                        props.roundInfo.recordedPrice) *
                        100,
                      2
                    )}
                    %
                  </span>
                </Box>
              ) : (
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src="/images/prediction/check1.png"
                    sx={{ width: "25px" }}
                  />
                  <Box component={"span"}>Currently winner</Box>
                </Box>
              )
            ) : (
              ""
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
