import { Box, Button } from "@mui/material";
import Carousel from "nuka-carousel";
import { useEffect, useState } from "react";

import CardDetail from "../CardDetail";
import useStyles from "./index.styles";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getRoundMaxValue, getRounds } from "../../reducers/predict.slice";

import {
  BetTypes,
  PredictRoundStatus,
  PredictSortStatus,
  PredictType,
  PredictionGettingRoundsType
} from "../../constants/prediction";

export default function RecentlyRoundCards() {
  const dispatch = useAppDispatch();
  const { homeLiveRounds, homeOpenRounds, roundMaxValue } = useAppSelector(
    (state) => state.predict
  );

  const { classes } = useStyles();
  const [slideIndex, setSlideIndex] = useState(0);
  const [showCount, setShowCount] = useState(4);

  const totNum = homeLiveRounds.length + homeOpenRounds.length;

  const prevHandle = () => {
    setSlideIndex((slideIndex) => {
      let prev = slideIndex - 1;
      if (prev < 0) {
        prev = totNum - 1;
      }
      return prev;
    });
  };

  const nextHandle = () => {
    setSlideIndex((slideIndex) => {
      let next = slideIndex + 1;
      if (next >= totNum) {
        next = 0;
      }
      return next;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (totNum > 4) {
        nextHandle();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [totNum]);

  useEffect(() => {
    if (roundMaxValue === 0) return;
    const fetch = async () => {
      await dispatch(
        getRounds({
          //getting live rounds
          statusValue: PredictRoundStatus.Started,
          sortValue: PredictSortStatus.Expiry,
          coinValue: [],
          minValue: 0,
          maxValue: roundMaxValue,
          typeValue: BetTypes[PredictType.All].valueStr,
          activePage: 1,
          countPerPage: 10,
          roundsType: PredictionGettingRoundsType.LiveRoundsHomeIndex,
          initStatus: false,
        })
      );
      await dispatch(
        getRounds({
          //getting live rounds
          statusValue: PredictRoundStatus.Waiting,
          sortValue: PredictSortStatus.Expiry,
          coinValue: [],
          minValue: 0,
          maxValue: roundMaxValue,
          typeValue: BetTypes[PredictType.All].valueStr,
          activePage: 1,
          countPerPage: 10,
          roundsType: PredictionGettingRoundsType.OpenRoundsHomeIndex,
          initStatus: false,
        })
      );
    };
    fetch();
  }, [roundMaxValue, dispatch]);

  useEffect(() => {
    dispatch(getRoundMaxValue());
  }, [dispatch]);

  useEffect(() => {
    let count = 4;
    if (window.innerWidth < 600) count = 1;
    else if (window.innerWidth < 900) count = 2;
    setShowCount(count);
  }, []);

  return (
    <Box className={classes.body}>
      <Box
        sx={{
          fontSize: "25px",
          marginTop: "20px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        Live & Open bets
      </Box>
      {homeLiveRounds.length > 0 || homeOpenRounds.length > 0 ? (
        <Box className={classes.carouselBody}>
          <Button
            className={classes.slideButton}
            disableRipple
            onClick={prevHandle}
          >
            <Box component="img" src="/images/icons/left-arrow.png" />
          </Button>
          <Box className={classes.carousel}>
            <Carousel
              wrapAround={false}
              dragging={true}
              enableKeyboardControls={true}
              slideIndex={slideIndex}
              slidesToShow={totNum < 4 ? totNum : showCount}
            >
              {[...homeLiveRounds, ...homeOpenRounds].map((round, index) => (
                <CardDetail
                  key={index}
                  sx={{ width: "90%" }}
                  round={round}
                  remainTime={true}
                  dotVisible={true}
                />
              ))}
            </Carousel>
          </Box>
          <Button
            className={classes.slideButton}
            disableRipple
            onClick={nextHandle}
          >
            <Box component="img" src="/images/icons/right-arrow1.png" />
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
