import {
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CustomDropdown from "../CustomDropdown";
import PredictMultiSelect from "../PredictMultiSelect";
import PredictRangePopup from "../PredictRangePopup";
import theme from "../../theme";

import useStyles from "./index.styles";
import {
  predictionStatus,
  sortArr,
  TokenList,
  PredictLpStatus,
  BetTypes,
} from "../../constants/prediction";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";

interface FiltersGroupForPredictionProps {
  status: number;
  sort: number;
  coin: string[];
  range: number[];
  type: number;
  changeStatus: (status: number) => void;
  changeSort: (sort: number) => void;
  changeCoin: (coin: string[]) => void;
  changeLp: (lp: number) => void;
  changeRange: (range: number[]) => void;
  changeType: (type: number) => void;
}

export default function FiltersGroupForPrediction(
  props: FiltersGroupForPredictionProps
) {
  const { classes } = useStyles();
  const { lpStatus } = useAppSelector((state) => state.predict);
  const [expanded, setExpanded] = useState(
    window.innerWidth < 600 ? false : true
  );
  const navigate = useNavigate();

  const handleChange = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <Box className={classes.filtersBody}>
      <Accordion
        onChange={handleChange}
        expanded={expanded}
        sx={{
          backgroundColor: "#13151e",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        >
          <Box className={classes.filtersText}>Filters</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.dropdownGroups}>
            <Box className={classes.dropdownRow}>
              <Box className={classes.dropdownLabel}>Status</Box>
              <CustomDropdown
                items={predictionStatus}
                value={props.status}
                setValue={props.changeStatus}
              />
            </Box>
            <Box className={classes.dropdownRow}>
              <Box className={classes.dropdownLabel}>Type</Box>
              <CustomDropdown
                items={BetTypes}
                value={props.type}
                setValue={props.changeType}
              />
            </Box>
            <Box className={classes.dropdownRow}>
              <Box className={classes.dropdownLabel}>Filter coins</Box>
              <PredictMultiSelect
                items={TokenList}
                value={props.coin}
                setValue={props.changeCoin}
              />
            </Box>
            <Box className={classes.dropdownRow}>
              <Box className={classes.dropdownLabel}>Sort by</Box>
              <CustomDropdown
                items={sortArr}
                value={props.sort}
                setValue={props.changeSort}
              />
            </Box>
            <Box className={classes.dropdownRow}>
              <Box className={classes.dropdownLabel}>Liquidity</Box>
              <CustomDropdown
                items={PredictLpStatus}
                value={lpStatus}
                setValue={props.changeLp}
              />
            </Box>
            <Box className={classes.dropdownRow}>
              <Box className={classes.dropdownLabel}>Range</Box>
              <PredictRangePopup
                rangeValue={props.range}
                setRangeValue={props.changeRange}
              />
            </Box>
            <Button
              onClick={() => navigate("/tokens")}
              className={classes.toSupportedAssetPage}
            >
              Supported assets
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
