import { ethers } from "ethers";
import Euro24Abi from "../../abis/euro24.json";
import { gameConfig } from "../../config";
import {
  approveToken,
  getBetTokens,
  getProvider,
  getTokenAllowance,
  getWeb3,
} from "./index";

export const getEuro24Contract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const Euro24Contract = new web3.eth.Contract(
    Euro24Abi,
    gameConfig.euro24Address
  );
  return Euro24Contract;
};

export const getEuro24BetTokens = async () => {
  const Euro24Contract = getEuro24Contract();

  return await getBetTokens(Euro24Contract);
};

export const finishRoundfun = async (
  account: string,
  roundId: number,
  result: number
) => {
  const Euro24Contract = getEuro24Contract(true);
  const data = await Euro24Contract.methods
    .finishRound(roundId, result)
    .send({ from: account });

  return data;
};

export const enterRound = async (
  roundId: number,
  tokenId: number,
  tokenAmount: number,
  tokenAddress: string,
  usdAmount: number,
  expectation: number,
  account: string
) => {
  const Euro24Contract = getEuro24Contract(true);
  const allowance = await getTokenAllowance(
    tokenAddress,
    account,
    gameConfig.euro24Address
  );

  console.log(allowance);
  const formattedUsdAmount = ethers.utils
    .parseUnits(usdAmount.toString(), 18)
    .toString();

  if (Number(allowance) < tokenAmount) {
    await approveToken(
      tokenAddress,
      tokenAmount,
      gameConfig.euro24Address,
      account
    );
  }

  const data = await Euro24Contract.methods
    .enterRound(roundId, tokenId, formattedUsdAmount, expectation)
    .send({
      from: account,
    });

  return data;
};

// (uint256 roundId, uint256 tokenId, uint256 usdAmount, uint256 expectation)
export const enterRoundWithPLS = async (
  roundId: number,
  tokenId: number,
  plsAmount: number,
  usdAmount: number,
  expectation: number,
  account: string
) => {
  const Euro24Contract = getEuro24Contract(true);
  const decimals = 18;
  const formattedPlsAmount = ethers.utils
    .parseUnits(plsAmount.toFixed(18), decimals)
    .toString();

  const formattedUsdAmount = ethers.utils
    .parseUnits(usdAmount.toString(), 18)
    .toString();

  const data = await Euro24Contract.methods
    .enterRound(roundId, tokenId, formattedUsdAmount, expectation)
    .send({
      from: account,
      value: formattedPlsAmount,
    });

  return data;
};
