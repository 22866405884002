import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  ldsEllipsis: {
    display: "inline-block",
    position: "relative",
    width: "80px",
    height: "30px",
    "& div": {
      position: "absolute",
      top: "10px",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#fff",
      animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",
      "&:nth-of-type(1)": {
        left: "8px",
        animation: "$ldsEllipsis1 0.6s infinite",
      },
      "&:nth-of-type(2), &:nth-of-type(3)": {
        left: "8px",
        animation: "$ldsEllipsis2 0.6s infinite",
      },
      "&:nth-of-type(3)": {
        left: "32px",
      },
      "&:nth-of-type(4)": {
        left: "56px",
        animation: "$ldsEllipsis3 0.6s infinite",
      },
    },
  },
  "@keyframes ldsEllipsis1": {
    "0%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@keyframes ldsEllipsis2": {
    "0%": {
      transform: "translate(0, 0)",
    },
    "100%": {
      transform: "translate(24px, 0)",
    },
  },
  "@keyframes ldsEllipsis3": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0)",
    },
  },
}));

export default styles;
