import { Box } from "@mui/material";

import CircularLogo from "../CircularLogo";
import { toUSDFormat } from "../../utils";

interface StakeCardProps {
  readonly text: string;
  readonly value: number;
  readonly sx?: object;
}

export default function StakeCard(props: StakeCardProps) {
  return (
    <Box
      sx={{
        ...props.sx,
        width: "100%",
        padding: "8px 30px",
        backgroundColor: "#1c1f28",
        display: "grid",
        borderRadius: "10px",
        gap: "15px",
        alignItems: "center",
        gridTemplateColumns: "6fr 2fr 2fr",
        fontSize: "18px",
      }}
    >
      <Box
        sx={{
          color: "#454f69",
        }}
      >
        {props.text}
      </Box>
      <Box
        sx={{
          textAlign: "right",
        }}
      >
        {toUSDFormat(props.value, 2)}
      </Box>
      <CircularLogo width={30} />
    </Box>
  );
}
