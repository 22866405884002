import { ethers } from "ethers";
import {
  getProvider,
  getWeb3,
  getBetTokens,
  getTokenAllowance,
  approveToken,
} from "./index";
import { gameConfig } from "../../config";
import PredictAbi from "../../abis/predict.json";
import { web3OnboardGlobal } from "@web3-onboard/react/dist/context";

export const getPredictContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const predictContract = new web3.eth.Contract(
    PredictAbi,
    gameConfig.predictAddress
  );
  return predictContract;
};

export const readPriceTokens = async () => {
  const predictContract = getPredictContract();
  const tokenInfo: any = await predictContract.methods.getPriceTokens().call();
  const tokens: any[] = tokenInfo.list;
  const prices: string[] = tokenInfo.prices.map(
    (price: number, index: number) => Number(ethers.utils.formatEther(price))
  );

  return [tokens, prices];
};

export const readRoundInfo = async (id: number) => {
  const predictContract = getPredictContract();
  const roundInfo: any = await predictContract.methods.getRound(id).call();
  return { ...roundInfo.round, ...roundInfo.option, ...roundInfo.timeInfo };
};

export const getPredictBetTokens = async () => {
  const predictContract = getPredictContract();
  return await getBetTokens(predictContract);
};

export const enterPredictRound = async (
  tokenId: number,
  tokenAddress: string,
  tokenAmount: number,
  account: string,
  roundId: number,
  usdAmount: number
) => {
  const predictContract = getPredictContract(true);
  if (tokenAddress === ethers.constants.AddressZero) {
    await predictContract.methods
      .placeBet(
        roundId,
        tokenId,
        ethers.utils.parseEther(usdAmount.toString()).toString()
      )
      .send({
        value: ethers.utils.parseEther(tokenAmount.toFixed(18)).toString(),
        from: account,
      });
  } else {
    const tokenAllowance = await getTokenAllowance(
      tokenAddress,
      account,
      gameConfig.predictAddress
    );
    if (Number(tokenAllowance) < tokenAmount) {
      await approveToken(
        tokenAddress,
        tokenAmount,
        gameConfig.predictAddress,
        account
      );
    }
    await predictContract.methods
      .placeBet(
        roundId,
        tokenId,
        ethers.utils.parseEther(usdAmount.toString()).toString()
      )
      .send({ from: account });
  }
};

export const createPredictRound = async (
  newRoundInfo: any,
  account: any,
  tokenAmount: any,
  betToken: any,
  isQuickBet: boolean,
  duration: number
) => {
  try {
    const predictContract = getPredictContract(true);
    if (betToken.tokenAddress === ethers.constants.AddressZero) {
      await predictContract.methods
        .createRound(
          newRoundInfo.priceTokenId,
          newRoundInfo.initialBet[0],
          newRoundInfo.initialBet[1],
          newRoundInfo.endTime,
          newRoundInfo.expireTime,
          newRoundInfo.pricePoint,
          newRoundInfo.operator,
          newRoundInfo.maxUSDPerPlayer,
          newRoundInfo.minUSDPerPlayer,
          newRoundInfo.roundMaximumValue,
          newRoundInfo.onlyOneTaker,
          newRoundInfo.isPrivate,
          newRoundInfo.startEarlyEnabled,
          isQuickBet,
          duration
        )
        .send({
          value: ethers.utils.parseEther(tokenAmount.toFixed(18)).toString(),
          from: account,
        });
    } else {
      const tokenAllowance = await getTokenAllowance(
        betToken.tokenAddress,
        account,
        gameConfig.predictAddress
      );

      if (Number(tokenAllowance) < tokenAmount) {
        await approveToken(
          betToken.tokenAddress,
          tokenAmount,
          gameConfig.predictAddress,
          account
        );
      }
      await predictContract.methods
        .createRound(
          newRoundInfo.priceTokenId,
          newRoundInfo.initialBet[0],
          newRoundInfo.initialBet[1],
          newRoundInfo.endTime,
          newRoundInfo.expireTime,
          newRoundInfo.pricePoint,
          newRoundInfo.operator,
          newRoundInfo.maxUSDPerPlayer,
          newRoundInfo.minUSDPerPlayer,
          newRoundInfo.roundMaximumValue,
          newRoundInfo.onlyOneTaker,
          newRoundInfo.isPrivate,
          newRoundInfo.startEarlyEnabled,
          isQuickBet,
          duration
        )
        .send({ from: account });
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const finishPredictRound = async (roundId: number, account: string) => {
  const PriceCallContract = getPredictContract(true);
  try {
    await PriceCallContract.methods
      .finishRound(roundId)
      .send({ from: account });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const cancelPredictRound = async (roundId: number, account: string) => {
  const PriceCallContract = getPredictContract(true);
  try {
    await PriceCallContract.methods
      .cancelRound(roundId)
      .send({ from: account });
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const readTokenPrice = async (tokenId: number): Promise<string> => {
  const PriceCallContract = getPredictContract();

  try {
    const price = await PriceCallContract.methods
      .getPriceOracle(tokenId)
      .call();
    // Check if price is undefined or null before converting
    if (!price) {
      throw new Error("Price is undefined or null");
    }
    return Number(ethers.utils.formatEther(price)).toFixed(8);
  } catch (error) {
    console.error("Failed to read token price:", error);
    throw error; // Re-throw the error after logging or handle it as needed
  }
};

export const readLastCreatedRoundId = async (account: string) => {
  const PriceCallContract = getPredictContract();
  const res = await PriceCallContract.methods
    .userLastCreatedRoundId(account)
    .call();
  return Number(res);
};

export const getPredictToken = async (tokenId: number) => {
  const PriceCallContract = getPredictContract();
  const res = await PriceCallContract.methods.getPriceOracle(tokenId).call();
  return res ? res.toString() : "0";
};
