import { Box } from "@mui/material";

import { PredictRoundStatus } from "../../constants/prediction";
import Dot from "../Dot";
import theme from "../../theme";

interface PredictStatusProps {
  readonly status: number;
  readonly curTime: number;
  readonly endTime: number;
}
export default function PredictStatus(props: PredictStatusProps) {
  return props.status === PredictRoundStatus.Waiting ? (
    <Box>
      <Dot
        sx={{
          width: "10px",
          height: "10px",
          backgroundColor: theme.palette.background.openColor,
        }}
      />
      Open bet
    </Box>
  ) : props.status === PredictRoundStatus.Started &&
    props.curTime < props.endTime ? (
    <Box>
      <Dot
        sx={{
          width: "10px",
          height: "10px",
          backgroundColor: theme.palette.background.startColor,
        }}
      />
      Bet is live
    </Box>
  ) : props.status === PredictRoundStatus.Started &&
    props.curTime >= props.endTime ? (
    <Box>
      <Dot
        sx={{
          width: "10px",
          height: "10px",
          backgroundColor: "#cc00ff",
        }}
      />
      Waiting to be finalized
    </Box>
  ) : props.status === PredictRoundStatus.Finished ? (
    <Box>
      <Dot
        sx={{
          width: "10px",
          height: "10px",
          backgroundColor: "#419657",
        }}
      />
      Bet is completed
    </Box>
  ) : props.status === PredictRoundStatus.Expired ? (
    <Box>
      <Dot
        sx={{
          width: "10px",
          height: "10px",
          backgroundColor: "#ff0000",
        }}
      />
      Expired
    </Box>
  ) : props.status === PredictRoundStatus.Canceled ? (
    <Box>
      <Dot
        sx={{
          width: "10px",
          height: "10px",
          backgroundColor: "#ff0000",
        }}
      />
      Canceled bet
    </Box>
  ) : (
    <Box />
  );
}
