import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  filtersBody: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    minWidth: "300px",
    paddingLeft: "40px",
    paddingRight: "40px",
    height: "100%",
    borderRight: "1px solid #2b2a2a",
    gap: "80px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      gap: '20px',
      width: '100%',
      minWidth: '100%',
    },
  },
  filtersText: {
    color: theme.palette.text.primary,
  },
  dropdownGroups: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  dropdownRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    '&>div:nth-of-type(2)': {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        display: 'flex',
      }
    }
  },
  dropdownLabel: {
    width: "40%",
    fontSize: "13px",
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      display: 'flex',
    }
  },
}));

export default useStyles;
