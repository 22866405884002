import * as React from "react";
import TextField from "@mui/material/TextField";
import theme from "../../theme";

interface TextBoxProps {
  text: string;
  onTextChange: (newValue: string) => void; // More specific type for event handler
}

const TextBox: React.FC<TextBoxProps> = ({ text, onTextChange }) => {
  return (
    <TextField
      hiddenLabel
      value={text}
      onChange={(e) => onTextChange(e.target.value)}
      size="small"
      inputProps={{
        style: { fontSize: "13px" },
      }}
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.card,
        borderRadius: "12px",
        padding: "8px 12px",
        fieldset: {
          border: "none",
        },
      }}
    />
  );
};

export default TextBox;
