import { Box } from "@mui/material";

import PredictionCreateHeader from "../../components/PredictionCreateHeader";
import PredictionCreateOptions from "../../components/PredictionCreateOptions";
import PredictCreateRightPart from "../../components/PredictCreateRightPart";

import theme from "../../theme";

export default function PredictionAdvancedCreate() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "32px 0",
        gap: "64px",
        [theme.breakpoints.down("lg")]: {
          padding: "32px 32px",
          margin: "auto",
          gap: "16px",
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: "1440px",
          margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
          padding: "32px 16px",
        },
        [theme.breakpoints.down("md")]: {
          padding: "6px",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "50px",
        }}
      >
        <Box
          sx={{
            width: "70%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <PredictionCreateHeader headerText={"Create price predict"} />
          <PredictionCreateOptions />
        </Box>
        <PredictCreateRightPart mobile={true} />
      </Box>
    </Box>
  );
}
