import { makeStyles } from "tss-react/mui";

const infiniteFadeInOut2s = {
  "0%": {
    opacity: "0.4",
  },
  "50%": {
    opacity: "1",
  },
  "100%": {
    opacity: "0.4",
  },
};

const styles = makeStyles()((theme: any) => ({
  nightFightHeader: {
    display: "flex",
    justifyContent: "space-between",
    zIndex: 1,
    position: "relative",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      marginTop: "48px",
    },
  },
  nightFightHeaderTimer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    fontWeight: "600",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      order: 1,
      justifyContent: "center",
    },
    "& img": {
      width: "16px",
      height: "16px",
    },
    "& span:last-of-type": {
      animation: "infiniteFadeInOut2s infinite",
      "@keyframes infiniteFadeInOut2s": infiniteFadeInOut2s,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
  },
  nightFightHeaderInfo: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    fontWeight: "600",
    color: "white",
    justifyContent: "flex-end",
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  nightFightHeaderLogo: {
    "& img": {
      borderBottom: "1px solid #676767",
      paddingBottom: "14px",
      width: "180px",
    },
    "& a": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      textDecoration: "none",
      alignItems: "center",
      color: "#c5c5c5",
    },
    [theme.breakpoints.down("sm")]: {
      order: 0,
    },
  },
  nightFightHeaderLink: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    transition: "300ms",
    "&:hover": {
      color: "#cd72ee",
    },
  },
}));

export default styles;
