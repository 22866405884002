import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";

import { FCEventType } from "./constants/ufc";

import MainLayout from "./layout/MainLayout";
import Airdrop from "./pages/Airdrop";
import Earn from "./pages/Earn";
import EditProfile from "./pages/EditProfile";
import Events from "./pages/Events";
import EventsUFC from "./pages/EventsUFC";
import Home from "./pages/Home";
import LeaderBoard from "./pages/LeaderBoard";
import March from "./pages/March";
import Predictions from "./pages/Predictions";
import PredictionDetails from "./pages/Predictions/PredictionDetails";
import PredictionQuickCreate from "./pages/Predictions/PredictionQuickCreate";
import PredictionCreate from "./pages/Predictions/PredictionCreate";
import Profile from "./pages/Profile";
import Tokens from "./pages/Tokens";
import UEFA24 from "./pages/UEFA24";
import EURO24 from "./pages/EURO24";
import PredictionAdvancedCreate from "./pages/Predictions/PredictionAdvancedCreate";

function App() {
  return (
    <Box
      className="App"
      sx={{
        fontFamily: "K2D",
      }}
    >
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/predictions" element={<Predictions />} />
          <Route
            path="/predictions/detail/:id"
            element={<PredictionDetails />}
          />
          <Route
            path="/predictions/advanced-create"
            element={<PredictionAdvancedCreate />}
          />
          <Route
            path="/predictions/quick-create"
            element={<PredictionQuickCreate />}
          />
          <Route path="/predictions/create" element={<PredictionCreate />} />

          <Route path="/tokens" element={<Tokens />} />
          {/* <Route path="/tokens" element={<ComingSoon />} /> */}
          <Route path="/profile/:address" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/airdrop" element={<Airdrop />} />
          <Route path="/earn" element={<Earn />} />

          <Route path="/events" element={<Events />} />
          <Route
            path="/events/ufc299"
            element={<EventsUFC eventType={FCEventType.UFC299} />}
          />
          <Route
            path="/events/ufc300"
            element={<EventsUFC eventType={FCEventType.UFC300} />}
          />
          <Route
            path="/events/ifc"
            element={<EventsUFC eventType={FCEventType.IFC} />}
          />
          <Route
            path="/events/dazn"
            element={<EventsUFC eventType={FCEventType.DAZN} />}
          />
          <Route
            path="/events/election"
            element={<EventsUFC eventType={FCEventType.ELECTION} />}
          />
          <Route path="/events/march" element={<March />} />
          <Route path="/events/uefa2024" element={<UEFA24 />} />
          <Route path="/events/euro2024" element={<EURO24 />} />
        </Routes>
      </MainLayout>
    </Box>
  );
}

export default App;
