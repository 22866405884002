import { CssBaseline, ThemeProvider } from "@mui/material";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import injectedModule from "@web3-onboard/injected-wallets";
import { init, Web3OnboardProvider } from "@web3-onboard/react";
import trustModule from "@web3-onboard/trust";
import walletConnectModule from "@web3-onboard/walletconnect";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";

import App from "./App";
import { store } from "./app/store";
import { gameConfig } from "./config";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

import "./index.css";

TimeAgo.addDefaultLocale(en);

const container = document.getElementById("root")!;
const root = createRoot(container);

const injected = injectedModule();
const trust = trustModule();
const coinbaseWalletSdk = coinbaseWalletModule();
const walletConnect = walletConnectModule({
  projectId: "05c96d7b6183123d0d09d0b080843541",
  requiredChains: [gameConfig.chainId],
});

const wallets = [injected, trust, coinbaseWalletSdk, walletConnect];
const web3Onboard = init({
  chains: [
    {
      id: gameConfig.chainIDHex,
      token: gameConfig.chainSymbol,
      label: gameConfig.chainName,
      rpcUrl: gameConfig.providerList[0],
    },
  ],
  wallets,
  connect: { autoConnectLastWallet: true, autoConnectAllPreviousWallet: true },
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },
  theme: "dark",
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Web3OnboardProvider web3Onboard={web3Onboard}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Web3OnboardProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
