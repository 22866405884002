import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useConnectWallet } from "@web3-onboard/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";

import theme from "../../theme";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EuroMatchInfo } from "../../types/EuroMatchInfo";
import { getCountryAbbreviationByName } from "../../utils/euro";

import { finishRound } from "../../reducers/euro24.slice";
import useStyles from "./index.styles";

interface EuroRoundFinishPopupProps {
  readonly closeRoundFinishPopup: () => void;
  open: boolean;
  matchInfo?: EuroMatchInfo;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      backgroundColor: theme.palette.background.dropdown,
    },
  },
};

export default function EuroRoundFinishPopup(props: EuroRoundFinishPopupProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { open, closeRoundFinishPopup, matchInfo } = props;

  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const { isFinishing } = useAppSelector((state) => state.euro24);

  const [result, setResult] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const handleCountryChange = (event: SelectChangeEvent) => {
    setResult(event.target.value);
    setConfirmDisabled(false);
  };

  const handleClose = () => {
    closeRoundFinishPopup();
  };

  const handleConfirm = () => {
    if (matchInfo) {
      dispatch(
        finishRound({
          account,
          roundId: matchInfo.matchId,
          result: Number(result),
        })
      )
        .unwrap()
        .then(() => {
          handleClose();
        });
    }
  };

  return (
    <Box className={classes.body}>
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              backgroundColor: "#1c1f28",
              borderRadius: "10px",
              [theme.breakpoints.up("lg")]: {
                maxWidth: "420px",
              },
              [theme.breakpoints.down("sm")]: {
                maxWidth: "90%",
              },
              "& .MuiDialogContent-root": {
                [theme.breakpoints.down("sm")]: {
                  padding: "0",
                },
              },
            },
          },
        }}
      >
        <DialogContent>
          <Box className={classes.header}>
            <Box className={classes.title}>
              <Box>
                {matchInfo?.country1}
                <ReactCountryFlag
                  className={classes.matchCountryFlag}
                  countryCode={getCountryAbbreviationByName(
                    matchInfo?.country1
                  )}
                  svg
                  aria-label={matchInfo?.country1}
                />
              </Box>
              <Box>vs</Box>
              <Box>
                <ReactCountryFlag
                  className={classes.matchCountryFlag}
                  countryCode={getCountryAbbreviationByName(
                    matchInfo?.country2
                  )}
                  svg
                  aria-label={matchInfo?.country2}
                />{" "}
                {matchInfo?.country2}
              </Box>
            </Box>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.inputBox}>
              <Select
                displayEmpty
                value={result}
                onChange={handleCountryChange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Please select Winner</em>
                </MenuItem>

                <MenuItem key={0} value={0} className={classes.selectMenuItem}>
                  <ReactCountryFlag
                    className={classes.menuItemFlag}
                    countryCode={getCountryAbbreviationByName(
                      matchInfo?.country1
                    )}
                    svg
                    aria-label={matchInfo?.country1}
                  />
                  <Box>{matchInfo?.country1}</Box>
                </MenuItem>
                <MenuItem key={1} value={1} className={classes.selectMenuItem}>
                  <ReactCountryFlag
                    className={classes.menuItemFlag}
                    countryCode={getCountryAbbreviationByName(
                      matchInfo?.country2
                    )}
                    svg
                    aria-label={matchInfo?.country2}
                  />
                  <Box>{matchInfo?.country2}</Box>
                </MenuItem>
              </Select>
            </Box>

            <Box className={classes.confirmButton}>
              <Button
                disabled={confirmDisabled || isFinishing}
                onClick={handleConfirm}
              >
                {isFinishing ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  "Confirm"
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
