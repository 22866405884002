import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import theme from '../../theme';
import useStyles from './index.styles';
import { BetToken } from '../../helpers/contractFunctions';
import { toUSDFormat } from '../../utils';
import { useState } from 'react';

interface TokenTakerDialogProps {
  betTokens: BetToken[];
  tokenBalances: { [key: string]: string };
  usdAmount: number;
  open: boolean;
  processing: boolean;
  onClose: () => void;
  onAccept: (tokenId: number, tokenAmount: number) => void;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const TokenTakerDialog = (props: TokenTakerDialogProps) => {
  const { classes } = useStyles();
  const { tokenBalances, betTokens, usdAmount, open, processing, onClose, onAccept } = props;
  const tokenBalancesWithAssertion = tokenBalances as { [key: string]: string };
  const [currentToken, setCurrentToken] = useState(0);

  const sortedBetTokens = betTokens
    .slice()
    .map((token) => ({
      ...token,
      usdAmount: (parseFloat(tokenBalancesWithAssertion[token.name]) || 0) * (token.price || 0),
      balance: parseFloat(tokenBalancesWithAssertion[token.name]) || 0,
    }))
    .sort((a, b) => {
      const usdAmountA = a.price * a.balance;
      const usdAmountB = b.price * b.balance;
      return usdAmountB - usdAmountA;
    });

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            minHeight: '500px',
            backgroundColor: '#1c1f28',
            padding: '20px 10px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '300px',
              minHeight: '300px',
            },
            '& .MuiDialogContent-root': {
              [theme.breakpoints.down('sm')]: {
                padding: '0',
              },
            },
          },
        },
      }}
    >
      <DialogContent>
        <Box className={classes.row}>
          <Box>Asset</Box>
          <Box>Balance</Box>
          <Box>Use</Box>
        </Box>
        <Box className={classes.tableContainer}>
          {sortedBetTokens.map((token, index) => (
            <Box className={classes.row} key={token.name}>
              <Box>
                <Box component="img" src={`/images/tokens/${token.name?.toLowerCase()}.png`} />
                <Box>{token.name}</Box>
              </Box>
              <Box>
                <Box>{token.balance.toFixed(4)}</Box>
                <Box>$ {token.usdAmount.toFixed(4)}</Box>
              </Box>
              <Box>
                <Button
                  disabled={processing}
                  className={clsx({ tokenDisabled: usdAmount > token.usdAmount })}
                  onClick={() => {
                    const amount = (usdAmount / token.price) * 1.2;
                    onAccept(token.id, amount);
                    setCurrentToken(token.id);
                  }}
                >
                  ${toUSDFormat(usdAmount)}
                  {processing && currentToken == token.id && <CircularProgress size={15} sx={{ color: 'white', marginLeft: '3px' }} /> }
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default TokenTakerDialog;
