import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppBar, Skeleton, Tabs, Tab, Box, Pagination } from "@mui/material";
import theme from "../../theme";
import useStyles from "./index.styles";
import { useConnectWallet } from "@web3-onboard/react";

import Dot from "../Dot";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  cancelRound,
  getPriceTokens,
  getRoundMaxValue,
  getRounds,
  setStatusForRounds,
} from "../../reducers/predict.slice";
import {
  PredictRoundStatus,
  PredictSortStatus,
  PredictionGettingRoundsType,
} from "../../constants/prediction";
import CardDetail from "../CardDetail";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        width: "100%",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function RoundDetail() {
  const { classes } = useStyles();
  const [status, setStatus] = useState(0);
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const [page, setPage] = useState(1);
  const [tabValue, setTabVale] = useState(0);
  const { address } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(1);
    setTabVale(newValue);
  };
  const {
    rounds,
    loadingRounds,
    myRoundCountPerPage,
    totalRoundsCount,
    liveTotalRoundsCount,
    roundMaxValue,
  } = useAppSelector((state) => state.predict);

  const dispatch = useAppDispatch();

  const handleWithdrawFunds = (roundId: number) => {
    dispatch(cancelRound({ roundId, account }))
      .unwrap()
      .then(() => {
        dispatch(
          setStatusForRounds({ roundId, status: PredictRoundStatus.Canceled })
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getRoundMaxValue());
    };

    fetchData();

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getRounds({
        statusValue: 1,
        sortValue: 0,
        coinValue: [],
        minValue: 0,
        maxValue: roundMaxValue,
        typeValue: "all",
        activePage: 1,
        countPerPage: 1,
        roundsType: PredictionGettingRoundsType.LiveRoundsProfile,
        initStatus: true,
        player: address,
      })
    );
  }, [address, roundMaxValue]);

  useEffect(() => {
    dispatch(
      getRounds({
        statusValue: status,
        sortValue: PredictSortStatus.Expiry,
        coinValue: [],
        minValue: 0,
        maxValue: roundMaxValue,
        activePage: page,
        countPerPage: myRoundCountPerPage,
        player: address,
        typeValue: "all",
        roundsType: PredictionGettingRoundsType.NormalRoundsProfile,
        initStatus: true,
      })
    );
  }, [status, dispatch, page, address, roundMaxValue]);

  useEffect(() => {
    dispatch(getPriceTokens());
  }, []);

  useEffect(() => {
    if (tabValue === 0) {
      setStatus(PredictRoundStatus.Waiting);
    } else if (tabValue === 1) {
      setStatus(PredictRoundStatus.Started);
    } else if (tabValue === 2) {
      setStatus(PredictRoundStatus.Finished);
    } else if (tabValue === 3) {
      setStatus(PredictRoundStatus.ExpireOrCanceled);
    }
  }, [tabValue]);

  return (
    <Box className={classes.roundDetail}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.background.default,
          boxShadow: "none",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.background.card,
          borderRadius: "10px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor={theme.palette.background.card}
          textColor="inherit"
          variant="fullWidth"
          sx={{
            color: theme.palette.text.disabled,
            "& .Mui-selected": {
              backgroundColor: theme.palette.background.card,
              borderRadius: "10px",
            },
            "& .MuiButtonBase-root": {
              textTransform: "none",
            },
          }}
        >
          <Tab
            label={
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Dot
                  sx={{
                    backgroundColor: "#1eff00",
                    width: "10px",
                    height: "10px",
                  }}
                ></Dot>
                Open
              </Box>
            }
            {...a11yProps(PredictRoundStatus.Waiting)}
            defaultChecked={true}
          />
          <Tab
            label={
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Dot
                  sx={{
                    backgroundColor: "#ff1b1b",
                    width: "10px",
                    height: "10px",
                  }}
                ></Dot>
                Live now
                <Box
                  sx={{
                    width: "23px",
                    height: "23px",
                    borderRadius: "50%",
                    color: "white",
                    backgroundColor: "#f98080",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    right: "-30px",
                    top: "-10px",
                  }}
                >
                  {liveTotalRoundsCount}
                </Box>
              </Box>
            }
            {...a11yProps(PredictRoundStatus.Started)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={status} index={status}>
        <Box className={classes.tabBoxes}>
          <Box className={classes.tabBox}>
            {loadingRounds ? (
              <>
                <Skeleton variant="rectangular" className={classes.skeleton} />
                <Skeleton variant="rectangular" className={classes.skeleton} />
              </>
            ) : rounds.length === 0 ? (
              <Box className={classes.noRounds}>No Rounds</Box>
            ) : (
              rounds.map((round, index) => {
                return (
                  <CardDetail
                    key={index}
                    round={round}
                    isForProfile={true}
                    withdrawFunds={handleWithdrawFunds}
                    sx={{
                      width: "100%",
                    }}
                  />
                );
              })
            )}
          </Box>
        </Box>
        {rounds.length === 0 ? null : (
          <Box className={classes.paginationBody}>
            <Pagination
              count={Math.ceil(totalRoundsCount / myRoundCountPerPage)}
              defaultValue={1}
              page={page}
              variant="outlined"
              color="primary"
              onChange={(e, value) => setPage(value)}
            />
          </Box>
        )}
      </TabPanel>
    </Box>
  );
}
