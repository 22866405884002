import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";

import theme from "../../theme";
import useStyles from "./index.styles";
import { PredictToken } from "../../reducers/predict.slice";

interface CustomDropdownProps {
  readonly items: PredictToken[];
  readonly sx?: object;
  readonly value: string[];
  readonly setValue: (value: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PredictMultiSelect(props: CustomDropdownProps) {
  const { classes } = useStyles();

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const {
      target: { value },
    } = event;
    props.setValue(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-multiple-checkbox-label"></InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          value={props.value}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <>All</>;
            }
            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.background.dropdown,
                  maxHeight: "300px",
                  "&::-webkit-scrollbar": {
                    width: "3px",
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "silver",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "grey",
                  },
                },
              },
              children: {
                sx: {
                  fontSize: "12px",
                },
              },
            },
          }}
          IconComponent={ExpandMore}
        >
          {props.items.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              <Checkbox
                checked={
                  props.value.length === 0 ||
                  props.value.indexOf(item.name) > -1
                }
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "5px",
                }}
              >
                <Box>{item.token}</Box>-
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={`/images/prediction/${item.chain}.png`}
                    width={15}
                  ></Box>
                  {item.chain}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
