import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
  },
  predictionTitle: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    width: "100%",
    justifyContent: "start",
    "& img": {
      width: "22px",
    },
    fontSize: "17px",
  },
  predictionCard: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      minHeight: "300px",
    },
  },
  creatorAddress: {
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    gap: "15px",
    marginTop: "20px",
    "&>div:first-of-type": {
      color: theme.palette.text.disabled,
    },
  },
  commentTitle: {
    display: "flex",
    gap: "20px",
    marginTop: "30px",
    width: "100%",
    justifyContent: "start",
    "& img": {
      width: "22px",
    },
    fontSize: "17px",
  },
  commentBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "150px",
    backgroundColor: theme.palette.background.predictionCard,
    marginTop: "15px",
    borderRadius: "10px",
    color: theme.palette.text.stormGrey,
    fontSize: "18px",
    position: "relative",
    width: "100%",
  },
  commentPlaceholder: {
    position: "absolute",
    zIndex: 0,
    padding: "10px 20px",
  },
  commentInput: {
    width: "100%",
    height: "150px",
    border: "none",
    zIndex: 1,
    "& fieldset": {
      border: "none",
      "&::-webkit-scrollbar": {
        width: "4px",
      },

      "&::-webkit-scrollbar-track": {
        width: "4px",
        boxShadow: "inset 0 0 5px grey",
        borderRadius: "10px",
        border: "solid 3px rgba(255, 0, 0, 0)",
      },

      "&::-webkit-scrollbar-thumb": {
        width: "4px",
        borderRadius: "10px",
        background: "#34344b",
      },
    },
  },
  copyLink: {
    color: theme.palette.text.primary,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.background.card,
    borderRadius: "9px",
    padding: "10px 20px",
    display: "flex",
    textTransform: "none",
    gap: "15px",
    width: "100%",
    marginTop: "10px",
    "& img": {
      width: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 5px",
    },
  },

  comment: {
    backgroundColor: theme.palette.background.predictionCard,
    width: "100%",
    padding: "24px 32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 180,
    marginTop: 18,
    borderRadius: 12,
    color: "#8786a3",
    fontSize: "20px",
  },
}));

export default styles;
