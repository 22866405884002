import React from 'react';
import { Box } from '@mui/material';
import MyProfile from '../../components/MyProfile';

const EditProfile = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
      width: '100%',
      alignItems: 'center',
    }}>
      <MyProfile />
    </Box>
    
  )
}

export default EditProfile;