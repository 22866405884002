import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "40px",
    paddingRight: "6%",
    paddingLeft: "6%",
  },
  statusUnit: {
    display: "grid",
    gridTemplateColumns: "4fr 5fr",
    margin: "13px 0px",
  },
  statusUnitLabel: {
    padding: "0px 6px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& div": {
      display: "flex",
    },

    "& h6": {
      fontSize: "1.5rem",
      color: theme.palette.text.primary,
    },
    "& p": {
      fontSize: "13px",
      paddingLeft: "5px",
      paddingTop: "5px",
      color: theme.palette.text.primary,
      display: "flex",
      alignItems: "center",
    },
    "& img": {
      marginTop: 5,
    },
  },
  selectEmpty: {
    width: "100%",
    padding: "10px 15px 10px 30px",
    color: theme.palette.text.primary,
    "&:before": {
      borderBottom: `2px solid ${theme.palette.text.primary}`,
    },
    "& .MuiSelect-select": {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "12px",
      "& img": {
        marginRight: "5px",
      },
    },
  },
  select: {
    background: theme.palette.text.primary,

    "& ul": {
      background: theme.palette.text.primary,
    },
  },
  assetOption: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    "& img": {
      marginRight: "10px",
    },
  },
  assetOption_1: {
    display: "flex",
    justifyContent: "flex-start",
    color: `${theme.palette.text.primary} !important`,
    fontSize: "0.8rem !important",
    "& img": {
      marginRight: "10px",
    },
  },
  accuracySelect: {
    width: "100%",
    padding: "5px 15px 5px 30px",
    color: theme.palette.text.primary,
    borderRadius: "15px",
    background: theme.palette.text.primary,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&:before": {
      borderBottom: `none !important`,
    },
    "&:after": {
      borderBottom: `none !important`,
    },
    "&:hover": {
      borderBottom: `none !important`,
    },
    "& .MuiSelect-select": {
      display: "flex",
      justifyContent: "flex-start",
      paddingRight: "12px",
      fontSize: "0.8rem",

      "& img": {
        marginRight: "10px",
      },
    },
  },
  priceInput: {
    width: "100%",
    color: theme.palette.text.primary,
    borderRadius: "15px",
    position: "relative",
    background: theme.palette.background.card,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: `none !important`,
    },
    "& .MuiFilledInput-underline": {
      borderBottom: `none !important`,
      background: "transparent",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: `none !important`,
    },
    "& .MuiFilledInput-underline:hover": {
      borderBottom: `none !important`,
    },
    "& .MuiFilledInput-input": {
      borderRadius: "15px",
      fontSize: "1rem",
      padding: "5px 20px 5px 60px",
      height: "30px",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  controlPriceBtnGrouup: {
    display: "flex",
    flexDirection: "column",
    width: "30px",
    position: "absolute",
    left: "92%",
    transform: "translateX(-50%)",
    top: "8%",
  },
  controlPriceBtn: {
    minWidth: "30px",
    padding: "1px",
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
  },

  inputField: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  tagImg: {
    left: "16px",
    position: "absolute",
  },

  selectedAssetBtn: {
    width: "100%",
    textTransform: "inherit",
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },

  selectedAssetDiv: {
    width: "100%",
    justifyContent: "space-around",
    color: theme.palette.text.primary,
    display: "flex",
  },
  selectedToken: {
    display: "flex",
    fontSize: "0.8rem",
    alignItems: "center",
    "& img": {
      marginRight: "5px",
    },
  },
  selectedchain: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.6rem",
    "& img": {
      marginRight: "5px",
    },
  },
  down: {
    marginLeft: "10px",
  },
  emptyDiv: {
    width: "100%",
    height: "50px",
    borderRaidus: "15px",
    background: theme.palette.background.default,
    "&:hover": {
      background: theme.palette.background.default,
    },
  },
  caption: {
    color: theme.palette.text.primary,
    fontSize: 12,
    position: "absolute",
    right: 40,
    top: 15,
  },
  tooltip: {
    fontSize: 12,
    color: "white",
    width: 250,
    "& p": {
      margin: "5px 0px",
      textIndent: 6,
      textAlign: "justify",
    },
  },
  popoverContent: {
    background: `black !important`,
    color: "white !important",
    width: "250px !important",
    padding: "15px !important",

    "& span": {
      borderColor: `black !important`,
      color: "white !important",
    },
  },
  infoImg: {
    [theme.breakpoints.down("xs")]: {},
  },
  confirmDiv: {
    display: "flex",
    flexDirection: "column",
  },
}));
