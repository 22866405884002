import { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { LastPriceAnimationMode, createChart } from "lightweight-charts";
import type { LineData, SeriesMarker, Time } from "lightweight-charts";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getProfitLossChartValues } from "../../reducers/user.slice";
import { useParams } from "react-router-dom";
import { toUSDFormat } from "../../utils";

export default function PredictionProfitLossChart() {
  const dispatch = useAppDispatch();
  const { profitLossChartValues, loadingProfitLossChartValues } =
    useAppSelector((state) => state.user);
  const { address } = useParams();

  useEffect(() => {
    address && dispatch(getProfitLossChartValues(address));
  }, [address, dispatch]);

  useEffect(() => {
    if (profitLossChartValues.length === 0) return;
    const newChartElement = document.createElement("div");
    newChartElement.style.width = "100%";
    newChartElement.style.height = "100%";
    newChartElement.style.minHeight = "300px";
    newChartElement.style.position = "relative";

    const tooltip = document.createElement("div");
    tooltip.style.position = "absolute";
    tooltip.style.display = "none";
    tooltip.textContent = "aaaaaaa";
    tooltip.style.zIndex = "100";
    tooltip.style.color = "yellow";
    newChartElement.appendChild(tooltip);

    const chartContainer = document.getElementById("profitLossChartContainer");
    if (chartContainer) {
      chartContainer.appendChild(newChartElement);
    } else return;

    const chart = createChart(newChartElement, {
      layout: {
        background: { color: "#0d131c" },
        textColor: "#DDD",
      },
      grid: {
        vertLines: { color: "#44444475" },
        horzLines: { color: "#44444475" },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.2,
          bottom: 0,
        },
      },
      timeScale: {
        secondsVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          const date = new Date(parseInt(time) * 1000);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so we add 1
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const formattedTime = `${month}-${day} ${hours}:${minutes}`;
          return formattedTime;
        },
      },
    });
    const areaSeries = chart.addAreaSeries({
      lineColor: "#e0a119",
      lastPriceAnimation: LastPriceAnimationMode.Continuous,
      priceLineVisible: false,
      lineWidth: 3,
      lineType: 2,
      lastValueVisible: false,
      priceFormat: {
        type: "price",
        precision: 0,
        minMove: 1,
      },
    });
    const data: LineData[] = profitLossChartValues.map(
      (item) =>
        ({
          time: item.timestamp as Time,
          value: item.profit,
        } as LineData)
    );
    areaSeries.setData(data);
    const step =
      Math.floor(data.length / 10) === 0 ? 1 : Math.floor(data.length / 10);
    const markers: SeriesMarker<Time>[] = [];
    data.forEach((point, index) => {
      if (index === data.length - 1) {
        markers.push({
          time: point.time,
          position: "inBar",
          color: "yellow",
          shape: "circle",
          size: 0.3,
          text: point.value.toFixed(2) + "$",
        });
      } else {
        if (index % step === 0) {
          markers.push({
            time: point.time,
            position: "inBar",
            color: "yellow",
            shape: "circle",
            size: 0.3,
            text: "",
          });
        }
      }
    });
    areaSeries.setMarkers(markers);

    const startTime = Number(data[0].time);
    const endTime = Number(data[data.length - 1].time);
    const timeRange = endTime - startTime;
    chart.timeScale().setVisibleRange({
      from: (startTime - timeRange * 0.1) as Time,
      to: (endTime + timeRange * 0.1) as Time,
    });

    chart.subscribeCrosshairMove((param) => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.y < 0
      ) {
        tooltip.style.display = "none";
      } else {
        const price = param.seriesData.values().next().value.value;
        tooltip.textContent = ((toUSDFormat(price) ?? "0") + "$") as string;
        tooltip.style.display = "block";
        tooltip.style.left = param.point.x - 10 + "px";
        tooltip.style.top = param.point.y - 40 + "px";
      }
    });

    return () => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
    };
  }, [profitLossChartValues]);
  return (
    <>
      {loadingProfitLossChartValues ? (
        <Skeleton width={"100%"} height={300}></Skeleton>
      ) : (
        <Box
          id={"profitLossChartContainer"}
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "30px",
              left: "30px",
              zIndex: 100,
              color: "#444E68",
            }}
          >
            Profit / Loss
          </Box>
        </Box>
      )}
    </>
  );
}
