import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./index.styles";
import {
  madnessStatus,
  madnessPlay,
  MarchMadnessRoundStatus,
} from "../../constants";
import { useAppSelector } from "../../app/hooks";

import theme from "../../theme";
import { Entry } from "../../reducers/march.slice";

interface MarchCardProps {
  readonly status: number;
  readonly name: string;
  readonly avatar: string;
  readonly playerIndex: number;
  readonly gameIndex: number;
  readonly grade: number;
  readonly groupStatus?: number;
  readonly pickClick?: (
    grade: number,
    gameIndex: number,
    playerIndex: number
  ) => void;
  readonly closeClick?: (
    grade: number,
    gameIndex: number,
    playerIndex: number
  ) => void;
  readonly getMatchStatus: (grade: number, gameIndex: number) => number;
  readonly championId: number;
  readonly isTicketView: boolean;
  readonly gameResult?: number;
  readonly isFinishedTicketView: boolean;
}

export default function MarchCard(props: MarchCardProps) {
  const { gameResults, entries, roundStatus } = useAppSelector(
    (state) => state.marchMadness
  );
  // const roundStatus = 2;

  const { classes } = useStyles();
  let statusClass = "";
  const status = props.status;

  const nextGameIndex = Math.ceil((props.gameIndex + 1) / 2) - 1;
  const latestWinner = props.getMatchStatus(props.grade, nextGameIndex);

  const getPercent = () => {
    const gameCount = entries.length;
    let matchIndex = Math.ceil((props.gameIndex + 1) / 2) - 1;
    if (props.grade === madnessPlay.round16) {
    } else if (props.grade === madnessPlay.quarter) {
      matchIndex += 8;
    } else if (props.grade === madnessPlay.semi) {
      matchIndex += 12;
    } else if (
      props.grade === madnessPlay.final ||
      props.grade === madnessPlay.winner
    ) {
      matchIndex += 14;
    }
    let totWinnerCount = 0;
    if (props.status === madnessStatus.waiting) {
      return -1;
    } else if (entries && entries.length > 0) {
      totWinnerCount = entries.filter((entry: Entry) => {
        return Number(entry.predictions[matchIndex]) === props.playerIndex + 1;
      }).length;
      return ((totWinnerCount / gameCount) * 100).toFixed(0);
    } else {
      return 0;
    }
  };

  if (status === madnessStatus.waiting) {
    statusClass = "waiting";
  } else if (status === madnessStatus.enable) {
    statusClass = "enable";
  } else if (status === madnessStatus.winner) {
    statusClass = "winner";
  } else if (status === madnessStatus.loser) {
    statusClass = "loser";
  }

  let side = "";
  if (
    (props.grade === madnessPlay.round16 && props.gameIndex >= 8) ||
    (props.grade === madnessPlay.quarter && props.gameIndex >= 4) ||
    (props.grade === madnessPlay.semi && props.gameIndex >= 2)
  ) {
    statusClass += " right";
    side = "right";
  } else {
    statusClass += " left";
    side = "left";
  }

  return (
    <Box
      className={
        classes.body +
        " " +
        statusClass +
        " " +
        (props.grade === madnessPlay.quarter
          ? "quarter"
          : props.grade === madnessPlay.semi
          ? "semi"
          : props.grade === madnessPlay.final
          ? "final"
          : props.grade === madnessPlay.winner
          ? "champion"
          : "") +
        " " +
        (props.grade > madnessPlay.round16 &&
        props.playerIndex === props.championId
          ? "championLine"
          : "") +
        (props.gameResult &&
        props.playerIndex === props.gameResult - 1 &&
        roundStatus >= MarchMadnessRoundStatus.Closed &&
        props.isFinishedTicketView
          ? " winnerCorrect"
          : "")
      }
    >
      {side === "right" ? (
        <Box
          onClick={
            window.matchMedia("(max-width: 768px)").matches &&
            status === madnessStatus.enable &&
            props.groupStatus &&
            props.groupStatus === madnessStatus.enable
              ? () =>
                  props.pickClick &&
                  props.pickClick(
                    props.grade,
                    props.gameIndex,
                    props.playerIndex
                  )
              : () => {}
          }
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box className={classes.percent + " " + statusClass}>
            {props.gameResult &&
            props.playerIndex === props.gameResult - 1 &&
            roundStatus === MarchMadnessRoundStatus.Closed &&
            props.isFinishedTicketView ? (
              <Box component="span" className={classes.resultUpCount}>
                + 1
              </Box>
            ) : getPercent() !== -1 ? (
              getPercent() + "%"
            ) : null}
          </Box>
          <Box
            sx={{
              width: "105px",
              display: "flex",
              gap: "5px",
              padding: "0 3px",
              paddingRight: "5px",
            }}
          >
            <Box
              className={classes.avatar + " " + statusClass}
              component="img"
              src={`/images/events/madness/${props.avatar}`}
            />
            <Box
              className={
                classes.name +
                " " +
                (props.gameResult &&
                props.playerIndex === props.gameResult - 1 &&
                roundStatus === MarchMadnessRoundStatus.Closed &&
                props.isFinishedTicketView
                  ? " winner"
                  : "") +
                statusClass
              }
            >
              {status === madnessStatus.waiting ? (
                props.gameIndex % 2 === 0 &&
                props.grade === madnessPlay.quarter ? (
                  "Quarter"
                ) : props.gameIndex % 2 === 0 &&
                  props.grade === madnessPlay.semi ? (
                  "Semi"
                ) : props.gameIndex % 2 === 0 &&
                  props.grade === madnessPlay.final ? (
                  "Final"
                ) : props.gameIndex % 2 === 0 &&
                  props.grade === madnessPlay.winner ? (
                  <Box sx={{ color: theme.palette.text.winnerText }}>
                    Winner
                  </Box>
                ) : (
                  "Waiting"
                )
              ) : (
                props.name
              )}
            </Box>
          </Box>
          <Box
            className={
              classes.checkIcon +
              " " +
              (props.isFinishedTicketView ? statusClass : "loser") +
              (props.gameResult &&
              props.playerIndex === props.gameResult - 1 &&
              roundStatus === MarchMadnessRoundStatus.Closed &&
              props.isFinishedTicketView
                ? " winner"
                : "")
            }
          >
            {status === madnessStatus.winner ? (
              <Box component="img" src="/images/icons/check_increase.png" />
            ) : status === madnessStatus.enable &&
              props.groupStatus &&
              props.groupStatus === madnessStatus.enable ? (
              <Box
                onClick={() =>
                  props.pickClick &&
                  props.pickClick(
                    props.grade,
                    props.gameIndex,
                    props.playerIndex
                  )
                }
                className={
                  classes.pickBut +
                  " " +
                  (roundStatus != MarchMadnessRoundStatus.Started
                    ? classes.hide
                    : "")
                }
              >
                PICK
              </Box>
            ) : props.grade === madnessPlay.winner ? (
              <Box component="img" src="/images/icons/medal.png" />
            ) : (
              <Box component="img" src="/images/icons/check_increase.png" />
            )}
          </Box>
        </Box>
      ) : (
        <Box
          onClick={
            window.matchMedia("(max-width: 768px)").matches &&
            status === madnessStatus.enable &&
            props.groupStatus &&
            props.groupStatus === madnessStatus.enable
              ? () =>
                  props.pickClick &&
                  props.pickClick(
                    props.grade,
                    props.gameIndex,
                    props.playerIndex
                  )
              : () => {}
          }
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            className={
              classes.checkIcon +
              " " +
              (props.isFinishedTicketView ? statusClass : "loser") +
              (props.gameResult &&
              props.playerIndex === props.gameResult - 1 &&
              roundStatus === MarchMadnessRoundStatus.Closed &&
              props.isFinishedTicketView
                ? " winner"
                : "")
            }
          >
            {status === madnessStatus.winner ? (
              <Box component="img" src="/images/icons/check_increase.png" />
            ) : status === madnessStatus.enable &&
              props.groupStatus &&
              props.groupStatus === madnessStatus.enable ? (
              <Box
                onClick={() =>
                  props.pickClick &&
                  props.pickClick(
                    props.grade,
                    props.gameIndex,
                    props.playerIndex
                  )
                }
                className={
                  classes.pickBut +
                  " " +
                  (roundStatus != MarchMadnessRoundStatus.Started
                    ? classes.hide
                    : "")
                }
              >
                PICK
              </Box>
            ) : props.grade === madnessPlay.winner ? (
              <Box component="img" src="/images/icons/medal.png" />
            ) : (
              <Box component="img" src="/images/icons/check_increase.png" />
            )}
          </Box>
          <Box
            sx={{
              width: "105px",
              display: "flex",
              gap: "5px",
              padding: "0 3px",
            }}
          >
            <Box
              className={classes.avatar + " " + statusClass}
              component="img"
              src={`/images/events/madness/${props.avatar}`}
            />
            <Box
              className={
                classes.name +
                " " +
                ((
                  props.gameResult &&
                  props.playerIndex === props.gameResult - 1 &&
                  roundStatus === MarchMadnessRoundStatus.Closed &&
                  props.isFinishedTicketView
                    ? " winner"
                    : ""
                )
                  ? " winner"
                  : "") +
                statusClass
              }
            >
              {status === madnessStatus.waiting ? (
                props.gameIndex % 2 === 0 &&
                props.grade === madnessPlay.quarter ? (
                  "Quarter"
                ) : props.gameIndex % 2 === 0 &&
                  props.grade === madnessPlay.semi ? (
                  "Semi"
                ) : props.gameIndex % 2 === 0 &&
                  props.grade === madnessPlay.final ? (
                  "Final"
                ) : props.gameIndex % 2 === 0 &&
                  props.grade === madnessPlay.winner ? (
                  <Box sx={{ color: theme.palette.text.winnerText }}>
                    Winner
                  </Box>
                ) : (
                  "Waiting"
                )
              ) : (
                props.name
              )}
            </Box>
          </Box>
          <Box className={classes.percent + " " + statusClass}>
            {props.gameResult &&
            props.playerIndex === props.gameResult - 1 &&
            roundStatus === MarchMadnessRoundStatus.Closed &&
            props.isFinishedTicketView ? (
              <Box component="span" className={classes.resultUpCount}>
                + 1
              </Box>
            ) : getPercent() !== -1 ? (
              getPercent() + "%"
            ) : null}
          </Box>
        </Box>
      )}

      {latestWinner === 1 &&
      props.status === madnessStatus.winner &&
      !props.isTicketView ? (
        <CloseIcon
          className={classes.closeBut + " " + statusClass}
          onClick={() =>
            props.closeClick &&
            props.closeClick(props.grade, props.gameIndex, props.playerIndex)
          }
        />
      ) : null}
    </Box>
  );
}
