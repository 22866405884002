import React, { useState, useEffect, useRef } from "react";
import { Box, Skeleton, Button } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import { useNavigate } from "react-router-dom";

import Blocky from "../Blocky";
import { useAppSelector } from "../../app/hooks";
import { shortAddress, shortName, Toast } from "../../utils";
import TokenTakerDialogWithoutEnter from "../TokenTakerDialogWithoutEnter";
import { BetToken } from "../../helpers/contractFunctions";
import { PlayerStatus, PredictRoundStatus } from "../../constants/prediction";
import { gameConfig } from "../../config";
import { calculatePercent } from "../../utils/predict";

interface PredictBetSectionProps {
  readonly roundInfo: any;
  readonly enteringToken: BetToken | null;
  readonly usdAmount: number;
  readonly setEnteringToken: (enteringToken: BetToken) => void;
  readonly fakePrice: number;
}

export default function PredictBetSection(props: PredictBetSectionProps) {
  const { loadingMetaInfo, loadingRoundInfo, betTokens, tokenBalances } =
    useAppSelector((state) => state.predict);
  const { profiles } = useAppSelector((state) => state.user);
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const [selectButAnchor, setSelectButAnchor] =
    useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const profile1 = profiles.find(
    (profile) =>
      profile.address.toLowerCase() === props.roundInfo.creator?.toLowerCase()
  );
  const profile2 =
    props.roundInfo.bets &&
    props.roundInfo.bets.length >= 2 &&
    profiles.find(
      (profile) =>
        profile.address.toLowerCase() ===
        props.roundInfo.bets[1].player.toLowerCase()
    );

  const toProfilePage = (address: string) => {
    navigate(`/profile/${address}`);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => toProfilePage(props.roundInfo.creator)}
        >
          {profile1 && profile1.avatar.length > 0 ? (
            <Box
              component={"img"}
              src={profile1.avatar}
              alt=""
              width={70}
              height={70}
              sx={{
                borderRadius: "50%",
              }}
            />
          ) : (
            <Blocky
              address={props.roundInfo?.creator}
              size={70}
              scale={1}
              sx={{
                borderRadius: "50%",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => toProfilePage(props.roundInfo.creator)}
        >
          {loadingRoundInfo || loadingMetaInfo ? (
            <Skeleton animation="wave" variant="rectangular" width={170} />
          ) : profile1 ? (
            profile1.name.length > 15 ? (
              shortName(profile1.name)
            ) : (
              profile1.name
            )
          ) : props.roundInfo.creator ? (
            shortAddress(props.roundInfo.creator)
          ) : null}
        </Box>
      </Box>
      <Box>
        {props.roundInfo.status === PredictRoundStatus.Finished ||
        props.roundInfo.status === PredictRoundStatus.Expired ? (
          <a
            href={`${gameConfig.explorerUrl}tx/${props.roundInfo.txHash}`}
            target="__blank"
          >
            <Button
              sx={{
                textTransform: "none",
                backgroundColor: "#419657",
                borderRadius: "8px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#317242",
                },
              }}
            >
              View transaction
            </Button>
          </a>
        ) : null}
      </Box>
      <Box
        sx={{
          opacity:
            props.roundInfo.status === PredictRoundStatus.Canceled ||
            props.roundInfo.status === PredictRoundStatus.Expired
              ? 0.5
              : 1,
        }}
      >
        {props.roundInfo &&
        (props.roundInfo.status === PredictRoundStatus.Waiting ||
          props.roundInfo.status === PredictRoundStatus.Canceled ||
          props.roundInfo.status === PredictRoundStatus.Expired) ? (
          <>
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() =>
                props.roundInfo.bets[1] &&
                toProfilePage(props.roundInfo.bets[1].player)
              }
            >
              <Button
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  if (
                    props.roundInfo.status !== PredictRoundStatus.Canceled &&
                    props.roundInfo.status !== PredictRoundStatus.Expired
                  ) {
                    if (account) {
                      setSelectButAnchor(event.currentTarget);
                    } else {
                      Toast.fire({
                        icon: "warning",
                        title: "Please connect wallet.",
                      });
                    }
                  }
                }}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {props.enteringToken ? (
                  <Box
                    width={45}
                    component="img"
                    src={`/images/tokens/${props.enteringToken.name.toLowerCase()}.png`}
                  />
                ) : (
                  <Box
                    component="img"
                    src="/images/icons/plus.png"
                    width={45}
                  />
                )}
              </Button>
              <Box
                sx={{
                  opacity:
                    props.roundInfo.status === PredictRoundStatus.Canceled ||
                    props.roundInfo.status === PredictRoundStatus.Expired
                      ? 0
                      : 1,
                }}
              >
                <Box
                  component="img"
                  src="/images/icons/left-arrow1.png"
                  sx={{
                    width: "10px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "15px",
                  }}
                >
                  Select token
                </Box>
              </Box>
            </Box>
            <TokenTakerDialogWithoutEnter
              betTokens={betTokens}
              tokenBalances={tokenBalances}
              usdAmount={props.usdAmount}
              butAnchor={selectButAnchor}
              onClose={setSelectButAnchor}
              setEnteringToken={(token: BetToken) =>
                props.setEnteringToken(token)
              }
            />

            {props.roundInfo.status !== PredictRoundStatus.Expired ? (
              <Box sx={{ fontSize: "23px" }}>Waiting</Box>
            ) : (
              <Box sx={{ fontSize: "20px" }}>Nobody accepted</Box>
            )}
          </>
        ) : props.roundInfo && props.roundInfo.bets?.length >= 2 ? (
          <>
            <Box
              onClick={() => toProfilePage(props.roundInfo.bets[1].player)}
              sx={{
                cursor: "pointer",
              }}
            >
              {profile2 && profile2.avatar.length > 0 ? (
                <Box
                  component={"img"}
                  src={profile2.avatar}
                  alt=""
                  width={70}
                  height={70}
                  sx={{
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <Blocky
                  address={props.roundInfo.bets[1].player}
                  size={70}
                  scale={1}
                  sx={{
                    borderRadius: "50%",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                fontSize: "17px",
                cursor: "pointer",
              }}
              onClick={() => toProfilePage(props.roundInfo.bets[1].player)}
            >
              {loadingRoundInfo || loadingMetaInfo ? (
                <Skeleton animation="wave" variant="rectangular" width={170} />
              ) : profile2 ? (
                profile2.name.length > 15 ? (
                  shortName(profile2.name)
                ) : (
                  profile2.name
                )
              ) : (
                shortAddress(props.roundInfo.bets[1].player)
              )}
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
