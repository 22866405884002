export const uefa24RoundStatus = {
  Waiting: 0,
  Started: 1,
  Closed: 2,
  Finishing: 3,
  Fished: 4,
};

export const uefa24Status = {
  WAITING: 0,
  ENABLE: 1,
  WINNER: 2,
  LOSER: 3,
};

export const uefa24PairStatus = {
  PICKED: 0,
  PICKABLE: 1,
};

export const uefa24Play = {
  QUARTER: 1,
  SEMI: 2,
  FINAL: 3,
  WINNER: 4,
};
