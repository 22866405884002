import { makeStyles } from "tss-react/mui";

const arrowAnimation = {
  "0%": {
    paddingTop: "30px",
  },
  "50%": {
    paddingTop: "0",
  },
  "100%": {
    paddingTop: "30px",
  },
};

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 50px 30px 50px",
    maxWidth: "100vw",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      margin: "0 15px 30px 15px",
    },
  },
  bodyContainer: {
    overflowX: "auto",
    maxWidth: "calc(100vw - 100px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100vw - 20px)",
    },
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#646a78",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#7e889e",
      },
    },
  },
  header: {
    width: "100%",
    display: "grid",
    alignItems: "end",
    gridTemplateColumns: "2fr 9fr 2fr",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  animationArrow: {
    position: "absolute",
    flexDirection: "column",
    backgroundColor: "#13151E",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    "&>svg": {
      fontSize: "45px",
      minHeight: "80px",
      color: "#258542",
      animation: "arrowAnimation 1.5s infinite ease-in-out",
      "@keyframes arrowAnimation": arrowAnimation,
    },
    "&>div": {
      color: theme.palette.text.dark,
    },
  },
  logoPart: {
    display: "flex",
    justifyContent: "center",
    gap: "50px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "10px",
      alignItems: "center",
      width: "100%",
      marginTop: "10px",
    },
  },
  ticketSold: {
    minWidth: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    "&>div:nth-of-type(1)": {
      fontSize: "13px",
      color: theme.palette.text.dark,
      [theme.breakpoints.down("sm")]: {
        flex: 1,
      },
    },
    "&>div:nth-of-type(2)": {
      fontsize: "17px",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      height: "50px",
      backgroundColor: theme.palette.background.card,
      borderRadius: "10px",
      display: "flex",
      gap: "10px",
      "& img": {
        width: "25px",
      },
      [theme.breakpoints.down("sm")]: {
        flex: 3,
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "100%",
      order: 1,
    },
  },
  logo: {
    height: "120px",
    maxHeight: "120px",
  },
  pooledFunds: {
    minWidth: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    "&>div:nth-of-type(1)": {
      fontSize: "13px",
      color: theme.palette.text.dark,
      [theme.breakpoints.down("sm")]: {
        flex: 1,
      },
    },
    "&>div:nth-of-type(2)": {
      fontsize: "17px",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      height: "50px",
      backgroundColor: theme.palette.background.card,
      borderRadius: "10px",
      display: "flex",
      gap: "10px",
      "& img": {
        width: "25px",
      },
      [theme.breakpoints.down("sm")]: {
        flex: 3,
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "100%",
      order: 2,
    },
  },
  information: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "60px",
      right: "10px",
    },
  },
  contentBody: {
    width: "100%",
    maxWidth: "1300px",
    minWidth: "1300px",
    boxSizing: "border-box",
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  semiLeftPart: {
    marginTop: "80px",
    "&>div:nth-of-type(2)": {
      marginTop: "10px",
    },
    "&>div:nth-of-type(3)": {
      marginTop: "160px",
    },
    "&>div:nth-of-type(4)": {
      marginTop: "10px",
    },
  },
  finalLeftPart: {
    marginTop: "80px",
    marginLeft: "-30px",
    "&>div:nth-of-type(1)": {
      marginTop: "135px",
      "&::before": {
        content: '""',
        height: "82px",
        width: "calc(50% - 30px)",
        position: "absolute",
        left: "30px",
        top: 0,
        borderRight: "1px solid rgb(27, 31, 40)",
        borderTop: "1px solid rgb(27, 31, 40)",
        transform: "translate(0, -82px)",
      },
    },
    "&>div:nth-of-type(2)": {
      marginTop: "10px",
      "&::before": {
        content: '""',
        height: "78px",
        width: "calc(50% - 30px)",
        position: "absolute",
        left: "30px",
        top: 0,
        borderRight: "1px solid rgb(27, 31, 40)",
        borderBottom: "1px solid rgb(27, 31, 40)",
        transform: "translate(0, 52px)",
      },
    },
  },
  finalRightPart: {
    marginTop: "80px",
    marginLeft: "60px",
    "&>div:nth-of-type(1)": {
      marginTop: "135px",
      "&::before": {
        content: '""',
        height: "78px",
        width: "calc(50% - 30px)",
        position: "absolute",
        left: "100px",
        top: 0,
        borderLeft: "1px solid rgb(27, 31, 40)",
        borderTop: "1px solid rgb(27, 31, 40)",
        transform: "translate(0, -82px)",
      },
    },
    "&>div:nth-of-type(2)": {
      marginTop: "10px",
      "&::before": {
        content: '""',
        height: "78px",
        width: "calc(50% - 30px)",
        position: "absolute",
        left: "100px",
        top: 0,
        borderLeft: "1px solid rgb(27, 31, 40)",
        borderBottom: "1px solid rgb(27, 31, 40)",
        transform: "translate(0, 52px)",
      },
    },
  },
  semiRightPart: {
    marginTop: "80px",
    marginLeft: "-30px",
    "&>div:nth-of-type(2)": {
      marginTop: "10px",
    },
    "&>div:nth-of-type(3)": {
      marginTop: "160px",
    },
    "&>div:nth-of-type(4)": {
      marginTop: "10px",
    },
  },
  winnerPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "435px",
    minWidth: "435px",
    marginLeft: "60px",
    "&>div:nth-of-type(4)": {
      marginTop: "10px",
      "&::before": {
        content: '""',
        height: "50px",
        width: "50%",
        position: "absolute",
        left: "0",
        top: 0,
        borderRight: "1px solid rgb(27, 31, 40)",
        transform: "translate(0, 50px)",
      },
    },
    "&>div:nth-of-type(5)": {
      marginTop: "50px",
      "&::before": {
        content: '""',
        height: "54px",
        width: "50%",
        position: "absolute",
        left: "0",
        top: 0,
        borderLeft: "1px solid rgb(27, 31, 40)",
        borderBottom: "1px solid rgb(27, 31, 40)",
        transform: "translate(-100px, 1px)",
      },
      "&::after": {
        content: '""',
        height: "50px",
        width: "80px",
        position: "absolute",
        left: "0",
        top: 0,
        borderBottom: "1px solid rgb(27, 31, 40)",
        transform: "translate(-180px, -47px)",
      },
    },
    "&>div:nth-of-type(6)": {
      marginTop: "10px",
      "&::before": {
        content: '""',
        height: "54px",
        width: "50%",
        position: "absolute",
        left: "0",
        top: 0,
        borderRight: "1px solid rgb(27, 31, 40)",
        borderBottom: "1px solid rgb(27, 31, 40)",
        transform: "translate(200px, -57px)",
      },
      "&::after": {
        content: '""',
        height: "50px",
        width: "80px",
        position: "absolute",
        left: "0",
        top: 0,
        borderBottom: "1px solid rgb(27, 31, 40)",
        transform: "translate(300px, -107px)",
      },
    },
  },
  remainTime: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "14px",
    color: theme.palette.text.disabled,
  },
  timeLeft: {
    fontSize: "17px",
    marginTop: "5px",
  },
  trophy: {
    width: "50px",
    marginTop: "20px",
  },
  championText: {
    fontSize: "17px",
    marginTop: "10px",
  },
  buttonsBody: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    gap: "10px",
    marginTop: "15px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& div:nth-of-type(1)": {
        width: "100%",
        textAlign: "center",
        "& button": {
          width: "100%",
        },
      },
    },
  },
  viewTicketButtonBody: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    alignItems: "center",
  },
  totalUsd: {
    height: "60px",
    border: "4px solid #1b1f28",
    width: "130px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      width: "75px",
    },
  },
  description: {
    color: theme.palette.text.disabled,
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "& img": {
      width: "20px",
      height: "20px",
      borderRadius: "4px",
      marginRight: "8px"
    }
  },

  descriptionProfile: {
    display: "flex",
    alignItems: "center"
  },
  
  viewTicketButton: {
    height: "60px",
    backgroundColor: "#1b1f28",
    width: "230px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    gap: "10px",
    color: "white",
    textTransform: "none",
    "& img": {
      width: "27px",
    },
  },
  statBody: {
    display: "flex",
    width: "100%",
    marginTop: "30px",
    justifyContent: "space-between",
    gap: "100px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

export default styles;
