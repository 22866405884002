import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Box, Button } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useConnectWallet } from "@web3-onboard/react";

import MarchCard from "../../components/MarchCard";
import TicketTable from "../../components/TicketTable";
import TourLeaderBoard from "../../components/TourLeaderBoard";
import Dot from "../../components/Dot";
import InformationDialog from "../../components/InformationDialog";
import TicketPurchaseBtn from "../../components/TicketPurchaseBtn";
import TicketAmountSelect from "../../components/TicketAmountSelect";
import TicketEnteredPopup from "../../components/TicketEnteredPopup";
import Blocky from "../../components/Blocky";
import PageLoadingMov from "../../components/PageLoadingMov";

import theme from "../../theme";
import {
  madnessPlay,
  madnessStatus,
  MarchMadnessRoundStatus,
  pageLoadingTime,
} from "../../constants";
import useStyles from "./index.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addEntry,
  getEntries,
  getMatchesInfo,
  setConfirmingTransaction,
} from "../../reducers/march.slice";

import { UserInfo } from "../../reducers/user.slice";

import { gameConfig } from "../../config";
import { shortAddress, shortName } from "../../utils";

const eventURL = "event/march-madness";
export default function March() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const accountRef = useRef("");
  const { profiles } = useAppSelector((state) => state.user);

  const {
    roundStatus,
    totalTickets,
    closeBetAt,
    totalRoundUSD,
    entries,
    confirmingTransaction,
    gameResults,
    ticketPrice,
  } = useAppSelector((state) => state.marchMadness);
  // const gameResults = [1,4,5,8,9,12,13,16,4,8,12,13,4,8,4];
  // const gameResults = [1, 4, 5, 8, 9, 12, 13, 16, 4, 0, 0, 0, 0, 0, 0];
  // const roundStatus = 2;

  const [totalUsdAmount, setTotalUsdAmount] = useState(25);
  const [remainingTime, setRemainingTime] = useState("");
  const presentTicket = useRef(0);
  const [isShowView, setIsShowView] = useState(false);
  const [viewTicket, setViewTicket] = useState(0);
  const [viewTicketAddress, setViewTicketAddress] = useState("");
  const [viewTicketProfile, setViewTicketProfile] = useState<
    UserInfo | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [isViewTicket, setIsViewTicket] = useState(false);
  const [presentMatchGrade, setPresentMatchGrade] = useState(0);

  const players = useMemo(
    () => [
      { name: "Uconn", avatar: "uconn.svg" },
      { name: "San Diego St", avatar: "san-diego-st.svg" }, // not yet
      { name: "Illinois", avatar: "illinois.svg" },
      { name: "Iowa St.", avatar: "iowa-st.svg" },
      { name: "North Carolina", avatar: "north-carolina.svg" },
      { name: "Alabama", avatar: "alabama.svg" },
      { name: "Clemson", avatar: "clemson.svg" },
      { name: "Arizona", avatar: "arizona.svg" },
      { name: "Houston", avatar: "houston.svg" }, // not yet
      { name: "Duke", avatar: "duke.svg" },
      { name: "NC State", avatar: "north-carolina-st.svg" },
      { name: "Marquette", avatar: "marquette.svg" },
      { name: "Purdue", avatar: "purdue.svg" },
      { name: "Gonzaga", avatar: "gonzaga.svg" },
      { name: "Creighton", avatar: "creighton.svg" },
      { name: "Tennessee", avatar: "tennessee.svg" },
    ],
    []
  );

  const [round16Players, setRound16Players] = useState([
    { id: 0, status: madnessStatus.enable },
    { id: 1, status: madnessStatus.enable },
    { id: 2, status: madnessStatus.enable },
    { id: 3, status: madnessStatus.enable },
    { id: 4, status: madnessStatus.enable },
    { id: 5, status: madnessStatus.enable },
    { id: 6, status: madnessStatus.enable },
    { id: 7, status: madnessStatus.enable },
    { id: 8, status: madnessStatus.enable },
    { id: 9, status: madnessStatus.enable },
    { id: 10, status: madnessStatus.enable },
    { id: 11, status: madnessStatus.enable },
    { id: 12, status: madnessStatus.enable },
    { id: 13, status: madnessStatus.enable },
    { id: 14, status: madnessStatus.enable },
    { id: 15, status: madnessStatus.enable },
  ]);

  const [quarterPlayers, setQuarterPlayers] = useState([
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
  ]);

  const [semiPlayers, setSemiPlayers] = useState([
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
  ]);

  const [finalPlayers, setFinalPlayers] = useState([
    { id: 0, status: madnessStatus.waiting },
    { id: 0, status: madnessStatus.waiting },
  ]);

  const [winnerPlayer, setWinnerPlayer] = useState({ id: 0, status: 0 });
  const [championId, setChampionId] = useState(-1);

  const setConfirmingTransactionFalse = () => {
    dispatch(setConfirmingTransaction());
  };

  const handleViewHistory = useCallback(
    (dat: any, viewTicketStatus: boolean = true) => {
      setIsShowView(true);
      setViewTicket(dat.entryId);
      setViewTicketAddress(dat.player);
      setIsViewTicket(viewTicketStatus);

      const profile = profiles.find(
        (profile) => profile.address.toLowerCase() === dat.player.toLowerCase()
      );

      setViewTicketProfile(profile);

      const predictions = dat.predictions;
      predictions[14] !== 0 && setChampionId(predictions[14] - 1);
      if (predictions.length < 15) return;
      setRound16Players((prev) => {
        const updated = [...prev];
        for (let i = 0; i < 8; i++) {
          const index: number = players.findIndex((player, index) => {
            const predictionId: number = parseInt(predictions[i]);
            return index === predictionId - 1;
          });
          if (index === -1) continue;
          const gameIndex: number = i * 2;
          if (index === updated[gameIndex].id) {
            updated[gameIndex] = {
              id: updated[gameIndex].id,
              status: madnessStatus.winner,
            };
            updated[gameIndex + 1] = {
              id: updated[gameIndex + 1].id,
              status: madnessStatus.loser,
            };
          } else {
            updated[gameIndex + 1] = {
              id: updated[gameIndex + 1].id,
              status: madnessStatus.winner,
            };
            updated[gameIndex] = {
              id: updated[gameIndex].id,
              status: madnessStatus.loser,
            };
          }
        }
        return updated;
      });
      setQuarterPlayers((prev) => {
        const updated = [...prev];
        for (let i = 0; i < 8; i++) {
          const predictionId: number = parseInt(predictions[i]);
          predictionId !== 0 &&
            (updated[i] = {
              id: predictionId - 1,
              status: madnessStatus.enable,
            });
        }
        for (let i = 8; i < 12; i++) {
          const index: number = players.findIndex((player, index) => {
            const predictionId: number = parseInt(predictions[i]);
            return index === predictionId - 1;
          });
          if (index === -1) continue;
          const gameIndex: number = (i - 8) * 2;
          if (index === updated[gameIndex].id) {
            updated[gameIndex] = {
              id: updated[gameIndex].id,
              status: madnessStatus.winner,
            };
            updated[gameIndex + 1] = {
              id: updated[gameIndex + 1].id,
              status: madnessStatus.loser,
            };
          } else {
            updated[gameIndex + 1] = {
              id: updated[gameIndex + 1].id,
              status: madnessStatus.winner,
            };
            updated[gameIndex] = {
              id: updated[gameIndex].id,
              status: madnessStatus.loser,
            };
          }
        }
        return updated;
      });
      setSemiPlayers((prev) => {
        const updated = [...prev];
        for (let i = 8; i < 12; i++) {
          const predictionId: number = parseInt(predictions[i]);
          predictionId !== 0 &&
            (updated[i - 8] = {
              id: predictionId - 1,
              status: madnessStatus.enable,
            });
        }
        for (let i = 12; i < 14; i++) {
          const index: number = players.findIndex((player, index) => {
            const predictionId: number = parseInt(predictions[i]);
            return index === predictionId - 1;
          });
          if (index === -1) continue;
          const gameIndex: number = (i - 12) * 2;
          if (index === updated[gameIndex].id) {
            updated[gameIndex] = {
              id: updated[gameIndex].id,
              status: madnessStatus.winner,
            };
            updated[gameIndex + 1] = {
              id: updated[gameIndex + 1].id,
              status: madnessStatus.loser,
            };
          } else {
            updated[gameIndex + 1] = {
              id: updated[gameIndex + 1].id,
              status: madnessStatus.winner,
            };
            updated[gameIndex] = {
              id: updated[gameIndex].id,
              status: madnessStatus.loser,
            };
          }
        }
        return updated;
      });
      setFinalPlayers((prev) => {
        const updated = [...prev];
        for (let i = 12; i < 14; i++) {
          const predictionId: number = parseInt(predictions[i]);
          predictionId !== 0 &&
            (updated[i - 12] = {
              id: predictionId - 1,
              status: madnessStatus.enable,
            });
        }

        const index: number = players.findIndex((player, index) => {
          const predictionId: number = parseInt(predictions[14]);
          return index === predictionId - 1;
        });
        if (index === -1) return updated;
        if (index === updated[0].id) {
          updated[0] = { id: updated[0].id, status: madnessStatus.winner };
          updated[1] = { id: updated[1].id, status: madnessStatus.loser };
        } else {
          updated[1] = { id: updated[1].id, status: madnessStatus.winner };
          updated[0] = { id: updated[0].id, status: madnessStatus.loser };
        }
        return updated;
      });
      predictions[14] !== 0 &&
        setWinnerPlayer({
          id: parseInt(predictions[14]) - 1,
          status: madnessStatus.winner,
        });
    },
    [players, profiles]
  );

  useEffect(() => {
    accountRef.current = account;
  }, [account]);

  useEffect(() => {
    dispatch(getMatchesInfo());
    dispatch(getEntries());

    const setupEventSource = () => {
      const eventSource = new EventSource(`${gameConfig.serverUrl}${eventURL}`);
      eventSource.onmessage = async (e) => {
        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);
          console.log("March event: ", res);
          dispatch(addEntry(res.result));
          if (accountRef.current === res.result.player.toLowerCase()) {
            presentTicket.current = Number(res.result.entryId);
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();
    return () => {
      eventSource.close();
    };
  }, [dispatch]);

  useEffect(() => {
    if (roundStatus >= MarchMadnessRoundStatus.Closed && !isViewTicket) {
      const data: any = {
        entryId: 0,
        player: "",
        predictions: gameResults,
      };
      handleViewHistory(data, false);
    }
  }, [roundStatus, gameResults, handleViewHistory, isViewTicket]);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const currentTimeUnix = Math.floor(Date.now() / 1000);
      if (!closeBetAt) {
        return;
      }
      const timeDiff = closeBetAt - currentTimeUnix;
      if (timeDiff < 0) {
        setRemainingTime("Finished");
        return;
      }
      const days: number = Math.floor(timeDiff / 3600 / 24);
      const hours: number = Math.floor((timeDiff % (3600 * 24)) / 3600);
      const minutes: number = Math.floor((timeDiff % 3600) / 60);
      setRemainingTime(
        (days > 1 ? days + " days, " : days === 1 ? "1 day, " : "") +
          (hours > 1 ? hours + " hours & " : hours === 1 ? "1 hour & " : "") +
          (minutes > 1
            ? " " + minutes + " mins"
            : minutes === 1
            ? " 1 min"
            : "")
      );
    };

    calculateRemainingTime();
    setInterval(calculateRemainingTime, 10000);
  }, [closeBetAt]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, pageLoadingTime);
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    for (let i = 0; i < gameResults.length; i++) {
      if (gameResults[i] === 0) {
        if (i >= 0 && i < 8) {
          setPresentMatchGrade(madnessPlay.round16);
        } else if (i >= 8 && i < 12) {
          setPresentMatchGrade(madnessPlay.quarter);
        } else if (i >= 12 && i < 14) {
          setPresentMatchGrade(madnessPlay.semi);
        } else if (i === 14) {
          setPresentMatchGrade(madnessPlay.final);
        } else {
          setPresentMatchGrade(0);
        }
        break;
      }
    }
  }, [gameResults]);

  const getPredictions = () => {
    const round16Winners = round16Players
      .filter((item) => item.status === madnessStatus.winner)
      .map(({ id }) => id + 1);
    const quarterWinners = quarterPlayers
      .filter((item) => item.status === madnessStatus.winner)
      .map(({ id }) => id + 1);
    const semiWinners = semiPlayers
      .filter((item) => item.status === madnessStatus.winner)
      .map(({ id }) => id + 1);
    const finalWinners = finalPlayers
      .filter((item) => item.status === madnessStatus.winner)
      .map(({ id }) => id + 1);
    return [
      ...round16Winners,
      ...quarterWinners,
      ...semiWinners,
      ...finalWinners,
    ];
  };
  const predictions = getPredictions();

  const getMatchStatus = (grade: number, gameIndex: number) => {
    if (grade === madnessPlay.round16) {
      return quarterPlayers[gameIndex].status;
    } else if (grade === madnessPlay.quarter) {
      return semiPlayers[gameIndex].status;
    } else if (grade === madnessPlay.semi) {
      return finalPlayers[gameIndex].status;
    } else if (grade === madnessPlay.final) {
      return 1;
    }
    return 0;
  };
  const pickClick = (grade: number, gameIndex: number, playerIndex: number) => {
    if (grade === madnessPlay.round16) {
      setQuarterPlayers((prevQuarterPlayers) => {
        const updatedQuarterPlayers = [...prevQuarterPlayers];
        updatedQuarterPlayers[Math.ceil((gameIndex + 1) / 2) - 1] = {
          id: gameIndex,
          status: 1,
        };
        return updatedQuarterPlayers;
      });

      const groupIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setRound16Players((prevRoundPlayers) => {
        const updatedRoundPlayers = [...prevRoundPlayers];
        updatedRoundPlayers[gameIndex] = { id: gameIndex, status: 2 };
        return updatedRoundPlayers;
      });
      setRound16Players((prevRoundPlayers) => {
        const updatedRoundPlayers = [...prevRoundPlayers];
        updatedRoundPlayers[groupIndex] = { id: groupIndex, status: 3 };
        return updatedRoundPlayers;
      });
    } else if (grade === madnessPlay.quarter) {
      setSemiPlayers((prevSemiPlayers) => {
        const updatedSemiPlayers = [...prevSemiPlayers];
        updatedSemiPlayers[Math.ceil((gameIndex + 1) / 2) - 1] = {
          id: playerIndex,
          status: 1,
        };
        return updatedSemiPlayers;
      });

      const groupIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setQuarterPlayers((prevQuarterPlayers) => {
        const updatedQuarterPlayers = [...prevQuarterPlayers];
        updatedQuarterPlayers[gameIndex] = { id: playerIndex, status: 2 };
        return updatedQuarterPlayers;
      });
      setQuarterPlayers((prevQuarterPlayers) => {
        const updatedQuarterPlayers = [...prevQuarterPlayers];
        updatedQuarterPlayers[groupIndex] = {
          id: quarterPlayers[groupIndex].id,
          status: 3,
        };
        return updatedQuarterPlayers;
      });
    } else if (grade === madnessPlay.semi) {
      setFinalPlayers((prevFinalPlayers) => {
        const updatedFinalPlayers = [...prevFinalPlayers];
        updatedFinalPlayers[Math.ceil((gameIndex + 1) / 2) - 1] = {
          id: playerIndex,
          status: 1,
        };
        return updatedFinalPlayers;
      });

      const groupIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setSemiPlayers((prevSemiPlayers) => {
        const updatedSemiPlayers = [...prevSemiPlayers];
        updatedSemiPlayers[gameIndex] = { id: playerIndex, status: 2 };
        return updatedSemiPlayers;
      });
      setSemiPlayers((prevSemiPlayers) => {
        const updatedSemiPlayers = [...prevSemiPlayers];
        updatedSemiPlayers[groupIndex] = {
          id: semiPlayers[groupIndex].id,
          status: 3,
        };
        return updatedSemiPlayers;
      });
    } else if (grade === madnessPlay.final) {
      setWinnerPlayer((prevWinnerPlayer) => {
        let updatedFinalPlayer = { ...prevWinnerPlayer };
        updatedFinalPlayer = { id: playerIndex, status: 1 };
        return updatedFinalPlayer;
      });

      const groupIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setFinalPlayers((prevFinalPlayers) => {
        const updatedFinalPlayers = [...prevFinalPlayers];
        updatedFinalPlayers[gameIndex] = { id: playerIndex, status: 2 };
        return updatedFinalPlayers;
      });
      setFinalPlayers((prevFinalPlayers) => {
        const updatedFinalPlayers = [...prevFinalPlayers];
        updatedFinalPlayers[groupIndex] = {
          id: finalPlayers[groupIndex].id,
          status: 3,
        };
        return updatedFinalPlayers;
      });

      setChampionId(playerIndex);
    }
  };

  const closeRound16 = (
    gameIndex: number,
    playerIndex: number,
    pairRemove: boolean
  ) => {
    if (pairRemove) {
      setRound16Players((prevRound16Players) => {
        let updatedRound16Players = [...prevRound16Players];
        updatedRound16Players[gameIndex] = { id: playerIndex, status: 1 };
        return updatedRound16Players;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setRound16Players((prevRound16Players) => {
        let updatedRound16Players = [...prevRound16Players];
        updatedRound16Players[pairIndex] = {
          id: round16Players[pairIndex].id,
          status: 1,
        };
        return updatedRound16Players;
      });
    }

    closeQuarter(Math.ceil((gameIndex + 1) / 2) - 1, playerIndex, false);
  };

  const closeQuarter = (
    gameIndex: number,
    playerIndex: number,
    pairRemove: boolean
  ) => {
    if (pairRemove) {
      setQuarterPlayers((prevQuarterPlayers) => {
        let updatedQuarterPlayers = [...prevQuarterPlayers];
        updatedQuarterPlayers[gameIndex] = { id: playerIndex, status: 1 };
        return updatedQuarterPlayers;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setQuarterPlayers((prevQuarterPlayers) => {
        let updatedQuarterPlayers = [...prevQuarterPlayers];
        updatedQuarterPlayers[pairIndex] = {
          id: quarterPlayers[pairIndex].id,
          status: 1,
        };
        return updatedQuarterPlayers;
      });
    } else {
      setQuarterPlayers((prevQuarterPlayers) => {
        let updatedQuarterPlayers = [...prevQuarterPlayers];
        updatedQuarterPlayers[gameIndex] = { id: playerIndex, status: 0 };
        return updatedQuarterPlayers;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setQuarterPlayers((prevQuarterPlayers) => {
        let updatedQuarterPlayers = [...prevQuarterPlayers];
        if (updatedQuarterPlayers[pairIndex].status !== 0) {
          updatedQuarterPlayers[pairIndex] = {
            id: quarterPlayers[pairIndex].id,
            status: 1,
          };
        }
        return updatedQuarterPlayers;
      });
    }

    closeSemi(Math.ceil((gameIndex + 1) / 2) - 1, playerIndex, false);
  };

  const closeSemi = (
    gameIndex: number,
    playerIndex: number,
    pairRemove: boolean
  ) => {
    if (pairRemove) {
      setSemiPlayers((prevSemiPlayers) => {
        let updatedSemiPlayers = [...prevSemiPlayers];
        updatedSemiPlayers[gameIndex] = { id: playerIndex, status: 1 };
        return updatedSemiPlayers;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setSemiPlayers((prevSemiPlayers) => {
        let updatedSemiPlayers = [...prevSemiPlayers];
        updatedSemiPlayers[pairIndex] = {
          id: semiPlayers[pairIndex].id,
          status: 1,
        };
        return updatedSemiPlayers;
      });
    } else {
      setSemiPlayers((prevSemiPlayers) => {
        let updatedSemiPlayers = [...prevSemiPlayers];
        updatedSemiPlayers[gameIndex] = { id: playerIndex, status: 0 };
        return updatedSemiPlayers;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setSemiPlayers((prevSemiPlayers) => {
        let updatedSemiPlayers = [...prevSemiPlayers];
        if (updatedSemiPlayers[pairIndex].status !== 0) {
          updatedSemiPlayers[pairIndex] = {
            id: semiPlayers[pairIndex].id,
            status: 1,
          };
        }
        return updatedSemiPlayers;
      });
    }

    closeFinal(Math.ceil((gameIndex + 1) / 2) - 1, playerIndex, false);
  };

  const closeFinal = (
    gameIndex: number,
    playerIndex: number,
    pairRemove: boolean
  ) => {
    if (pairRemove) {
      setFinalPlayers((prevFinalPlayers) => {
        let updatedFinalPlayers = [...prevFinalPlayers];
        updatedFinalPlayers[gameIndex] = { id: playerIndex, status: 1 };
        return updatedFinalPlayers;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setFinalPlayers((prevFinalPlayers) => {
        let updatedFinalPlayers = [...prevFinalPlayers];
        updatedFinalPlayers[pairIndex] = {
          id: finalPlayers[pairIndex].id,
          status: 1,
        };
        return updatedFinalPlayers;
      });
    } else {
      setFinalPlayers((prevFinalPlayers) => {
        let updatedFinalPlayers = [...prevFinalPlayers];
        updatedFinalPlayers[gameIndex] = { id: playerIndex, status: 0 };
        return updatedFinalPlayers;
      });
      const pairIndex = gameIndex % 2 ? gameIndex - 1 : gameIndex + 1;
      setFinalPlayers((prevFinalPlayers) => {
        let updatedFinalPlayers = [...prevFinalPlayers];
        if (updatedFinalPlayers[pairIndex].status !== 0) {
          updatedFinalPlayers[pairIndex] = {
            id: finalPlayers[pairIndex].id,
            status: 1,
          };
        }
        return updatedFinalPlayers;
      });
    }

    setWinnerPlayer({ id: 0, status: 0 });
  };

  const closeClick = (
    grade: number,
    gameIndex: number,
    playerIndex: number
  ) => {
    if (grade === madnessPlay.round16) {
      closeRound16(gameIndex, playerIndex, true);
    } else if (grade === madnessPlay.quarter) {
      closeQuarter(gameIndex, playerIndex, true);
    } else if (grade === madnessPlay.semi) {
      closeSemi(gameIndex, playerIndex, true);
    } else if (grade === madnessPlay.final) {
      closeFinal(gameIndex, playerIndex, true);
    }
    setChampionId(-1);
  };

  const onNewCreate = () => {
    setIsShowView(false);
    setRound16Players((prevMatches) => {
      return prevMatches.map((player) => {
        return { ...player, status: madnessStatus.enable };
      });
    });
    setQuarterPlayers((prevMatches) => {
      return prevMatches.map((player) => {
        return { ...player, id: 0, status: madnessStatus.waiting };
      });
    });
    setSemiPlayers((prevMatches) => {
      return prevMatches.map((player) => {
        return { ...player, id: 0, status: madnessStatus.waiting };
      });
    });
    setFinalPlayers((prevMatches) => {
      return prevMatches.map((player) => {
        return { ...player, id: 0, status: madnessStatus.waiting };
      });
    });
    setWinnerPlayer((prevMatches) => {
      return { ...prevMatches, id: 0, status: madnessStatus.waiting };
    });
  };

  const setTicketView = (address: string, ticketId: number) => {
    setViewTicket(ticketId);
    setViewTicketAddress(address);
  };

  return loading ? (
    <PageLoadingMov />
  ) : (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: "30px",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: "2%",
        paddingRight: "2%",
        paddingBottom: "40px",
        maxWidth: "1700px",
        marginLeft: "calc((100% - 1700px) / 2)",
        [theme.breakpoints.up("xl")]: {
          paddingLeft: "5%",
          paddingRight: "5%",
        },
        [theme.breakpoints.down("xl")]: {
          maxWidth: "1650px",
          marginLeft: 0,
        },
      }}
    >
      <Box className={classes.header}>
        <Box
          sx={{
            flex: 1,
          }}
        ></Box>
        <Box
          sx={{
            flex: 3,
            display: "flex",
            justifyContent: "center",
            marginTop: "-30px",
            gap: "50px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              width: "100%",
              marginTop: "10px",
            },
          }}
        >
          <Box className={classes.ticketSold}>
            <Box>Tickets sold</Box>
            <Box>
              <Box component="img" src="/images/icons/ticket.png" />
              <Box>{totalTickets ? totalTickets.toFixed(0) : 0}</Box>
            </Box>
          </Box>
          <Box
            component="img"
            src="/images/events/marchlogo.png"
            className={classes.logo}
          />
          <Box className={classes.ticketSoldMobile}>
            <Box>Tickets sold</Box>
            <Box>
              <Box component="img" src="/images/icons/ticket.png" />
              <Box>{totalTickets ? totalTickets.toFixed(0) : 0}</Box>
            </Box>
          </Box>
          <Box className={classes.pooledFunds}>
            <Box>Pooled funds</Box>
            <Box>
              <Box component="img" src="/images/tokens/edai.png" />
              <Box>${totalRoundUSD ? totalRoundUSD.toFixed(0) : 0}</Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "end",
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              top: "60px",
              right: "10px",
            },
          }}
        >
          <InformationDialog />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            overflowX: "auto",
            display: "flex",
            overflowY: "clip",
            paddingBottom: "20px",
            marginTop: "-50px",
            paddingTop: "50px",
            position: "relative",
            "&::-webkit-scrollbar": {
              height: "4px",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#646a78",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#7e889e",
              },
            },
          }}
        >
          <Box
            sx={
              presentMatchGrade === madnessPlay.round16
                ? { top: "-25px", left: "80px", display: "flex" }
                : presentMatchGrade === madnessPlay.quarter
                ? { top: "10px", left: "300px", display: "flex" }
                : presentMatchGrade === madnessPlay.semi
                ? { top: "70px", left: "440px", display: "flex" }
                : presentMatchGrade === madnessPlay.final
                ? { top: "190px", left: "590px", display: "flex" }
                : { display: "none" }
            }
            className={classes.animationArrow}
          >
            <KeyboardDoubleArrowDownIcon />
            <Box>
              {presentMatchGrade === madnessPlay.round16
                ? "Round of 16"
                : presentMatchGrade === madnessPlay.quarter
                ? "Quarter"
                : presentMatchGrade === madnessPlay.semi
                ? "Semi Final"
                : presentMatchGrade === madnessPlay.final
                ? "Final"
                : ""}
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: "30px",
            }}
          >
            {round16Players.map((player, index) =>
              index < 8 ? (
                <MarchCard
                  key={index}
                  playerIndex={index}
                  gameIndex={index}
                  status={player.status}
                  name={players[player.id].name}
                  avatar={players[player.id].avatar}
                  grade={madnessPlay.round16}
                  groupStatus={
                    index % 2 === 1
                      ? round16Players[index - 1].status
                      : round16Players[index + 1].status
                  }
                  pickClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => pickClick(grade, gameIndex, playerIndex)}
                  closeClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => closeClick(grade, gameIndex, playerIndex)}
                  getMatchStatus={(grade: number, gameIndex: number) =>
                    getMatchStatus(grade, gameIndex)
                  }
                  championId={championId}
                  isTicketView={isShowView}
                  isFinishedTicketView={isViewTicket}
                />
              ) : null
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "30px",
            }}
          >
            {quarterPlayers.map((player, index) =>
              index < 4 ? (
                <MarchCard
                  key={index}
                  playerIndex={player.id}
                  gameIndex={index}
                  status={player.status}
                  name={players[player.id].name}
                  avatar={players[player.id].avatar}
                  grade={madnessPlay.quarter}
                  groupStatus={
                    index % 2 === 1
                      ? quarterPlayers[index - 1].status
                      : quarterPlayers[index + 1].status
                  }
                  pickClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => pickClick(grade, gameIndex, playerIndex)}
                  closeClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => closeClick(grade, gameIndex, playerIndex)}
                  getMatchStatus={(grade: number, gameIndex: number) =>
                    getMatchStatus(grade, gameIndex)
                  }
                  championId={championId}
                  isTicketView={isShowView}
                  gameResult={gameResults[index]}
                  isFinishedTicketView={isViewTicket}
                />
              ) : null
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "-40px",
            }}
          >
            {semiPlayers.map((player, index) =>
              index < 2 ? (
                <MarchCard
                  key={index}
                  playerIndex={player.id}
                  gameIndex={index}
                  status={player.status}
                  name={players[player.id].name}
                  avatar={players[player.id].avatar}
                  grade={madnessPlay.semi}
                  groupStatus={
                    index % 2 === 1
                      ? semiPlayers[index - 1].status
                      : semiPlayers[index + 1].status
                  }
                  pickClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => pickClick(grade, gameIndex, playerIndex)}
                  closeClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => closeClick(grade, gameIndex, playerIndex)}
                  getMatchStatus={(grade: number, gameIndex: number) =>
                    getMatchStatus(grade, gameIndex)
                  }
                  championId={championId}
                  isTicketView={isShowView}
                  gameResult={gameResults[8 + index]}
                  isFinishedTicketView={isViewTicket}
                />
              ) : null
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "80px",
            }}
            className={classes.middleBody}
          >
            <Box className={classes.remainTime}>
              <Dot
                sx={{
                  width: "9px",
                  height: "9px",
                  backgroundColor: theme.palette.background.timeLeftDot,
                  boxShadow:
                    "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24",
                }}
              />
              <Box>Time left</Box>
            </Box>
            <Box className={classes.timeLeft}>{remainingTime}</Box>
            <Box
              component="img"
              src="/images/icons/trophy.png"
              className={classes.trophy}
            />
            <Box className={classes.championText}>Champion!</Box>
            <MarchCard
              playerIndex={winnerPlayer.id}
              gameIndex={0}
              status={winnerPlayer.status}
              name={players[winnerPlayer.id].name}
              avatar={players[winnerPlayer.id].avatar}
              grade={madnessPlay.winner}
              pickClick={(
                grade: number,
                gameIndex: number,
                playerIndex: number
              ) => pickClick(grade, gameIndex, playerIndex)}
              closeClick={(
                grade: number,
                gameIndex: number,
                playerIndex: number
              ) => closeClick(grade, gameIndex, playerIndex)}
              getMatchStatus={(grade: number, gameIndex: number) =>
                getMatchStatus(grade, gameIndex)
              }
              championId={championId}
              isTicketView={isShowView}
              gameResult={gameResults[14]}
              isFinishedTicketView={isViewTicket}
            />
            <Box>
              <MarchCard
                playerIndex={finalPlayers[0].id}
                gameIndex={0}
                status={finalPlayers[0].status}
                name={players[finalPlayers[0].id].name}
                avatar={players[finalPlayers[0].id].avatar}
                grade={madnessPlay.final}
                groupStatus={finalPlayers[1].status}
                pickClick={(
                  grade: number,
                  gameIndex: number,
                  playerIndex: number
                ) => pickClick(grade, gameIndex, playerIndex)}
                closeClick={(
                  grade: number,
                  gameIndex: number,
                  playerIndex: number
                ) => closeClick(grade, gameIndex, playerIndex)}
                getMatchStatus={(grade: number, gameIndex: number) =>
                  getMatchStatus(grade, gameIndex)
                }
                championId={championId}
                isTicketView={isShowView}
                gameResult={gameResults[12]}
                isFinishedTicketView={isViewTicket}
              />
              <MarchCard
                playerIndex={finalPlayers[1].id}
                gameIndex={1}
                status={finalPlayers[1].status}
                name={players[finalPlayers[1].id].name}
                avatar={players[finalPlayers[1].id].avatar}
                grade={madnessPlay.final}
                groupStatus={finalPlayers[0].status}
                pickClick={(
                  grade: number,
                  gameIndex: number,
                  playerIndex: number
                ) => pickClick(grade, gameIndex, playerIndex)}
                closeClick={(
                  grade: number,
                  gameIndex: number,
                  playerIndex: number
                ) => closeClick(grade, gameIndex, playerIndex)}
                getMatchStatus={(grade: number, gameIndex: number) =>
                  getMatchStatus(grade, gameIndex)
                }
                championId={championId}
                isTicketView={isShowView}
                gameResult={gameResults[13]}
                isFinishedTicketView={isViewTicket}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: "80px",
            }}
          >
            {semiPlayers.map((player, index) =>
              index >= 2 && index < 4 ? (
                <MarchCard
                  key={index}
                  playerIndex={player.id}
                  gameIndex={index}
                  status={player.status}
                  name={players[player.id].name}
                  avatar={players[player.id].avatar}
                  grade={madnessPlay.semi}
                  groupStatus={
                    index % 2 === 1
                      ? semiPlayers[index - 1].status
                      : semiPlayers[index + 1].status
                  }
                  pickClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => pickClick(grade, gameIndex, playerIndex)}
                  closeClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => closeClick(grade, gameIndex, playerIndex)}
                  getMatchStatus={(grade: number, gameIndex: number) =>
                    getMatchStatus(grade, gameIndex)
                  }
                  championId={championId}
                  isTicketView={isShowView}
                  gameResult={gameResults[index + 8]}
                  isFinishedTicketView={isViewTicket}
                />
              ) : null
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "-40px",
            }}
          >
            {quarterPlayers.map((player, index) =>
              index >= 4 && index < 8 ? (
                <MarchCard
                  key={index}
                  playerIndex={player.id}
                  gameIndex={index}
                  status={player.status}
                  name={players[player.id].name}
                  avatar={players[player.id].avatar}
                  grade={madnessPlay.quarter}
                  groupStatus={
                    index % 2 === 1
                      ? quarterPlayers[index - 1].status
                      : quarterPlayers[index + 1].status
                  }
                  pickClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => pickClick(grade, gameIndex, playerIndex)}
                  closeClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => closeClick(grade, gameIndex, playerIndex)}
                  getMatchStatus={(grade: number, gameIndex: number) =>
                    getMatchStatus(grade, gameIndex)
                  }
                  championId={championId}
                  isTicketView={isShowView}
                  gameResult={gameResults[index]}
                  isFinishedTicketView={isViewTicket}
                />
              ) : null
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "30px",
            }}
          >
            {round16Players.map((player, index) =>
              index >= 8 ? (
                <MarchCard
                  key={index}
                  playerIndex={index}
                  gameIndex={index}
                  status={player.status}
                  name={players[player.id].name}
                  avatar={players[player.id].avatar}
                  grade={madnessPlay.round16}
                  groupStatus={
                    index % 2 === 1
                      ? round16Players[index - 1].status
                      : round16Players[index + 1].status
                  }
                  pickClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => pickClick(grade, gameIndex, playerIndex)}
                  closeClick={(
                    grade: number,
                    gameIndex: number,
                    playerIndex: number
                  ) => closeClick(grade, gameIndex, playerIndex)}
                  getMatchStatus={(grade: number, gameIndex: number) =>
                    getMatchStatus(grade, gameIndex)
                  }
                  championId={championId}
                  isTicketView={isShowView}
                  isFinishedTicketView={isViewTicket}
                />
              ) : null
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: "10px",
          marginTop: "-15px",
          [theme.breakpoints.down("lg")]: {
            marginTop: "-10px",
          },
        }}
      >
        {confirmingTransaction ? (
          <TicketEnteredPopup
            ticketId={presentTicket.current}
            onNewCreate={onNewCreate}
            setConfirmingTransactionFalse={setConfirmingTransactionFalse}
            setIsShowView={setIsShowView}
            setTicketView={setTicketView}
            entryInfo={entries[entries.length - 1]}
          />
        ) : null}

        {!isShowView && roundStatus === MarchMadnessRoundStatus.Started ? (
          <>
            <TicketPurchaseBtn
              text="Purchase ticket"
              usdAmount={totalUsdAmount}
              predictions={predictions}
              totalMatches={15}
            />
            <TicketAmountSelect
              setTotalUsdAmount={(value) => setTotalUsdAmount(value)}
              ticketPrice={ticketPrice}
            />
            <Box className={classes.viewTicketButtonBody}>
              <Box className={classes.totalUsd}>
                ${totalUsdAmount.toFixed(0)}
              </Box>
              <Box className={classes.description}>Total</Box>
            </Box>
          </>
        ) : isShowView ? (
          <>
            <Box className={classes.viewTicketButtonBody}>
              <Box className={classes.viewTicketButton}>
                <Box component="img" src="/images/icons/eye_green.png" />
                Ticket #{viewTicket}
              </Box>
              <Box className={classes.description}>
                <Box component="span" sx={{ color: "white" }}>
                  Created by{" "}
                </Box>
                <Box className={classes.descriptionProfile}>
                  {viewTicketProfile && viewTicketProfile.avatar.length > 0 ? (
                    <img src={viewTicketProfile.avatar} alt="" srcSet="" />
                  ) : (
                    <Blocky
                      address={viewTicketAddress.toLowerCase()}
                      size={16}
                      scale={1}
                    />
                  )}

                  {viewTicketProfile ? (
                    viewTicketProfile.name.length > 15 ? (
                      shortName(viewTicketProfile.name)
                    ) : (
                      viewTicketProfile.name
                    )
                  ) : (
                    <span>{shortAddress(viewTicketAddress)}</span>
                  )}
                </Box>
              </Box>
            </Box>
            {roundStatus === MarchMadnessRoundStatus.Started ? (
              <Box className={classes.viewTicketButtonBody}>
                <Button
                  className={classes.viewTicketButton}
                  onClick={onNewCreate}
                >
                  <Box component="img" src="/images/icons/ticket-green.png" />
                  Create new ticket
                </Button>
                <Box className={classes.description}></Box>
              </Box>
            ) : null}
          </>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "30px",
          justifyContent: "space-between",
          gap: "100px",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <TicketTable viewHistory={(dat: object) => handleViewHistory(dat)} />
        <TourLeaderBoard
          viewHistory={(dat: object) => handleViewHistory(dat)}
        />
      </Box>
    </Box>
  );
}
