import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { walletDelayTime } from "../../constants";
import { BetToken } from "../../helpers/contractFunctions";
import { readLastCreatedRoundId } from "../../helpers/contractFunctions/predict";
import {
  createPredict,
  getBetTokens,
  getPriceTokenLp,
  getPriceTokens,
  getTokenBalances,
  getTokenPrice,
  getTokenPriceFromContract,
  initRoundInfo,
  PredictToken,
  setBetUSDAmount,
  setCreatingStatus,
  setEndTime,
  setInitialBetAmount,
  setOperator,
  setPricePoint,
  setPriceToken,
} from "../../reducers/predict.slice";
import { Toast } from "../../utils";
import CustomMenuUnit from "../PredictionCreateOptions/CustomMenu";
import TokenTakerDialogWithoutEnter from "../TokenTakerDialogWithoutEnter";
import useStyles from "./index.styles";

export default function PredictionQuickBet() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const {
    tokens,
    newRoundInfo,
    prices,
    betTokens,
    tokenBalances,
    creatingRound,
  } = useAppSelector((state) => state.predict);
  const { priceToken, operator } = newRoundInfo;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(0);
  const [betAmount, setBetAmount] = useState("0");
  const [selectButAnchor, setSelectButAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [enteringToken, setEnteringToken] = useState<BetToken | null>(null);
  const lastRoundId = useRef(0);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAsset = (item: PredictToken, index: number) => {
    dispatch(setPriceToken({ item, index }));

    dispatch(getTokenPriceFromContract({ tokenId: item.id }));
    dispatch(getTokenPrice({ tx: item.dexLink }));
  };

  const handleOperator = (operator: number) => {
    dispatch(setOperator(operator));
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handlePlusUsd = (usd: number) => {
    setBetAmount((betAmount) =>
      ((isNaN(Number(betAmount)) ? 0 : Number(betAmount)) + usd).toString()
    );
  };

  const handleChangeUsd = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBetAmount(e.target.value);
  };

  const handleClearUsd = () => {
    setBetAmount("0");
  };

  const handleCreatePredict = () => {
    if (!duration) {
      Toast.fire({
        icon: "warning",
        title: "Please select the duration time.",
      });
      return;
    } else if (!enteringToken) {
      Toast.fire({
        icon: "warning",
        title: "Please select the bet token.",
      });
      return;
    }
    if (!newRoundInfo.priceToken) return;
    dispatch(
      getTokenPriceFromContract({ tokenId: newRoundInfo.priceToken.id })
    );
    dispatch(
      setEndTime(new Date().getTime() + duration * 60 * 1000 + walletDelayTime)
    );
    dispatch(
      createPredict({
        account,
        tokenId: enteringToken.id,
        tokenAmount: isNaN(Number(betAmount))
          ? 0
          : (Number(betAmount) / enteringToken.price) * 1.1,
        isQuickBet: true,
        duration: duration * 60,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setCreatingStatus(false));

        Toast.fire({
          icon: "success",
          title: "PricePredict Room created.",
        });
        setTimeout(() => {
          gotoDetailPage();
        }, 1000);
      });
  };

  const gotoDetailPage = async () => {
    const latestRoundId = await readLastCreatedRoundId(account);
    lastRoundId.current = latestRoundId;
    dispatch(initRoundInfo());
    navigate(`/predictions/detail/${latestRoundId}`);
  };

  useEffect(() => {
    if (prices.priceUsd !== undefined && typeof prices.priceUsd === "number") {
      setPrice(prices.priceUsd);
      dispatch(setPricePoint(prices.priceUsd));
    }
  }, [prices, priceToken]);

  useEffect(() => {
    dispatch(getPriceTokens());
  }, [dispatch]);

  useEffect(() => {
    if (tokens.length === 0) return;
    const fetchLp = async () => {
      await dispatch(getPriceTokenLp(tokens.map((token, index) => token.pair)));
    };

    fetchLp();
  }, [tokens]);

  useEffect(() => {
    dispatch(getBetTokens());
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      dispatch(getTokenBalances(account));
    }
  }, [dispatch, account]);

  useEffect(() => {
    setEnteringToken(null);
    dispatch(
      setInitialBetAmount(
        2 * (isNaN(Number(betAmount)) ? 0 : Number(betAmount))
      )
    );
    dispatch(setBetUSDAmount(isNaN(Number(betAmount)) ? 0 : Number(betAmount)));
  }, [betAmount, account]);

  return (
    <Box className={classes.body}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <Box component="img" src="/images/icons/enter.png" />
          <Box component="span">New quick bet</Box>
        </Box>
        <Button
          className={classes.backBut}
          onClick={() => navigate("/predictions")}
        >
          <img src="/images/prediction/return.png" alt="return" width={15} />
          <Typography variant="body2" className={classes.back}>
            Back to overview
          </Typography>
        </Button>
      </Box>
      <Box className={classes.betBody}>
        <Box className={classes.betDetail}>
          <Box className={classes.betText}>
            1. What token do you want to bet on ?
          </Box>
          <Box className={classes.betPart}>
            <Button
              className={clsx(
                classes.roundButton,
                classes.betTokenBut,
                priceToken ? classes.focusBut : classes.scaleAnimation
              )}
              onClick={handleClick}
            >
              {priceToken ? (
                <Box
                  component={"img"}
                  src={`/images/tokens/${priceToken?.img}.png`}
                  className={classes.tokenImg}
                ></Box>
              ) : (
                <AddIcon />
              )}
            </Button>
            <CustomMenuUnit
              anchorEl={anchorEl}
              tokens={tokens}
              menuOpen={menuOpen}
              handleClose={handleClose}
              changeAsset={changeAsset}
            />
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.betDetail,
            priceToken ? null : classes.disabled
          )}
        >
          <Box className={classes.betText}>
            2. Do you think the price will go up or down ?
          </Box>
          <Box className={classes.betPart}>
            <Button
              className={clsx(
                classes.roundButton,
                classes.priceUpBut,
                operator === 0 ? classes.focusBut : null
              )}
              onClick={() => handleOperator(0)}
              disabled={priceToken ? false : true}
            >
              <Box component={"img"} src="/images/prediction/up-arrow.png" />
              Up
            </Button>
            <Button
              className={clsx(
                classes.roundButton,
                classes.priceDownBut,
                operator === 1 ? classes.focusBut : null
              )}
              onClick={() => handleOperator(1)}
              disabled={priceToken ? false : true}
            >
              <Box component={"img"} src="/images/prediction/down-arrow1.png" />
              Down
            </Button>
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.betDetail,
            priceToken && operator !== -1 ? null : classes.disabled
          )}
        >
          <Box className={classes.betText}>
            3. What duration of time should the bet run ?
          </Box>
          <Box className={classes.betPart}>
            <Button
              className={clsx(
                classes.roundButton,
                classes.timeBut,
                duration === 10 ? classes.focusBut : null
              )}
              onClick={() => handleDuration(10)}
              disabled={priceToken && operator !== -1 ? false : true}
            >
              10min
            </Button>
            <Button
              className={clsx(
                classes.roundButton,
                classes.timeBut,
                duration === 30 ? classes.focusBut : null
              )}
              onClick={() => handleDuration(30)}
              disabled={priceToken && operator !== -1 ? false : true}
            >
              30min
            </Button>
            <Button
              className={clsx(
                classes.roundButton,
                classes.timeBut,
                duration === 60 ? classes.focusBut : null
              )}
              onClick={() => handleDuration(60)}
              disabled={priceToken && operator !== -1 ? false : true}
            >
              60min
            </Button>
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.betDetail,
            priceToken && operator !== -1 && duration !== 0
              ? null
              : classes.disabled
          )}
        >
          <Box className={classes.betText}>
            4. How much would you like to bet ?
            <Box className={classes.usdValue}>
              <TextField
                value={betAmount}
                onChange={handleChangeUsd}
                disabled={
                  priceToken && operator !== -1 && duration !== 0 ? false : true
                }
              />
              $
              <Box component={"span"} onClick={handleClearUsd}>
                clear
              </Box>
            </Box>
          </Box>
          <Box className={classes.betPart}>
            <Button
              className={clsx(
                classes.roundButton,
                classes.betTokenBut,
                enteringToken
                  ? classes.focusBut
                  : priceToken &&
                    operator !== -1 &&
                    duration !== 0 &&
                    Number(betAmount) > 0
                  ? classes.scaleAnimation
                  : null
              )}
              disabled={
                priceToken && operator !== -1 && duration !== 0 ? false : true
              }
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (!account) {
                  Toast.fire({
                    icon: "warning",
                    title: "Please connect wallet.",
                  });
                } else if (
                  !betAmount ||
                  isNaN(Number(betAmount)) ||
                  Number(betAmount) <= 0
                ) {
                  Toast.fire({
                    icon: "warning",
                    title: "Please input the bet USD.",
                  });
                } else {
                  setSelectButAnchor(event.currentTarget);
                }
              }}
            >
              {enteringToken ? (
                <Box
                  component={"img"}
                  width={40}
                  src={`/images/tokens/${enteringToken.name.toLowerCase()}.png`}
                ></Box>
              ) : (
                <AddIcon />
              )}
            </Button>
            <TokenTakerDialogWithoutEnter
              betTokens={betTokens}
              tokenBalances={tokenBalances}
              usdAmount={isNaN(Number(betAmount)) ? 0 : Number(betAmount)}
              butAnchor={selectButAnchor}
              onClose={setSelectButAnchor}
              setEnteringToken={(token: BetToken) => setEnteringToken(token)}
            />
            <Button
              className={clsx(classes.roundButton, classes.usdBut)}
              onClick={() => handlePlusUsd(5)}
              disabled={
                priceToken && operator !== -1 && duration !== 0 ? false : true
              }
            >
              +5$
            </Button>
            <Button
              className={clsx(classes.roundButton, classes.usdBut)}
              onClick={() => handlePlusUsd(50)}
              disabled={
                priceToken && operator !== -1 && duration !== 0 ? false : true
              }
            >
              +50$
            </Button>
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.betDetail,
            priceToken && operator !== -1 && duration !== 0 && enteringToken
              ? null
              : classes.disabled
          )}
        >
          <Box className={classes.betText}>
            5. Confirm and create your price prediction bet
          </Box>
          <Box className={classes.betPart}>
            <Button
              className={classes.createBut}
              disabled={
                priceToken &&
                operator !== -1 &&
                duration !== 0 &&
                enteringToken &&
                !creatingRound
                  ? false
                  : true
              }
              onClick={handleCreatePredict}
            >
              <CheckIcon />
              Create bet
              {creatingRound && (
                <CircularProgress
                  size={15}
                  sx={{ color: "white", marginLeft: "3px" }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
