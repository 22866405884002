import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  profileBody: {
    display: "grid",
    width: "100%",
    gap: "50px",
    gridTemplateColumns: "3fr 4fr 3fr",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  statistics: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    justifyContent: "flex-end",
  },
  statisticsHeader: {
    display: "flex",
    gap: "15px",
    fontSize: "20px",
    alignItems: "flex-end",
  },
  statisticsChartIcon: {
    width: "30px",
  },
  statisticsBody: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  statisticsDetail: {
    display: "flex",
    justifyContent: "space-between",
    "&>div:first-of-type": {
      color: "#444E68",
    },
  },
  detailValue: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  detailRank: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    backgroundColor: "#1b1f28",
    fontSize: "9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rank: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "flex-end",
  },
  rankHeader: {
    display: "flex",
    gap: "15px",
    fontSize: "20px",
    alignItems: "center",
    justifyContent: "right",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  winFontColor: {
    color: "#3bcf73",
  },
  ranking: {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    backgroundColor: "#1b1f28",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#3bcf73",
  },
  rankBody: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  rankDetail: {
    display: "flex",
    justifyContent: "space-between",
    "&>div:first-of-type": {
      color: "#444E68",
    },
  },
  middleSection: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      order: -1,
    },
  },
  address: {
    fontSize: "22px",
    color: theme.palette.text.default,
  },
  avatar: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.background.lightBack,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "40px",
    backgroundColor: theme.palette.background.dropdown,
  },
  text: {
    color: theme.palette.text.disabled,
    fontSize: "15px",
    display: "flex",
    gap: "5px",
    "& a": {
      color: theme.palette.text.disabled,
    },
  },
  followBut: {
    color: "#444E68",
    textTransform: "none",
  },
  feedback: {
    backgroundColor: "#1b1f28",
    padding: "20px 40px",
    width: "100%",
    borderRadius: "15px",
    color: "#ffffff50",
    textAlign: "center",
    maxWidth: "400px",
  },
  leaderBoard: {
    color: "#444E68",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "right",
  },
  leaderBoardIcon: {
    width: "20px",
  },
  yellow: {
    color: "yellow",
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
}));

export default styles;
