import { useConnectWallet } from "@web3-onboard/react";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Web3 from "web3";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";

import ReactTimeAgo from "react-time-ago";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import CircularLogo from "../components/CircularLogo";
import { gameConfig } from "../config";
import { deadAddress } from "../constants";
import { switchNetwork } from "../helpers/wallet/network";
import { getAirdropInfo, getStakes } from "../reducers/airdrop.slice";
import {
  Notification,
  clearNotificationBadge,
  getNotifications,
  getUnreadNotificationInfo,
  handleNewNotification,
} from "../reducers/notification.slice";
import {
  getPlayerProfiles,
  getUserInfo,
  markReadNotification,
  markReadNotifications,
} from "../reducers/user.slice";
import {
  isLikelyEthereumAddress,
  shortAddress,
  shortName,
  toUSDFormat,
} from "../utils";
import { getCurrentAirdropForStake } from "../utils/airdrop";
import { useStyles } from "./MainLayout.styles";

const pages = [
  {
    title: "Price predictions",
    path: "/predictions",
    icon: "line-chart.png",
    new: true,
  },
  { title: "Events", path: "/events", icon: "basketball.png" },
  {
    title: "Earn",
    path: "/earn",
    icon: "drop-shipping.png",
  },
  { title: "Leaderboard", path: "/leaderboard", icon: "token.png" },
  {
    title: "Docs",
    path: "https://learn.pulsepredict.info",
    icon: "docs.png",
    blank: true,
  },
  { title: "Claim", path: "/airdrop", icon: "love.png", subTitle: "$PREDICT" },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const MainLayout = (props: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { hasMore, notifications, notificationCount } = useAppSelector(
    (state) => state.notification
  );

  const { userInfo } = useAppSelector((state) => state.user);
  const { info, stakingList } = useAppSelector((state) => state.airdrop);
  const [{ wallet }, connect, disconnect] = useConnectWallet();

  const [, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  const [showSidebar, toggleSideBar] = useState<boolean>(false);
  const [showNotification, toggleNotification] = useState<boolean>(false);

  const navbarRef = useRef<React.ElementRef<"div">>(null);
  const currentNavRef = useRef<any>(null);

  const isConnected = wallet ? true : false;
  const account = wallet ? wallet.accounts[0].address : "";
  const isSm = useMediaQuery("(max-width:900px)");

  const [currentPage, setCurrentPage] = useState(0);
  const notificationCountPerPage = 10;
  const [time, setTime] = useState(new Date().getTime() / 1000);

  const predictAmount = stakingList.reduce(
    (total, item) => total + getCurrentAirdropForStake(time, item),
    0
  );

  const handleShowNotification = (event: React.MouseEvent<HTMLElement>) => {
    toggleNotification((previousOpen) => !previousOpen);
    setAnchorElNotification(event.currentTarget);

    if (notifications.length === 0) return;

    dispatch(clearNotificationBadge());
  };

  const handleHideNotification = () => {
    if (showNotification) toggleNotification(false);

    const notificationId = Math.max(
      ...notifications.map((notification, _) => {
        return notification.id;
      })
    );

    dispatch(markReadNotification(notificationId));

    dispatch(
      markReadNotifications({
        notificationId,
        address: account,
      })
    );
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const connectWallet = useCallback(async () => {
    const wallets = await connect();
    if (wallets[0] != null) {
      const web3 = new Web3(wallets[0].provider);
      (window as any).provider = web3;
    }
  }, [connect]);

  const disconnectWallet = useCallback(async () => {
    if (wallet) {
      disconnect({ label: wallet.label });
    }
  }, [wallet, disconnect]);

  const handleMouseMove = (e: React.MouseEvent) => {
    const hoverSpan = document.getElementById("navbar-hover-span");
    if (!navbarRef.current || !hoverSpan) return;
    const childs = navbarRef.current.getElementsByTagName("a");

    for (let i = 0; i < childs.length; i++) {
      const rect = childs[i].getClientRects()[0];

      if (rect.left < e.clientX && rect.right > e.clientX) {
        if (currentNavRef.current === childs[i]) return;
        const navbarRect = navbarRef.current.getClientRects()[0];
        const iconElem = childs[i].getElementsByTagName("img")[0];
        const iconRect = iconElem.getClientRects()[0];
        hoverSpan.style.left =
          iconRect.left - navbarRect.left - (25 - iconRect.width) + "px";
        hoverSpan.style.width = rect.width + 50 + "px";
        hoverSpan.style.opacity = "1";
        currentNavRef.current = childs[i];
        return;
      }
    }

    hoverSpan.style.opacity = "0";
  };

  const handleMouseLeave = () => {
    currentNavRef.current = null;
    const hoverSpan = document.getElementById("navbar-hover-span");
    hoverSpan && (hoverSpan.style.opacity = "0");
  };

  const fetchMoreNotification = () => {
    dispatch(
      getNotifications({
        address: account,
        from: (currentPage + 1) * notificationCountPerPage,
        to: notificationCountPerPage,
      })
    );

    setCurrentPage((currentPage) => currentPage + 1);
  };

  useEffect(() => {
    if (wallet) {
      const checkNetwork = () => {
        const web3 = new Web3(wallet.provider);
        (window as any).provider = web3;
        if (wallet.chains[0].id !== `0x${gameConfig.chainIDHex.toString(16)}`) {
          disconnectWallet();
          switchNetwork(wallet.provider)
            .then(() => {
              connectWallet();
            })
            .catch((error) => {
              disconnectWallet();
            });
        }
      };

      checkNetwork();

      dispatch(getUserInfo(wallet.accounts[0].address));
    }
  }, [wallet, dispatch, connectWallet, disconnectWallet]);

  useEffect(() => {
    if (!wallet) return;

    dispatch(
      getUnreadNotificationInfo({
        address: account,
        notificationId: userInfo.notificationId,
      })
    );

    dispatch(
      getNotifications({
        address: account,
        from: currentPage,
        to: notificationCountPerPage,
      })
    );
  }, [userInfo, dispatch, account, currentPage, wallet]);

  useEffect(() => {
    const setupEventSource = () => {
      const eventSource = new EventSource(
        `${gameConfig.serverUrl}event/notification`
      );
      eventSource.onmessage = async (e) => {
        if (e.data.includes("address")) {
          const notification: Notification = JSON.parse(e.data);
          if (
            notification.address.toLowerCase() === deadAddress ||
            notification.address.toLowerCase() === account.toLowerCase()
          ) {
            dispatch(handleNewNotification(notification));
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();

    return () => {
      eventSource.close();
    };
  }, [account, dispatch]);

  useEffect(() => {
    dispatch(getPlayerProfiles());
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      dispatch(getAirdropInfo(account));
      dispatch(getStakes(account));
    }
  }, [account]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().getTime() / 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const NewBadge = () => {
    return <Box className={clsx(classes.badge)}>NEW</Box>;
  };

  const Sidebar = (
    <Box
      className={classes.sidebar}
      role="presentation"
      onClick={() => toggleSideBar(false)}
    >
      <List
        subheader={
          <ListSubheader
            className={classes.sidebarHeader}
            component="div"
            id="nested-list-subheader"
          >
            <Box
              component="img"
              src="/images/logo.png"
              className={classes.sidebarLogo}
            ></Box>
            <NavLink to="/"> PulsePredict.io</NavLink>
          </ListSubheader>
        }
      >
        {pages.map((item, index) => (
          <ListItem key={item.path}>
            <ListItemButton>
              <NavLink className={classes.sidebarNavLink} to={item.path}>
                <Box className={classes.mobileSidebarList}>
                  {item.title}
                  {item.new && <NewBadge />}
                </Box>
              </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {isConnected && (
          <ListItem>
            <ListItemButton className={classes.sidebarNavLink}>
              <NavLink
                className={classes.sidebarNavLink}
                to={`/profile/${account}`}
              >
                <Typography component="span" sx={{ fontWeight: 700 }}>
                  {shortAddress(account)}
                </Typography>
                <Box
                  component="img"
                  width={18}
                  sx={{ marginLeft: "10px" }}
                  src="/images/icons/user.png"
                ></Box>
              </NavLink>
            </ListItemButton>
          </ListItem>
        )}

        <ListItem>
          <ListItemButton
            className={classes.sideBarConnectWalletBtn}
            onClick={() => (isConnected ? disconnectWallet() : connectWallet())}
          >
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {isConnected ? "Disconnect" : "Connect Wallet"}
            </Typography>
            {isConnected && (
              <Box
                component="img"
                width={18}
                sx={{ marginLeft: "10px" }}
                src="/images/icons/power-off.png"
              ></Box>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const NotificationPanel = () => {
    const canBeOpen = showNotification && Boolean(anchorElNotification);
    const id = canBeOpen ? "transition-popper" : undefined;

    return (
      <Popper
        id={id}
        open={showNotification}
        anchorEl={anchorElNotification}
        transition
        sx={{
          zIndex: 2,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleHideNotification}>
            <Fade {...TransitionProps} timeout={350}>
              <Box className={classes.notificationPanel}>
                <Box className={classes.notificationHeader}>
                  <p>Notifications</p>

                  <Button className={classes.notificationSettingBtn}>
                    <Box
                      component="img"
                      src="/images/icons/settings.png"
                      width={20}
                    />
                  </Button>
                </Box>

                <Box className={classes.notificationList} id="scrollableDiv">
                  {notifications.length > 0 ? (
                    <InfiniteScroll
                      dataLength={notifications.length}
                      hasMore={hasMore}
                      next={fetchMoreNotification}
                      loader={<></>}
                      scrollableTarget="scrollableDiv"
                    >
                      {renderNotifications()}
                    </InfiniteScroll>
                  ) : (
                    <Box className={classes.notificationEmpty}>
                      No new notifications
                    </Box>
                  )}
                </Box>
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    );
  };

  const renderNotifications = () => {
    return notifications.map((notification, index) => {
      return (
        <Box
          className={
            notification.id > userInfo.notificationId || notification.id == null
              ? classes.notificationListItem
              : clsx(classes.notificationListItem, "read")
          }
          key={index}
          sx={{
            cursor: Number.isInteger(notification.roundId) ? "pointer" : "auto",
          }}
          onClick={
            Number.isInteger(notification.roundId)
              ? () => navigate(`/predictions/detail/${notification.roundId}`)
              : () => {}
          }
        >
          <Box className={classes.notificationTypeIcon} width={20}>
            <Box
              component="img"
              src="/images/icons/check_increase.png"
              width={20}
            />
          </Box>

          <Box className={classes.notificationContent}>
            <p> {notification.content}</p>
            <p>
              <ReactTimeAgo
                date={notification.timestamp * 1000}
                locale="en-US"
              />
            </p>
          </Box>
        </Box>
      );
    });
  };

  return (
    <Box>
      <AppBar position="static" sx={{ background: "none", boxShadow: "none" }}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.mdLogoWrapper}>
            <Box
              onClick={() => navigate("/")}
              component="img"
              src="/images/logo.png"
              sx={{
                width: { md: 90, xl: 120 },
                marginTop: "20px",
                cursor: "pointer",
              }}
            ></Box>
          </Box>

          <Box sx={{ flexGrow: 1, display: { md: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => toggleSideBar(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              PaperProps={{ className: classes.sidebarPaper }}
              open={showSidebar}
              onClose={() => toggleSideBar(false)}
            >
              {Sidebar}
            </Drawer>
          </Box>

          <Box
            component="img"
            src="/images/logo.png"
            sx={{ display: { lg: "none", md: "block" }, width: 50 }}
          ></Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", lg: "none" },
              flexGrow: 1,
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            PulsePredict
          </Typography>

          <Box
            ref={navbarRef}
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                lg: "flex",
                justifyContent: "space-around",
              },
              justifyContent: "center",
              position: "relative",
            }}
            onMouseMove={(e) => handleMouseMove(e)}
            onMouseLeave={() => handleMouseLeave()}
          >
            {pages.map((page) => (
              <NavLink
                key={page.path}
                to={page.path}
                onClick={handleCloseNavMenu}
                className={classes.mdLink}
                target={page.blank ? "_blank" : undefined}
              >
                <Box
                  className={classes.lgNavIcon}
                  component={"img"}
                  src={`/images/icons/${page.icon}`}
                ></Box>
                {page.subTitle && account ? "" : page.title}
                {page.subTitle && (
                  <Box className={classes.subDetailPart}>
                    <CircularLogo width={30} />
                    <Box className={classes.detailSubTitle}>
                      {account
                        ? toUSDFormat(
                            predictAmount +
                              info.activity +
                              info.nft +
                              info.sacrifice,
                            0
                          )
                        : page.subTitle}
                    </Box>
                  </Box>
                )}
                {page.new && <NewBadge />}
              </NavLink>
            ))}
            <Box
              component="span"
              className={classes.navHoverSpan}
              id="navbar-hover-span"
            />
          </Box>

          <Box className={classes.userSection}>
            {isConnected && (
              <>
                <Button
                  className={classes.notificationBtn}
                  aria-describedby={"transition-popper"}
                  onClick={handleShowNotification}
                >
                  <Badge
                    badgeContent={notificationCount}
                    color="error"
                    sx={{ cursor: "pointer" }}
                  >
                    <Box
                      component="img"
                      src="/images/icons/notification.png"
                      width={21}
                    />
                  </Badge>
                </Button>

                {NotificationPanel()}
              </>
            )}

            {isConnected && (
              <Box className={classes.menuListBody}>
                <NavLink
                  to={`profile/${account}`}
                  style={{
                    color: "#444E68",
                  }}
                  className={classes.profileLinkText}
                >
                  My profile
                </NavLink>
                <NavLink
                  to={`profile/${account}`}
                  className={classes.userProfileBtn}
                >
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    {isLikelyEthereumAddress(userInfo.name) || !userInfo.name
                      ? isSm
                        ? shortAddress(account, 3, 3)
                        : shortAddress(account)
                      : userInfo.name.length > 15
                      ? shortName(userInfo.name)
                      : userInfo.name}
                  </Typography>

                  <Box
                    component="img"
                    width={18}
                    sx={{ marginLeft: "10px", verticalAlign: "sub" }}
                    src={
                      userInfo.avatar.length > 0
                        ? userInfo.avatar
                        : "/images/icons/user.png"
                    }
                  ></Box>
                </NavLink>
              </Box>
            )}

            <Tooltip title={isConnected ? "Disconnect" : "Connect"}>
              <Button
                className={classes.connectWalletBtn}
                onClick={() =>
                  isConnected ? disconnectWallet() : connectWallet()
                }
              >
                {isConnected ? (
                  <>
                    <Box
                      component="img"
                      width={24}
                      src="/images/icons/power-off.png"
                    ></Box>
                  </>
                ) : isSm ? (
                  <>
                    <Box
                      component={"img"}
                      width={24}
                      src="/images/icons/wallet.png"
                    />
                  </>
                ) : (
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    Connect Wallet
                  </Typography>
                )}
              </Button>
            </Tooltip>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default MainLayout;
