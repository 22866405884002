import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import ConfettiExplosion from "react-confetti-explosion";

import CheckIcon from "@mui/icons-material/Check";
import theme from "../../theme";
import useStyles from "./index.styles";

interface TicketEnteredDialogProps {
  open: boolean;
  onTicketView: () => void;
  ticketId: number;
  onNewCreate: () => void;
  onClose: () => void;
  ticketCount: number;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TicketEnteredDialog = (props: TicketEnteredDialogProps) => {
  const { classes } = useStyles();

  return (
    <BootstrapDialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            minHeight: "500px",
            backgroundColor: "#1c1f28",
            padding: "20px 30px",
            borderRadius: "10px",
            [theme.breakpoints.up("lg")]: {
              maxWidth: "800px",
            },
            [theme.breakpoints.down("lg")]: {
              maxWidth: "600px",
            },
            [theme.breakpoints.down("sm")]: {
              maxWidth: "90%",
              minHeight: "300px",
            },
            "& .MuiDialogContent-root": {
              [theme.breakpoints.down("sm")]: {
                padding: "0",
              },
            },
          },
        },
      }}
    >
      <DialogContent>
        <Box className={classes.header}>
          <CheckIcon className={classes.checkIcon} />
          <Box>Success! #{props.ticketId} Your ticket is placed</Box>
        </Box>
        <Box className={classes.content}>
          <Button className={classes.createButton} onClick={props.onNewCreate}>
            <AddCircleOutlineIcon />
            Create another ticket
          </Button>
          <Box className={classes.orText}>or</Box>
          <Button
            className={classes.viewTicketButton}
            onClick={props.onTicketView}
          >
            <Box component="img" src="/images/icons/ticket.png" /> View ticket #
            {props.ticketId}
          </Button>
          <Box className={classes.predictText}>You also earned</Box>
          <Box className={classes.predictBody}>
            <Box>
              <Box component="img" src="/images/logo.png" />
            </Box>
            <Box>{500 * props.ticketCount}</Box>
            <Box>$PREDICT</Box>
            <Box component="img" src="/images/icons/burn.png" />
          </Box>
          <Box className={classes.description}>
            View your active tickets by navigating to the "My tickets" block and
            click "Show"
          </Box>
        </Box>
      </DialogContent>
      <ConfettiExplosion
        duration={2500}
        zIndex={10000}
        width={2000}
        height={"100vh"}
        style={{ position: "absolute", top: "0", left: "50%", width: "100%" }}
      />
    </BootstrapDialog>
  );
};

export default TicketEnteredDialog;
