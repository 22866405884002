import Theme from "../../theme";
import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  roundDetail: {
    backgroundColor: theme.palette.background.main,
    width: "100%",
    marginTop: "50px",
  },
  tabBoxes: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minHeight: "300px",
  },
  tabBox: {
    display: "flex",
    gap: "30px",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "530px",
  },
  skeleton: {
    padding: "70px 0",
    width: "calc((100% - 30px) / 2)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  noRounds: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    color: "#444E68",
  },
  paginationBody: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
  },
}));

export default styles;
