import { ethers } from "ethers";

export const formatUFCEnteredRoundInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    entryId: Number(event.result.returnValues.entryId),
    player: event.result.returnValues.player,
    tokenName: event.result.returnValues.tokenName,
    tokenAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.tokenAmount)
    ),
    usdAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.usdAmount)
    ),
    expectation: Number(event.result.returnValues.expectation),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};
export const formatUFCRoundFinishedInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    result: Number(event.result.returnValues.result),
    winner: event.result.returnValues.winner,
    usdAmount: Number(
      ethers.utils.formatEther(event.result.returnValues.usdAmount)
    ),
    fee: Number(ethers.utils.formatEther(event.result.returnValues.fee)),
    plspBurnt: Number(
      ethers.utils.formatEther(event.result.returnValues.plspBurnt)
    ),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};
export const formatUFCRoundCancelledInfo = (event: any) => {
  return {
    roundId: Number(event.result.returnValues.roundId),
    timestamp: Number(event.result.returnValues.timestamp),
  };
};
