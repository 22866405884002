import React from "react";
import { Button, Box, Popover, Slider } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils";

interface PredictRangePopupProps {
  rangeValue: number[];
  setRangeValue: (rangeValue: number[]) => void;
}

export default function PredictRangePopup(props: PredictRangePopupProps) {
  const { roundMaxValue } = useAppSelector((state) => state.predict);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const marks = [
    {
      value: 0,
      label: 0,
    },
    {
      value: roundMaxValue,
      label: roundMaxValue,
    },
  ];

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      props.setRangeValue([newValue[0], props.rangeValue[1]]);
    } else {
      props.setRangeValue([props.rangeValue[0], newValue[1]]);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{
            minWidth: "120px",
            backgroundColor: "#1c1f28",
            borderRadius: "20px",
            height: "30px",
            "&:hover": {
              backgroundColor: "#1c1f28",
            },
          }}
        >
          {toUSDFormat(props.rangeValue[0])} -{" "}
          {toUSDFormat(props.rangeValue[1])}
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#1c1f28",
            borderRadius: "10px",
            padding: "40px 25px 10px 25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
          "& .MuiSlider-markLabel": {
            color: "white",
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <Slider
            getAriaLabel={() => "Minimum distance"}
            value={props.rangeValue}
            onChange={handleChange}
            valueLabelDisplay="auto"
            disableSwap
            max={roundMaxValue}
            min={0}
            marks={marks}
          />
        </Box>
      </Popover>
    </div>
  );
}
