import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  ufcEntries: {
    padding: "24px 24px 16px 24px",
    borderRadius: "12px",
    backgroundColor: "#1b1f28",
    flexGrow: 1,
  },
  ufcEntriesHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerUserCount: {
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "600",
  },
  headerTotalUsd: {
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "600",
  },
  ufcEntriesList: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "16px",
    paddingRight: "8px",
    minHeight: "240px",
    maxHeight: "240px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "rgba(83, 83, 83, 0.377)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(255, 255, 255, 0.2)",
    },
  },
  entriesListItem: {
    display: "grid",
    gridTemplateColumns: "1fr 4fr 2fr 2fr 1fr",
    "& span": {
      color: "#acacac",
      fontWeight: "600",
    },

    "& img": {
      width: "20px",
      height: "20px",
    },
  },
  entryUsdAmount: {
    color: "#778199",
  },
  entryTokenImageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default styles;
