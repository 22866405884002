import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  euroMatchList: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",

    margin: "32px 0 0 0",
    maxHeight: "540px",
    overflowY: "auto",
    padding: "0 12px",

    [theme.breakpoints.down("md")]: {
      order: 3,
      margin: "12px 0",
    },
  },
}));

export default styles;
