import { makeStyles } from "tss-react/mui";

const scaleAnimation = {
  "0%": {
    transform: "scale(0.8)",
    opacity: 0,
  },
  "100%": {
    transform: "scale(1)",
    opacity: 1,
  },
};

const styles = makeStyles()((theme: any) => ({
  modalOverlay: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    left: "0",
    top: "0",
    background: "rgba(0, 0, 0, 0.6)",
    zIndex: "14",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    padding: "32px",
    width: "640px",
    backgroundColor: "#383944",
    borderRadius: "16px",
    position: "relative",
    animation: "scaleAnimation 0.3s",
    "@keyframes scaleAnimation": scaleAnimation,
    transform: "scale(1)",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
  },
  modalClose: {
    position: "absolute",
    right: "32px",
    top: "32px",
    width: "32px",
    height: "32px",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    "& svg": {
      width: "32px",
      height: "32px",
      transform: "translate(0.5, 0.5)",
      color: "grey",
    },
  },
  disclaimerContent: {
    "&>h3": {
      fontWeightL: "600",
      fontsize: "32px",
      color: "#cd72ee",
      textAlign: "center",
      marginTop: "4px",
      marginLeft: "4px",
    },
  },
}));

export default styles;
