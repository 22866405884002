import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "100px",
    paddingBottom: "30px",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },
  },
  bodyContainer: {
    minWidth: "700px",
    maxWidth: "1200px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
      maxWidth: "100%",
      padding: "0 10px",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "center",
  },
  mainBody: {
    marginTop: "80px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "100px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "40px",
    },
  },
  detail: {
    display: "grid",
    gridTemplateColumns: "1fr 7fr 2fr",
    alignItems: "center",
    gap: "5px",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#1b1f28",
    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",
    },
  },
  checkIcon: {
    color: "#219b6b",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  hourGlassIcon: {
    color: "#c87b3d",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  text: {
    color: "#404554",
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  value: {
    display: "flex",
    gap: "5px",
    justifyContent: "right",
    alignItems: "center",
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      gap: "5px",
    },
  },
  pendingHeader: {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    paddingBottom: "40px",
    paddingLeft: "15px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "20px",
    },
    "&>img": {
      width: "25px",
    },
  },
  completeHeader: {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    paddingBottom: "40px",
    paddingLeft: "15px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "20px",
    },
    "&>img": {
      width: "25px",
    },
  },
  pendingBody: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  completeBody: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  completeDetailsBody: {
    backgroundColor: "#1b1f28",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderRadius: "15px",
    padding: "20px 20px 20px 0",
  },
  pendingDetailsBody: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderRadius: "15px",
    padding: "20px 20px 20px 0",
  },
  skeletonBody: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  skeletonDetail: {
    display: "flex",
    gap: "10px",
    width: "100%",
    minWidth: "500px",
    alignItems: "center",
    "&>span:nth-of-type(1)": {
      width: "50px",
      height: "50px",
    },
    "&>span:nth-of-type(2)": {
      width: "calc(100% - 120px)",
      height: "50px",
      borderRadius: "50px",
    },
    "&>span:nth-of-type(3)": {
      width: "50px",
      height: "50px",
    },
  },
}));

export default styles;
