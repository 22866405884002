import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  getPriceTokenLp,
  getPriceTokens,
  getTokenPrice,
  getTokenPriceFromContract,
  PredictToken,
  setEndTime,
  setInitialBetAmount,
  setPricePoint,
  setPriceToken,
} from "../../reducers/predict.slice";
import { ceilDecimal } from "../../utils";

import PredictCreateConfirmComp from "../PredictCreateConfirmComp";

import CustomDateTimePicker from "./CustomDateTimePicker";
import CustomMenuUnit from "./CustomMenu";
import { CustomNumberFormat } from "./CustomNumberFormat";

import { useStyles } from "./index.styles";

const PredictionCreateOptions = () => {
  const { tokens, newRoundInfo, prices } = useAppSelector(
    (state) => state.predict
  );
  const { priceToken, pricePoint, endTime, initialBetAmount } = newRoundInfo;
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [percent, setPercent] = useState(0);
  const [price, setPrice] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAsset = (item: PredictToken, index: number) => {
    dispatch(setPriceToken({ item, index }));

    dispatch(getTokenPriceFromContract({ tokenId: item.id }));
    dispatch(getTokenPrice({ tx: item.dexLink }));
  };

  const changePrice = (e: any) => {
    const value = parseFloat(e.target.value.replace(/[^\d.]/g, ""));

    dispatch(setPricePoint(value));
  };

  const changeInitialValue = (e: any) => {
    const value = parseFloat(e.target.value.replace(/[^\d.]/g, ""));

    dispatch(setInitialBetAmount(value));
  };

  const increasePrice = () => {
    if (!!pricePoint) dispatch(setPricePoint((pricePoint * 101) / 100));
  };
  const decreasePrice = () => {
    if (!!pricePoint) dispatch(setPricePoint((pricePoint * 99) / 100));
  };

  const increaseInitialValue = () => {
    if (!!initialBetAmount) dispatch(setInitialBetAmount(initialBetAmount + 1));
  };

  const decreaseInitialValue = () => {
    if (!!initialBetAmount) dispatch(setInitialBetAmount(initialBetAmount - 1));
  };

  const openDateTimePicker = () => {
    setOpen(true);
  };
  const handleOpen = (status: boolean) => {
    setOpen(status);
  };
  const handleDate = (value: number) => {
    dispatch(setEndTime(new Date(value.toString()).getTime()));
  };

  useEffect(() => {
    if (tokens.length === 0) return;

    const fetchLp = async () => {
      await dispatch(getPriceTokenLp(tokens.map((token, index) => token.pair)));
    };

    fetchLp();
  }, [tokens, dispatch]);

  useEffect(() => {
    if (price > 0 && pricePoint !== undefined) {
      setPercent((100 * (pricePoint - price)) / price);
    }
  }, [price, pricePoint]);

  useEffect(() => {
    if (prices.priceUsd !== undefined && typeof prices.priceUsd === "number") {
      setPrice(prices.priceUsd);
      dispatch(setPricePoint(prices.priceUsd));
    }
  }, [prices]);

  useEffect(() => {
    dispatch(setEndTime(new Date().getTime() + 60 * 60 * 1000));
    dispatch(getPriceTokens());
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <Box className={classes.statusUnit}>
        <Box className={classes.statusUnitLabel}>
          <Box>
            <Typography variant="h6">1.</Typography>
            <Typography variant="body2">Select asset</Typography>
          </Box>
          <Tooltip
            title={
              <Box className={classes.tooltip}>
                <p>
                  Select the asset from our Chainlink oracle supported list in
                  the dropdown.
                </p>
                <p>
                  This asset is what you will attempt to predict the price on.
                </p>
              </Box>
            }
          >
            <img
              className={classes.infoImg}
              src="/images/prediction/info.png"
              alt="info"
              width={15}
            />
          </Tooltip>
        </Box>
        <Box>
          <Button
            id="demo-positioned-button"
            aria-controls={menuOpen ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={handleClick}
            className={classes.selectedAssetBtn}
          >
            {priceToken !== null && priceToken !== undefined ? (
              <Box className={classes.selectedAssetDiv}>
                <Box className={classes.selectedToken}>
                  <img
                    src={`/images/tokens/${priceToken?.img}.png`}
                    width={20}
                    alt="token"
                  />
                  {priceToken.token}
                </Box>
                <Box className={classes.selectedchain}>
                  <img
                    src={`/images/prediction/${priceToken.chain}.png`}
                    alt="chain"
                    width={20}
                  />
                  {priceToken.chain}
                  <Box className={classes.down}>
                    <img
                      src="/images/prediction/down.png"
                      alt="down"
                      width={10}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className={classes.emptyDiv}></Box>
            )}
          </Button>
          <CustomMenuUnit
            anchorEl={anchorEl}
            tokens={tokens}
            menuOpen={menuOpen}
            handleClose={handleClose}
            changeAsset={changeAsset}
          />
        </Box>
      </Box>

      <Box className={classes.statusUnit}>
        <Box className={classes.statusUnitLabel}>
          <Box>
            <Typography variant="h6">2.</Typography>
            <Typography variant="body2">Price point</Typography>
          </Box>
        </Box>
        <Box className={classes.inputField}>
          <TextField
            variant="filled"
            label=""
            className={classes.priceInput}
            name="price"
            value={pricePoint}
            onChange={changePrice}
            placeholder="0"
            InputProps={{
              inputComponent: CustomNumberFormat,
            }}
          />
          <Box style={{ position: "absolute", left: -35, top: 10 }}>
            <Tooltip
              title={
                <Box className={classes.tooltip}>
                  <p>Increase / decrease from current price.</p>
                </Box>
              }
            >
              <Typography
                variant="body2"
                style={{
                  color: percent >= 0 ? "lime" : "red",
                  fontSize: "16px",
                }}
              >
                {ceilDecimal(percent, 0)}%
              </Typography>
            </Tooltip>
          </Box>
          <img
            src="/images/prediction/tag.png"
            alt="tag"
            width={18}
            className={classes.tagImg}
          />

          <Box className={classes.controlPriceBtnGrouup}>
            <Button
              variant="contained"
              onClick={increasePrice}
              className={classes.controlPriceBtn}
            >
              <img src="/images/prediction/up.png" alt="up" width={15} />
            </Button>
            <Button
              variant="contained"
              onClick={decreasePrice}
              className={classes.controlPriceBtn}
            >
              <img src="/images/prediction/down.png" alt="down" width={15} />
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className={classes.statusUnit}>
        <Box className={classes.statusUnitLabel}>
          <Box>
            <Typography variant="h6">3.</Typography>
            <Typography variant="body2">Date & time</Typography>
          </Box>

          <Tooltip
            title={
              <Box className={classes.tooltip}>
                <p>
                  Select the date of which the contract will check if the asset
                  price is above or under the selected price point.
                </p>
              </Box>
            }
          >
            <img
              className={classes.infoImg}
              src="/images/prediction/info.png"
              alt="info"
              width={15}
            />
          </Tooltip>
        </Box>
        <Box position={`relative`}>
          <CustomDateTimePicker
            open={open}
            date={endTime}
            handleOpen={handleOpen}
            handleDate={handleDate}
            openDateTimePicker={openDateTimePicker}
          />
        </Box>
      </Box>

      <Box className={classes.statusUnit}>
        <Box className={classes.statusUnitLabel}>
          <Box>
            <Typography variant="h6">4.</Typography>
            <Typography variant="body2">Total price call value</Typography>
          </Box>

          <Tooltip
            title={
              <Box className={classes.tooltip}>
                <p>
                  Define the total amount of value all participants needs to put
                  into the bet.
                </p>
              </Box>
            }
          >
            <img src="/images/prediction/info.png" alt="info" width={15} />
          </Tooltip>
        </Box>
        <Box className={classes.inputField}>
          <TextField
            variant="filled"
            label=""
            className={classes.priceInput}
            name="price"
            value={initialBetAmount}
            onChange={changeInitialValue}
            placeholder="0"
            InputProps={{
              inputComponent: CustomNumberFormat,
            }}
          />
          <img
            src="/images/prediction/tag.png"
            alt="tag"
            width={18}
            className={classes.tagImg}
          />

          <Box className={classes.controlPriceBtnGrouup}>
            <Button
              variant="contained"
              onClick={increaseInitialValue}
              className={classes.controlPriceBtn}
            >
              <img src="/images/prediction/up.png" alt="up" width={15} />
            </Button>
            <Button
              variant="contained"
              onClick={decreaseInitialValue}
              className={classes.controlPriceBtn}
            >
              <img src="/images/prediction/down.png" alt="down" width={15} />
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className={classes.statusUnit}>
        <Box className={classes.statusUnitLabel}>
          <Box>
            <Typography variant="h6">5.</Typography>
            <Typography variant="body2">
              Define odds & select prediction
            </Typography>
          </Box>

          <Tooltip
            title={
              <Box className={classes.tooltip}>
                <p>Define your desired odds ratio between each outcome.</p>
                <p>Who is the favorite and who is the underdog?</p>
                <p>Also select the side which you want to bet on.</p>
              </Box>
            }
          >
            <img
              className={classes.infoImg}
              src="/images/prediction/info.png"
              alt="info"
              width={15}
            />
          </Tooltip>
        </Box>
        <Box></Box>
      </Box>
      <PredictCreateConfirmComp />
    </Box>
  );
};

export default PredictionCreateOptions;
