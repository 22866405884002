import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import theme from "../../theme";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import useStyles from "./index.styles";
import TokenImage from "../TokenImage";
import { PredictToken, PriceTokenLp } from "../../reducers/predict.slice";
import { toUSDFormat } from "../../utils";
import clsx from "clsx";

interface IProps {
  token: PredictToken;
  lp: PriceTokenLp | null;
  addFavoriteToken: (address: string) => void;
  removeFavoriteToken: (address: string) => void;
  favorite: number;
}

export default function TokenCardDetail(props: IProps) {
  const { classes } = useStyles();

  return (
    <Card
      sx={{
        width: "calc((100% - 90px) / 4)",
        backgroundColor: theme.palette.background.dropdown,
        padding: "0",
        [theme.breakpoints.down("md")]: {
          width: "calc((100% - 30px) / 2)",
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
    >
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardHeader}>
          <Box className={classes.chain}>{props.token.chain}</Box>
          <StarOutlineIcon
            className={clsx(
              classes.star,
              props.favorite !== -1 ? classes.starFocused : null
            )}
            onClick={() => {
              props.token.tokenAddress &&
                (props.favorite !== -1
                  ? props.removeFavoriteToken(props.token.tokenAddress)
                  : props.addFavoriteToken(props.token.tokenAddress));
            }}
          />
        </Box>
        <Box className={classes.token}>
          <TokenImage token={props.token.img} sx={{ width: "30px" }} />
          <Box className={classes.tokenText}>{props.token.token}</Box>
          <Box className={classes.amount}>
            ${toUSDFormat(props.lp ? props.lp.liquidity : 0, 2)}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
