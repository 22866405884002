import React from "react";
import useStyles from "./index.styles";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TextContent() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <Box className={classes.contentBody}>
      <Box className={classes.topText}>Peer-to-peer prediction market</Box>
      <Box className={classes.middleText}>
        Make your <br /> Pulse predictions
      </Box>
      <Button
        className={classes.button}
        onClick={() => navigate("/predictions/create")}
      >
        <Box
          component={"img"}
          src="/images/prediction/thunder-yellow.png"
          className={classes.createIcon}
        />
        Create bet
        <Box
          component={"img"}
          src="/images/prediction/hand.png"
          className={classes.handsIcon}
        />
      </Button>
    </Box>
  );
}
