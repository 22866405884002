import { makeStyles } from "tss-react/mui";
import theme from "../../theme";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  header: {
    display: "flex",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    borderBottom: "1px solid #222630",
    paddingBottom: "20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50px",
    paddingBottom: "40px",
    gap: "20px",
  },
  inputBox: {
    display: "grid",
    gridTemplateColumns: "4fr 3fr",
    width: "80%",
    gap: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    justifyContent: "center",
    "& img": {
      width: "25px",
    },
    "&>div:first-of-type": {
      maxWidth: "250px",
      height: "50px",
      display: "flex",
      gap: "15px",
      backgroundColor: "#0f1117",
      borderRadius: "10px",
      padding: "15px 15px",
      color: "white",
      textTransform: "none",
      fontSize: "17px",
      justifyContent: "left",
      alignItems: "center",
    },
    "&>div:last-of-type": {
      maxWidth: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      "&>button": {
        color: "white",
        backgroundColor: "#21ac74",
        maxWidth: "120px",
        minWidth: "120px",
        height: "50px",
        borderRadius: "10px",
        padding: "0 30px",
        textTransform: "none",
        ":disabled": {
          backgroundColor: theme.palette.background.lightBack,
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
  },
  descriptionBox: {
    display: "grid",
    gridTemplateColumns: "4fr 3fr",
    width: "80%",
    gap: "50px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& img": {
      width: "25px",
    },
    "&>div:first-of-type": {
      maxWidth: "250px",
      width: "100%",
      height: "50px",
      display: "flex",
      gap: "15px",
      backgroundColor: "#0f1117",
      borderRadius: "10px",
      padding: "15px 15px",
      color: "white",
      textTransform: "none",
      fontSize: "17px",
      justifyContent: "left",
      marginTop: "10px",
      alignItems: "center",
    },
    "&>div:last-of-type": {
      maxWidth: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
  },
}));

export default styles;
