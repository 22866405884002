import { Skeleton, Box } from "@mui/material";

import Blocky from "../Blocky";
import TokenImage from "../TokenImage";

import { shortAddress, shortName, toUSDFormat } from "../../utils";
import { useAppSelector } from "../../app/hooks";

import { gameConfig } from "../../config";
import { UFCPlayerType } from "../../constants/ufc";
import useStyles from "./index.styles";

interface UfcEntriesProps {
  readonly player: number;
}

export default function UfcEntries({ player }: UfcEntriesProps) {
  const { classes } = useStyles();
  const { profiles } = useAppSelector((state) => state.user);

  const { currentRoundDetails, loadingRoundDetails } = useAppSelector(
    (state) => state.ufc
  );

  return (
    <Box className={classes.ufcEntries}>
      <Box className={classes.ufcEntriesHeader}>
        <Box className={classes.headerUserCount}>
          <Box
            component="img"
            src="/images/events/ufc/entries_coin.png"
            alt=""
            srcSet=""
          />
          {loadingRoundDetails ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ bgcolor: "#ffffff1c" }}
              width={80}
              height={21}
            />
          ) : player === UFCPlayerType.PLAYER1 ? (
            `${currentRoundDetails.player1EntryData.length} Players`
          ) : (
            `${currentRoundDetails.player2EntryData.length} Players`
          )}
        </Box>
        <Box className={classes.headerTotalUsd}>
          <Box
            component="img"
            src="/images/events/ufc/entries_coin.png"
            alt=""
            srcSet=""
          />
          {loadingRoundDetails ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ bgcolor: "#ffffff1c" }}
              width={80}
              height={21}
            />
          ) : player === UFCPlayerType.PLAYER1 ? (
            `$${toUSDFormat(currentRoundDetails.player1TotalUsd)}`
          ) : (
            `$${toUSDFormat(currentRoundDetails.player2TotalUsd)}`
          )}
        </Box>
      </Box>
      <Box className={classes.ufcEntriesList}>
        {loadingRoundDetails
          ? [0, 0, 0, 0, 0, 0, 0, 0, 0].map((_, index) => {
              return (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={{ bgcolor: "#ffffff1c" }}
                  width="100%"
                  height={20}
                  key={index}
                />
              );
            })
          : (player === UFCPlayerType.PLAYER1
              ? currentRoundDetails.player1EntryData
              : currentRoundDetails.player2EntryData
            ).map((entry, index) => {
              const profile = profiles.find(
                (profile) =>
                  profile.address.toLowerCase() === entry.player.toLowerCase()
              );
              return (
                <Box className={classes.entriesListItem} key={index}>
                  {profile && profile.avatar.length > 0 ? (
                    <img src={profile.avatar} alt="" srcSet="" />
                  ) : (
                    <Blocky
                      address={entry.player.toLowerCase()}
                      size={20}
                      scale={1}
                    />
                  )}

                  {profile ? (
                    profile.name.length > 15 ? (
                      shortName(profile.name)
                    ) : (
                      profile.name
                    )
                  ) : (
                    <span>{shortAddress(entry.player)}</span>
                  )}

                  <span className={classes.entryUsdAmount}>
                    ${toUSDFormat(entry.usdAmount)}
                  </span>
                  <span>{toUSDFormat(entry.tokenAmount)}</span>

                  <Box className={classes.entryTokenImageContainer}>
                    <Box
                      component="a"
                      href={gameConfig.explorerUrl + "tx/" + entry.txHash}
                      target="_blank"
                    >
                      <TokenImage
                        token={entry.tokenName}
                        sx={{
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
}
