import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import theme from "../../theme";
import { formatBytes } from "../../utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface InputFileUploadProps {
  avatar: string;
  handleChangeAvatar: (newValue: string) => void;

  file: File | null;
  handleChangeFile: (newFile: File | null) => void;
}

const InputFileUpload: React.FC<InputFileUploadProps> = ({
  avatar,
  handleChangeAvatar,
  file,
  handleChangeFile,
}) => {
  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Set the maximum allowed file size in bytes (e.g., 2 MB)
      const maxFileSize = 500 * 1024;
      const maxWidth = 1024;
      const maxHeight = 1024;

      if (file.type === "image/png" || file.type === "image/jpeg") {
        // Check if the file size is within the allowed limit
        if (file.size <= maxFileSize) {
          const img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = () => {
            if (
              img.naturalWidth <= maxWidth &&
              img.naturalHeight <= maxHeight
            ) {
              handleChangeAvatar(URL.createObjectURL(file));
              handleChangeFile(file);
            } else {
              alert(
                `Image dimensions exceed the maximum allowed limit of ${maxWidth}x${maxHeight}.`
              );
            }
          };
        } else {
          alert(
            `File size exceeds the maximum allowed limit of ${formatBytes(
              maxFileSize
            )}.`
          );
        }
      } else {
        alert("File is not a PNG or JPG image.");
      }
    }
  };

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      sx={{
        width: "120px",
        height: "120px",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.card,
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.palette.background.lightBack,
        fontSize: "12px",
        color: theme.palette.text.disabled,
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: theme.palette.background.card,
        },

        padding: avatar ? "0" : "6px 16px",
      }}
    >
      {avatar ? (
        <>
          <Box
            component="img"
            src={avatar}
            sx={{
              width: "100%",
              borderRadius: "50%",
            }}
          />
        </>
      ) : (
        <>
          <Box
            component="img"
            src="/images/icons/upload.png"
            sx={{
              width: "25px",
            }}
          />
          Upload
        </>
      )}
      <VisuallyHiddenInput type="file" onChange={handleFileChange} />
    </Button>
  );
};

export default InputFileUpload;
