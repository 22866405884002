const firebaseConfig = {
  apiKey: "AIzaSyA_GL8yo9cGM1WygW-NPgw3M1xCum50toc",
  authDomain: "pulsepot-5b629.firebaseapp.com",
  projectId: "pulsepot-5b629",
  storageBucket: "pulsepot-5b629.appspot.com",
  messagingSenderId: "969654326273",
  appId: "1:969654326273:web:82379b16094ca7c0245c00",
};

export default firebaseConfig;
