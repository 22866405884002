import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    justifyContent: "center",
    gap: "100px",
    paddingBottom: "30px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },
  },
  leftSection: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    maxWidth: "350px",
    paddingTop: "50px",
  },
  leftTitle: {
    display: "flex",
    gap: "20px",
    fontSize: "22px",
    alignItems: "center",
    "& img": {
      width: "22px",
    },
  },
  earnActivity: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1c1f28",
    borderRadius: "15px",
    padding: "25px 30px",
    gap: "20px",
    "&>button": {
      padding: "3px 7px",
      backgroundColor: "#1b941b",
      color: "white",
      fontSize: "13px",
      textTransform: "none",
      maxWidth: "100px",
      borderRadius: "5px",
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#25cb25",
      },
    },
  },
  stakeList: {},
  stakeListHeader: {
    color: "#2d3141",
    fontSize: "13px",
    "&>div:nth-of-type(3)": {
      display: "flex",
      justifyContent: "end",
    },
  },
  stakeDetail: {
    padding: "10px 5px",
    borderBottom: "1px solid #2d3141",
    display: "grid",
    gridTemplateColumns: "4fr 3fr 5fr",
    gap: "5px",
  },
  stakeItem: {
    padding: "10px 5px",
    display: "grid",
    gridTemplateColumns: "4fr 3fr 5fr",
    gap: "5px",
    button: {
      padding: "3px 10px",
      textTransform: "none",
      color: "white",
      backgroundColor: "#101219",
      justifyContent: "center",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "#101219",
      },
      "&:disabled": {
        color: "#50535d",
      },
    },
    img: {
      width: "25px",
    },
    "div:nth-of-type(2)": {
      display: "flex",
      gap: "3px",
      justifyContent: "end",
      alignItems: "center",
    },
  },
  stakeListBody: {
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  middleSection: {
    flex: 4,
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&>div:nth-of-type(2)": {
      fontSize: "23px",
      marginTop: "20px",
    },
    "&>div:nth-of-type(3)": {
      fontSize: "24px",
      color: "#454f69",
      marginTop: "20px",
    },
    "&>div:nth-of-type(4)": {
      fontSize: "16px",
      color: "#2b2c3b",
      marginTop: "30px",
    },
    "&>button": {
      color: "white",
      padding: "15px 30px",
      borderRadius: "10px",
      backgroundColor: "#1c1f28",
      marginTop: "35px",
      textTransform: "none",
      fontSize: "18px",
      display: "flex",
      gap: "5px",
      "&>img": {
        width: "30px",
      },
    },
  },
  rightSection: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    maxWidth: "500px",
    paddingTop: "50px",
  },
  rightTitle: {
    display: "flex",
    justifyContent: "space-between",
    "&>div:first-of-type": {
      display: "flex",
      gap: "20px",
      fontSize: "22px",
      alignItems: "center",
      "& img": {
        width: "22px",
      },
    },
    "&>div:last-of-type": {
      display: "flex",
      alignItems: "flex-start",
      fontSize: "14px",
      color: "#50535d",
      marginRight: "20px",
    },
  },
  leaderboard: {
    padding: "20px 20px",
    border: "5px solid #2b2c3b",
    borderRadius: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
      fontSize: "14px",
    },
  },
  leaderboardBody: {
    minHeight: "450px",
    maxHeight: "450px",
    overflowY: "auto",
    padding: "0 10px",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "silver",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#7e889e",
      },
    },
  },
  leaderboardDetail: {
    display: "grid",
    gridTemplateColumns: "2fr 5fr 4fr 4fr",
    gap: "5px",
    padding: "5px 5px",
    width: "100%",
  },
  leaderboardHeader: {
    color: "#4b4c65",
  },

  leaderboardList: {
    borderBottom: "1px solid #2b2c3b",
  },
  comingSoon: {
    fontSize: "22px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
  },
  leaderboardCard: {
    backgroundColor: "#1c1f28",
    padding: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "15px",
    gap: "15px",
    marginBottom: "10px",
    "& span": {
      color: "#4b4c65",
    },
    "&>div:last-of-type": {
      fontSize: "14px",
    },
  },
  rankUp: {
    color: "#648f5f",
  },
  rankDown: {
    color: "#6c4751",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    color: "#4b4c65",
  },
}));

export default styles;
