import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useConnectWallet } from "@web3-onboard/react";
import { ChangeEvent, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";

import theme from "../../theme";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EuroMatchInfo } from "../../types/EuroMatchInfo";
import { toUSDFormat } from "../../utils";
import { getCountryAbbreviationByName } from "../../utils/euro";

import { enterEuroRound } from "../../reducers/euro24.slice";
import useStyles from "./index.styles";

interface EuroMatchEnterPopupProps {
  readonly closeMatchEnterPopup: () => void;
  open: boolean;
  matchInfo?: EuroMatchInfo;
  matchResult: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      backgroundColor: theme.palette.background.dropdown,
    },
  },
};

export default function EuroMatchEnterPopup(props: EuroMatchEnterPopupProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { open, closeMatchEnterPopup, matchInfo, matchResult } = props;

  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";

  const { betTokens, tokenBalances, isEntering } = useAppSelector(
    (state) => state.euro24
  );

  const [usdAmount, setUsdAmount] = useState("");
  const [betToken, setBetToken] = useState("");
  const [selectLabel, setSelectLabel] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const handleBetTokenChange = (event: SelectChangeEvent<typeof betToken>) => {
    setBetToken(event.target.value);
  };

  const handleBetUsdAmountChange = (event: ChangeEvent) => {
    const newUSDAmount = (event.target as HTMLInputElement).value;
    setUsdAmount(newUSDAmount);

    setSelectLabel("");
    setConfirmDisabled(false);
  };

  const handleClose = () => {
    closeMatchEnterPopup();
  };

  const handleConfirm = () => {
    const token = betTokens.find((token) => token.name === betToken);

    if (!matchInfo) return;
    if (!token) return;

    const roundInfo = {
      account,
      roundId: matchInfo.matchId,
      tokenId: betTokens.indexOf(token),
      tokenAmount:
        (token.isStable ? parseFloat(usdAmount) : parseFloat(usdAmount) * 1.1) /
        token.price,
      tokenAddress: token.tokenAddress,
      expectation: matchResult,
      usdAmount: parseFloat(usdAmount),
    };

    dispatch(enterEuroRound(roundInfo))
      .unwrap()
      .then(() => {
        handleClose();
      });
  };

  useEffect(() => {
    if (betToken === "" || usdAmount === "") {
      setConfirmDisabled(true);
    } else {
      setConfirmDisabled(false);

      const currentTokenIndex = betTokens.findIndex(
        (token, index) => token.name === betToken
      );

      if (currentTokenIndex !== -1) {
        const balanceInUSD =
          betTokens[currentTokenIndex].price *
          parseFloat(tokenBalances[betTokens[currentTokenIndex].name]);

        if (balanceInUSD < parseFloat(usdAmount)) {
          setSelectLabel("Insufficient balance");

          setConfirmDisabled(true);
        }
      }
    }
  }, [usdAmount, betToken, betTokens, tokenBalances]);

  return (
    <Box className={classes.body}>
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              backgroundColor: "#1c1f28",
              borderRadius: "10px",
              [theme.breakpoints.up("lg")]: {
                maxWidth: "420px",
              },
              [theme.breakpoints.down("sm")]: {
                maxWidth: "90%",
              },
              "& .MuiDialogContent-root": {
                [theme.breakpoints.down("sm")]: {
                  padding: "0",
                },
              },
            },
          },
        }}
      >
        <DialogContent>
          <Box className={classes.header}>
            <Box>
              Bet on{" "}
              {matchResult === 0
                ? matchInfo?.country1
                : matchResult === 1
                ? matchInfo?.country2
                : "Draw"}
              {matchResult !== 2 && (
                <ReactCountryFlag
                  className={classes.matchCountryFlag}
                  countryCode={getCountryAbbreviationByName(
                    matchResult === 0
                      ? matchInfo?.country1
                      : matchResult === 1
                      ? matchInfo?.country2
                      : ""
                  )}
                  svg
                  aria-label={
                    matchResult === 0
                      ? matchInfo?.country1
                      : matchResult === 1
                      ? matchInfo?.country2
                      : ""
                  }
                />
              )}
            </Box>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.inputBox}>
              <Box>
                <TextField
                  placeholder={"Input USD Amount"}
                  onChange={handleBetUsdAmountChange}
                  value={usdAmount}
                  type="number"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "17px",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        // Hide the default number input spinners
                        WebkitAppearance: "none",
                        margin: 0,
                      },

                    "& p": {
                      color: "white",
                    },
                    fieldset: {
                      border: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "13px",
                      color: "#b04747 !important",
                      top: "12px",
                    },
                  }}
                  // error={isInputValid || isExceededLimit}
                />
              </Box>
            </Box>

            <Box className={classes.inputBox}>
              <Select
                displayEmpty
                value={betToken}
                onChange={handleBetTokenChange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Please select Token</em>
                </MenuItem>
                {[...betTokens]
                  .sort(
                    (a, b) =>
                      b.price * parseFloat(tokenBalances[b.name]) -
                      a.price * parseFloat(tokenBalances[a.name])
                  )
                  .map((token, index) => {
                    const disabled =
                      token.price * parseFloat(tokenBalances[token.name]) ===
                        0 ||
                      token.price * parseFloat(tokenBalances[token.name]) <
                        parseFloat(usdAmount);

                    return (
                      <MenuItem
                        key={index}
                        value={token.name}
                        className={classes.selectMenuItem}
                        disabled={disabled}
                      >
                        <Box
                          component="img"
                          src={`/images/tokens/${token.name.toLowerCase()}.png`}
                        ></Box>
                        <Box>{token.name}</Box>
                        <Box>
                          {toUSDFormat(parseFloat(usdAmount) / token.price)}
                        </Box>
                        <Box>
                          $
                          {toUSDFormat(
                            token.price * parseFloat(tokenBalances[token.name])
                          )}
                        </Box>
                      </MenuItem>
                    );
                  })}
              </Select>
              <Box>{selectLabel}</Box>
            </Box>

            <Box className={classes.inputBox}>
              <Box>
                <Box component="img" src="/images/logo.png"></Box>
                {(parseFloat(usdAmount) || 0) * 100}
              </Box>
            </Box>
            <Box className={classes.confirmButton}>
              <Button
                disabled={confirmDisabled || isEntering}
                onClick={handleConfirm}
              >
                {isEntering ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  "Confirm"
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
