import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  ufc: {
    display: "grid",
    padding: "72px 64px 32px 64px",
    gap: "32px",
    maxWidth: "1440px",
    margin: "auto",

    [theme.breakpoints.down("md")]: {
      padding: "60px 32px 32px 32px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "60px 16px 32px 16px",
    },
  },

  election: {
    display: "grid",
    gridTemplateColumns: "3.5fr 1fr",

    padding: "72px 64px 32px 64px",
    gap: "16px",
    maxWidth: "1440px",
    margin: "auto",

    [theme.breakpoints.down("md")]: {
      padding: "60px 32px 32px 32px",

      display: "flex",
      flexDirection: "column",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "60px 16px 32px 16px",
    },
  },

  ufcRightCard: {
    display: "flex",
    flexDirection: "column",
    gap: 8,

    "&>iframe": {
      display: "initial",
    },
  },
}));

export default styles;
