import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { gameConfig } from "../config";
import { acceptedTokens } from "../config/tokens";
import { FCEventType, UFCPlayerType, UFCRoundStatus } from "../constants/ufc";
import { BetToken, getTokenBalance } from "../helpers/contractFunctions";
import {
  enterRound,
  enterRoundWithPLS,
  getRoundInfoStatus,
  getUFCBetTokens,
} from "../helpers/contractFunctions/ufc";
import { Toast } from "../utils";

function getRoundId(roundId: number, eventType: number) {
  if (eventType === FCEventType.IFC) return roundId;
  else if (eventType === FCEventType.DAZN) return 3 - roundId;
  else if (eventType === FCEventType.ELECTION) return 1 - roundId;
  else return 5 - roundId;
}

function getServer(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
    case FCEventType.UFC299:
    case FCEventType.ELECTION:
    case FCEventType.UFC300:
    case FCEventType.DAZN:
      return gameConfig.serverUrl;
  }

  return "";
}

function getEndpoint(eventType: number) {
  switch (eventType) {
    case FCEventType.IFC:
      return "ifc";
    case FCEventType.UFC299:
      return "karate";
    case FCEventType.UFC300:
      return "ufc/300";
    case FCEventType.DAZN:
      return "dazn";
    case FCEventType.ELECTION:
      return "ufc/election";
  }
}

export interface Entry {
  entryId: number;
  roundId: number;
  player: string;
  tokenName: string;
  tokenAmount: number;
  usdAmount: number;
  expectation: number;
  block: number;
  timestamp: number;
  txHash: string;
}

export interface RoundInfo {
  roundId: number;
  player1TotalUsd: number;
  player2TotalUsd: number;
  totalUsd: number;
}

export interface UFCRoundDetails {
  totalUsd: number;
  player1TotalUsd: number;
  player1EntryData: Entry[];
  player2TotalUsd: number;
  player2EntryData: Entry[];
  txHash: string;
}

export interface UFCRoundStatusDetails {
  status: number;
  closeAt: number;
  finishAt: number;
  result: number;
}

export interface UFCNewRoundDetails {
  selectedToken: string;
  usdValue: number;
  tokenAmount: number;

  selectedTokenBalance: number;
  selectedTokenUsdValue: number;
}

export interface UFCState {
  currentRound: number;

  betTokens: BetToken[];
  betTokensLoading: boolean;
  tokenBalances: { [key: string]: string };
  loadingTokenBalances: boolean;

  roundInfos: RoundInfo[];

  showBetCompleteModal: boolean;
  showDisclaimerModal: boolean;
  showBettingInput: boolean;
  loadingRoundInfos: boolean;
  loadingRoundDetails: boolean;
  loadingRoundStatus: boolean;
  isEntering: boolean;

  lastBettedUsdAmount: number;
  roundStatus: number;
  selectedPlayer: number;

  currentRoundDetails: UFCRoundDetails;
  currentRoundStatus: UFCRoundStatusDetails;
  newRoundDetails: UFCNewRoundDetails;
}

const initialState: UFCState = {
  currentRound: 0,

  betTokens: [],
  betTokensLoading: false,
  tokenBalances: {},
  loadingTokenBalances: false,

  roundInfos: [],

  showBetCompleteModal: false,
  showDisclaimerModal: false,
  showBettingInput: false,
  loadingRoundInfos: false,
  loadingRoundDetails: false,
  loadingRoundStatus: false,
  isEntering: false,

  lastBettedUsdAmount: 0,
  roundStatus: UFCRoundStatus.STARTED,
  selectedPlayer: UFCPlayerType.PLAYER1,

  currentRoundDetails: {
    totalUsd: 0,
    player1TotalUsd: 0,
    player1EntryData: [],
    player2TotalUsd: 0,
    player2EntryData: [],
    txHash: "",
  },

  currentRoundStatus: {
    status: UFCRoundStatus.STARTED,
    closeAt: 0,
    finishAt: 0,
    result: -1,
  },

  newRoundDetails: {
    selectedToken: "",
    usdValue: 0,
    tokenAmount: 0,

    selectedTokenBalance: 0,
    selectedTokenUsdValue: 0,
  },
};

export const getBetTokensList = createAsyncThunk(
  "ufc/getBetTokensList",
  async (eventType: number) => {
    const result = await getUFCBetTokens(eventType);
    return result;
  }
);

export const getTokenBalances = createAsyncThunk(
  "ufc/getTokenBalances",
  async (account: string) => {
    const balanceArray = await Promise.all(
      acceptedTokens.map(async (token) => {
        const balance = await getTokenBalance(token.address, account);
        return { [token.name]: balance };
      })
    );

    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});

    return balances;
  }
);

export const getRoundStatus = createAsyncThunk(
  "ufc/getRoundStatus",
  async ({ roundId, eventType }: { roundId: number; eventType: number }) => {
    const { roundInfo } = await getRoundInfoStatus(
      eventType,
      getRoundId(roundId, eventType)
    );

    return { roundInfo };
  }
);

export const enterUFCRound = createAsyncThunk(
  "ufc/enterRound",
  async ({
    eventType,
    roundId,
    tokenId,
    tokenAmount,
    tokenAddress,
    usdAmount,
    expectation,
    account,
  }: {
    eventType: number;
    roundId: number;
    tokenId: number;
    tokenAmount: number;
    tokenAddress: string;
    usdAmount: number;
    expectation: number;
    account: string;
  }) => {
    if (tokenAddress !== ethers.constants.AddressZero) {
      await enterRound(
        eventType,
        getRoundId(roundId, eventType),
        tokenId,
        tokenAmount,
        tokenAddress,
        usdAmount,
        expectation,
        account
      );
    } else {
      await enterRoundWithPLS(
        eventType,
        getRoundId(roundId, eventType),
        tokenId,
        tokenAmount,
        usdAmount,
        expectation,
        account
      );
    }

    return {
      eventType,
      roundId,
      tokenId,
      tokenAmount,
      tokenAddress,
      usdAmount,
      expectation,
      account,
    };
  }
);

export const getRoundInfoById = createAsyncThunk(
  "ufc/getRoundInfoById",
  async ({ eventType, roundId }: { eventType: number; roundId: number }) => {
    const response = await fetch(
      `${getServer(eventType)}${getEndpoint(eventType)}/round/${getRoundId(
        roundId,
        eventType
      )}`
    );
    const result = await response.json();

    const {
      totalUsd,
      player1TotalUsd,
      player1EntryData,
      player2TotalUsd,
      player2EntryData,
      txHash,
    } = result.data;

    return {
      totalUsd,
      player1TotalUsd,
      player1EntryData,
      player2TotalUsd,
      player2EntryData,
      txHash,
    };
  }
);

export const getRoundInfo = createAsyncThunk(
  "ufc/getRoundInfo",
  async ({ eventType }: { eventType: number }) => {
    const response = await fetch(
      getServer(eventType) + getEndpoint(eventType) + `/roundInfo/`
    );
    const result = await response.json();

    return result.roundInfoList;
  }
);

export const ufcReducer = createSlice({
  name: "ufc",
  initialState,
  reducers: {
    setCurrentRound: (state, { payload }) => {
      state.currentRound = payload;
    },

    setRoundStatus: (state, { payload }) => {
      state.roundStatus = payload;
    },

    setShowBetCompleteModal: (state, { payload }) => {
      state.showBetCompleteModal = payload;
    },

    setLastBettedUsdAmount: (state, { payload }) => {
      state.lastBettedUsdAmount = payload;
    },

    setShowDisclaimer: (state, { payload }) => {
      state.showDisclaimerModal = payload;
    },

    setShowBettingInput: (state, { payload }) => {
      state.showBettingInput = payload;
    },

    setSelectedPlayer: (state, { payload }) => {
      state.selectedPlayer = payload;
    },

    handleEnteredRound: (state, { payload }) => {},
    handleFinishedRound: (state, { payload }) => {},
    handleCancelledRound: (state, { payload }) => {},

    clearNewRoundDetails: (state) => {
      state.newRoundDetails = {
        ...state.newRoundDetails,
        selectedToken: "",

        selectedTokenBalance: 0,
        selectedTokenUsdValue: 0,
      };
    },
    setNewRoundToken: (state, { payload }) => {
      state.newRoundDetails = {
        ...state.newRoundDetails,
        selectedToken: payload,
      };
    },

    setNewRoundUSD: (state, { payload }) => {
      state.newRoundDetails = {
        ...state.newRoundDetails,
        usdValue: payload,
      };
    },

    setSelectedTokenBalance: (state, { payload }) => {
      state.newRoundDetails = {
        ...state.newRoundDetails,
        selectedTokenBalance: payload,
      };
    },

    setSelectedTokenUsdValue: (state, { payload }) => {
      state.newRoundDetails = {
        ...state.newRoundDetails,
        selectedTokenUsdValue: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBetTokensList.pending, (state) => {
      state.betTokensLoading = true;
    });

    builder.addCase(getBetTokensList.fulfilled, (state, { payload }) => {
      state.betTokensLoading = false;
      state.betTokens = payload;
    });

    builder.addCase(getBetTokensList.rejected, (state, { error }) => {
      state.betTokensLoading = false;
      state.betTokens = [];
      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Failed to get bet token list.",
      });
    });

    builder.addCase(getTokenBalances.pending, (state) => {
      state.loadingTokenBalances = true;
    });
    builder.addCase(getTokenBalances.fulfilled, (state, { payload }) => {
      state.tokenBalances = payload;
      state.loadingTokenBalances = false;
    });
    builder.addCase(getTokenBalances.rejected, (state, { error }) => {
      state.tokenBalances = {};
      state.betTokensLoading = false;
      console.log(error);
    });

    builder.addCase(getRoundStatus.pending, (state) => {
      state.loadingRoundStatus = true;
    });

    builder.addCase(
      getRoundStatus.fulfilled,
      (state, { payload: { roundInfo } }) => {
        state.loadingRoundStatus = false;

        if (
          roundInfo.status === UFCRoundStatus.STARTED &&
          +new Date(roundInfo.closeAt * 1000) - +new Date() < 0
        ) {
          roundInfo.status = UFCRoundStatus.CLOSED;
        }

        state.currentRoundStatus = {
          ...state.currentRoundStatus,
          status: roundInfo.status,
          closeAt: roundInfo.closeAt,
          finishAt: roundInfo.finishAt,
          result: roundInfo.result,
        };
      }
    );

    builder.addCase(getRoundStatus.rejected, (state, { error }) => {
      state.loadingRoundStatus = false;

      state.currentRoundStatus = {
        ...state.currentRoundStatus,
        status: UFCRoundStatus.STARTED,
        closeAt: 0,
        finishAt: 0,
        result: -1,
      };

      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Failed to get bet token list.",
      });
    });

    builder.addCase(getRoundInfoById.pending, (state, { payload }) => {
      state.loadingRoundDetails = true;
    });

    builder.addCase(getRoundInfoById.fulfilled, (state, { payload }) => {
      state.loadingRoundDetails = false;
      state.currentRoundDetails = {
        ...state.currentRoundDetails,
        totalUsd: payload.totalUsd,
        player1TotalUsd: payload.player1TotalUsd,
        player1EntryData: payload.player1EntryData,
        player2TotalUsd: payload.player2TotalUsd,
        player2EntryData: payload.player2EntryData,
        txHash: payload.txHash,
      };
    });
    builder.addCase(getRoundInfoById.rejected, (state, { error }) => {
      state.loadingRoundDetails = false;

      Toast.fire({
        icon: "error",
        title: "Failed to get round info.",
      });
    });

    builder.addCase(getRoundInfo.pending, (state, { payload }) => {
      state.loadingRoundInfos = true;
    });

    builder.addCase(getRoundInfo.fulfilled, (state, { payload }) => {
      state.loadingRoundInfos = false;
      state.roundInfos = payload;
    });

    builder.addCase(getRoundInfo.rejected, (state, { payload }) => {
      state.loadingRoundInfos = false;

      Toast.fire({
        icon: "error",
        title: "Failed to get round info.",
      });
    });

    builder.addCase(enterUFCRound.pending, (state) => {
      state.isEntering = true;
    });

    builder.addCase(enterUFCRound.fulfilled, (state, { payload }) => {
      state.isEntering = false;
      state.showBettingInput = false;

      Toast.fire({
        icon: "success",
        title: "Entered round successfully",
      });
    });

    builder.addCase(enterUFCRound.rejected, (state, { error }) => {
      state.isEntering = false;

      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Failed to enter round.",
      });
    });
  },
});

export const {
  setCurrentRound,
  setRoundStatus,
  setShowDisclaimer,
  setShowBetCompleteModal,
  setShowBettingInput,
  setLastBettedUsdAmount,
  setSelectedPlayer,
  handleEnteredRound,
  handleFinishedRound,
  handleCancelledRound,
  clearNewRoundDetails,
  setNewRoundToken,
  setNewRoundUSD,
  setSelectedTokenBalance,
  setSelectedTokenUsdValue,
} = ufcReducer.actions;

export default ufcReducer.reducer;
