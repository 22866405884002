import { Box, Button, Popover } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { BetToken } from "../../helpers/contractFunctions";
import theme from "../../theme";
import { toUSDFormat } from "../../utils";
import useStyles from "./index.styles";

interface TokenTakerDialogProps {
  betTokens: BetToken[];
  tokenBalances: { [key: string]: string };
  usdAmount: number;
  butAnchor: HTMLButtonElement | null;
  onClose: (anchor: HTMLButtonElement | null) => void;
  setEnteringToken: (token: BetToken) => void;
}

const TokenTakerDialog = (props: TokenTakerDialogProps) => {
  const { classes } = useStyles();
  const {
    tokenBalances,
    betTokens,
    usdAmount,
    butAnchor,
    setEnteringToken,
    onClose,
  } = props;
  const tokenBalancesWithAssertion = tokenBalances as { [key: string]: string };

  const open = Boolean(butAnchor);

  const handleClose = () => {
    onClose(null);
  };

  const sortedBetTokens = betTokens
    .slice()
    .map((token) => ({
      ...token,
      usdAmount:
        (parseFloat(tokenBalancesWithAssertion[token.name]) || 0) *
        (token.price || 0),
      balance: parseFloat(tokenBalancesWithAssertion[token.name]) || 0,
    }))
    .sort((a, b) => {
      const usdAmountA = a.price * a.balance;
      const usdAmountB = b.price * b.balance;
      return usdAmountB - usdAmountA;
    });

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={butAnchor}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "400px",
          minHeight: "500px",
          backgroundColor: "#1c1f28",
          padding: "20px 10px",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "300px",
            minHeight: "300px",
          },
          "& .MuiDialogContent-root": {
            [theme.breakpoints.down("sm")]: {
              padding: "0",
            },
          },
        },
      }}
    >
      <Box className={classes.row}>
        <Box>Asset</Box>
        <Box>Balance</Box>
        <Box>Use</Box>
      </Box>
      <Box className={classes.tableContainer}>
        {sortedBetTokens.map((token, index) => (
          <Box className={classes.row} key={token.name}>
            <Box>
              <Box
                component="img"
                src={`/images/tokens/${token.name?.toLowerCase()}.png`}
              />
              <Box>{token.name}</Box>
            </Box>
            <Box>
              <Box>{token.balance.toFixed(4)}</Box>
              <Box>$ {token.usdAmount.toFixed(4)}</Box>
            </Box>
            <Box>
              <Button
                className={clsx({
                  tokenDisabled: usdAmount > token.usdAmount,
                })}
                onClick={
                  usdAmount <= token.usdAmount
                    ? () => {
                        setEnteringToken(token);
                        handleClose();
                      }
                    : () => {}
                }
              >
                Select {toUSDFormat(usdAmount, 2)}$
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default TokenTakerDialog;
