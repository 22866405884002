import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    width: "100%",
    overflowX: "auto",
  },
  mainTableBody: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    minWidth: "800px",
  },
  bodySection: {
    maxHeight: "2500px",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: "4px",
    },

    "&::-webkit-scrollbar-track": {
      width: "4px",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
      border: "solid 3px rgba(255, 0, 0, 0)",
    },

    "&::-webkit-scrollbar-thumb": {
      width: "4px",
      borderRadius: "10px",
      background: "#34344b",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#4a4a6d",
    },
  },
  infiniteBody: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 2fr 1fr 2fr 3fr",
    padding: "5px 10px",
  },
  tableBody: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 2fr 1fr 2fr 3fr",
    backgroundColor: "#1c1f28",
    padding: "7px 10px",
    borderRadius: "7px",
    cursor: "pointer",
    transition: ".3s",
    "&:hover": {
      backgroundColor: "#2c303b",
      transition: ".3s",
    },
  },
  rankDetail: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    "&>img": {
      width: "20px",
    },
  },
  playerDetail: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  airdropDetail: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  winPercentDetail: {
    color: "#7d862d",
    display: "flex",
    alignItems: "center",
  },
  totalBetsDetail: {
    display: "flex",
    alignItems: "center",
  },
  profitLossDetail: {
    display: "flex",
    alignItems: "center",
  },
  plus: {
    color: "#39a923",
  },
  minus: {
    color: "#555a2a",
  },
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default styles;
