import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: any) => ({
  mdLogoWrapper: {
    width: 300,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 300,
    },
  },
  xsLogoWrapper: {
    width: 50,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  mdLink: {
    marginLeft: "20px",
    marginRight: "20px",
    textDecoration: "none",
    fontSize: "16px",
    color: theme.palette.text.disabled,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    "&.active": {
      color: "white",
    },
    "&:hover>img": {
      width: "25px",
      marginRight: "10px",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "40px",
      marginRight: "40px",
      fontSize: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "20px",
      marginRight: "20px",
      fontSize: "18px",
    },
  },
  userSection: {
    flexGrow: 0,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "10px",
    [theme.breakpoints.up("md")]: {
      marginRight: "50px",
    },
  },
  myRoundLink: {
    color: theme.palette.text.disabled,
    fontSize: "19px",
    marginLeft: 5,
    marginRight: 15,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 35,
      marginRight: 30,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  notificationBtn: {
    padding: "10px",
    minWidth: "unset",
    borderRadius: "50%",
    marginRight: "10px",
  },

  userProfileBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.background.card,
    padding: "10px 27px",
    borderRadius: "10px",
    minWidth: "40px",

    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "10px",
      "& span": {
        display: "none",
      },
      "& img": {
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down("xl")]: {
      padding: "10px 16px",
    },
  },

  connectWalletBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.background.card,
    padding: "10px",
    borderRadius: "10px",
    textTransform: "none",
    minWidth: "48px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "10px",
      "& span": {
        display: "none",
      },
      "& img": {
        marginLeft: 0,
      },
    },
  },
  sidebar: {
    width: 250,
    background: theme.palette.background.card,
  },
  sidebarNavLink: {
    color: theme.palette.text.disabled,
    "&.active": {
      color: theme.palette.text.primary,
    },
  },
  sidebarPaper: {
    background: theme.palette.background.card,
  },
  sidebarHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: 64,
    background: theme.palette.background.card,
    gap: 15,
    "& a": {
      color: theme.palette.text.primary,
      fontSize: "19px",
    },
  },
  sidebarLogo: {
    width: 40,
  },
  sideBarConnectWalletBtn: {
    display: "flex",
    justifyContent: "center",
    background: "#262a35",
    borderRadius: "12px",
  },
  lgNavIcon: {
    width: 0,
    marginRight: "35px",
    transition: "all 0.5s",
  },
  navHoverSpan: {
    position: "absolute",
    background: theme.palette.background.card,
    width: "150px",
    height: "calc(100% + 30px)",
    top: "-15px",
    borderRadius: "25px",
    transition: "all 0.5s",
    zIndex: "-1",
    opacity: 0,
  },

  notificationPanel: {
    borderRadius: "16px",
    backgroundColor: "#1c1f28",
    width: "360px",
    padding: "24px 0 0 0",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    zIndex: 2,
  },

  notificationHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px 0 32px",

    "& p": {
      margin: "0",
      padding: "0",
      fontSize: "18px",
      fontWeight: "800",
    },
  },

  notificationSettingBtn: {
    minWidth: "0px",
    width: "32px",
    height: "32px",
    padding: "4px",
    borderRadius: "16px",
  },

  notificationList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "320px",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: "4px",
    },

    "&::-webkit-scrollbar-track": {
      width: "4px",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
      border: "solid 3px rgba(255, 0, 0, 0)",
    },

    "&::-webkit-scrollbar-thumb": {
      width: "4px",
      borderRadius: "10px",
      background: "#34344b",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#4a4a6d",
    },
  },

  notificationEmpty: {
    paddingTop: "16px",
    paddingBottom: "32px",
    display: "flex",
    justifyContent: "center",
    fontSize: "18px",
    color: "rgba(255, 255, 255, 0.3)",
  },

  notificationListItem: {
    paddingTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #20232d",
    display: "flex",
    gap: "8px",
    paddingLeft: "24px",
    "&.read": {
      opacity: "0.5",
    },
  },

  notificationTypeIcon: {
    width: "48px",
    height: "48px",
    backgroundColor: "#11141b",
    borderRadius: "50%",
    border: "1px solid #393b47",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  notificationContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    maxWidth: "240px",

    "& p:first-of-type": {
      fontSize: "14px",
      margin: "0",
    },

    "& p:last-of-type": {
      fontSize: "12px",
      margin: "0",
      color: "#454b5f",
    },
  },
  badge: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: "10px",
    backgroundColor: "#ea5353",
    color: "white",
    borderRadius: "10px",
    padding: "2px 4px",
    transform: "translateY(-80%)",
  },
  mobileSidebarList: {
    position: "relative",
    "&>div:last-of-type": {
      transform: "translateY(0)",
      right: "-40px",
    },
  },
  detailSubTitle: {
    fontSize: "12px",
    color: "white",
  },
  subDetailPart: {
    display: "flex",
    gap: "3px",
    alignItems: "center",
    marginLeft: "5px",
  },
  menuListBody: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  profileLinkText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
