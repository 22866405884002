import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PredictDetailLeftSection from "../../components/PredictDetailLeftSection";
import PredictDetailMiddleSection from "../../components/PredictDetailMiddleSection";
import PredictDetailRightSection from "../../components/PredictDetailRightSection";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { BetToken } from "../../helpers/contractFunctions";
import {
  getRoundInfo,
  getRoundMetaInfo,
  getPriceTokens,
  getPriceTokenLp,
  getPriceOneToken,
  PredictToken,
} from "../../reducers/predict.slice";
import theme from "../../theme";
import { gameConfig } from "../../config";
import { PredictionEventName } from "../../constants/prediction";

export default function PredictionDetails() {
  const dispatch = useAppDispatch();
  const { metaInfo, roundInfo, tokens, loadingMetaInfo } = useAppSelector(
    (state) => state.predict
  );
  const [comment, setComment] = useState("");
  const [usdAmount, setUsdAmount] = useState(0);
  const [enteringToken, setEnteringToken] = useState<BetToken | null>(null);
  const [token, setToken] = useState<PredictToken | undefined>(undefined);
  const [curTime, setCurTime] = useState(
    Math.floor(new Date().getTime() / 1000)
  );
  const { id } = useParams();
  const setBetUsdAmount = (amount: number) => {
    setUsdAmount(amount);
  };
  const eventURL = "event/prediction";
  useEffect(() => {
    const interval = setInterval(async () => {
      if (curTime === 0) {
        clearInterval(interval);
        reloadRound();
      }
      setCurTime(Math.floor(new Date().getTime() / 1000));
    }, 1000);

    const reloadRound = async () => {
      if (id) {
        await dispatch(getRoundInfo(Number(id))).unwrap();
        await dispatch(getRoundMetaInfo(Number(id))).unwrap();
      }
    };

    const setupEventSource = () => {
      const eventSource = new EventSource(`${gameConfig.serverUrl}${eventURL}`);
      eventSource.onmessage = async (e) => {
        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);
          console.log("Prediction event: ", res);
          if (
            res.eventName === PredictionEventName.BetPlaced &&
            id?.toString() === res?.result.returnValues?.roundId
          ) {
            await reloadRound();
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();

    return () => {
      eventSource.close();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await dispatch(getRoundInfo(parseInt(id)));
        await dispatch(getRoundMetaInfo(parseInt(id)));
        await dispatch(getPriceTokens());
      }
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (loadingMetaInfo || !tokens) return;
    setToken(
      tokens.find(
        (token) =>
          token?.token?.toLowerCase() ===
          metaInfo?.priceTokenName?.toLowerCase()
      )
    );
  }, [tokens, loadingMetaInfo]);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchData = async () => {
      if (token) {
        await dispatch(getPriceOneToken(token.id));
      }
    };

    fetchData();
    const interval = setInterval(async () => {
      await fetchData();
    }, 5000);
    return () => {
      window.clearInterval(interval);
    };
  }, [token, dispatch]);

  useEffect(() => {
    if (tokens.length === 0) return;
    const fetch = async () => {
      await dispatch(getPriceTokenLp(tokens.map((token) => token.pair)));
    };
    fetch();
  }, [tokens, dispatch]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "32px 0",
        gap: "64px",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
        [theme.breakpoints.down("lg")]: {
          padding: "32px 32px",
          margin: "auto",
          gap: "16px",
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: "1440px",
          margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
          padding: "32px 16px",
        },
      }}
    >
      <PredictDetailLeftSection
        roundInfo={{ ...metaInfo, ...roundInfo }}
        comment={comment}
        setComment={setComment}
        curTime={curTime}
      />
      <PredictDetailMiddleSection
        roundInfo={{ ...metaInfo, ...roundInfo }}
        usdAmount={usdAmount}
        setUsdAmount={setBetUsdAmount}
        enteringToken={enteringToken}
        setEnteringToken={(enteringToken) => setEnteringToken(enteringToken)}
        curTime={curTime}
      />
      <PredictDetailRightSection
        roundInfo={{ ...metaInfo, ...roundInfo }}
        tokens={tokens}
        usdAmount={usdAmount}
        enteringToken={enteringToken}
        curTime={curTime}
      />
    </Box>
  );
}
