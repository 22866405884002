import { styled } from "@mui/material/styles";

import { Box, InputBase, Paper, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../app/hooks";
import { PredictToken } from "../../reducers/predict.slice";
import { toUSDFormat } from "../../utils";
import { getLpStatus } from "../../utils/predict";

const CustomMenu = styled(Menu)((theme: any) => ({
  paper: {
    borderRadius: "16px !important",
    background: "#3c3e4d !important",
  },
  list: {
    width: 360,
    borderRadius: "15px",
    background: "#3c3e4d",
  },
  "& .MuiPaper-root": {
    backgroundColor: "#1b1f28",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
}));

const useStyles = makeStyles()((theme: any) => ({
  priceCoinList: {
    maxHeight: "400px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      width: "4px",
      borderRadius: "4px",
      backgroundColor: "#34344b",
    },
    "&::-webkit-scrollbar-track": {
      width: "4px",
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
      border: "solid 3px rgba(255,0,0,0)",
    },
  },
  paperRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    background: theme.palette.background.default,
    borderRadius: "15px",
  },
  input: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
    flex: 1,
  },
  menuTitle: {
    width: "100%",
    display: "flex",
    color: theme.palette.text.primary,
    justifyContent: "space-between",
  },
  closeBtn: {
    cursor: "pointer",
    color: "gray",
  },
  searchBtn: {
    alignSelf: "end",
    marginRight: "10px",
    marginTop: "6px",
  },
  gridDiv: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 2fr",
    color: theme.palette.text.primary,
    fontSize: "0.8rem",
    width: "100%",
  },
  token: {
    color: theme.palette.text.primary,
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "10px",
    },
  },
  chain: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "10px",
    },
  },
  lpBadge: {
    "& div": {
      padding: "4px",
      textAlign: "center",
      marginRight: "24px",
    },

    "& .good": {
      backgroundColor: "#4CAF50EE",
      borderRadius: "8px",
    },

    "& .caution": {
      backgroundColor: "#ad9e1eee",
      borderRadius: "8px",
    },

    "& .risky": {
      backgroundColor: "#bf7a15ee",
      borderRadius: "8px",
    },

    "& .danger": {
      backgroundColor: "#F44336EE",
      borderRadius: "8px",
    },
  },
}));

interface CustomMenuUnitProps {
  anchorEl: any;
  menuOpen: boolean;
  handleClose: () => void;
  changeAsset: (item: PredictToken, index: number) => void;
  tokens: PredictToken[];
}

const CustomMenuUnit = (props: CustomMenuUnitProps) => {
  const { classes } = useStyles();
  const { tokens, tokenLps, newRoundInfo } = useAppSelector(
    (state) => state.predict
  );

  const { anchorEl, menuOpen, handleClose, changeAsset } = props;

  const handleSearch = () => {};

  const selectAsset = (item: PredictToken, index: number) => {
    handleClose();
    changeAsset(item, index);
  };

  const renderLpBadge = (priceToken: string) => {
    const priceTokenInfo = tokens.find((token, index) => {
      return token?.token?.toLowerCase() === priceToken?.toLowerCase();
    });

    if (!priceTokenInfo)
      return <Box className={clsx(classes.lpBadge, "lpBadge")}></Box>;

    const lp = tokenLps.find((tokenLp, index) => {
      return (
        tokenLp?.pairAddress?.toLowerCase() ===
        priceTokenInfo?.pair?.toLowerCase()
      );
    });

    if (!lp) return <Box className={clsx(classes.lpBadge, "lpBadge")}></Box>;

    const lpStatus = getLpStatus(lp.liquidity);

    return (
      <Box className={clsx(classes.lpBadge, "lpBadge")}>
        <Tooltip placement="top" title={`$${toUSDFormat(lp.liquidity)}`}>
          <Box className={lpStatus?.toLowerCase()}>
            {lpStatus?.toUpperCase()}
          </Box>
        </Tooltip>
      </Box>
    );
  };

  return (
    <CustomMenu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleClose}
      classes={{ paper: clsx("coin-list-popover", classes.priceCoinList) }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem>
        <Box className={classes.menuTitle}>
          <Box>Select asset</Box>
        </Box>
      </MenuItem>

      <MenuItem>
        <Paper component="form" className={classes.paperRoot}>
          <InputBase
            className={classes.input}
            placeholder="Search name or contract address ..."
            inputProps={{ "aria-label": "search google maps" }}
          />
          <Box className={classes.searchBtn} onClick={handleSearch}>
            <img src="/images/prediction/search.png" alt="search" width={20} />
          </Box>
        </Paper>
      </MenuItem>

      <MenuItem>
        <Box className={classes.gridDiv}>
          <Box>Asset</Box>
          <Box>Data provider</Box>
          <Box>LP Status</Box>
        </Box>
      </MenuItem>

      {tokens.map((item, index) => {
        return (
          !item.disabled &&
          item.token && (
            <MenuItem key={index} onClick={() => selectAsset(item, index)}>
              <Box className={classes.gridDiv}>
                <Box className={classes.token}>
                  <img
                    src={`/images/tokens/${item.img}.png`}
                    width={20}
                    alt={item.token}
                  />
                  {item.token}
                </Box>
                <Box className={classes.chain}>
                  <img
                    src={`/images/prediction/${item.chain}.png`}
                    alt="chain"
                    width={15}
                  />
                  {item.chain}
                </Box>
                <Box>{renderLpBadge(item.token)}</Box>
              </Box>
            </MenuItem>
          )
        );
      })}
    </CustomMenu>
  );
};

export default CustomMenuUnit;
