import { makeStyles } from 'tss-react/mui';
import theme from '../../theme';

const styles = makeStyles()((theme: any) => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    height: '40px',
    cursor: 'pointer',
    paddingLeft: '10px',
    paddingRight: '10px',
    justifyContent: 'space-between',
    width: '180px',
    borderRadius: '4px',
    position: 'relative',
    userSelect: 'none',
    '&.winnerCorrect': {
      border: '3px solid #268544',
    },
    '&.waiting.left': {
      '&>div>div:nth-of-type(1)': {
        display: 'none',
      },
      '&>div>div:nth-of-type(2)': {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '&>div': {
          marginLeft: '25px',
        }
      },
    },
    '&.waiting.right': {
      '&>div>div:nth-of-type(3)': {
        display: 'none',
      },
      '&>div>div:nth-of-type(2)': {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '&>div': {
          marginRight: '25px',
        }
      },
    },
    '&.waiting, &.enable, &.winner': {
      backgroundColor: theme.palette.background.eventActive,
    },
    '&.loser': {
      backgroundColor: theme.palette.background.eventDisable,
    },
    '&:nth-of-type(odd)': {
      marginTop: '32px',
      '&.quarter': {
        marginTop: '92px',
        '&:nth-of-type(3)': {
          marginTop: '152px',
        },
      },
      '&.semi': {
        marginTop: '212px'
      },
      '&.waiting>div:nth-of-type(3)': {
        color: theme.palette.text.santasGrey,
      },

    },
    '&:nth-of-type(even)': {
      marginTop: '8px',
    },
    '&.quarter': {
      '&.left::before': {
        content: '""',
        height: '13px',
        width: 'calc(50% + 28px)',
        position: 'absolute',
        left: '0',
        top: '0',
        borderStyle: 'solid',
        borderColor: theme.palette.background.card,
      },
      '&.left:nth-of-type(1)::before': {
        borderWidth: '1px 1px 0 0',
        transform: 'translate(-25px, -16px)',
      },
      '&.left:nth-of-type(2)::before': {
        borderWidth: '0 1px 1px 0',
        transform: 'translate(-25px, 43px)',
      },
      '&.left:nth-of-type(3)::before': {
        borderWidth: '1px 1px 0 0',
        transform: 'translate(-25px, -16px)',
      },
      '&.left:nth-of-type(4)::before': {
        borderWidth: '0 1px 1px 0',
        transform: 'translate(-25px, 43px)',
      },
      '&.right::before': {
        content: '""',
        height: '13px',
        width: 'calc(50% + 28px)',
        position: 'absolute',
        left: '0',
        top: '0',
        borderStyle: 'solid',
        borderColor: theme.palette.background.card,
      },
      '&.right:nth-of-type(1)::before': {
        borderWidth: '1px 0 0 1px',
        transform: 'translate(calc(100% - 28px), -16px)',
      },
      '&.right:nth-of-type(2)::before': {
        borderWidth: '0 0 1px 1px',
        transform: 'translate(calc(100% - 28px), 43px)',
      },
      '&.right:nth-of-type(3)::before': {
        borderWidth: '1px 0 0 1px',
        transform: 'translate(calc(100% - 28px), -16px)',
      },
      '&.right:nth-of-type(4)::before': {
        borderWidth: '0 0 1px 1px',
        transform: 'translate(calc(100% - 28px), 43px)',
      },
    },
    '&.semi': {
      '&.left::before': {
        content: '""',
        height: '73px',
        width: 'calc(50% - 42px)',
        position: 'absolute',
        left: 0,
        top: 0,
        borderStyle: 'solid',
        borderColor: theme.palette.background.card,
      },
      '&.left:nth-of-type(1)::before': {
        borderWidth: '1px 1px 0 0',
        transform: 'translate(42px, -76px)',
      },
      '&.left:nth-of-type(2)::before': {
        borderWidth: '0 1px 1px 0',
        transform: 'translate(42px, 43px)',
      },
      '&.right::before': {
        content: '""',
        height: '73px',
        width: 'calc(50% - 42px)',
        position: 'absolute',
        left: 0,
        top: 0,
        borderStyle: 'solid',
        borderColor: theme.palette.background.card,
      },
      '&.right:nth-of-type(1)::before': {
        borderWidth: '1px 0 0 1px',
        transform: 'translate(calc(100% + 42px), -76px)',
      },
      '&.right:nth-of-type(2)::before': {
        borderWidth: '0 0 1px 1px',
        transform: 'translate(calc(100% + 42px), 43px)',
      },
    },
    '&.final:nth-of-type(1)::before': {
      content: '""',
      height: '50px',
      width: 'calc(25%)',
      position: 'absolute',
      top: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.background.card,
      left: '-25%',
      borderWidth: '0 0 1px 1px',
      transform: 'translate(0, -5px)',
    },
    '&.final:nth-of-type(1)::after': {
      content: '""',
      height: '73px',
      width: 'calc(25% + 8px)',
      position: 'absolute',
      top: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.background.card,
      left: '-50%',
      borderWidth: '1px 0 0 0',
      transform: 'translate(-8px, -5px)',
    },
    '&.final:nth-of-type(2)::before': {
      content: '""',
      height: '45px',
      width: 'calc(25%)',
      position: 'absolute',
      top: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.background.card,
      right: '-25%',
      borderWidth: '0 1px 1px 0',
      transform: 'translate(0, -48px)',
    },
    '&.final:nth-of-type(2)::after': {
      content: '""',
      height: '73px',
      width: 'calc(25%)',
      position: 'absolute',
      top: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.background.card,
      right: '-50%',
      borderWidth: '1px 0 0 0',
      transform: 'translate(0, -50px)',
    },
    '&.champion::before': {
      content: '""',
      height: '26px',
      width: 0,
      position: 'absolute',
      bottom: '-3px',
      borderStyle: 'solid',
      borderColor: theme.palette.background.card,
      left: '50%',
      borderWidth: '0 1px 0 0',
      transform: 'translate(50%, 100%)',
    },
    '&.championLine::before, &.championLine::after': {
      borderColor: theme.palette.text.activeLine + '!important',
    },
  },
  checkIcon: {
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    '&.left img': {
      marginRight: '11px',
    },
    '&.right img': {
      marginLeft: '11px',
    },
    '& img': {
      width: '18px',
    },
    '&.winner, &.enable': {
      opacity: 1,
    },
  },
  pickBut: {
    borderRadius: '3px',
    padding: '2px 3px',
    fontSize: '9px',
    backgroundColor: theme.palette.background.pickButton,
  },
  avatar: {
    width: '17px',
    '&.waiting': {
      display: 'none',
    }
  },
  name: {
    fontSize: '13px',
    letterSpacing: '0px',
    '&.waiting': {
      color: theme.palette.text.disabled,
    },
    '&.winner, &.enable': {
      color: theme.palette.text.primary,
    },
    '&.loser': {
      color: theme.palette.text.dark,
    }
  },
  percent: {
    color: theme.palette.text.dark,
    fontSize: '11px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  closeBut: {
    position: 'absolute',
    bottom: '0',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.closeButton,
    zIndex: '10',
    '&.right': {
      left: '0',
      transform: 'translate(-50%, 50%)',
    },
    '&.left': {
      right: '0',
      transform: 'translate(50%, 50%)',
    },
  },
  resultUpCount: {
    fontSize: '16px',
    color: '#268544',
  },
  hide: {
    opacity: '0'
  }
}));

export default styles;