import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  euroMatchListItem: {
    display: "flex",
    fontFamily: "K2D",
    gap: 48,

    [theme.breakpoints.down("lg")]: { gap: 16 },

    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplate: "'a a' 'b c'",

      border: "1px solid #80808036",
      padding: 16,
      borderRadius: 16,
    },
  },

  disableDraw: {
    width: 120,
  },

  disableDrawLine: {
    "&>div:nth-of-type(1)": {
      "&::after": {
        content: '""',
        position: "absolute",
        width: 152,
        height: 2,
        backgroundColor: "#1b1f28",
        right: "-152px",
        top: "50%",
        transform: "translate(0, -50%)",
        zIndex: -1,
      },

      "&:last-of-type": {
        "&::after": {
          content: "none",
        },
      },
    },
  },

  matchInfoBlockGroup: {
    display: "flex",
    gap: 16,
    position: "relative",
    gridArea: "a",
    transition: "300ms",

    "&>div": {
      "&::after": {
        content: '""',
        position: "absolute",
        width: 16,
        height: 2,
        backgroundColor: "#1b1f28",
        right: "-16px",
        top: "50%",
        transform: "translate(0, -50%)",
        zIndex: -1,
      },

      "&:last-of-type": {
        "&::after": {
          content: "none",
        },
      },
    },

    "&::after": {
      content: '""',
      position: "absolute",
      width: 48,
      height: 2,
      backgroundColor: "#1b1f28",
      right: "-48px",
      top: "50%",
      transform: "translate(0, -50%)",
      zIndex: -1,
    },

    "&:last-of-type": {
      "&::after": {
        content: "none",
      },
    },

    [theme.breakpoints.down("md")]: {
      gap: 8,
      "&::after": {
        content: "none",
      },
    },
  },

  matchInfoBlockGroupB: {
    gridArea: "b",
    display: "initial",
  },

  matchInfoBlockGroupC: {
    gridArea: "c",
    display: "initial",
  },

  matchInfoBlock: {
    backgroundColor: "#1b1f28",
    borderRadius: 16,
    display: "flex",
    padding: "12px 24px",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
    cursor: "pointer",
    position: "relative",

    "& img": {
      width: 24,
      height: 24,
    },

    "&>div": {
      display: "flex",
      flexDirection: "column",
    },

    "& a": {
      textDecoration: "none",
    },

    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 1px",
    transition: "300ms",

    "&:hover": {
      boxShadow: "rgba(78, 131, 184, 1) 0px 0px 10px 1px",
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: "12px",
      paddingRight: "12px",

      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },

  mathInfoBlockWinner: {
    backgroundColor: "#28af7b",
  },

  progressBox: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  progressLabel: {
    lineHeight: "42px",
    fontWeight: "bold",
  },

  completedLabel: {
    fontWeight: "bold",
    width: 72,
    color: "white",
  },

  completedTx: { color: "#363742", fontSize: 12 },

  winnerTag: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 16,
    backdropFilter: "blur(1px)",

    fontSize: 18,
    fontWeight: 900,
  },

  drawTag: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 16,
    backdropFilter: "blur(1px)",

    fontSize: 18,
    fontWeight: 900,
  },

  countryBlock: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 4,
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },

  centered: {
    paddingTop: "6px!important",
    paddingBottom: "6px!important",

    "&>div": {
      alignItems: "center",
    },
  },

  reversed: {
    flexDirection: "row-reverse",
  },

  wideGap: {
    gap: 16,
  },

  timeLabel: {
    color: "#363742",
    fontSize: 12,
  },

  time: { fontWeight: "bold", width: 72 },

  matchCountryOdd: {
    width: 50,
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      order: -1,
    },
  },

  matchCountryName: {
    fontSize: 16,
    width: 80,
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  matchCountryWinner: {
    fontSize: 12,
    color: "#363742",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  matchCountryFlag: {
    width: "32px !important",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      order: -1,
    },
  },

  drawOddLabel: { color: "#363742", fontSize: 12 },

  drawOdd: {
    fontSize: 24,
    fontWeight: "bold",
    width: 72,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },

  viewBets: {
    color: "#363742",
    fontSize: 12,
    textAlign: "right",
  },

  betTotal: { fontWeight: "bold", width: 72, textAlign: "right" },
}));

export default styles;
