import { Box } from "@mui/material";

import PredictionQuickBet from "../../components/PredictionQuickBet";
import PredictRulesCard from "../../components/PredictRulesCard";

import theme from "../../theme";

export default function PredictionQuickCreate() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "32px 0",
        gap: "0",
        [theme.breakpoints.down("lg")]: {
          padding: "32px 32px",
          margin: "auto",
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: "1440px",
          margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
          padding: "32px 16px",
        },
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          padding: "20px",
          paddingTop: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: "50px",
          [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <PredictionQuickBet />
        <PredictRulesCard />
      </Box>
    </Box>
  );
}
