import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import PredictionBody from "../../components/PredictionBody";
import RoundFilters from "../../components/RoundFilters";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { gameConfig } from "../../config";
import {
  BetTypes,
  PredictRoundStatus,
  PredictSortStatus,
  PredictType,
  PredictionEventName,
  PredictionGettingRoundsType,
  TokenList,
} from "../../constants/prediction";
import {
  PredictToken,
  getRoundMaxValue,
  getRounds,
  setHasMoreRounds,
  setLpStatus,
  setRounds,
} from "../../reducers/predict.slice";
import theme from "../../theme";
import { getLpStatusValue } from "../../utils/predict";

const Predictions = () => {
  const {
    roundMaxValue,
    loadingRoundMaxValue,
    countPerPage,
    lpStatus,
    tokenLps,
  } = useAppSelector((state) => state.predict);
  const [statusValue, setStatusValue] = useState(0);
  const [sortValue, setSortValue] = useState(0);
  const [coinValue, setCoinValue] = useState<string[]>([]);
  const [rangeValue, setRangeValue] = useState([0, roundMaxValue]);
  const [activePage, setActivePage] = useState(1);
  const [liveActivePage, setLiveActivePage] = useState(1);
  const [typeValue, setTypeValue] = useState(PredictType.All);
  const [filterStatus, setFilterStatus] = useState(false);
  const dispatch = useAppDispatch();
  const eventURL = "event/prediction";

  const changeStatusValue = (value: number) => {
    setActivePage(1);
    setStatusValue(value);
  };

  const changeSortValue = (value: number) => {
    setActivePage(1);
    setSortValue(value);
  };

  const changeCoinValues = (values: string[]) => {
    setActivePage(1);
    setCoinValue(values);
  };

  const changeLpStatus = (value: number) => {
    setActivePage(1);
    dispatch(setLpStatus(value));
  };

  const changeType = (type: number) => {
    setActivePage(1);
    setTypeValue(type);
  };

  const getRoundWithFilter = useCallback(
    ({
      page,
      roundsType,
      initStatus,
      flag = true,
    }: {
      page: number;
      roundsType: number;
      initStatus: boolean;
      flag?: boolean;
    }) => {
      if (flag && rangeValue[1]) {
        let coins: string[] = [];
        let tokenList: PredictToken[] = [];
        if (lpStatus !== 0) {
          if (coinValue.length === 0) {
            tokenList = [...TokenList];
          } else {
            tokenList = coinValue
              .map((coin) => {
                const token = TokenList.find(
                  (token) => token.token.toLowerCase() === coin
                );
                return token;
              })
              .filter((token) => token !== undefined) as PredictToken[];
          }
          coins = tokenList
            .map((token) => {
              const lp = tokenLps.find(
                (lp) =>
                  token.pair.toLowerCase() === lp.pairAddress.toLowerCase()
              );
              if (lp && getLpStatusValue(lp?.liquidity) === lpStatus) {
                return token.token.toLowerCase();
              }
              return undefined;
            })
            .filter((coin) => coin !== undefined) as string[];

          if (coins.length === 0) {
            dispatch(setRounds());
            return;
          }
        } else {
          coins = [...coinValue];
        }
        dispatch(
          getRounds({
            statusValue,
            sortValue,
            coinValue: coins,
            minValue: rangeValue[0],
            maxValue: rangeValue[1],
            typeValue: BetTypes[typeValue].valueStr,
            activePage: page,
            countPerPage,
            roundsType,
            initStatus,
          })
        );
      }
    },
    [
      statusValue,
      sortValue,
      coinValue,
      rangeValue,
      lpStatus,
      dispatch,
      typeValue,
      filterStatus,
      countPerPage,
      tokenLps,
      dispatch,
    ]
  );

  useEffect(() => {
    const setupEventSource = () => {
      const eventSource = new EventSource(`${gameConfig.serverUrl}${eventURL}`);
      eventSource.onmessage = async (e) => {
        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);
          console.log("Prediction event: ", res);
          if (res.eventName === PredictionEventName.BetPlaced) {
            setActivePage(1);
            setFilterStatus((filterStatus) => !filterStatus);
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getRoundMaxValue());
    };

    fetchData();

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    setRangeValue((prev: number[]) => {
      let updated = [...prev];
      updated[1] = roundMaxValue;
      return updated;
    });
  }, [roundMaxValue]);

  useEffect(() => {
    if (!loadingRoundMaxValue && roundMaxValue !== 0) {
      dispatch(setHasMoreRounds(true));
      getRoundWithFilter({
        page: activePage,
        roundsType: PredictionGettingRoundsType.NormalsPredictIndex,
        initStatus: true,
      });
    }
  }, [
    statusValue,
    sortValue,
    coinValue,
    rangeValue,
    loadingRoundMaxValue,
    roundMaxValue,
    filterStatus,
    typeValue,
    lpStatus,
  ]);

  useEffect(() => {
    if (!loadingRoundMaxValue && roundMaxValue !== 0) {
      dispatch(
        getRounds({
          //getting live rounds
          statusValue: PredictRoundStatus.Started,
          sortValue: PredictSortStatus.Expiry,
          coinValue: [],
          minValue: rangeValue[0],
          maxValue: rangeValue[1],
          typeValue: BetTypes[PredictType.All].valueStr,
          activePage: liveActivePage,
          countPerPage,
          roundsType: PredictionGettingRoundsType.LiveRoundsPredictIndex,
          initStatus: false,
        })
      );
    }
  }, [
    statusValue,
    sortValue,
    coinValue,
    rangeValue,
    loadingRoundMaxValue,
    roundMaxValue,
    filterStatus,
    typeValue,
    lpStatus,
    liveActivePage,
    countPerPage,
    dispatch,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "32px 0",
        gap: "0",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
        [theme.breakpoints.down("lg")]: {
          padding: "32px 32px",
          margin: "auto",
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: "1440px",
          margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
          padding: "32px 16px",
        },
      }}
    >
      <RoundFilters
        status={statusValue}
        sort={sortValue}
        coin={coinValue}
        range={rangeValue}
        type={typeValue}
        changeStatus={changeStatusValue}
        changeSort={changeSortValue}
        changeCoin={changeCoinValues}
        changeLp={changeLpStatus}
        changeRange={setRangeValue}
        changeType={changeType}
      />
      <PredictionBody
        activePage={activePage}
        liveActivePage={liveActivePage}
        status={statusValue}
        changeActivePage={setActivePage}
        changeLiveActivePage={setLiveActivePage}
        getRounds={() => {
          getRoundWithFilter({
            page: activePage + 1,
            roundsType: PredictionGettingRoundsType.NormalsPredictIndex,
            initStatus: false,
          });
          setActivePage((page) => page + 1);
        }}
      />
    </Box>
  );
};

export default Predictions;
