import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gameConfig } from "../config";

const SERVER_URL = gameConfig.serverUrl;
// const SERVER_URL = "http://192.168.8.154:3000/";

export interface UserInfo {
  id: number;
  address: string;
  notificationId: number;
  name: string;
  bio: string;
  avatar: string;
  totalRounds: number;
  totalBets: number;
  totalQuickBets: number;
  totalAdvancedBets: number;
  quickRoundsWon: number;
  advancedRoundsWon: number;
  profitLoss: number;
  predictAllocation: number;
  quickBetsWinPercent: number;
  advancedBetsWinPercent: number;
  totalWinPercent: number;
}

export interface UserState {
  userInfo: UserInfo;
  otherUserInfo: UserInfo;
  profiles: UserInfo[];
  loadingUserInfo: boolean;
  loadingOtherUserInfo: boolean;
  updatingUserInfo: boolean;
  allUserInfos: UserInfo[];
  loadingAllUserInfos: boolean;
  userGlobalRank: GlobalRank[];
  loadingUserGlobalRank: boolean;
  profitLossChartValues: ProfitLossChartValue[];
  loadingProfitLossChartValues: boolean;
}

export interface GlobalRank {
  id: number;
  address: string;
  rank: number;
  date: number;
}

export interface ProfitLossChartValue {
  timestamp: number;
  profit: number;
}

const initialState: UserState = {
  userInfo: {
    id: -1,
    address: "",
    notificationId: -1,
    name: "",
    bio: "",
    avatar: "",
    totalRounds: 0,
    totalBets: 0,
    totalQuickBets: 0,
    totalAdvancedBets: 0,
    quickRoundsWon: 0,
    advancedRoundsWon: 0,
    profitLoss: 0,
    predictAllocation: 0,
    quickBetsWinPercent: 0,
    advancedBetsWinPercent: 0,
    totalWinPercent: 0,
  },
  otherUserInfo: {
    id: -1,
    address: "",
    notificationId: -1,
    name: "",
    bio: "",
    avatar: "",
    totalRounds: 0,
    totalBets: 0,
    totalQuickBets: 0,
    totalAdvancedBets: 0,
    quickRoundsWon: 0,
    advancedRoundsWon: 0,
    profitLoss: 0,
    predictAllocation: 0,
    quickBetsWinPercent: 0,
    advancedBetsWinPercent: 0,
    totalWinPercent: 0,
  },
  profiles: [],
  loadingUserInfo: false,
  loadingOtherUserInfo: false,
  updatingUserInfo: false,
  allUserInfos: [],
  loadingAllUserInfos: false,
  userGlobalRank: [],
  loadingUserGlobalRank: false,
  profitLossChartValues: [],
  loadingProfitLossChartValues: false,
};

export const getPlayerProfiles = createAsyncThunk(
  "user/getPlayerProfiles",
  async () => {
    const response = await fetch(`${SERVER_URL}user/`);
    const result = await response.json();
    return result;
  }
);

export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (address: string) => {
    const response = await fetch(`${SERVER_URL}user/${address}`);
    const result = await response.json();
    return result;
  }
);

export const getOtherUserInfo = createAsyncThunk(
  "user/getOtherUserInfo",
  async (address: string) => {
    const response = await fetch(`${SERVER_URL}user/${address}`);
    const user = await response.json();
    return { user };
  }
);

export const getAllUserInfos = createAsyncThunk(
  "user/getAllUserInfos",
  async () => {
    const response = await fetch(`${SERVER_URL}user/stats/all`);
    const result = await response.json();
    return result;
  }
);

export const markReadNotifications = createAsyncThunk(
  "user/markReadNotifications",
  async ({
    address,
    notificationId,
  }: {
    address: string;
    notificationId: number;
  }) => {
    const param = {
      notificationId,
    };
    const response = await fetch(`${SERVER_URL}user/${address}`, {
      method: "PUT", // Specify the method
      headers: {
        "Content-Type": "application/json", // Indicate the type of content being sent
      },
      body: JSON.stringify(param), // Convert the param object to a JSON string
    });
    const result = await response.json();
    return result;
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async ({
    address,
    name,
    bio,
    avatar,
    signature,
  }: {
    address: string;
    name: string;
    bio: string;
    avatar: string;
    signature: string;
  }) => {
    const param = { address, name, bio, avatar, signature };
    const response = await fetch(`${SERVER_URL}user`, {
      method: "POST", // Specify the method
      headers: {
        "Content-Type": "application/json", // Indicate the type of content being sent
      },
      body: JSON.stringify(param), // Convert the param object to a JSON string
    });
    const result = await response.json();
    return result;
  }
);

export const getUserGlobalRankList = createAsyncThunk(
  "user/getUserGlobalRankList",
  async (address: string) => {
    const response = await fetch(`${SERVER_URL}rank/history/${address}`);
    const result = await response.json();
    return result;
  }
);

export const getProfitLossChartValues = createAsyncThunk(
  "user/getProfitLossChartValues",
  async (address: string) => {
    const response = await fetch(
      `${SERVER_URL}prediction/profit-history/${address}`
    );
    const result = await response.json();
    return result;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    markReadNotification: (state, { payload }) => {
      state.userInfo = {
        ...state.userInfo,
        notificationId: payload,
      };
    },
    setAllUserInfos: (state, { payload }) => {
      state.allUserInfos = [...payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.loadingUserInfo = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.loadingUserInfo = false;
      state.userInfo = {
        ...payload,
        avatar: payload.avatar ? payload.avatar : "",
        name: payload.name ? payload.name : "",
        bio: payload.bio ? payload.bio : "",
      };
    });
    builder.addCase(getUserInfo.rejected, (state, { error }) => {
      state.loadingUserInfo = true;
    });

    builder.addCase(getOtherUserInfo.pending, (state) => {
      state.loadingOtherUserInfo = true;
    });
    builder.addCase(getOtherUserInfo.fulfilled, (state, { payload }) => {
      const { user } = payload;
      state.loadingOtherUserInfo = false;
      state.otherUserInfo = {
        ...user,
        avatar: user.avatar ? user.avatar : "",
        name: user.name ? user.name : "",
        bio: user.bio ? user.bio : "",
      };
    });
    builder.addCase(getOtherUserInfo.rejected, (state, { error }) => {
      state.loadingOtherUserInfo = false;
    });

    builder.addCase(markReadNotifications.pending, (state) => {});
    builder.addCase(markReadNotifications.fulfilled, (state) => {});
    builder.addCase(markReadNotifications.rejected, (state) => {});

    builder.addCase(updateUserProfile.pending, (state) => {
      state.updatingUserInfo = true;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
      state.updatingUserInfo = false;
    });
    builder.addCase(updateUserProfile.rejected, (state, { error }) => {
      state.updatingUserInfo = false;
    });

    builder.addCase(getPlayerProfiles.pending, (state) => {});
    builder.addCase(getPlayerProfiles.fulfilled, (state, { payload }) => {
      state.profiles = payload;
    });
    builder.addCase(getPlayerProfiles.rejected, (state, { error }) => {});

    builder.addCase(getAllUserInfos.pending, (state) => {
      state.loadingAllUserInfos = true;
    });
    builder.addCase(getAllUserInfos.fulfilled, (state, { payload }) => {
      state.loadingAllUserInfos = false;
      state.allUserInfos = [...payload];
    });
    builder.addCase(getAllUserInfos.rejected, (state, { error }) => {
      console.log({ error });
      state.loadingAllUserInfos = false;
    });

    builder.addCase(getUserGlobalRankList.pending, (state, { payload }) => {
      state.loadingUserGlobalRank = true;
    });
    builder.addCase(getUserGlobalRankList.fulfilled, (state, { payload }) => {
      state.loadingUserGlobalRank = false;
      state.userGlobalRank = [...payload];

      let subItem: GlobalRank = { id: 0, address: "", rank: 0, date: 0 };

      state.userGlobalRank = payload.map((item: GlobalRank) => {
        item = { ...item, date: new Date(item.date).getTime() };
        if (item.date === subItem.date) {
          subItem = { ...subItem, date: subItem.date + 1 };
          return { ...item, date: item.date + 1 };
        }
        subItem = { ...item };
        return item;
      });
    });
    builder.addCase(getUserGlobalRankList.rejected, (state) => {
      state.loadingUserGlobalRank = true;
    });

    builder.addCase(getProfitLossChartValues.pending, (state) => {
      state.loadingProfitLossChartValues = true;
    });
    builder.addCase(
      getProfitLossChartValues.fulfilled,
      (state, { payload }) => {
        state.loadingProfitLossChartValues = false;
        let subItem: ProfitLossChartValue = { timestamp: 0, profit: 0 };
        state.profitLossChartValues = payload.map(
          (item: ProfitLossChartValue) => {
            if (item.timestamp === subItem.timestamp) {
              subItem = { ...subItem, timestamp: subItem.timestamp + 1 };
              return { ...item, timestamp: item.timestamp + 1 };
            }
            subItem = { ...item };
            return item;
          }
        );
      }
    );
    builder.addCase(getProfitLossChartValues.rejected, (state) => {
      state.loadingProfitLossChartValues = false;
    });
  },
});

export const { markReadNotification, setAllUserInfos } = userSlice.actions;

export default userSlice.reducer;
