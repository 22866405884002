import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "560px",
    width: "50%",
    gap: "20px",
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: "30px",
      padding: "16px",
    },
  },
  profileName: {},
  description: {},
  profileAvatar: {},
  editColumn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",

    "&>div": {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
    },
  },
  label: {
    width: "160px",
    color: theme.palette.text.disabled,
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
  },

  sign: {
    padding: "8px 24px",
    borderRadius: "12px",
    backgroundColor: theme.palette.background.card,
    color: theme.palette.text.disabled,
  },
}));

export default styles;
