import { Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";

import EventsBody from "../../components/EventsBody";
import PageLoadingMov from "../../components/PageLoadingMov";

// Assuming pageLoadingTime is defined in your constants
import { pageLoadingTime } from "../../constants";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [fade, setFade] = useState(true); // New state to control the fade-out effect

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
      // Start the fade-out effect
      const fadeId = setTimeout(() => {
        setFade(false);
      }, 500); // Adjust time for fade-out effect as needed

      return () => clearTimeout(fadeId);
    }, pageLoadingTime);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Fade in={fade} timeout={1000}>
        <Box
          sx={{
            position: "absolute", // Ensure the loader is positioned correctly
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: loading ? 1000 : 1000, // Use zIndex to ensure loader is on top, then hides
          }}
        >
          <PageLoadingMov />
        </Box>
      </Fade>

      {!loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <EventsBody />
        </Box>
      )}
    </>
  );
};

export default Home;
