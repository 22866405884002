import BlockiesSvg from "blockies-react-svg";

interface BlockyProps {
  readonly address: string;
  readonly size: number;
  readonly scale: number;
  readonly className?: string;
  readonly sx?: React.CSSProperties;
}

export default function Blocky(props: BlockyProps) {
  return (
    <BlockiesSvg
      address={props.address}
      size={props.size}
      scale={props.scale}
      style={{
        borderRadius: "3px",
        ...props.sx,
      }}
    />
  );
}
