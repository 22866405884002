import Swal from "sweetalert2";
import { MoneyLineStatus } from "../constants";

export const isLikelyEthereumAddress = (address: string) => {
  // Check if the address is hexadecimal and contains at least some common Ethereum address length
  const hexRegex = /^(0x)?[0-9a-fA-F]+$/;
  return hexRegex.test(address) && address.length >= 30; // Adjust this threshold as needed
};

export const shortAddress = (
  address: string,
  prefix: number = 6,
  suffix: number = 4
) => {
  return address.substring(0, prefix) + "..." + address.slice(suffix * -1);
};

export const shortName = (name: string) => {
  // Check if the input 'name' is a valid Ethereum address
  if (isLikelyEthereumAddress(name)) {
    return shortAddress(name);
  } else {
    return name.substring(0, 15) + "...";
  }
};

export const toUSDFormat = (number: any, digit = 2) => {
  if (number)
    return parseFloat(number).toLocaleString("en-US", {
      maximumFractionDigits: digit,
    });
  return 0;
};

const swalBackground = "#343544";
const swalColor = "white";

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  background: swalBackground,
  color: swalColor,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const calculateDaysDifference = (from: Date, to: Date): number => {
  const fromTimeStamp = from.getTime();
  const toTimeStamp = to.getTime();
  const diffMilli = toTimeStamp - fromTimeStamp;
  if (diffMilli <= 0) {
    return -1;
  } else {
    const diffDays = Math.floor(diffMilli / 1000 / 3600 / 24);
    return diffDays;
  }
};

export const calculateDaysDifferenceByTimeStamp = (
  from: number,
  to: number
): string => {
  const diffSeconds = to - from;
  if (diffSeconds <= 0) {
    return "-1";
  } else {
    const diffDays = Math.floor(diffSeconds / 3600 / 24);
    if (diffDays > 0) return diffDays + " days";
    const hours = Math.floor(diffSeconds / 3600);
    if (hours > 0) return hours + " hours";
    const mins = Math.floor(diffSeconds / 3600 / 24);
    if (mins > 0) return mins + " minutes";
    return "-1";
  }
};

export const CalculateFullDateByTimeStamp = (
  from: number,
  to: number
): string => {
  // sample output: 2 days, 13 hours and 33 minutes left
  const diffSeconds = to - from;
  if (diffSeconds <= 0) {
    return "-1";
  } else {
    const diffDays = Math.floor(diffSeconds / 3600 / 24);
    const hours = Math.floor((diffSeconds % (24 * 3600)) / 3600);
    const mins = Math.floor((diffSeconds / 60) % 60);
    const seconds = Math.floor(diffSeconds % 60);
    let res = "";
    if (diffDays > 0) {
      res += diffDays + " days";
      if (hours > 0 || mins > 0) {
        res += ",";
      }
    }
    if (hours > 0) {
      res += " " + hours + " hours";
      if (mins > 0) {
        res += " and";
      }
    }
    if (mins > 0) {
      res += " " + mins + " minutes";
    }
    if (diffDays === 0 && hours === 0) {
      res += " " + seconds + " seconds";
    }
    return res;
  }
};

export const convertDateTime = (sec: number) => {
  const oneDay = 24 * 3600;
  const oneHour = 3600;
  const days = Math.floor(sec / oneDay);
  const hours = Math.floor((sec % oneDay) / oneHour);
  const minutes = Math.floor((sec % oneHour) / 60);
  const seconds = Math.floor((sec % oneHour) % 60);
  if (days > 0) return `${days} days & ${hours} hours`;
  else if (hours > 0) return `${hours} hours  & ${minutes} mins`;
  else return ` ${minutes} mins & ${seconds} secs`;
};

export const convertDateTimeSimple = (sec: number) => {
  const oneDay = 24 * 3600;
  const oneHour = 3600;
  const days = Math.floor(sec / oneDay);
  const hours = Math.floor((sec % oneDay) / oneHour);
  const minutes = Math.floor((sec % oneHour) / 60);
  const seconds = Math.floor((sec % oneHour) % 60);
  if (days > 0) return `${days} days`;
  else if (hours > 0) return `${hours} hours`;
  else if (minutes > 0) return ` ${minutes} mins`;
  else return `${seconds} secs`;
};

const getOrdinalSuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const convertUTCTime = (time: number) => {
  const date = new Date(time);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZone: "UTC",
  };

  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);
  const day = parseInt(formattedDate.split(" ")[0]);
  const ordinalSuffix = getOrdinalSuffix(day);
  const formattedDay = `${day}${ordinalSuffix}`;
  return formattedDate.replace(/\d+/, formattedDay).replace(" at ", ", ");
};

export const remainingTime = (diff: number) => {
  if (diff < 0) return -1;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = diff % 60;

  // Format the time string
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return formattedTime;
};

// Function to format bytes into a human-readable format
export const formatBytes = (bytes: any) => {
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return Math.round(100 * (bytes / Math.pow(k, i))) / 100 + " " + sizes[i];
};

export const ceilDecimal = (value: any, decimal: number) => {
  const k = Math.pow(10, decimal);
  return Math.ceil(value * k) / k;
};

export const digitsLength = (value: number) => {
  return Math.floor(value).toString().length;
};

export const getMoneyLineOdds = (
  my: number,
  other: number
): {
  symbol: string;
  value: number;
  type: string;
} | null => {
  if (my === 0 || other === 0) return null;
  if (my > other) {
    return {
      symbol: "-",
      value: Math.floor((100 / other) * my),
      type: "Favorite",
    };
  } else if (my < other) {
    return {
      symbol: "+",
      value: Math.floor((100 / my) * other),
      type: "Underdog",
    };
  } else {
    return {
      symbol: "-1",
      value: Math.floor((100 / my) * other),
      type: "Draw",
    };
  }
};
