import { ethers } from "ethers";
import { getProvider, getWeb3, getTokenAllowance, approveToken } from "./index";
import { gameConfig } from "../../config";

import AirdropAbi from "../../abis/airdrop.json";
import { Staking } from "../../reducers/airdrop.slice";

export const getPLSPStakingContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const stakingContract = new web3.eth.Contract(
    AirdropAbi,
    gameConfig.plspStakingAddress
  );
  return stakingContract;
};

export const stakePlsp = async (stakeAmount: string, account: string) => {
  const amount = Number(stakeAmount);
  const stakingContract = getPLSPStakingContract(true);
  const tokenAllowance = await getTokenAllowance(
    gameConfig.plspTokenAddress,
    account,
    gameConfig.plspStakingAddress
  );

  if (Number(tokenAllowance) < amount) {
    await approveToken(
      gameConfig.plspTokenAddress,
      amount,
      gameConfig.plspStakingAddress,
      account
    );
  }
  await stakingContract.methods
    .stake(ethers.utils.parseEther(stakeAmount).toString())
    .send({ from: account });
};

export const unstakePlsp = async (stakeId: number, account: string) => {
  const stakingContract = getPLSPStakingContract(true);
  await stakingContract.methods.unStake(stakeId).send({ from: account });
};

export const getIsFinishedStakingList = async (stakingList: Staking[]) => {
  const stakingContract = getPLSPStakingContract(true);

  const promises = stakingList.map(async (list) => {
    const info: any = await stakingContract.methods.stakings(list.id).call();
    return info.isFinished;
  });
  const res: boolean[] = await Promise.all(promises);

  return res;
};
