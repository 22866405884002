import { Box, Skeleton } from "@mui/material";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getRoundInfoById } from "../../reducers/euro24.slice";
import { EuroMatchInfo } from "../../types/EuroMatchInfo";
import useStyles from "./index.styles";
import { shortAddress, toUSDFormat } from "../../utils";
import TokenImage from "../TokenImage";
import { Link } from "react-router-dom";
import { gameConfig } from "../../config";
import ReactCountryFlag from "react-country-flag";
import { getCountryAbbreviationByName } from "../../utils/euro";
import clsx from "clsx";

export default function EuroMatchDetailPanel({
  match,
  enableDraw,
}: {
  match: EuroMatchInfo;
  enableDraw: boolean;
}) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { profiles } = useAppSelector((state) => state.user);
  const { roundInfos } = useAppSelector((state) => state.euro24);

  const roundInfo = roundInfos[match.matchId - 1];

  useEffect(() => {
    if (!match) return;

    dispatch(getRoundInfoById({ roundId: match.matchId }));
  }, [dispatch, match]);

  const renderSkeleton = () => {
    return (
      <Box className={classes.betListItem}>
        <Skeleton variant="rounded" animation="wave" width={14} height={14} />

        <Skeleton variant="rounded" animation="wave" width={80} height={16} />

        <Skeleton variant="rounded" animation="wave" width={45} height={16} />

        <Skeleton variant="rounded" animation="wave" width={14} height={14} />
      </Box>
    );
  };

  return (
    <Box
      className={clsx(
        classes.matchDetailPanel,
        !enableDraw ? classes.subMatchDetailPanel : null
      )}
    >
      {roundInfos.length > 0 && (
        <>
          <Box className={classes.panel}>
            <Box className={classes.panelHeader}>
              <Box className={classes.betCount}>
                {roundInfos[match.matchId - 1].player1EntryData &&
                  roundInfos[match.matchId - 1].player1EntryData.length}{" "}
                Bets
              </Box>

              <Box>
                <ReactCountryFlag
                  className={classes.matchCountryFlag}
                  countryCode={getCountryAbbreviationByName(match.country1)}
                  svg
                  aria-label={match.country1}
                />
              </Box>

              <Box className={classes.betAmount}>
                ${toUSDFormat(roundInfos[match.matchId - 1].player1TotalUsd)}
              </Box>
            </Box>

            <Box className={classes.betList}>
              {roundInfo.player1EntryData
                ? roundInfo.player1EntryData.map((entry, index) => {
                    const profile = profiles.find(
                      (profile, index) =>
                        profile.address.toLowerCase() ===
                        entry.player.toLowerCase()
                    );

                    return (
                      <Box className={classes.betListItem} key={index}>
                        <Box
                          component="img"
                          src={profile?.avatar}
                          className={classes.avatar}
                        ></Box>
                        <Box>{shortAddress(entry.player)}</Box>
                        <Box>${entry.usdAmount}</Box>
                        <Link
                          to={`${gameConfig.explorerUrl}tx/${entry.txHash}`}
                          target="_blank"
                        >
                          <TokenImage
                            token={entry.tokenName}
                            sx={{
                              width: "14px",
                              height: "14px",
                            }}
                          />
                        </Link>
                      </Box>
                    );
                  })
                : renderSkeleton()}
            </Box>
          </Box>

          {enableDraw ? (
            <Box
              className={clsx(
                classes.panel,
                !enableDraw ? classes.subPanel : null
              )}
            >
              <Box className={classes.panelHeader}>
                <Box className={classes.betCount}>
                  {roundInfo.drawEntryData && roundInfo.drawEntryData.length}{" "}
                  Bets
                </Box>

                <Box>Draw</Box>

                <Box className={classes.betAmount}>
                  ${toUSDFormat(roundInfo.drawUsd)}
                </Box>
              </Box>

              <Box className={classes.betList}>
                {roundInfo.drawEntryData
                  ? roundInfo.drawEntryData.map((entry, index) => {
                      const profile = profiles.find(
                        (profile, index) =>
                          profile.address.toLowerCase() ===
                          entry.player.toLowerCase()
                      );

                      return (
                        <Box className={classes.betListItem} key={index}>
                          <Box
                            component="img"
                            src={profile?.avatar}
                            className={classes.avatar}
                          ></Box>
                          <Box>{shortAddress(entry.player)}</Box>
                          <Box>${entry.usdAmount}</Box>
                          <Link
                            to={`${gameConfig.explorerUrl}tx/${entry.txHash}`}
                            target="_blank"
                          >
                            <TokenImage
                              token={entry.tokenName}
                              sx={{
                                width: "14px",
                                height: "14px",
                              }}
                            />
                          </Link>
                        </Box>
                      );
                    })
                  : renderSkeleton()}
              </Box>
            </Box>
          ) : null}

          <Box className={classes.panel}>
            <Box className={classes.panelHeader}>
              <Box className={classes.betCount}>
                {roundInfo.player2EntryData &&
                  roundInfo.player2EntryData.length}{" "}
                Bets
              </Box>

              <Box>
                <ReactCountryFlag
                  className={classes.matchCountryFlag}
                  countryCode={getCountryAbbreviationByName(match.country2)}
                  svg
                  aria-label={match.country2}
                />
              </Box>

              <Box className={classes.betAmount}>
                ${toUSDFormat(roundInfo.player2TotalUsd)}
              </Box>
            </Box>

            <Box className={classes.betList}>
              {roundInfo.player2EntryData
                ? roundInfo.player2EntryData.map((entry, index) => {
                    const profile = profiles.find(
                      (profile, index) =>
                        profile.address.toLowerCase() ===
                        entry.player.toLowerCase()
                    );

                    return (
                      <Box className={classes.betListItem} key={index}>
                        <Box
                          component="img"
                          src={profile?.avatar}
                          className={classes.avatar}
                        ></Box>
                        <Box>{shortAddress(entry.player)}</Box>
                        <Box>${entry.usdAmount}</Box>
                        <Link
                          to={`${gameConfig.explorerUrl}tx/${entry.txHash}`}
                          target="_blank"
                        >
                          <TokenImage
                            token={entry.tokenName}
                            sx={{
                              width: "14px",
                              height: "14px",
                            }}
                          />
                        </Link>
                      </Box>
                    );
                  })
                : renderSkeleton()}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
