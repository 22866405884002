import { Box, Button, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import ConfettiExplosion from "react-confetti-explosion";

import theme from "../../theme";
import useStyles from "./index.styles";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";

interface UfcBetCompletePopupProps {
  open: boolean;
  readonly betUsdAmount: number;
  onClose: () => void;
  onCreateBet: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const dialogStyle = {
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      minHeight: "320px",
      backgroundColor: "#1c1f28",
      padding: "20px 30px",
      borderRadius: "10px",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "800px",
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: "600px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
        minHeight: "300px",
      },
      "& .MuiDialogContent-root": {
        [theme.breakpoints.down("sm")]: {
          padding: "0",
        },
      },
    },
  },
};

export default function UfcBetCompletePopup(props: UfcBetCompletePopupProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.body}>
      <BootstrapDialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        sx={dialogStyle}
      >
        <DialogContent>
          <Box className={classes.header}>
            <CheckIcon className={classes.checkIcon} />
            <Box>Success! Your bet is placed</Box>
          </Box>
          <Box className={classes.content}>
            <Button
              className={classes.createButton}
              onClick={props.onCreateBet}
            >
              <AddCircleOutlineIcon />
              Create another bet
            </Button>

            <Box className={classes.predictText}>You earned</Box>
            <Box className={classes.predictBody}>
              <Box>
                <Box component="img" src="/images/logo.png" />
              </Box>
              <Box>{100 * props.betUsdAmount}</Box>
              <Box>$PREDICT</Box>
              <Box component="img" src="/images/icons/burn.png" />
            </Box>
          </Box>
        </DialogContent>

        <ConfettiExplosion
          duration={2500}
          zIndex={10000}
          width={2000}
          height={"100vh"}
          style={{ position: "absolute", top: "0", left: "50%", width: "100%" }}
        />
      </BootstrapDialog>
    </Box>
  );
}
