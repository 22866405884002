import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gameConfig } from "../config";
import { middleAirdropTime } from "../constants";
import {
  getIsFinishedStakingList,
  stakePlsp,
  unstakePlsp,
} from "../helpers/contractFunctions/airdrop";
import { Toast } from "../utils";
import { getCurrentAirdropForStake } from "../utils/airdrop";

export interface AirdropState {
  stakingList: Staking[];
  isFinishedStakingList: boolean[];
  info: {
    sacrifice: number;
    activity: number;
    staking: number;
    nft: number;
  };
  isStaking: boolean;
  isUnstaking: boolean;
  isLoadingInfo: boolean;
  supplyList: Supply[];
  beforeSupplyList: Supply[];
  totalAmount: number;
  isLoadingSupplyList: boolean;
  lastAirdropTime: number;
  totalPayout: number;
  loadingEarnHistory: boolean;
  earnHistory: EarnHistory[];
}

export interface Staking {
  id: number;
  user: string;
  amount: number;
  timestamp: number;
}

export interface EarnHistory {
  id: number;
  goalId: number;
  address: string;
}

export interface Supply {
  id: number;
  address: string;
  sacrifice: number;
  activity: number;
  staking: number;
  nft: number;
  stakingData: Staking[];
  amount: number;
  beforeAmount: number;
}

const initialState: AirdropState = {
  stakingList: [],
  isFinishedStakingList: [],
  info: {
    sacrifice: 0,
    activity: 0,
    staking: 0,
    nft: 0,
  },
  isStaking: false,
  isUnstaking: false,
  isLoadingInfo: false,
  supplyList: [],
  beforeSupplyList: [],
  isLoadingSupplyList: false,
  totalAmount: 0,
  lastAirdropTime: 0,
  totalPayout: 0,
  loadingEarnHistory: false,
  earnHistory: [],
};

export const getAirdropInfo = createAsyncThunk(
  "airdrop/getAirdroInfo",
  async (address: string) => {
    if (address === "") {
      return {
        sacrifice: 0,
        activity: 0,
        staking: 0,
        nft: 0,
      };
    }
    const response = await fetch(gameConfig.serverUrl + `airdrop/${address}`);
    const result = await response.json();
    return {
      activity: result?.activity || 0,
      staking: result?.staking || 0,
      sacrifice: result?.sacrifice || 0,
      nft: result?.nft || 0,
    };
  }
);

export const getStakes = createAsyncThunk(
  "airdrop/getStakes",
  async (address: string) => {
    if (address === "") {
      return [];
    }
    const response = await fetch(gameConfig.serverUrl + `stake/${address}`);
    const result = await response.json();
    return (
      result?.map((item: any) => ({
        id: Number(item.id),
        user: item.address,
        amount: Number(item.amount),
        timestamp: Number(item.timestamp),
      })) || []
    );
  }
);

export const stake = createAsyncThunk(
  "airdrop/stake",
  async ({
    stakeAmount,
    account,
  }: {
    stakeAmount: string;
    account: string;
  }) => {
    await stakePlsp(stakeAmount, account);
  }
);

export const unstake = createAsyncThunk(
  "airdrop/unstake",
  async ({ stakeId, account }: { stakeId: number; account: string }) => {
    await unstakePlsp(stakeId, account);
  }
);

export const getIsFinishedList = createAsyncThunk(
  "airdrop/getIsFinishedList",
  async ({ stakingList }: { stakingList: Staking[] }) => {
    const list = await getIsFinishedStakingList(stakingList);
    return list;
  }
);

export const getSupplyList = createAsyncThunk("airdrop", async () => {
  const response = await fetch(gameConfig.serverUrl + `airdrop`);
  const result = await response.json();
  return result;
});

export const getLeaderBoardData = createAsyncThunk(
  "earn/getLeaderBoardData",
  async () => {
    const response = await fetch(gameConfig.serverUrl + "leaderboard/info");
    const { lastAirdropTime, totalPayout } = await response.json();
    return { lastAirdropTime, totalPayout };
  }
);

export const getEarnHistory = createAsyncThunk(
  "earn/getEarnHistory",
  async (address: string) => {
    const response = await fetch(gameConfig.serverUrl + "earn/" + address);
    const result = response.json();
    return result;
  }
);

export const airdropSlice = createSlice({
  name: "airdrop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAirdropInfo.pending, (state) => {
      state.isLoadingInfo = true;
    });
    builder.addCase(getAirdropInfo.fulfilled, (state, { payload }) => {
      state.info = payload;
      state.isLoadingInfo = false;
    });
    builder.addCase(getAirdropInfo.rejected, (state, { error }) => {
      state.isLoadingInfo = false;
      console.log(error);
    });
    builder.addCase(getStakes.pending, (state) => {});
    builder.addCase(getStakes.fulfilled, (state, { payload }) => {
      state.stakingList = payload;
    });
    builder.addCase(getStakes.rejected, (state, { error }) => {
      console.log(error);
    });

    builder.addCase(stake.pending, (state) => {
      state.isStaking = true;
    });
    builder.addCase(stake.fulfilled, (state, { payload }) => {
      state.isStaking = false;
      Toast.fire({
        icon: "success",
        title: "Staked successfully",
      });
    });
    builder.addCase(stake.rejected, (state, { error }) => {
      state.isStaking = false;
      Toast.fire({
        icon: "error",
        title: "Failed to stake.",
      });
      console.error(error);
    });

    builder.addCase(unstake.pending, (state) => {
      state.isUnstaking = true;
    });
    builder.addCase(unstake.fulfilled, (state, { payload }) => {
      state.isUnstaking = false;
      Toast.fire({
        icon: "success",
        title: "Unstaked successfully",
      });
    });
    builder.addCase(unstake.rejected, (state, { error }) => {
      state.isUnstaking = false;
      Toast.fire({
        icon: "error",
        title: "Failed to unstake.",
      });
      console.error(error);
    });

    builder.addCase(getIsFinishedList.pending, (state) => {
      state.isFinishedStakingList = [];
    });
    builder.addCase(getIsFinishedList.fulfilled, (state, { payload }) => {
      state.isFinishedStakingList = payload;
    });
    builder.addCase(getIsFinishedList.rejected, (state, { error }) => {});

    builder.addCase(getLeaderBoardData.pending, (state) => {});
    builder.addCase(getLeaderBoardData.fulfilled, (state, { payload }) => {
      state.lastAirdropTime = payload.lastAirdropTime + 24 * 60 * 60;
      state.totalPayout = payload.totalPayout;
    });
    builder.addCase(getLeaderBoardData.rejected, (state, { error }) => {
      console.error(error);
    });

    builder.addCase(getEarnHistory.pending, (state) => {
      state.loadingEarnHistory = true;
    });
    builder.addCase(getEarnHistory.fulfilled, (state, { payload }) => {
      state.loadingEarnHistory = false;
      state.earnHistory = [];
      payload.forEach((item: any) => {
        state.earnHistory.push({
          id: Number(item.id),
          goalId: Number(item.goalId),
          address: item.address,
        });
      });
    });
    builder.addCase(getEarnHistory.rejected, (state, { error }) => {
      state.loadingEarnHistory = false;
      console.error(error);
    });

    builder.addCase(getSupplyList.pending, (state) => {
      state.isLoadingSupplyList = true;
      state.supplyList = [];
    });
    builder.addCase(getSupplyList.fulfilled, (state, { payload }) => {
      let tempTotalAmount: number = 0;
      const tempSupplyList: Supply[] = [];
      payload.forEach((item: any) => {
        let updatedItem: Supply = {
          id: item.id,
          address: item.address,
          sacrifice: item.sacrifice,
          activity: item.activity,
          staking: item.staking,
          nft: item.nft,
          stakingData: item.stakingData,
          amount: 0,
          beforeAmount: item.amount,
        };
        updatedItem.amount = updatedItem.stakingData.reduce(
          (sum: number, stakingItem: Staking) =>
            sum +
            getCurrentAirdropForStake(
              middleAirdropTime.getTime() / 1000,
              stakingItem
            ),
          0
        );
        updatedItem.amount +=
          updatedItem.activity + updatedItem.sacrifice + updatedItem.nft;
        tempTotalAmount += updatedItem.amount;
        tempSupplyList.push(updatedItem);
      });
      state.supplyList = tempSupplyList.slice().sort((a: Supply, b: Supply) => {
        return b.amount - a.amount;
      });
      state.beforeSupplyList = tempSupplyList
        .slice()
        .sort((a: Supply, b: Supply) => {
          return b.beforeAmount - a.beforeAmount;
        });
      state.totalAmount = tempTotalAmount;
      state.isLoadingSupplyList = false;
    });
    builder.addCase(getSupplyList.rejected, (state, { error }) => {
      state.isLoadingSupplyList = false;
      Toast.fire({
        icon: "error",
        title: "Failed to get leaderboard data",
      });
      console.log(error);
    });
  },
});

export default airdropSlice.reducer;
