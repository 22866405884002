import { Box } from "@mui/material";
import EventCard from "../EventCard";
import Dot from "../Dot";

import useStyles from "./index.styles";

interface Events {
  name?: string;
  matchDay: string;
  status: string;
  logoName: string;
  url?: string;
}

export default function MyProfile() {
  const { classes } = useStyles();
  const liveEvents: Events[] = [];
  const upcomingEvents: Events[] = [
    {
      matchDay: "November 15th 2024",
      status: "Upcoming",
      logoName: "event-tyson.png",
    },
    {
      matchDay: "November 5th 2024",
      status: "Upcoming",
      logoName: "us-election-white.png",
      url: "election",
    },
  ];
  const pastEvents: Events[] = [
    {
      matchDay: "June 14th 2024",
      status: "Completed",
      logoName: "euro2024.png",
      url: "euro2024",
    },
    {
      matchDay: "April 9th 2024",
      status: "Completed",
      logoName: "uefa.png",
      url: "uefa2024",
    },
    {
      matchDay: "May 18th 2024",
      status: "Completed",
      logoName: "fury.png",
      url: "dazn",
    },
    {
      matchDay: "April 13th 2024",
      status: "Completed",
      logoName: "ufc300.png",
      url: "ufc300",
    },
    {
      matchDay: "March 19th 2024",
      status: "Completed",
      logoName: "event-marchmadness.png",
      url: "march",
    },
    {
      matchDay: "March 9th 2024",
      status: "Completed",
      logoName: "event-ufc299.png",
      url: "ufc299",
    },
    {
      matchDay: "Febrary 16th 2024",
      status: "Completed",
      logoName: "event-ifc1.png",
      url: "ifc",
    },
  ];

  return (
    <Box className={classes.body}>
      {liveEvents.length > 0 ? (
        <Box className={classes.headerText}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Dot
              sx={{
                backgroundColor: "#ff1b1b",
                width: "10px",
                height: "10px",
                boxShadow:
                  "0 0 8px #e91d24, 0 0 16px #e91d24, 0 0 24px #e91d24, 0 0 56px #e91d24;",
              }}
            ></Dot>
            <Box>Live</Box>
          </Box>
        </Box>
      ) : null}

      <Box className={classes.cardBody}>
        {liveEvents.map((eve: Events, index) => (
          <EventCard
            key={index}
            matchDay={eve.matchDay}
            status={eve.status}
            image={eve.logoName}
            url={eve.url}
            sx={{ maxWidth: "250px" }}
          />
        ))}
      </Box>
      <Box className={classes.headerText}>
        <Box>Upcoming events</Box>
      </Box>
      <Box className={classes.cardBody}>
        {upcomingEvents.map((eve: Events, index) => (
          <EventCard
            key={index}
            matchDay={eve.matchDay}
            status={eve.status}
            image={eve.logoName}
            url={eve.url}
            sx={{ maxWidth: "250px" }}
          />
        ))}
      </Box>
      <Box className={classes.headerText}>
        <Box>Past events</Box>
      </Box>
      <Box className={classes.cardBody}>
        {pastEvents.map((eve: Events, index) => (
          <EventCard
            key={index}
            matchDay={eve.matchDay}
            status={eve.status}
            image={eve.logoName}
            url={eve.url}
            sx={{ maxWidth: "250px" }}
          />
        ))}
      </Box>
    </Box>
  );
}
