import * as React from 'react';
import { Button, Box} from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import theme from '../../theme';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Uefa24InformationDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button sx={{
        color: theme.palette.text.primary,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.background.card,
        borderRadius: '9px',
        padding: '10px 20px',
        display: 'flex',
        textTransform: 'none',
        gap: '15px',
        '& img': {
          width: '22px',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '5px 5px',
        }
      }} onClick={handleClickOpen}>
        <Box component='img' src='/images/icons/info.png' />
        <Box sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          }
        }}>information</Box>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: '100%',
              maxWidth: '700px',
              minHeight: '700px',
              backgroundColor: '#1b1f28',
              padding: '20px 10px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '300px',
                minHeight: '300px',
              },
              '& .MuiDialogContent-root': {
                borderBottom: 'none',
                borderTop: '1px solid #2f3441',
                [theme.breakpoints.down('sm')]: {
                  padding: '0',
                },
              },
              '&>.MuiTypography-root': {
                textAlign: 'center',
              },
            }
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'center', gap: '15px', alignItems: 'center' }} id="customized-dialog-title">
          <Box component='img' src='/images/icons/info.png' sx={{
            width: '25px',
          }} />
          <Box sx={{
            fontSize: '30px',
            [theme.breakpoints.down('sm')]: {
              fontSize: '24px',
            }
          }}>information</Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers >
          <Box sx={{
            padding: '30px 50px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            alignItems: 'center',
            fontSize: '20px',
            [theme.breakpoints.down('sm')]: {
              fontSize: '16px',
              padding: '30px 20px',
            }
          }}>
            <Box>Game dates:</Box>
            <Box sx={{
              color: '#6a6d73',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Box>Quarter finals 9-17 April</Box>
              <Box>Semi finals 30th April - 7th May</Box>
              <Box>Final 1st June</Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Box sx={{
              padding: '30px 50px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              alignItems: 'center',
              fontSize: '20px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                padding: '10px 20px',
              }
            }}>Tournament rules:</Box>
            <Box sx={{
              color: '#6a6d73',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '18px',
              width: '70%',
              justifyContent: 'center',
              textAlign: 'center',
              [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                padding: '10px 20px',
                width: '100%',
              }
            }}>
              <Box>- 1 ticket equals 7 predictions.</Box>
              <Box>- 1 ticket costs 5 $USD</Box>
              <Box>- The winning ticket(s) share the pool of funds</Box>
              <Box>- The winning ticket(s) are defined by having at least 1 correct prediction.</Box>
              <Box sx={{marginTop: '30px'}}>Winning ticket(s) will received all pooled funds on the 1st June shortly after the final game ended.</Box>
            </Box>
          </Box>
          <Box>
            
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}