import Lottie from "lottie-react";
import loadingMov from "../../constants/loading.json";

export default function PageLoadingMov() {
  return (
    <Lottie animationData={loadingMov} style={{
      width: '200px',
      height: '200px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }} />
  );
}