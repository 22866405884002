import React, { useEffect, useState, useRef } from "react";
import { Box, Popover, Button, Skeleton } from "@mui/material";

import CircularLogo from "../../components/CircularLogo";
import LeaderBoardTable from "../../components/LeaderBoardTable";

import useStyles from "./index.styles";
import clsx from "clsx";
import { remainingTime, toUSDFormat } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getLeaderBoardData,
  getSupplyList,
} from "../../reducers/airdrop.slice";
import { getAllUserInfos, UserInfo } from "../../reducers/user.slice";
import { globalRankWeight } from "../../constants";

export default function LeaderBoard() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { lastAirdropTime, totalPayout, isLoadingSupplyList } = useAppSelector(
    (state) => state.airdrop
  );
  const { allUserInfos, loadingAllUserInfos } = useAppSelector(
    (state) => state.user
  );
  const [diffTime, setDiffTime] = useState(-1);
  const { supplyList } = useAppSelector((state) => state.airdrop);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [updateUserInfos, setUpdateUserInfos] = useState<UserInfo[]>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const getWinRate = (addressInfo: UserInfo) => {
    if (!addressInfo || addressInfo.totalRounds === 0) return 0;
    return (
      ((addressInfo.quickRoundsWon + addressInfo.advancedRoundsWon) /
        addressInfo.totalRounds) *
      100
    );
  };

  const getPredictAllocation = (address: string) => {
    const supply = supplyList.find(
      (item) => item.address.toLowerCase() === address?.toLowerCase()
    );
    return supply?.amount ?? 0;
  };

  const getGlobalRank = (updateUserInfos: UserInfo[]) => {
    const maxTotalBets = updateUserInfos.reduce((max, obj) => {
      return obj.totalBets > max ? obj.totalBets : max;
    }, 0);
    const maxSupply = updateUserInfos.reduce((max, obj) => {
      return obj.predictAllocation > max ? obj.predictAllocation : max;
    }, 0);
    let maxProfitLoss = updateUserInfos.reduce(
      (max, obj) => {
        return obj.profitLoss > max ? obj.profitLoss : max;
      },
      updateUserInfos.length > 0 ? updateUserInfos[0].profitLoss : 0
    );
    let minProfitLoss = updateUserInfos.reduce(
      (min, obj) => {
        return obj.profitLoss < min ? obj.profitLoss : min;
      },
      updateUserInfos.length > 0 ? updateUserInfos[0].profitLoss : 0
    );
    const profitDiff = 0 - minProfitLoss;
    maxProfitLoss = maxProfitLoss - minProfitLoss;
    const sortedList = updateUserInfos
      .slice()
      .sort((a: UserInfo, b: UserInfo) => {
        const valueB =
          (getWinRate(b) / 100) * globalRankWeight.WinRate +
          (b.totalBets / maxTotalBets) * globalRankWeight.TotalBets +
          (b.predictAllocation / maxSupply) * globalRankWeight.Airdrop +
          ((b.profitLoss + profitDiff) / maxProfitLoss) *
            globalRankWeight.ProfitLoss;
        const valueA =
          (getWinRate(a) / 100) * globalRankWeight.WinRate +
          (a.totalBets / maxTotalBets) * globalRankWeight.TotalBets +
          (a.predictAllocation / maxSupply) * globalRankWeight.Airdrop +
          ((a.profitLoss + profitDiff) / maxProfitLoss) *
            globalRankWeight.ProfitLoss;
        return valueB - valueA;
      });
    // sortedList.map((b) =>
    //   console.log(
    //     b,
    //     (getWinRate(b) / 100) * globalRankWeight.WinRate +
    //       (b.totalBets / maxTotalBets) * globalRankWeight.TotalBets +
    //       (b.predictAllocation / maxSupply) * globalRankWeight.Airdrop +
    //       ((b.profitLoss + profitDiff) / maxProfitLoss) *
    //         globalRankWeight.ProfitLoss
    //   )
    // );
    setUpdateUserInfos(sortedList);
  };

  const getQuickBetsWinPercent = (addressInfo: UserInfo) => {
    if (!addressInfo || addressInfo.totalQuickBets === 0) return 0;
    return (addressInfo.quickRoundsWon / addressInfo.totalQuickBets) * 100;
  };

  const getAdvancedBetsWinPercent = (addressInfo: UserInfo) => {
    if (!addressInfo || addressInfo.totalAdvancedBets === 0) return 0;
    return (
      (addressInfo.advancedRoundsWon / addressInfo.totalAdvancedBets) * 100
    );
  };

  const renderCardBox = () => (
    <>
      <Box className={clsx(classes.detailCard, classes.playersCard)}>
        <Box className={classes.softColor}>Ranked players</Box>
        <Box>
          {supplyList.length === 0 ? (
            <Skeleton width={100} height={30} />
          ) : (
            toUSDFormat(supplyList.length)
          )}
        </Box>
      </Box>
      <Box className={clsx(classes.detailCard, classes.timeCard)}>
        <Box className={classes.softColor}>Next reward drop in:</Box>
        <Box>
          {remainingTime(diffTime) === -1 ? (
            <Skeleton width={100} height={30} />
          ) : (
            remainingTime(diffTime)
          )}
        </Box>
        <Box
          className={classes.airdropIcon}
          component={"img"}
          src="/images/prediction/bitcoin.png"
        />

        <Button onClick={handleClick} className={classes.infoIcon}>
          <Box component={"img"} src="/images/prediction/info.png" />
        </Button>
        <Popover
          aria-describedby={"popover"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#1c1f28",
              padding: "20px 60px",
              display: "flex",
              flexDirection: "column",
              "&>div": {
                display: "flex",
                alignItems: "center",
                gap: "10px",
              },
            },
          }}
        >
          <Box>1. 150,000</Box>
          <Box>2. 120,000</Box>
          <Box>3. 100,000</Box>
          <Box>4. 75,000</Box>
          <Box>5. 50,000</Box>
          <Box>6-10: 25,000</Box>
          <Box>11-20: 15,000</Box>
          <Box>21-50: 10,000</Box>
        </Popover>
      </Box>
      <Box className={clsx(classes.detailCard, classes.payoutCard)}>
        <Box className={classes.softColor}>Leaderboard payout</Box>
        <Box>
          <CircularLogo width={30} />
          {totalPayout === 0 ? (
            <Skeleton width={100} height={30} />
          ) : (
            toUSDFormat(totalPayout)
          )}
        </Box>
      </Box>
    </>
  );

  const renderListSkeleton = () => {
    const skeletons = [
      <Box className={classes.detailSkeleton} key={-1}>
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={35} />
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={35} />
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={35} />
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={35} />
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={35} />
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={35} />
      </Box>,
    ];
    for (let i = 0; i < 50; i++) {
      skeletons.push(
        <Box className={classes.detailSkeleton} key={i}>
          <Skeleton variant="rectangular" sx={{ width: "100%" }} height={45} />
          <Skeleton variant="rectangular" sx={{ width: "100%" }} height={45} />
          <Skeleton variant="rectangular" sx={{ width: "100%" }} height={45} />
          <Skeleton variant="rectangular" sx={{ width: "100%" }} height={45} />
          <Skeleton variant="rectangular" sx={{ width: "100%" }} height={45} />
          <Skeleton variant="rectangular" sx={{ width: "100%" }} height={45} />
        </Box>
      );
    }
    return <Box className={classes.skeletonBody}>{skeletons}</Box>;
  };

  useEffect(() => {
    dispatch(getLeaderBoardData());
    dispatch(getAllUserInfos());
  }, []);

  useEffect(() => {
    dispatch(getSupplyList());
  }, [dispatch]);

  useEffect(() => {
    let interval: any;
    if (lastAirdropTime) {
      interval = setInterval(() => {
        setDiffTime(lastAirdropTime - Math.floor(new Date().getTime() / 1000));
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [lastAirdropTime, dispatch]);

  useEffect(() => {
    if (isLoadingSupplyList || supplyList.length === 0 || loadingAllUserInfos) {
      return;
    }
    const updateInfos = allUserInfos.map((userInfo) => {
      const updateUserInfo = { ...userInfo };
      updateUserInfo.predictAllocation = getPredictAllocation(
        updateUserInfo.address
      );
      updateUserInfo.quickBetsWinPercent =
        getQuickBetsWinPercent(updateUserInfo);
      updateUserInfo.advancedBetsWinPercent =
        getAdvancedBetsWinPercent(updateUserInfo);
      updateUserInfo.totalWinPercent = getWinRate(updateUserInfo);
      return updateUserInfo;
    });
    getGlobalRank(updateInfos);
  }, [supplyList, dispatch, isLoadingSupplyList, loadingAllUserInfos]);

  return (
    <Box className={classes.body}>
      <Box
        className={classes.leaderBoardIcon}
        src={"/images/prediction/leaderboard.png"}
        component={"img"}
      />
      <Box className={classes.header}>Global rank leaderboard</Box>
      <Box className={classes.cardBox}>{renderCardBox()}</Box>
      <Box className={classes.leaderBoardTable}>
        {!updateUserInfos ||
        updateUserInfos.length === 0 ||
        isLoadingSupplyList ||
        loadingAllUserInfos ||
        supplyList.length === 0 ? (
          renderListSkeleton()
        ) : (
          <LeaderBoardTable data={updateUserInfos}></LeaderBoardTable>
        )}
      </Box>
    </Box>
  );
}
