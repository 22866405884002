import { useEffect, useState } from "react";
import { Box, Button, Skeleton } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import useStyles from "./index.styles";
import { shortAddress, shortName, toUSDFormat } from "../../utils";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import CountUp from "react-countup";
import {
  getAllUserInfos,
  getOtherUserInfo,
  setAllUserInfos,
  UserInfo,
} from "../../reducers/user.slice";
import { useConnectWallet } from "@web3-onboard/react";
import clsx from "clsx";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { getSupplyList } from "../../reducers/airdrop.slice";
import { ProfileRankGrade, globalRankWeight } from "../../constants";

export default function Profile() {
  const { classes } = useStyles();
  const {
    otherUserInfo,
    allUserInfos,
    loadingOtherUserInfo,
    loadingAllUserInfos,
  } = useAppSelector((state) => state.user);
  const { supplyList, isLoadingSupplyList } = useAppSelector(
    (state) => state.airdrop
  );
  const dispatch = useAppDispatch();
  const { address } = useParams();
  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const [globalRank, setGlobalRank] = useState(0);
  const [updateUserInfos, setUpdateUserInfos] = useState<UserInfo[]>();

  const getAddressInfo = (address: string) =>
    updateUserInfos?.find(
      (userInfo) => userInfo.address.toLowerCase() === address?.toLowerCase()
    );
  const addressInfo = getAddressInfo(address ?? "");

  const getProfileGradeByRank = (rank: number, total: number) => {
    if (!rank || !total) return ProfileRankGrade.Bad;
    return rank <= total * 0.2
      ? ProfileRankGrade.Great
      : rank <= total * 0.5
      ? ProfileRankGrade.Middle
      : ProfileRankGrade.Bad;
  };

  const getPredictAllocation = (address: string) => {
    const supply = supplyList.find(
      (item) => item.address.toLowerCase() === address?.toLowerCase()
    );
    return supply?.amount ?? 0;
  };

  const getPredictAllocationRank = () => {
    const supply = supplyList.findIndex(
      (item) => item.address.toLowerCase() === address?.toLowerCase()
    );
    const rank = supply === -1 ? 0 : supply + 1;
    return {
      rank,
      grade: getProfileGradeByRank(rank, supplyList.length),
    };
  };

  const getWinRate = (addressInfo: UserInfo) => {
    if (!addressInfo || addressInfo.totalRounds === 0) return 0;
    return (
      ((addressInfo.quickRoundsWon + addressInfo.advancedRoundsWon) /
        addressInfo.totalRounds) *
      100
    );
  };

  const getWinPercentageRank = () => {
    if (!addressInfo) return { rank: 0, grade: ProfileRankGrade.Bad };
    const sortedList = updateUserInfos
      ?.slice()
      .sort((a: UserInfo, b: UserInfo) => {
        return b.totalWinPercent - a.totalWinPercent;
      });
    if (!sortedList) return { rank: 0, grade: ProfileRankGrade.Bad };
    let rank = sortedList.findIndex(
      (item) => item.address === addressInfo.address
    );
    rank = (rank ?? -1) + 1;
    return { rank, grade: getProfileGradeByRank(rank, sortedList.length) };
  };

  const getQuickWinPercentageRank = () => {
    if (!addressInfo) return { rank: 0, grade: ProfileRankGrade.Bad };
    const sortedList = updateUserInfos
      ?.slice()
      .sort((a: UserInfo, b: UserInfo) => {
        return b.quickBetsWinPercent - a.quickBetsWinPercent;
      });
    if (!sortedList) return { rank: 0, grade: ProfileRankGrade.Bad };
    let rank = sortedList.findIndex(
      (item) => item.address === addressInfo.address
    );
    rank = (rank ?? -1) + 1;
    return {
      rank,
      grade: getProfileGradeByRank(rank, sortedList.length),
    };
  };

  const getAdvancedWinPercentageRank = () => {
    if (!addressInfo) return { rank: 0, grade: ProfileRankGrade.Bad };
    const sortedList = updateUserInfos
      ?.slice()
      .sort((a: UserInfo, b: UserInfo) => {
        return b.advancedBetsWinPercent - a.advancedBetsWinPercent;
      });
    if (!sortedList) {
      return { rank: 0, grade: ProfileRankGrade.Bad };
    }
    let rank = sortedList?.findIndex(
      (item) => item.address === addressInfo.address
    );
    rank = (rank ?? -1) + 1;
    return { rank, grade: getProfileGradeByRank(rank, sortedList.length) };
  };

  const getTotalBetsRank = () => {
    if (!addressInfo) return { rank: 0, grade: ProfileRankGrade.Bad };
    const sortedList = updateUserInfos
      ?.slice()
      .sort((a: UserInfo, b: UserInfo) => {
        return b.totalBets - a.totalBets;
      });
    if (!sortedList) return { rank: 0, grade: ProfileRankGrade.Bad };
    let rank = sortedList?.findIndex(
      (item) => item.address === addressInfo.address
    );
    rank = (rank ?? -1) + 1;
    return { rank, grade: getProfileGradeByRank(rank, sortedList.length) };
  };

  const getQuickBetsWinPercent = (addressInfo: UserInfo) => {
    if (!addressInfo || addressInfo.totalQuickBets === 0) return 0;
    return (addressInfo.quickRoundsWon / addressInfo.totalQuickBets) * 100;
  };

  const getAdvancedBetsWinPercent = (addressInfo: UserInfo) => {
    if (!addressInfo || addressInfo.totalAdvancedBets === 0) return 0;
    return (
      (addressInfo.advancedRoundsWon / addressInfo.totalAdvancedBets) * 100
    );
  };

  const getProfitLossRank = () => {
    if (!addressInfo) return { rank: 0, grade: ProfileRankGrade.Bad };
    const sortedList = updateUserInfos
      ?.slice()
      .sort((a: UserInfo, b: UserInfo) => {
        return b.profitLoss - a.profitLoss;
      });
    if (!sortedList) return { rank: 0, grade: ProfileRankGrade.Bad };
    let rank = sortedList?.findIndex(
      (item) => item.address === addressInfo.address
    );
    rank = (rank ?? -1) + 1;
    return { rank, grade: getProfileGradeByRank(rank, sortedList.length) };
  };

  useEffect(() => {
    if (!updateUserInfos || updateUserInfos.length === 0) return;
    const maxTotalBets = updateUserInfos.reduce((max, obj) => {
      return obj.totalBets > max ? obj.totalBets : max;
    }, 0);
    const maxSupply = updateUserInfos.reduce((max, obj) => {
      return obj.predictAllocation > max ? obj.predictAllocation : max;
    }, 0);
    let maxProfitLoss = updateUserInfos.reduce(
      (max, obj) => {
        return obj.profitLoss > max ? obj.profitLoss : max;
      },
      updateUserInfos.length > 0 ? updateUserInfos[0].profitLoss : 0
    );
    let minProfitLoss = updateUserInfos.reduce(
      (min, obj) => {
        return obj.profitLoss < min ? obj.profitLoss : min;
      },
      updateUserInfos.length > 0 ? updateUserInfos[0].profitLoss : 0
    );
    const profitDiff = 0 - minProfitLoss;
    maxProfitLoss = maxProfitLoss - minProfitLoss;
    const sortedList = updateUserInfos
      .slice()
      .sort((a: UserInfo, b: UserInfo) => {
        const valueB =
          (getWinRate(b) / 100) * globalRankWeight.WinRate +
          (b.totalBets / maxTotalBets) * globalRankWeight.TotalBets +
          (b.predictAllocation / maxSupply) * globalRankWeight.Airdrop +
          ((b.profitLoss + profitDiff) / maxProfitLoss) *
            globalRankWeight.ProfitLoss;
        const valueA =
          (getWinRate(a) / 100) * globalRankWeight.WinRate +
          (a.totalBets / maxTotalBets) * globalRankWeight.TotalBets +
          (a.predictAllocation / maxSupply) * globalRankWeight.Airdrop +
          ((a.profitLoss + profitDiff) / maxProfitLoss) *
            globalRankWeight.ProfitLoss;
        return valueB - valueA;
      });
    const rank = sortedList.findIndex(
      (item) => item.address === addressInfo?.address
    );
    setGlobalRank(rank + 1);
  }, [updateUserInfos, dispatch]);

  useEffect(() => {
    if (isLoadingSupplyList || supplyList.length === 0 || loadingAllUserInfos) {
      return;
    }
    const updateInfos = allUserInfos.map((userInfo) => {
      const updateUserInfo = { ...userInfo };
      updateUserInfo.predictAllocation = getPredictAllocation(
        updateUserInfo.address
      );
      updateUserInfo.quickBetsWinPercent =
        getQuickBetsWinPercent(updateUserInfo);
      updateUserInfo.advancedBetsWinPercent =
        getAdvancedBetsWinPercent(updateUserInfo);
      updateUserInfo.totalWinPercent = getWinRate(updateUserInfo);
      return updateUserInfo;
    });
    setUpdateUserInfos(updateInfos);
  }, [supplyList, dispatch, isLoadingSupplyList, loadingAllUserInfos]);

  useEffect(() => {
    if (address) {
      dispatch(getOtherUserInfo(address));
    }
  }, [address, dispatch]);

  useEffect(() => {
    dispatch(getAllUserInfos());
  }, [address, dispatch]);

  useEffect(() => {
    dispatch(getSupplyList());
  }, [dispatch]);

  return (
    <Box className={classes.profileBody}>
      <Box className={classes.statistics}>
        <Box className={classes.statisticsHeader}>
          <Box
            component={"img"}
            className={classes.statisticsChartIcon}
            src="/images/prediction/barchart.png"
          />

          <Box component={"span"}>Statistics</Box>
        </Box>
        <Box className={classes.statisticsBody}>
          <Box className={classes.statisticsDetail}>
            <Box>PREDICT allocation: </Box>
            <Box className={classes.detailValue}>
              <CountUp
                end={
                  isLoadingSupplyList || loadingAllUserInfos
                    ? 0
                    : addressInfo?.predictAllocation ?? 0
                }
                duration={1}
              />
              <Box
                className={classes.detailRank}
                sx={{
                  color: getPredictAllocationRank().grade,
                }}
              >
                <CountUp
                  end={
                    isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getPredictAllocationRank().rank
                  }
                  duration={1}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.statisticsDetail}>
            <Box>Win % Quick bets: </Box>
            <Box className={classes.detailValue}>
              <CountUp
                end={
                  addressInfo
                    ? isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getQuickBetsWinPercent(addressInfo)
                    : 0
                }
                duration={1}
              />{" "}
              %
              <Box
                className={classes.detailRank}
                sx={{
                  color: getQuickWinPercentageRank().grade,
                }}
              >
                <CountUp
                  end={
                    isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getQuickWinPercentageRank().rank
                  }
                  duration={1}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.statisticsDetail}>
            <Box>Win % Advanced bets: </Box>
            <Box className={classes.detailValue}>
              <CountUp
                end={
                  addressInfo
                    ? isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getAdvancedBetsWinPercent(addressInfo)
                    : 0
                }
                duration={1}
              />
              %
              <Box
                className={classes.detailRank}
                sx={{
                  color: getAdvancedWinPercentageRank().grade,
                }}
              >
                <CountUp
                  end={
                    isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getAdvancedWinPercentageRank().rank
                  }
                  duration={1}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.middleSection}>
        <Box className={classes.address}>
          {loadingOtherUserInfo ||
          isLoadingSupplyList ||
          loadingAllUserInfos ? (
            <Skeleton width={150} height={50} animation={"wave"} />
          ) : otherUserInfo.name ? (
            otherUserInfo.name.length > 15 ? (
              shortName(otherUserInfo.name)
            ) : (
              otherUserInfo.name
            )
          ) : (
            <span>{shortAddress(otherUserInfo.address)}</span>
          )}
        </Box>

        <Box className={classes.text}>
          {account === otherUserInfo.address ? (
            <NavLink to="/edit-profile">Edit profile</NavLink>
          ) : (
            <Button className={classes.followBut}>
              <StarOutlineIcon />
              <Box>Follow user</Box>
            </Button>
          )}
        </Box>

        <Box className={classes.avatar}>
          {isLoadingSupplyList || loadingAllUserInfos ? (
            <Skeleton width={100} height={100} variant="circular" />
          ) : otherUserInfo.avatar.length > 0 ? (
            <Box
              component="img"
              width="100%"
              height="100%"
              src={otherUserInfo.avatar}
              sx={{ borderRadius: "50%", padding: "2px" }}
            ></Box>
          ) : (
            <Box
              component="img"
              width="100%"
              height="100%"
              src="/images/icons/user.png"
              sx={{ borderRadius: "50%", padding: "12px" }}
            ></Box>
          )}
        </Box>
        <Box className={classes.feedback}>{otherUserInfo.bio}</Box>
      </Box>
      <Box className={classes.rank}>
        <NavLink to={"/leaderboard"} className={classes.leaderBoard}>
          <Box
            component={"img"}
            src="/images/prediction/right-arrow(3).png"
            className={classes.leaderBoardIcon}
          />
          View leaderboard
        </NavLink>
        <Box className={classes.rankHeader}>
          <Box component={"span"}>Global Rank</Box>
          <Box className={classes.ranking}>
            <CountUp
              end={
                isLoadingSupplyList || loadingAllUserInfos ? 0 : globalRank ?? 0
              }
              duration={1}
            />
          </Box>
        </Box>
        <Box className={classes.rankBody}>
          <Box className={classes.rankDetail}>
            <Box>Win percentage: </Box>
            <Box className={clsx(classes.winFontColor, classes.detailValue)}>
              <CountUp
                end={
                  addressInfo
                    ? isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getWinRate(addressInfo)
                    : 0
                }
                duration={1}
              />
              %
              <Box
                className={classes.detailRank}
                sx={{
                  color: getWinPercentageRank().grade,
                }}
              >
                {isLoadingSupplyList || loadingAllUserInfos
                  ? 0
                  : getWinPercentageRank().rank}
              </Box>
            </Box>
          </Box>
          <Box className={classes.rankDetail}>
            <Box>Total bets: </Box>
            <Box className={classes.detailValue}>
              <CountUp
                end={
                  isLoadingSupplyList || loadingAllUserInfos
                    ? 0
                    : addressInfo?.totalBets ?? 0
                }
                duration={1}
              />
              <Box
                className={classes.detailRank}
                sx={{
                  color: getTotalBetsRank().grade,
                }}
              >
                <CountUp
                  end={
                    isLoadingSupplyList || loadingAllUserInfos
                      ? 0
                      : getTotalBetsRank().rank
                  }
                  duration={1}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.rankDetail}>
            <Box>Profit / Loss: </Box>
            <Box
              className={classes.detailValue}
              sx={{
                color: getProfitLossRank().grade,
              }}
            >
              $
              <CountUp
                end={
                  isLoadingSupplyList || loadingAllUserInfos
                    ? 0
                    : addressInfo?.profitLoss ?? 0
                }
                duration={1}
                decimals={2}
              />
              <Box className={classes.detailRank}>
                <CountUp end={getProfitLossRank().rank} duration={1} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
