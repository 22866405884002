import { useState, useEffect } from "react";
import { Box } from "@mui/material";

import TextContent from "../../components/TextContent";
import EventCard from "../../components/EventCard";
import PageLoadingMov from "../../components/PageLoadingMov";

import { pageLoadingTime } from "../../constants";
import RecentlyRoundCards from "../../components/RecentlyRoundCards";
interface Events {
  name?: string;
  matchDay: string;
  matchDayFormat?: string;
  status: string;
  logoName: string;
  url?: string;
  matchDayTimeStamp?: number;
}

const Home = () => {
  const events: Events[] = [
    {
      matchDay: "November 5th 2024",
      matchDayFormat: "2024-11-05",
      status: "Upcoming",
      logoName: "us-election-white.png",
      url: "election",
    },
    {
      matchDay: "November 15th 2024",
      matchDayFormat: "2024-11-15",
      status: "Upcoming",
      logoName: "event-tyson.png",
    },
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, pageLoadingTime);
    return () => clearTimeout(timeoutId);
  });

  return loading ? (
    <PageLoadingMov />
  ) : (
    <Box
      sx={{
        display: "flex",
        paddingTop: "20px",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
        gap: "60px",
        paddingBottom: "30px",
      }}
    >
      <TextContent />
      <Box
        sx={{
          display: events.length >= 3 ? "grid" : "flex",
          justifyItems: "center",
          gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
          justifyContent: "center",
          gap: "30px",
          width: "60%",
          flexDirection: {
            xs: "column", // Mobile devices (screens smaller than 600px)
            sm: "row", // Resets to row for screens larger than 600px
          },
        }}
      >
        {events.map((eve: Events, index) => (
          <EventCard
            key={index}
            matchDay={eve.matchDay}
            status={eve.status}
            image={eve.logoName}
            url={eve.url}
            matchDayFormat={eve.matchDayFormat}
            matchDayTimeStamp={eve.matchDayTimeStamp}
            sx={{
              minWidth: "280px",
              maxWidth: "280px",
            }}
          />
        ))}
      </Box>

      <RecentlyRoundCards />
    </Box>
  );
};

export default Home;
