import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  menuBtn: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    marginRight: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "right",
    },
    "& img": {
      marginRight: "10px",
    },
    "& p": {
      fontSize: "0.75rem",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "2rem",
    marginLeft: "20px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
  },
  back: {
    fontWeight: 600,
  },
}));

export default useStyles;
