import { Box } from "@mui/material";
import { CSSProperties } from "react";

interface CircularLogoProps {
  width: number;
  sx?: CSSProperties;
}
export default function CircularLogo(props: CircularLogoProps) {
  return (
    <Box
      sx={{
        width: props.width + 7 + "px",
        height: props.width + 7 + "px",
        borderRadius: "50%",
        backgroundColor: "#13151e",
        border: "3px solid #1c1f28",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props?.sx,
      }}
    >
      <Box
        component="img"
        src="/images/logo.png"
        sx={{
          width: props.width + "px",
        }}
      />
    </Box>
  );
}
