import { Box } from "@mui/material";
import millify from "millify";

import { useAppSelector } from "../../app/hooks";
import { leaderBoardReward } from "../../constants/euro24";
import { EuroLeaderBoardItem } from "../../types/EuroLeaderBoardItem";
import { shortName } from "../../utils";
import useStyles from "./index.styles";

export default function EuroLeaderBoardPanel() {
  const { classes } = useStyles();
  const { leaderboard } = useAppSelector((state) => state.euro24);

  return (
    <Box className={classes.euroMyBetPanel}>
      <Box className={classes.header}>Leaderboard</Box>

      <Box className={classes.betList}>
        {leaderboard
          .slice(0, 10)
          .map((item: EuroLeaderBoardItem, index: number) => {
            return (
              <Box className={classes.betListItem} key={index}>
                <Box>{index + 1}.</Box>
                <Box className={classes.address}>{shortName(item.player)}</Box>
                <Box>{item.count} wins</Box>
                <Box
                  className={
                    item.winAmount > 0 ? classes.resultWin : classes.resultLose
                  }
                >
                  {item.winAmount >= 0 ? "+" : "-"}$
                  {Math.abs(item.winAmount).toFixed(0)}
                </Box>
                <Box className={classes.reward}>
                  {millify(leaderBoardReward[index])}
                  <Box component={"img"} src={"/images/logo.png"} width={20} />
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
