import { Box, Skeleton } from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  DAZNFightRoundInfo,
  ELECTIONRoundInfo,
  FCEventType,
  IFCFightRoundInfos,
  UFC299FightRoundInfos,
  UFC300FightRoundInfos,
} from "../../constants/ufc";
import { setCurrentRound } from "../../reducers/ufc.slice";
import { toUSDFormat } from "../../utils";

import useStyles from "./index.styles";

interface UFCRoundSwitchProps {
  eventType: number;
}

const FIGHT_COUNT = 5;

export default function UFCRoundSwitch(props: UFCRoundSwitchProps) {
  const { classes } = useStyles();
  const { eventType } = props;
  const dispatch = useAppDispatch();
  const { currentRound, roundInfos } = useAppSelector((state) => state.ufc);

  const fightCount = useMemo(() => {
    switch (eventType) {
      case FCEventType.DAZN:
        return DAZNFightRoundInfo.length;

      case FCEventType.ELECTION:
        return ELECTIONRoundInfo.length;

      default:
        return FIGHT_COUNT;
    }
  }, [eventType]);

  const handleSwitchRound = (index: number) => {
    dispatch(setCurrentRound(index));
  };

  const renderRoundSwitchTab = (
    round: any,
    newIndex: number,
    index: number
  ) => {
    return (
      <Box
        className={
          classes.nightFightRound +
          (index === currentRound ? ` ${classes.selected}` : "")
        }
        key={index}
        onClick={() => {
          handleSwitchRound(index);
        }}
      >
        {!roundInfos || roundInfos.length === 0 ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ bgcolor: "#ffffff1c" }}
            width={44}
            height={18}
            className={classes.nightFightRoundPool}
          />
        ) : (
          <Box component="span" className={classes.nightFightRoundPool}>
            ${toUSDFormat(roundInfos[newIndex].totalUsd)}
          </Box>
        )}

        <Box
          component="span"
          className={
            classes.nightFightRoundTitle +
            (index === currentRound ? " selected" : "")
          }
        >
          Fight {fightCount - index}
        </Box>
        <Box component="span">
          {round.player1.name} vs {round.player2.name}
        </Box>
        {!roundInfos || roundInfos.length === 0 ? (
          <Box className="nightFightRoundDetails">
            <Box className={classes.nightFightRoundMultipliers}>
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ bgcolor: "#ffffff1c" }}
                width={24}
                height={18}
                className={
                  `${classes.multiplier} ${classes.player1}` +
                  (index === currentRound ? " selected" : "")
                }
              />

              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ bgcolor: "#ffffff1c" }}
                width={24}
                height={18}
                className={
                  `${classes.multiplier} ${classes.player2}` +
                  (index === currentRound ? " selected" : "")
                }
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.nightFightRoundDetail}>
            <Box className={classes.nightFightRoundMultipliers}>
              <Box className={`${classes.multiplier} ${classes.player1}`}>
                {toUSDFormat(
                  roundInfos[newIndex].player2TotalUsd /
                    roundInfos[newIndex].player1TotalUsd +
                    1,
                  2
                )}
              </Box>
              <Box className={`${classes.multiplier} ${classes.player2}`}>
                {toUSDFormat(
                  roundInfos[newIndex].player1TotalUsd /
                    roundInfos[newIndex].player2TotalUsd +
                    1,
                  2
                )}
              </Box>
            </Box>
          </Box>
        )}

        {index === currentRound && (
          <Box className={classes.selectedIndicator}>
            <img src="/images/icons/indicator.png" alt="" srcSet="" />
          </Box>
        )}
      </Box>
    );
  };

  const renderRoundSwitch = () => {
    switch (eventType) {
      case FCEventType.UFC299:
        return UFC299FightRoundInfos.map((round, index) => {
          const newIndex = 4 - index;
          return renderRoundSwitchTab(round, newIndex, index);
        });
      case FCEventType.UFC300:
        return UFC300FightRoundInfos.map((round, index) => {
          const newIndex = 4 - index;
          return renderRoundSwitchTab(round, newIndex, index);
        });
      case FCEventType.IFC:
        return IFCFightRoundInfos.map((round, index) => {
          return renderRoundSwitchTab(round, index, index);
        });
      case FCEventType.DAZN:
        return DAZNFightRoundInfo.map((round, index) => {
          const newIndex = 2 - index;
          return renderRoundSwitchTab(round, newIndex, index);
        });

      case FCEventType.ELECTION:
        return ELECTIONRoundInfo.map((round, index) => {
          return renderRoundSwitchTab(round, index, index);
        });

      default:
        break;
    }
  };

  return (
    <Box
      className={clsx(
        classes.UFCRoundSwitch,
        eventType === FCEventType.DAZN
          ? classes.UFCRoundSwitchDAZN
          : eventType === FCEventType.ELECTION
          ? classes.UFCRoundSwitchElection
          : ""
      )}
    >
      {renderRoundSwitch()}
    </Box>
  );
}
