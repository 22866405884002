import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Slider, TextField } from "@mui/material";
import { useConnectWallet } from "@web3-onboard/react";
import clsx from "clsx";
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PredictCreateRightPart from "../../components/PredictCreateRightPart";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { gameConfig } from "../../config";
import { PredictionEventName } from "../../constants/prediction";
import { readLastCreatedRoundId } from "../../helpers/contractFunctions/predict";
import {
  createPredict,
  getBetTokens,
  getTokenBalances,
  initRoundInfo,
  saveCustomComment,
  setBetUSDAmount,
  setCreatingStatus,
  setOdd,
  setOperator,
} from "../../reducers/predict.slice";
import { Toast, ceilDecimal, toUSDFormat } from "../../utils";
import TokenTakerDialog from "../TokenTakerDialog";
import { useStyles } from "./index.styles";

const PredictCreateConfirmComp = () => {
  const { classes } = useStyles();
  const { newRoundInfo, betTokens, tokenBalances, creatingRound } =
    useAppSelector((state) => state.predict);
  const { operator, initialBetAmount, yesNo, odd } = newRoundInfo;
  const dispatch = useAppDispatch();
  const [betAmount, setBetAmount] = useState(0);
  const [curAmountUnder, setCurAmountUnder] = useState(0);
  const [curAmountOver, setCurAmountOver] = useState(0);
  const [multiplierUnder, setMultiplierUnder] = useState(0);
  const [multiplierOver, setMultiplierOver] = useState(0);
  const [open, setOpen] = useState(false);
  const lastRoundId = useRef(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editorOddValue, setEditorOddValue] = useState("");

  const [{ wallet }] = useConnectWallet();
  const account = wallet ? wallet.accounts[0].address : "";
  const navigate = useNavigate();

  const handleOperator = (value: number) => {
    dispatch(setOperator(value));
  };

  const handlePossibility = (value: number | number[]) => {
    if (typeof value === "number") {
      dispatch(setOdd(value));
    }
  };

  const handleBetAmount = (value: string) => {
    const amount = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    setBetAmount(amount);
    if (!initialBetAmount) return;
    if (operator === 1) {
      dispatch(setOdd(((initialBetAmount - amount) / initialBetAmount) * 1000));
    } else if (operator === 0) {
      dispatch(setOdd((amount / initialBetAmount) * 1000));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validation = () => {
    if (odd && initialBetAmount) {
      if (odd < 40) {
        dispatch(setOdd(40));
        if (operator === 1) {
          setBetAmount(ceilDecimal((initialBetAmount * (1000 - 40)) / 1000, 2));
        } else if (operator === 0) {
          setBetAmount(ceilDecimal((initialBetAmount * 40) / 1000, 2));
        }
      } else if (odd > 960) {
        dispatch(setOdd(960));
        if (operator === 1) {
          setBetAmount(
            ceilDecimal((initialBetAmount * (1000 - 960)) / 1000, 2)
          );
        } else if (operator === 0) {
          setBetAmount(ceilDecimal((initialBetAmount * 960) / 1000, 2));
        }
      }
    }
  };

  const handleEnterRound = (tokenId: number, tokenAmount: number) => {
    dispatch(
      createPredict({
        account,
        tokenId,
        tokenAmount,
        isQuickBet: false,
        duration: 0,
      })
    );
  };

  const handleEditorIcon = () => {
    setIsEditing(true);
    if (operator === 0) {
      setEditorOddValue(multiplierOver.toString());
    } else if (operator === 1) {
      setEditorOddValue(multiplierUnder.toString());
    }
  };

  const handleConfirmEdit = () => {
    setIsEditing(false);
    if (isNaN(Number(editorOddValue))) return;
    if (operator === 0) {
      dispatch(setOdd(1000 / Number(editorOddValue)));
    } else if (operator === 1) {
      dispatch(setOdd(1000 - 1000 / Number(editorOddValue)));
    }
  };

  const handleConfirmEditBefore = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleConfirmEdit();
    }
  };

  const handleChangeOddValue = (e: ChangeEvent<HTMLInputElement>) => {
    setEditorOddValue(e.target.value);
  };

  useEffect(() => {
    if (!initialBetAmount || !odd) return;
    dispatch(setOdd(1000 - odd));
  }, [operator, dispatch]);

  useEffect(() => {
    if (odd && initialBetAmount) {
      setIsEditing(false);
      setCurAmountUnder(
        ceilDecimal((initialBetAmount * (1000 - odd)) / 1000, 2)
      );
      setCurAmountOver(ceilDecimal((initialBetAmount * odd) / 1000, 2));
      setMultiplierUnder(ceilDecimal(1000 / (1000 - odd), 2));
      setMultiplierOver(ceilDecimal(1000 / odd, 2));
      if (operator === 1) {
        setBetAmount(ceilDecimal((initialBetAmount * (1000 - odd)) / 1000, 2));
      } else if (operator === 0) {
        setBetAmount(ceilDecimal((initialBetAmount * odd) / 1000, 2));
      }
    }
  }, [odd, initialBetAmount, dispatch, operator]);

  useEffect(() => {
    dispatch(getBetTokens());
    dispatch(setOperator(0));
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      dispatch(getTokenBalances(account));
    }
  }, [dispatch, account]);

  useEffect(() => {
    if (betAmount) {
      dispatch(setBetUSDAmount(betAmount));
    }
  }, [betAmount, dispatch]);

  useEffect(() => {
    const gotoDetailPage = async () => {
      Toast.fire({
        icon: "success",
        title: "PricePredict Room created.",
      });
      const latestRoundId = await readLastCreatedRoundId(account);
      lastRoundId.current = latestRoundId;
      dispatch(setCreatingStatus(false));
      dispatch(
        saveCustomComment({
          roundId: latestRoundId,
          isCustomComment: newRoundInfo.isCustomComment ?? false,
          customComment: newRoundInfo.customComment ?? "",
        })
      )
        .unwrap()
        .then(() => {
          dispatch(initRoundInfo());
          navigate(`/predictions/detail/${latestRoundId}`);
        });
    };

    const setupEventSource = () => {
      const eventSource = new EventSource(
        `${gameConfig.serverUrl}event/prediction`
      );
      eventSource.onmessage = async (e) => {
        if (e.data.includes("eventName")) {
          const res = JSON.parse(e.data);
          if (
            res.eventName === PredictionEventName.BetPlaced &&
            account === res?.result.returnValues?.player.toLowerCase()
          ) {
            gotoDetailPage();
          }
        }
      };
      return eventSource;
    };
    const eventSource = setupEventSource();

    return () => {
      eventSource.close();
    };
  }, [account, newRoundInfo, dispatch, navigate]);

  return (
    <Box className={classes.body}>
      <Box className={classes.oddBoxes}>
        <Box className={classes.oddUnderBox}>
          <Box className={classes.selectedIcon}>
            {operator === 1 ? (
              <>
                <Box component={"img"} src="/images/prediction/check1.png" />
                <Box>You Selected</Box>
              </>
            ) : null}
          </Box>
          <Box
            className={clsx(
              classes.mainOddBox,
              operator === 1 ? classes.selectOddBox : null
            )}
          >
            <Box className={classes.selectArrow}>
              {operator === 1 ? (
                <Box
                  component={"img"}
                  src="/images/prediction/down-arrow.png"
                />
              ) : null}
            </Box>
            <Box
              className={clsx(classes.oddPart, classes.mainUnderOddPart)}
              onClick={() => handleOperator(1)}
            >
              <Box>ODDS</Box>
              <Box className={classes.oddValue}>
                {operator === 1 && isEditing ? (
                  <TextField
                    className={classes.oddEditor}
                    value={editorOddValue}
                    onChange={handleChangeOddValue}
                    onKeyDown={handleConfirmEditBefore}
                  />
                ) : (
                  toUSDFormat(multiplierUnder)
                )}
                {operator === 1 && !isEditing ? (
                  <EditIcon
                    className={classes.editIcon}
                    onClick={handleEditorIcon}
                  />
                ) : operator === 1 && isEditing ? (
                  <CheckIcon
                    className={classes.editIcon}
                    onClick={handleConfirmEdit}
                  />
                ) : null}
              </Box>
              <Box>${toUSDFormat(curAmountUnder)}</Box>
            </Box>
            <Box className={classes.oddText}>UNDER</Box>
          </Box>
        </Box>
        <Box className={classes.oddSliderPart}>
          Drag odds slider
          <Slider
            value={odd}
            max={960}
            min={40}
            color="secondary"
            className={classes.oddSlider}
            onChange={(e, value) => handlePossibility(value)}
          />
        </Box>
        <Box className={classes.oddUpBox}>
          <Box className={classes.selectedIcon}>
            {operator === 0 ? (
              <>
                <Box component={"img"} src="/images/prediction/check1.png" />
                <Box>You Selected</Box>
              </>
            ) : null}
          </Box>
          <Box
            className={clsx(
              classes.mainOddBox,
              operator === 0 ? classes.selectOddBox : null
            )}
          >
            <Box className={classes.selectArrow}>
              {operator === 0 ? (
                <Box
                  component={"img"}
                  src="/images/prediction/down-arrow.png"
                />
              ) : null}
            </Box>
            <Box
              className={clsx(classes.oddPart, classes.mainOverOddPart)}
              onClick={() => handleOperator(0)}
            >
              <Box>ODDS</Box>
              <Box className={classes.oddValue}>
                {operator === 0 && isEditing ? (
                  <TextField
                    className={classes.oddEditor}
                    value={editorOddValue}
                    onChange={handleChangeOddValue}
                    onKeyDown={handleConfirmEditBefore}
                  />
                ) : (
                  toUSDFormat(multiplierOver)
                )}
                {operator === 0 && !isEditing ? (
                  <EditIcon
                    className={classes.editIcon}
                    onClick={handleEditorIcon}
                  />
                ) : operator === 0 && isEditing ? (
                  <CheckIcon
                    className={classes.editIcon}
                    onClick={handleConfirmEdit}
                  />
                ) : null}
              </Box>
              <Box>${toUSDFormat(curAmountOver)}</Box>
            </Box>
            <Box className={classes.oddText}>OVER</Box>
          </Box>
        </Box>
      </Box>
      <PredictCreateRightPart mobile={false} />
      <Box className={classes.takerBox}>
        <Box className={classes.enterInputBox}>
          $
          <TextField
            className={classes.enterInput}
            value={betAmount !== 0 ? betAmount : ""}
            onChange={(e) => handleBetAmount(e.target.value)}
          />
        </Box>
        <Button
          className={classes.tokenTakerPart}
          onClick={() => {
            if (account) {
              validation();
              setOpen(true);
            } else {
              Toast.fire({
                icon: "info",
                title: "Please connect wallet",
              });
            }
          }}
        >
          Create Predict Call
        </Button>
        <TokenTakerDialog
          betTokens={betTokens}
          tokenBalances={tokenBalances}
          usdAmount={betAmount}
          open={open}
          processing={creatingRound}
          onClose={handleClose}
          onAccept={handleEnterRound}
        />
      </Box>
    </Box>
  );
};

export default PredictCreateConfirmComp;
