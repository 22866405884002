import React from "react";
import { Box } from "@mui/material";

import CustomDropdown from "../CustomDropdown";

import useStyles from "./index.styles";
import { DexFilterValues, TokenFilterValues } from "../../constants/prediction";

interface TokenFiltersProps {
  sort: number;
  dex: number;
  handleSort: (value: number) => void;
  handleDex: (value: number) => void;
}

export default function TokenFilters(props: TokenFiltersProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.filtersBody}>
      <Box className={classes.filtersText}>Filters</Box>
      <Box className={classes.dropdownGroups}>
        <Box className={classes.dropdownRow}>
          <Box className={classes.dropdownLabel}>Sort by</Box>
          <CustomDropdown
            items={TokenFilterValues}
            value={props.sort}
            setValue={props.handleSort}
          />
        </Box>
        <Box className={classes.dropdownRow}>
          <Box className={classes.dropdownLabel}>Dexes</Box>
          <CustomDropdown
            items={DexFilterValues}
            value={props.dex}
            setValue={props.handleDex}
          />
        </Box>
      </Box>
    </Box>
  );
}
