import React, { useEffect, useRef, useState } from "react";
import { LastPriceAnimationMode, createChart } from "lightweight-charts";
import { Box, Skeleton } from "@mui/material";
import { digitsLength } from "../../utils";
import type { Time } from "lightweight-charts";
import { useParams } from "react-router-dom";
import { PartialPriceLine } from "./partialPriceLine";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  initDetailData,
  initDetailRoundInfo,
  setDetailTokenPrice,
} from "../../reducers/predict.slice";

interface PredictPriceChartProps {
  readonly roundInfo: any;
  readonly setFakePrice: (price: number) => void;
}

type LineData = {
  time: Time;
  value: number;
};

export default function PredictPriceChart(props: PredictPriceChartProps) {
  const {
    tokens,
    detailTokenPrice,
    loadingMetaInfo,
    loadingRoundInfo,
    roundInfo,
  } = useAppSelector((state) => state.predict);
  const tokensRef = useRef(tokens);
  const lineSeriesRef = useRef<any>(null);
  const detailPriceRef = useRef<number>(0);
  const realPriceRef = useRef<number>(0);
  const areaSeriesRef = useRef<any>(null);
  const [isDetailPrice, setIsDetailPrice] = useState(false);
  const mainSeriesRef = useRef<any>(null);
  const { id } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initDetailData());
    dispatch(initDetailRoundInfo());
  }, [id]);
  useEffect(() => {
    setIsDetailPrice(false);
    lineSeriesRef.current = null;
    dispatch(initDetailData());
    detailPriceRef.current = 0;

    return () => {
      dispatch(initDetailData());
    };
  }, []);

  useEffect(() => {
    if (detailTokenPrice) {
      detailPriceRef.current = detailTokenPrice;
      realPriceRef.current = detailTokenPrice;
      setIsDetailPrice(true);
    }
  }, [detailTokenPrice]);

  useEffect(() => {
    tokensRef.current = tokens;
  }, [tokens]);

  useEffect(() => {
    if (!isDetailPrice || loadingMetaInfo || loadingRoundInfo) return;

    const newChartElement = document.createElement("div");
    newChartElement.style.width = "100%";
    newChartElement.style.height = "100%";
    newChartElement.style.minHeight = "300px";

    const chartContainer = document.getElementById("chartContainer");
    if (chartContainer) {
      chartContainer.appendChild(newChartElement);
    } else return;

    const chart = createChart(newChartElement, {
      layout: {
        background: { color: "#0d131c" },
        textColor: "#DDD",
      },
      grid: {
        vertLines: { color: "#44444475" },
        horzLines: { color: "#44444475" },
      },
      timeScale: {
        secondsVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          const date = new Date(parseInt(time) * 1000);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const formattedTime = `${hours}:${minutes}:${seconds}`;
          return formattedTime.toString();
        },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.4,
          bottom: 0.4,
        },
        
      },
    });

    const mainSeries = chart.addCandlestickSeries();
    mainSeries.applyOptions({
      wickUpColor: "red",
      upColor: "red",
      wickDownColor: "red",
      downColor: "red",
      borderVisible: false,
      priceLineWidth: 2,
      priceFormat: {
        type: "price",
        precision: digitsLength(detailPriceRef.current) > 3 ? 0 : 10,
        minMove: digitsLength(detailPriceRef.current) > 3 ? 1 : 0.0000000001,
      },
    });
    mainSeries.setData([
      {
        time: (new Date().getTime() / 1000 - (100 - 1)) as Time,
        open: Number(roundInfo?.pricePoint), // Ensure open, high, low, and close are defined
        high: Number(roundInfo?.pricePoint),
        low: Number(roundInfo?.pricePoint),
        close: Number(roundInfo?.pricePoint),
      },
    ]);
    mainSeriesRef.current = mainSeries;

    // const subLineSeries = chart.addLineSeries({
    //   color: "transparent",
    //   lineWidth: 1,
    //   lineType: 2,
    //   priceLineVisible: false,
    //   lastValueVisible: false,
    // });
    // const data1: LineData[] = [
    //   {
    //     time: (new Date().getTime() / 1000 - 102) as Time,
    //     value: detailPriceRef.current - detailPriceRef.current / 50,
    //   },
    //   {
    //     time: (new Date().getTime() / 1000 - 101) as Time,
    //     value: detailPriceRef.current + detailPriceRef.current / 50,
    //   },
    // ];
    // subLineSeries.setData(data1);

    const lineSeries = chart.addLineSeries({
      color: "#e0a119",
      lastPriceAnimation: LastPriceAnimationMode.Continuous,
      lineWidth: 3,
      lineType: 2,
    });
    const data: LineData[] = [];
    for (let i = 0; i < 20; i++) {
      const randData = {
        time: (new Date().getTime() / 1000 - (100 - i)) as Time,
        value:
          i % 30 === 0
            ? detailPriceRef.current
            : detailPriceRef.current +
              ((-1) ** Math.floor(100 * Math.random()) *
                detailPriceRef.current *
                Math.random()) /
                500,
      };
      data.push(randData);
    }
    lineSeries.applyOptions({
      priceFormat: {
        type: "price",
        precision: digitsLength(detailPriceRef.current) > 3 ? 0 : 10,
        minMove: digitsLength(detailPriceRef.current) > 3 ? 1 : 0.0000000001,
      },
    });
    lineSeries.setData(data);

    const areaSeries = chart.addAreaSeries({
      lineColor: "#e0a119",
      priceLineVisible: false,
      lineWidth: 1,
      lastValueVisible: false,
    });
    areaSeries.setData(data);
    areaSeriesRef.current = areaSeries;

    const pos = chart.timeScale().scrollPosition();
    chart.timeScale().scrollToPosition(pos + 10, false);

    lineSeriesRef.current = lineSeries;

    return () => {
      dispatch(setDetailTokenPrice(0));
      dispatch(initDetailData());
      // setTimeout(() => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
      // }, 50)
      setIsDetailPrice(false);
    };
  }, [isDetailPrice, loadingMetaInfo, loadingRoundInfo]);

  useEffect(() => {
    if (loadingMetaInfo || loadingRoundInfo) return;
    if (!lineSeriesRef.current) {
      return;
    }
    const elements = document.getElementsByClassName(
      "tv-lightweight-charts"
    ) as HTMLCollectionOf<HTMLElement>;
    if (elements.length > 1) {
      for (const element of elements) {
        element.remove();
      }
    }

    lineSeriesRef.current.attachPrimitive(new PartialPriceLine());

    // Simulate real-time data
    function* getNextRealtimeUpdate(realtimeData: LineData[]) {
      for (const dataPoint of realtimeData) {
        yield dataPoint;
      }
      return null;
    }
    const intervalID = window.setInterval(() => {
      let rand =
        detailPriceRef.current +
        ((-1) ** Math.floor(100 * Math.random()) *
          detailPriceRef.current *
          Math.random()) /
          500;
      // if (
      //   (detailPriceRef.current > props.roundInfo.pricePoint &&
      //     rand < props.roundInfo.pricePoint) ||
      //   (detailPriceRef.current < props.roundInfo.pricePoint &&
      //     rand > props.roundInfo.pricePoint) ||
      //   (detailPriceRef.current === props.roundInfo.pricePoint &&
      //     ((props.roundInfo.operator === 1 &&
      //       rand > props.roundInfo.pricePoint) ||
      //       (props.roundInfo.operator === 0 &&
      //         rand < props.roundInfo.pricePoint)))
      // ) {
      //   rand = detailPriceRef.current;
      // }
      props.setFakePrice(rand);
      const realtimeUpdates: LineData[] = [
        {
          time: (new Date().getTime() / 1000) as Time,
          value: rand,
        },
      ];
      detailPriceRef.current = realtimeUpdates[0].value;
      if (!realtimeUpdates) {
        return;
      }
      const streamingDataProvider = getNextRealtimeUpdate(realtimeUpdates);
      const update = streamingDataProvider.next();
      lineSeriesRef.current.update(update.value);
      areaSeriesRef.current?.update(update.value);

      mainSeriesRef.current.setData([
        {
          time: (new Date().getTime() / 1000) as Time,
          open: Number(roundInfo?.pricePoint),
          high: Number(roundInfo?.pricePoint),
          low: Number(roundInfo?.pricePoint),
          close: Number(roundInfo?.pricePoint),
        },
      ]);
    }, 400);

    return () => {
      window.clearInterval(intervalID);
      return;
    };
  }, [tokens, detailTokenPrice, loadingMetaInfo, loadingRoundInfo]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
        id={"chartContainer"}
      >
        {loadingMetaInfo || loadingRoundInfo ? (
          <Skeleton
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
