import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./index.styles";
import { useAppSelector } from "../../app/hooks";
import {
  uefa24PairStatus,
  uefa24Play,
  uefa24RoundStatus,
  uefa24Status,
} from "../../constants/uefa24";

import { Entry, Uefa24Card } from "../../reducers/uefa24.slice";

interface UefaTeamCardProps {
  readonly sx?: object;
  readonly cardInfo: Uefa24Card;
  readonly pickClick: (cardInfo: Uefa24Card) => void;
  readonly closeClick: (cardInfo: Uefa24Card) => void;
}

export default function UefaTeamCard(props: UefaTeamCardProps) {
  const { classes } = useStyles();
  const { roundStatus, championId, entries } = useAppSelector(
    (state) => state.uefa24
  );

  const getPercent = () => {
    if (!props.cardInfo) return 0;
    let matchIndex = Math.floor(props.cardInfo.id / 2);
    if (matchIndex === 7) matchIndex = 6;
    const totalMatch = entries.length;
    const totWinnerCount = entries.filter((entry: Entry) => {
      return parseInt(entry.predictions[matchIndex]) === props.cardInfo.playerId;
    }).length;
    if (totalMatch === 0) {
      return 0;
    } else {
      return ((totWinnerCount / totalMatch) * 100).toFixed(0);
    }
  };

  return (
    <Box
      className={
        classes.body +
        " " +
        (props.cardInfo && props.cardInfo.side === 2 ? classes.rightSide : "") +
        " " +
        (roundStatus >= uefa24RoundStatus.Started
          ? !props.cardInfo ||
            (props.cardInfo &&
              (props.cardInfo.status === uefa24Status.WAITING ||
                props.cardInfo.status === uefa24Status.LOSER))
            ? classes.nonActiveCard
            : ""
          : "") +
        " " +
        (props.cardInfo &&
        props.cardInfo.status !== uefa24Status.WAITING &&
        props.cardInfo.playerId === championId
          ? classes.champion
          : "") +
        " " +
        (props.cardInfo &&
        roundStatus >= uefa24RoundStatus.Closed &&
        props.cardInfo.gameResult !== 0 &&
        props.cardInfo.gameResult === props.cardInfo.playerId &&
        props.cardInfo.isShowView
          ? classes.correct
          : "")
      }
      sx={{
        ...props.sx,
      }}
    >
      <Box
        className={classes.football + " football" + props.cardInfo?.id}
        component={"img"}
        src={"/images/events/uefa/football.png"}
      />
      <Box
        className={classes.leftPart}
        onClick={
          window.matchMedia("(max-width: 768px)").matches &&
          props.cardInfo &&
          props.cardInfo.status === uefa24Status.ENABLE &&
          props.cardInfo.pairStatus === uefa24PairStatus.PICKABLE
            ? () => props.pickClick && props.pickClick(props.cardInfo)
            : () => {}
        }
      >
        {props.cardInfo &&
        props.cardInfo.status > uefa24Status.WAITING &&
        props.cardInfo.gameGrade === uefa24Play.WINNER ? (
          <Box component={"img"} src="/images/icons/medal.png" />
        ) : roundStatus === uefa24RoundStatus.Started &&
          props.cardInfo &&
          props.cardInfo.status === uefa24Status.ENABLE &&
          props.cardInfo.pairStatus === uefa24PairStatus.PICKABLE ? (
          <Box
            className={classes.pickBut}
            onClick={() => props.pickClick && props.pickClick(props.cardInfo)}
          >
            PICK
          </Box>
        ) : (roundStatus === uefa24RoundStatus.Started &&
            props.cardInfo &&
            props.cardInfo.status === uefa24Status.WINNER) ||
          (props.cardInfo &&
            roundStatus >= uefa24RoundStatus.Closed &&
            props.cardInfo.gameResult !== 0 &&
            props.cardInfo.gameResult === props.cardInfo.playerId &&
            props.cardInfo.isShowView) ? (
          <Box component={"img"} src="/images/icons/check_increase.png" />
        ) : (
          ""
        )}
      </Box>
      <Box
        className={classes.middlePart}
        onClick={
          window.matchMedia("(max-width: 768px)").matches &&
          props.cardInfo &&
          props.cardInfo.status === uefa24Status.ENABLE &&
          props.cardInfo.pairStatus === uefa24PairStatus.PICKABLE
            ? () => props.pickClick && props.pickClick(props.cardInfo)
            : () => {}
        }
      >
        {props.cardInfo && props.cardInfo.status > uefa24Status.WAITING ? (
          <Box className={classes.namePart}>
            <Box
              component={"img"}
              src={
                "/images/events/uefa/" +
                (props.cardInfo ? props.cardInfo.url : "")
              }
            />{" "}
            {props.cardInfo.name}
          </Box>
        ) : null}
        {props.cardInfo ? (
          props.cardInfo.status === uefa24Status.WAITING ? (
            props.cardInfo.gameGrade === uefa24Play.SEMI ? (
              props.cardInfo.id % 2 ? (
                <Box className={classes.emptyName}>Waiting</Box>
              ) : (
                <Box className={classes.emptyName}>Semi</Box>
              )
            ) : props.cardInfo.gameGrade === uefa24Play.FINAL ? (
              props.cardInfo.id % 2 ? (
                <Box className={classes.emptyName}>Waiting</Box>
              ) : (
                <Box className={classes.emptyName}>Final</Box>
              )
            ) : (
              <Box className={classes.emptyNameWinner}>Winner</Box>
            )
          ) : (
            ""
          )
        ) : (
          "null"
        )}
      </Box>
      <Box
        className={classes.rightPart}
        onClick={
          window.matchMedia("(max-width: 768px)").matches &&
          props.cardInfo &&
          props.cardInfo.status === uefa24Status.ENABLE &&
          props.cardInfo.pairStatus === uefa24PairStatus.PICKABLE
            ? () => props.pickClick && props.pickClick(props.cardInfo)
            : () => {}
        }
      >
        {props.cardInfo &&
        roundStatus >= uefa24RoundStatus.Closed &&
        props.cardInfo.gameResult !== 0 &&
        props.cardInfo.gameResult === props.cardInfo.playerId &&
        props.cardInfo.isShowView ? (
          <Box className={classes.upCount}>+1</Box>
        ) : props.cardInfo && props.cardInfo.status !== uefa24Status.WAITING ? (
          <Box>{getPercent()}%</Box>
        ) : null}
      </Box>
      {props.cardInfo && props.cardInfo.latestStatus ? (
        <CloseIcon
          className={
            classes.closeBut +
            (props.cardInfo && props.cardInfo.side === 2 ? " right" : "")
          }
          onClick={(e) => {
            e.preventDefault();
            props.closeClick && props.closeClick(props.cardInfo);
          }}
        />
      ) : null}
    </Box>
  );
}
